import React, { JSX, FC } from "react";

import AuthContainer from "../../components/Authentication/AuthContainer/AuthContainer";
import NewPasswordForm from "../../components/Authentication/NewPasswordForm/NewPasswordForm";

const NewPasswordPage: FC = (): JSX.Element => {
  return <AuthContainer authForm={<NewPasswordForm />} />;
};

export default NewPasswordPage;
