import {Spin} from "antd";
import React, { JSX, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { fetchDashboard } from "../../redux/thunks/orders.thunks";
import { useSelector } from "react-redux";
import { InitialAccountStateType } from "../../redux/reducers/account.reducer";
import { AppStateType } from "../../redux/reducers/mainReducer";
import { Link } from "react-router-dom";

import DashboardHeader from "../../components/Dashboard/DashboardHeader/DashboardHeader";
import DashboardAnalytics from "../../components/Dashboard/DashboardAnalytics/DashboardAnalytics";
import DashboardProducts from "../../components/Dashboard/DashboardProducts/DashboardProducts";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import PrimaryButton from "../../components/ui/PrimaryButton/PrimaryButton";
import { ReactComponent as ShopIcon } from "../../assets/icons/shop.svg";

interface IDashboardProps {
}

const DashboardPage: FC<IDashboardProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const account: InitialAccountStateType = useSelector((state: AppStateType) => state.account);

  // период меняется в графике
  const [period, setPeriod] = useState<"week" | "month" | "year">("week");
  const [topMode, setTopMode] = useState<"revenue" | "quantity">("quantity");

  const hasApiKey: boolean = !!account.userInfo?.company?.wb_api_key;

  useEffect(() => {
    dispatch(fetchDashboard({
      top: topMode,
      period: period
    }));
  }, []);

  const renderConnectButton = () => {
    return (
      <Link to="/connect">
        <PrimaryButton text="Подключить маркетплейс" />
      </Link>
    );
  };

  return (
    <div className={`p-6 ${!hasApiKey ? "flex justify-center items-center h-screen" : ""}`}>
      {hasApiKey ? (
        <>
          <DashboardHeader />
          <DashboardAnalytics setPeriod={setPeriod} topMode={topMode} />
          <DashboardProducts period={period} setTopMode={setTopMode} />
        </>
      ) : (
        <EmptyBlock
          icon={<ShopIcon />}
          title="Необходимо подключить маркетплейс"
          description="Подключите маркетплейс для начала работы"
          btn={renderConnectButton()}
        />
      )}
    </div>
  );
};

export default DashboardPage;
