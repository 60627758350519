import React, { JSX, FC } from "react";
import InfoCard from "../../../ui/InfoCard/InfoCard";

interface ISupplyInfoCardsProps {
  isAssemblyStatus: boolean;
  isDeliveryStatus: boolean;
  setIsShowLabelingRulesModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowShippingRulesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SupplyInfoCards: FC<ISupplyInfoCardsProps> = ({
  isAssemblyStatus,
  isDeliveryStatus,
  setIsShowLabelingRulesModal,
  setIsShowShippingRulesModal,
}): JSX.Element => {
  const renderDetailedBtn = (handler: () => void): JSX.Element => {
    return (
      <p className="blue-color cursor-pointer" onClick={handler}>
        Подробнее
      </p>
    );
  };

  return (
    <div className="flex">
      <InfoCard
        className={isDeliveryStatus ? "w-full" : "mr-3"}
        text={
          isAssemblyStatus
            ? "Распечатайте лист подбора и маркировку для товара, затем промаркируйте товар согласно листу подбора."
            : "Обязательно распечатайте QR-код поставки. Без него склад маркетплейса не примет вашу поставку."
        }
        actionBtn={
          isAssemblyStatus
            ? renderDetailedBtn(() => setIsShowLabelingRulesModal(true))
            : null
        }
      />
      {isAssemblyStatus && (
        <InfoCard
          text={`Если вы везёте поставку на ПВ3, не забудьте создать короба 
            и распечатать их QR-коды во вкладке «Упаковка»
          `}
          actionBtn={renderDetailedBtn(() => setIsShowShippingRulesModal(true))}
        />
      )}
    </div>
  );
};

export default SupplyInfoCards;
