import {
  DashboardType,
  OrderType,
  OrdersFacetsType,
  RequestOrdersFiltersType,
  RequestPaginationType
} from "app/types";
import {
  FETCH_ORDERS_LIST_REQUEST,
  FETCH_ORDERS_LIST_SUCCESS,
  FETCH_ORDERS_LIST_FAILURE,
  CLEAR_ORDERS_LIST,
  UPLOAD_NEW_ORDERS_REQUEST,
  UPLOAD_NEW_ORDERS_SUCCESS,
  UPLOAD_NEW_ORDERS_FAILURE,
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  FETCH_ORDERS_FACETS_REQUEST,
  FETCH_ORDERS_FACETS_SUCCESS,
  FETCH_ORDERS_FACETS_FAILURE,
} from "../../constants/actionTypes";

export type OrdersActionsType =
  | FetchOrdersListRequestType
  | FetchOrdersListSuccessType
  | FetchOrdersListFailureType
  | ClearOrdersListType
  | UploadNewOrdersRequestType
  | UploadNewOrdersSuccessType
  | UploadNewOrdersFailureType
  | FetchDashboardRequestType
  | FetchDashboardSuccess
  | FetchDashboardFailure
  | FetchOrdersFacetsRequestType
  | FetchOrdersFacetsSuccessType
  | FetchOrdersFacetsFailureType
  ;

// получение списка сборочных заданий
type FetchOrdersListRequestType = {
  type: typeof FETCH_ORDERS_LIST_REQUEST,
  payload: {
    pagination: RequestPaginationType;
    filters: RequestOrdersFiltersType;
  }
}; 
 
export const fetchOrdersListRequest = (
  pagination: RequestPaginationType,
  filters: RequestOrdersFiltersType
): FetchOrdersListRequestType => ({
  type: FETCH_ORDERS_LIST_REQUEST,
  payload: {
    pagination,
    filters
  }
});

type FetchOrdersListSuccessType = {
  type: typeof FETCH_ORDERS_LIST_SUCCESS;
  payload: {
    items: OrderType[];
    count: number;
  };
};

export const fetchOrdersListSuccess = ({
  items,
  count,
}): FetchOrdersListSuccessType => ({
  type: FETCH_ORDERS_LIST_SUCCESS,
  payload: {
    items,
    count,
  },
});

type FetchOrdersListFailureType = {
  type: typeof FETCH_ORDERS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchOrdersListFailure = (error: string): FetchOrdersListFailureType => ({
  type: FETCH_ORDERS_LIST_FAILURE,
  payload: {
    error,
  },
});

// очистка
type ClearOrdersListType = {
  type: typeof CLEAR_ORDERS_LIST;
  payload: null;
};

export const clearOrdersList = (): ClearOrdersListType => ({
  type: CLEAR_ORDERS_LIST,
  payload: null,
});

// Загрузка новых сборочных заданий из маркетплейсов
type UploadNewOrdersRequestType = {
  type: typeof UPLOAD_NEW_ORDERS_REQUEST;
};

export const uploadNewOrdersRequest = (): UploadNewOrdersRequestType => ({
  type: UPLOAD_NEW_ORDERS_REQUEST,
});

type UploadNewOrdersSuccessType = {
  type: typeof UPLOAD_NEW_ORDERS_SUCCESS;
};

export const uploadNewOrdersSuccess = (): UploadNewOrdersSuccessType => ({
  type: UPLOAD_NEW_ORDERS_SUCCESS,
});

type UploadNewOrdersFailureType = {
  type: typeof UPLOAD_NEW_ORDERS_FAILURE;
  payload: {
    error: any
  }
};

export const uploadNewOrdersFailure = (error: any): UploadNewOrdersFailureType => ({
  type: UPLOAD_NEW_ORDERS_FAILURE,
  payload: {
    error
  }
});

// Получение данных для дашборда
type FetchDashboardRequestType = {
  type: typeof FETCH_DASHBOARD_REQUEST;
};

export const fetchDashboardRequest = (): FetchDashboardRequestType => ({
  type: FETCH_DASHBOARD_REQUEST,
});

type FetchDashboardSuccess = {
  type: typeof FETCH_DASHBOARD_SUCCESS;
  payload: {
    dashboard: DashboardType;
  };
};

export const fetchDashboardSuccess = (dashboard: DashboardType): FetchDashboardSuccess => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: {
    dashboard
  }
});

type FetchDashboardFailure = {
  type: typeof FETCH_DASHBOARD_FAILURE;
  payload: {
    error: any
  }
};

export const fetchDashboardFailure = (error: any): FetchDashboardFailure => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: {
    error
  }
});

// фасеты
type FetchOrdersFacetsRequestType = {
  type: typeof FETCH_ORDERS_FACETS_REQUEST;
  payload: {
    filters: RequestOrdersFiltersType;
    tab: string
  }
};

export const fetchOrdersFacetsRequest = (
  filters: RequestOrdersFiltersType,
  tab?: string
): FetchOrdersFacetsRequestType => ({
  type: FETCH_ORDERS_FACETS_REQUEST,
  payload: {
    filters,
    tab
  }
});

type FetchOrdersFacetsSuccessType = {
  type: typeof FETCH_ORDERS_FACETS_SUCCESS;
  payload: {
    facets: OrdersFacetsType;
    tab: string;
  }
};

export const fetchOrdersFacetsSuccess = (
  facets: OrdersFacetsType,
  tab?: string
): FetchOrdersFacetsSuccessType => ({
  type: FETCH_ORDERS_FACETS_SUCCESS,
  payload: {
    facets,
    tab,
  }
});

type FetchOrdersFacetsFailureType = {
  type: typeof FETCH_ORDERS_FACETS_FAILURE;
  payload: {
    error: string
  }
};

export const fetchOrdersFacetsFailure = (
  error: string
): FetchOrdersFacetsFailureType => ({
  type: FETCH_ORDERS_FACETS_FAILURE,
  payload: {
    error
  }
});