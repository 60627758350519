import {DraggerFileType, ProductMediaType} from "app/types";

export const transformLoadedFiles = (files: ProductMediaType[]): DraggerFileType[] => {
  return files?.map(file => {
    const filename = file.filename;

    return {
      uid: file.id,
      url: file.attachment,
      name: filename,
      percent: 100,
      status: "success",
      isSent: true,
      size: file.file_size,
      responseId: file.id,
      loadingType: "loaded",
    };
  });
};
