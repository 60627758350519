import React, { JSX, FC, useState, useEffect, useMemo } from "react";
import * as OrdersAPI from "../../../api/orders.api";
import {
  OrderType,
  RequestOrdersFiltersType,
  RequestPaginationType,
  StickerType
} from "app/types";
import { useDispatch, useSelector } from "react-redux";
import type { AppStateType } from "../../../redux/reducers/mainReducer";
import type { InitialOrdersStateType } from "../../../redux/reducers/orders.reducer";
import { AppDispatch } from "../../../redux/store/store";
import { fetchOrders } from "../../../redux/thunks/orders.thunks";

import { ReactComponent as FilterIcon } from "../../../assets/icons/page_info_icon.svg";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/chart_bar_square.svg";

// components
import { Space } from "antd";
import ProductsTable, { TableColumnType } from "../../Products/ProductsTable/ProductsTable";
import GhostButton from "../../ui/GhostButton/GhostButton";
import EmptyBlock from "components/EmptyBlock/EmptyBlock";

import css from "../OrdersTab/style.module.css";
import { SearchInput } from "components/ui/SearchInput/SearchInput";

interface IArchiveTabProps {
  initFilters?: RequestOrdersFiltersType;
}

const commonColumns: TableColumnType[] = [
  "photo", "order_number", "created_at", "name",
  "cost", "warehouse", "shipping_method",
  "sticker_number", "status"
];

const ArchiveTab: FC<IArchiveTabProps> = ({ initFilters }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);

  const [filters, setFilters] = useState<RequestOrdersFiltersType>(initFilters ?? {});
  const [list, setList] = useState<OrderType[]>(null);

  useEffect(() => {
    if (orders.list?.length) {
      setList(orders.list);

      OrdersAPI.getStickers({
        order_ids: orders.list.map((order) => order.id),
        type: "png",
        size: "size400x300"
      })
        .then((res) => {
          if (res?.data) {
            setList((prev: OrderType[]) => prev.map((order: OrderType) => {
              const sticker: StickerType = res?.data
                ?.filter((sticker: StickerType) => !sticker.error)
                ?.find((sticker: StickerType) => sticker.order_id === order.id);

              return {
                ...order,
                sticker: sticker ? `${sticker.part_a} ${sticker.part_b}` : null,
              };
            }));
          }
        })
        .catch(console.error);
    } else {
      setList([]);
    }
  }, [orders.list]);

  const fetchOrdersList = (pagination: RequestPaginationType, filters: RequestOrdersFiltersType): void => {
    dispatch(fetchOrders(pagination, filters));
  };

  const renderSearch = (): JSX.Element => {
    return (
      <div className={css.searchWrap}>
        <SearchInput
          placeholder="Найти по артикулу или названию"
          onSearch={(state: RequestOrdersFiltersType) => setFilters(state)}
          filters={filters}
          isGeneralSearch={false}
          isDisabled={orders.isFetching && !orders.list}
        />
        <GhostButton
          size="large"
          text="Фильтры"
          icon={<FilterIcon />}
          onClickHandler={() => { }} // TODO: Доделать как будут фильтры по сборочным заданиям
          isDisabled={orders.isFetching}
        />
      </div>
    );
  };

  const renderEmptyBlock = (): JSX.Element => (
    <EmptyBlock
      icon={<EmptyIcon className={`mb-6 ${css.emptyIcon}`} />}
      style={{ height: "calc(100vh - 23rem)" }}
      title="Заданий в архиве нет"
      description={
        <>Здесь появятся сборочные задания <br /> имеющие статусы завершенных.</>
      }
    />
  );

  return (
    <Space className="flex" direction="vertical">
      <p className="text-14-r secondary-color mb-3">
        Список товаров, которые отгружены на склад маркетплейса
      </p>
      <div className={css.controlsWrap}>
        {renderSearch()}
      </div>
      <ProductsTable
        columns={commonColumns}
        list={list}
        count={orders.count}
        fetchList={fetchOrdersList}
        isFetching={orders.isFetching}
        filters={filters}
        emptyBlock={renderEmptyBlock()}
        isShowEmptyBlock={true}
      />
    </Space>
  );
};

export default ArchiveTab;
