import * as process from "process";
import axios from "../utils/axios";

interface IListParams {
	search?: string,
	marketplace?: string,
}

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const list = async (params?: IListParams): Promise<any> => {
	const queryParams = new URLSearchParams();

	if (params) {
		for (const key in params) {
			if (params[key]) {
				queryParams.append(key, params[key]);
			}
		}
	}

	return axios.GET(API_BASE, "/api/catalog/marketplace_cities", params);
};

export const search = async (search: string): Promise<any> => {
	return list({ search });
};

export const filter = async (marketplace: string, search?: string): Promise<any> => {
	const params: IListParams = {
		marketplace,
		search
	};

	return list(params);
};
