import React, { JSX, FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/arrow_left.svg";

interface IGoBackButtonProps {
  link: string;
  text: string;
}

const GoBackButton: FC<IGoBackButtonProps> = ({ link, text }): JSX.Element => {
  return (
    <Link to={link} className="flex items-center blue-color mb-2">
      <LeftArrowIcon className="text-14-r mr-2"/>
      <p className="text-16-r mt-1">{text}</p>
    </Link>
  );
};

export default GoBackButton;
