import { combineReducers } from "redux";
import account from "./account.reducer";
import products from "./products.reducer";
import warehouses from "./warehouses.reducer";
import marketplaces from "./marketplaces.reducer";
import deliveryMethods from "./deliveryMethods.reducer";
import marketplaceCities from "./marketplaceCities.reducer";
import officesWildberries from "./officesWildberries.reducer";
import wbCategories from "./wbCategories.reducer";
import wbCharacteristics from "./wbCharacteristics.reducer";
import wbColors from "./wbColors.reducer";
import wbCountries from "./wbCountries.reducer";
import wbKinds from "./wbKinds.reducer";
import wbSeasons from "./wbSeasons.reducer";
import wbTnveds from "./wbTnveds.reducer";
import rests from "./rests.reducer";
import orders from "./orders.reducer";
import supplies from "./supplies.reducer";
import supplyBoxes from "./supplyBoxes.reducer";

const mainReducer = combineReducers({
  account,
  products,
  warehouses,
  wbCategories,
  marketplaces,
  wbCharacteristics,
  deliveryMethods,
  marketplaceCities,
  officesWildberries,
  wbCountries,
  wbKinds,
  wbSeasons,
  wbTnveds,
  wbColors,
  rests,
  orders,
  supplies,
  supplyBoxes
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
