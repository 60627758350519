// Account actions 
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";
export const FETCH_LK_USER_INFO_REQUEST = "FETCH_LK_USER_INFO_REQUEST";
export const FETCH_LK_USER_INFO_SUCCESS = "FETCH_LK_USER_INFO_SUCCESS";
export const FETCH_LK_USER_INFO_FAILURE = "FETCH_LK_USER_INFO_FAILURE";
export const GET_USER_TOKEN_DESCRIPTION_SUCCESS = "GET_USER_TOKEN_DESCRIPTION_SUCCESS";
export const GET_USER_TOKEN_DESCRIPTION_FAILURE = "GET_USER_TOKEN_DESCRIPTION_FAILURE";
export const TOKEN_REVOCATION_SUCCESS = "TOKEN_REVOCATION_SUCCESS";
export const TOKEN_REVOCATION_FAILURE = "TOKEN_REVOCATION_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_EMAIL_REQUEST = "RESET_PASSWORD_EMAIL_REQUEST";
export const RESET_PASSWORD_EMAIL_SUCCESS = "RESET_PASSWORD_EMAIL_SUCCESS";
export const RESET_PASSWORD_EMAIL_FAILURE = "RESET_PASSWORD_EMAIL_FAILURE";

// Warehouses actions
export const FETCH_WAREHOUSES_LIST_REQUEST = "FETCH_WAREHOUSES_LIST_REQUEST";
export const FETCH_WAREHOUSES_LIST_SUCCESS = "FETCH_WAREHOUSES_LIST_SUCCESS";
export const FETCH_WAREHOUSES_LIST_FAILURE = "FETCH_WAREHOUSES_LIST_FAILURE";
export const FIND_WAREHOUSE_REQUEST = "FIND_WAREHOUSE_REQUEST";
export const FIND_WAREHOUSE_SUCCESS = "FIND_WAREHOUSE_SUCCESS";
export const FIND_WAREHOUSE_FAILURE = "FIND_WAREHOUSE_FAILURE";
export const CLEAR_FOUND_WAREHOUSE = "CLEAR_FOUND_WAREHOUSE";
export const ADD_WAREHOUSE_REQUEST = "ADD_WAREHOUSE_REQUEST";
export const ADD_WAREHOUSE_SUCCESS = "ADD_WAREHOUSE_SUCCESS";
export const ADD_WAREHOUSE_FAILURE = "ADD_WAREHOUSE_FAILURE";
export const UPDATE_WAREHOUSE_REQUEST = "UPDATE_WAREHOUSE_REQUEST";
export const UPDATE_WAREHOUSE_SUCCESS = "UPDATE_WAREHOUSE_SUCCESS";
export const UPDATE_WAREHOUSE_FAILURE = "UPDATE_WAREHOUSE_FAILURE";
export const DELETE_WAREHOUSE_REQUEST = "DELETE_WAREHOUSE_REQUEST";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_FAILURE = "DELETE_WAREHOUSE_FAILURE";
export const SYNC_WAREHOUSE_REQUEST = "SYNC_WAREHOUSE_REQUEST";
export const SYNC_WAREHOUSE_SUCCESS = "SYNC_WAREHOUSE_SUCCESS";
export const SYNC_WAREHOUSE_FAILURE = "SYNC_WAREHOUSE_FAILURE";

// Marketplaces actions
export const FETCH_MARKETPLACES_LIST_REQUEST = "FETCH_MARKETPLACES_LIST_REQUEST";
export const FETCH_MARKETPLACES_LIST_SUCCESS = "FETCH_MARKETPLACES_LIST_SUCCESS";
export const FETCH_MARKETPLACES_LIST_FAILURE = "FETCH_MARKETPLACES_LIST_FAILURE";
export const FETCH_MARKETPLACE_REQUEST = "FETCH_MARKETPLACE_REQUEST";
export const FETCH_MARKETPLACE_SUCCESS = "FETCH_MARKETPLACE_SUCCESS";
export const FETCH_MARKETPLACE_FAILURE = "FETCH_MARKETPLACE_FAILURE";

// Delivery methods actions
export const FETCH_DELIVERY_METHODS_LIST_REQUEST = "FETCH_DELIVERY_METHODS_LIST_REQUEST";
export const FETCH_DELIVERY_METHODS_LIST_SUCCESS = "FETCH_DELIVERY_METHODS_LIST_SUCCESS";
export const FETCH_DELIVERY_METHODS_LIST_FAILURE = "FETCH_DELIVERY_METHODS_LIST_FAILURE";
export const FETCH_DELIVERY_METHOD_REQUEST = "FETCH_DELIVERY_METHOD_REQUEST";
export const FETCH_DELIVERY_METHOD_SUCCESS = "FETCH_DELIVERY_METHOD_SUCCESS";
export const FETCH_DELIVERY_METHOD_FAILURE = "FETCH_DELIVERY_METHOD_FAILURE";

// Marketplace cities actions
export const FETCH_MARKETPLACE_CITIES_LIST_REQUEST = "FETCH_MARKETPLACE_CITIES_LIST_REQUEST";
export const FETCH_MARKETPLACE_CITIES_LIST_SUCCESS = "FETCH_MARKETPLACE_CITIES_LIST_SUCCESS";
export const FETCH_MARKETPLACE_CITIES_LIST_FAILURE = "FETCH_MARKETPLACE_CITIES_LIST_FAILURE";
export const FETCH_MARKETPLACE_CITY_REQUEST = "FETCH_MARKETPLACE_CITY_REQUEST";
export const FETCH_MARKETPLACE_CITY_SUCCESS = "FETCH_MARKETPLACE_CITY_SUCCESS";
export const FETCH_MARKETPLACE_CITY_FAILURE = "FETCH_MARKETPLACE_CITY_FAILURE";

// Marketplace offices actions
export const FETCH_OFFICES_WILDBERRIES_LIST_REQUEST = "FETCH_OFFICES_WILDBERRIES_LIST_REQUEST";
export const FETCH_OFFICES_WILDBERRIES_LIST_SUCCESS = "FETCH_OFFICES_WILDBERRIES_LIST_SUCCESS";
export const FETCH_OFFICES_WILDBERRIES_LIST_FAILURE = "FETCH_OFFICES_WILDBERRIES_LIST_FAILURE";
export const FETCH_MARKETPLACE_OFFICE_REQUEST = "FETCH_MARKETPLACE_OFFICE_REQUEST";
export const FETCH_MARKETPLACE_OFFICE_SUCCESS = "FETCH_MARKETPLACE_OFFICE_SUCCESS";
export const FETCH_MARKETPLACE_OFFICE_FAILURE = "FETCH_MARKETPLACE_OFFICE_FAILURE";

// WB Categories actions
export const FETCH_WB_CATEGORIES_LIST_REQUEST = "FETCH_WB_CATEGORIES_LIST_REQUEST";
export const FETCH_WB_CATEGORIES_LIST_SUCCESS = "FETCH_WB_CATEGORIES_LIST_SUCCESS";
export const FETCH_WB_CATEGORIES_LIST_FAILURE = "FETCH_WB_CATEGORIES_LIST_FAILURE";
export const FETCH_WB_CATEGORY_REQUEST = "FETCH_WB_CATEGORY_REQUEST";
export const FETCH_WB_CATEGORY_SUCCESS = "FETCH_WB_CATEGORY_SUCCESS";
export const FETCH_WB_CATEGORY_FAILURE = "FETCH_WB_CATEGORY_FAILURE";

// WB Characteristics actions
export const FETCH_WB_CHARACTERISTICS_LIST_REQUEST = "FETCH_WB_CHARACTERISTICS_LIST_REQUEST";
export const FETCH_WB_CHARACTERISTICS_LIST_SUCCESS = "FETCH_WB_CHARACTERISTICS_LIST_SUCCESS";
export const FETCH_WB_CHARACTERISTICS_LIST_FAILURE = "FETCH_WB_CHARACTERISTICS_LIST_FAILURE";
export const FETCH_WB_CHARACTERISTIC_REQUEST = "FETCH_WB_CHARACTERISTIC_REQUEST";
export const FETCH_WB_CHARACTERISTIC_SUCCESS = "FETCH_WB_CHARACTERISTIC_SUCCESS";
export const FETCH_WB_CHARACTERISTIC_FAILURE = "FETCH_WB_CHARACTERISTIC_FAILURE";

// WB Colors actions
export const FETCH_WB_COLORS_LIST_REQUEST = "FETCH_WB_COLORS_LIST_REQUEST";
export const FETCH_WB_COLORS_LIST_SUCCESS = "FETCH_WB_COLORS_LIST_SUCCESS";
export const FETCH_WB_COLORS_LIST_FAILURE = "FETCH_WB_COLORS_LIST_FAILURE";
export const FETCH_WB_COLOR_REQUEST = "FETCH_WB_COLOR_REQUEST";
export const FETCH_WB_COLOR_SUCCESS = "FETCH_WB_COLOR_SUCCESS";
export const FETCH_WB_COLOR_FAILURE = "FETCH_WB_COLOR_FAILURE";

// WB Kinds actions
export const FETCH_WB_KINDS_LIST_REQUEST = "FETCH_WB_KINDS_LIST_REQUEST";
export const FETCH_WB_KINDS_LIST_SUCCESS = "FETCH_WB_KINDS_LIST_SUCCESS";
export const FETCH_WB_KINDS_LIST_FAILURE = "FETCH_WB_KINDS_LIST_FAILURE";
export const FETCH_WB_KIND_REQUEST = "FETCH_WB_KIND_REQUEST";
export const FETCH_WB_KIND_SUCCESS = "FETCH_WB_KIND_SUCCESS";
export const FETCH_WB_KIND_FAILURE = "FETCH_WB_KIND_FAILURE";

// WB Seasons actions
export const FETCH_WB_SEASONS_LIST_REQUEST = "FETCH_WB_SEASONS_LIST_REQUEST";
export const FETCH_WB_SEASONS_LIST_SUCCESS = "FETCH_WB_SEASONS_LIST_SUCCESS";
export const FETCH_WB_SEASONS_LIST_FAILURE = "FETCH_WB_SEASONS_LIST_FAILURE";
export const FETCH_WB_SEASON_REQUEST = "FETCH_WB_SEASON_REQUEST";
export const FETCH_WB_SEASON_SUCCESS = "FETCH_WB_SEASON_SUCCESS";
export const FETCH_WB_SEASON_FAILURE = "FETCH_WB_SEASON_FAILURE";

// WB Countries actions
export const FETCH_WB_COUNTRIES_LIST_REQUEST = "FETCH_WB_COUNTRIES_LIST_REQUEST";
export const FETCH_WB_COUNTRIES_LIST_SUCCESS = "FETCH_WB_COUNTRIES_LIST_SUCCESS";
export const FETCH_WB_COUNTRIES_LIST_FAILURE = "FETCH_WB_COUNTRIES_LIST_FAILURE";
export const FETCH_WB_COUNTRY_REQUEST = "FETCH_WB_COUNTRY_REQUEST";
export const FETCH_WB_COUNTRY_SUCCESS = "FETCH_WB_COUNTRY_SUCCESS";
export const FETCH_WB_COUNTRY_FAILURE = "FETCH_WB_COUNTRY_FAILURE";

// WB Tnveds actions
export const FETCH_WB_TNVEDS_LIST_REQUEST = "FETCH_WB_TNVEDS_LIST_REQUEST";
export const FETCH_WB_TNVEDS_LIST_SUCCESS = "FETCH_WB_TNVEDS_LIST_SUCCESS";
export const FETCH_WB_TNVEDS_LIST_FAILURE = "FETCH_WB_TNVEDS_LIST_FAILURE";
export const FETCH_WB_TNVED_REQUEST = "FETCH_WB_TNVED_REQUEST";
export const FETCH_WB_TNVED_SUCCESS = "FETCH_WB_TNVED_SUCCESS";
export const FETCH_WB_TNVED_FAILURE = "FETCH_WB_TNVED_FAILURE";

// Products actions
export const FETCH_PRODUCTS_LIST_REQUEST = "FETCH_PRODUCTS_LIST_REQUEST";
export const FETCH_PRODUCTS_LIST_SUCCESS = "FETCH_PRODUCTS_LIST_SUCCESS";
export const FETCH_PRODUCTS_LIST_FAILURE = "FETCH_PRODUCTS_LIST_FAILURE";
export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";
export const CLEAR_PRODUCTS_LIST = "CLEAR_PRODUCTS_LIST";
export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";
export const UPDATE_WHOLE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_WHOLE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_WHOLE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";
export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const SYNC_PRODUCTS_REQUEST = "SYNC_PRODUCTS_REQUEST";
export const SYNC_PRODUCTS_SUCCESS = "SYNC_PRODUCTS_SUCCESS";
export const SYNC_PRODUCTS_FAILURE = "SYNC_PRODUCTS_FAILURE";
export const SYNC_PRODUCTS_CARD_REQUEST = "SYNC_PRODUCTS_CARD_REQUEST";
export const SYNC_PRODUCTS_CARD_SUCCESS = "SYNC_PRODUCTS_CARD_SUCCESS";
export const SYNC_PRODUCTS_CARD_FAILURE = "SYNC_PRODUCTS_CARD_FAILURE";
export const FETCH_CARD_INFO_REQUEST = "FETCH_CARD_INFO_REQUEST";
export const FETCH_CARD_INFO_SUCCESS = "FETCH_CARD_INFO_SUCCESS";
export const FETCH_CARD_INFO_FAILURE = "FETCH_CARD_INFO_FAILURE";
export const FETCH_PRODUCTS_FACETS_FAILURE = "FETCH_PRODUCTS_FACETS_FAILURE";
export const FETCH_PRODUCTS_FACETS_REQUEST = "FETCH_PRODUCTS_FACETS_REQUEST";
export const FETCH_PRODUCTS_FACETS_SUCCESS = "FETCH_PRODUCTS_FACETS_SUCCESS";

// Prices
export const CREATE_PRICE_REQUEST = "CREATE_PRICE_REQUEST";
export const CREATE_PRICE_SUCCESS = "CREATE_PRICE_SUCCESS";
export const CREATE_PRICE_FAILURE = "CREATE_PRICE_FAILURE";
export const FETCH_SHOT_PRICE_REQUEST = "FETCH_SHOT_PRICE_REQUEST";
export const FETCH_SHOT_PRICE_SUCCESS = "FETCH_SHOT_PRICE_SUCCESS";
export const FETCH_SHOT_PRICE_FAILURE = "FETCH_SHOT_PRICE_FAILURE";

// Rests
export const FETCH_RESTS_LIST_REQUEST = "FETCH_RESTS_LIST_REQUEST";
export const FETCH_RESTS_LIST_SUCCESS = "FETCH_RESTS_LIST_SUCCESS";
export const FETCH_RESTS_LIST_FAILURE = "FETCH_RESTS_LIST_FAILURE";

// Orders
export const FETCH_ORDERS_LIST_REQUEST = "FETCH_ORDERS_LIST_REQUEST";
export const FETCH_ORDERS_LIST_SUCCESS = "FETCH_ORDERS_LIST_SUCCESS";
export const FETCH_ORDERS_LIST_FAILURE = "FETCH_ORDERS_LIST_FAILURE";
export const CLEAR_ORDERS_LIST = "CLEAR_ORDERS_LIST";
export const UPLOAD_NEW_ORDERS_REQUEST = "UPLOAD_NEW_ORDERS_REQUEST";
export const UPLOAD_NEW_ORDERS_SUCCESS = "UPLOAD_NEW_ORDERS_SUCCESS";
export const UPLOAD_NEW_ORDERS_FAILURE = "UPLOAD_NEW_ORDERS_FAILURE";
export const FETCH_DASHBOARD_REQUEST = "FETCH_DASHBOARD_REQUEST";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";
export const FETCH_ORDERS_FACETS_REQUEST = "FETCH_ORDERS_FACETS_REQUEST";
export const FETCH_ORDERS_FACETS_SUCCESS = "FETCH_ORDERS_FACETS_SUCCESS";
export const FETCH_ORDERS_FACETS_FAILURE = "FETCH_ORDERS_FACETS_FAILURE";

// Supplies
export const FETCH_SUPPLIES_LIST_REQUEST = "FETCH_SUPPLIES_LIST_REQUEST";
export const FETCH_SUPPLIES_LIST_SUCCESS = "FETCH_SUPPLIES_LIST_SUCCESS";
export const FETCH_SUPPLIES_LIST_FAILURE = "FETCH_SUPPLIES_LIST_FAILURE";
export const FETCH_SUPPLY_REQUEST = "FETCH_SUPPLY_REQUEST";
export const FETCH_SUPPLY_SUCCESS = "FETCH_SUPPLY_SUCCESS";
export const FETCH_SUPPLY_FAILURE = "FETCH_SUPPLY_FAILURE";
export const SUPPLY_DELIVER_REQUEST = "SUPPLY_DELIVER_REQUEST";
export const SUPPLY_DELIVER_SUCCESS = "SUPPLY_DELIVER_SUCCESS";
export const SUPPLY_DELIVER_FAILURE = "SUPPLY_DELIVER_FAILURE";
export const CLEAR_SUPPLIES_LIST = "CLEAR_SUPPLIES_LIST";

// Supply Boxes
export const CREATE_SUPPLY_BOXES_REQUEST = "CREATE_SUPPLY_BOXES_REQUEST";
export const CREATE_SUPPLY_BOXES_SUCCESS = "CREATE_SUPPLY_BOXES_SUCCESS";
export const CREATE_SUPPLY_BOXES_FAILURE = "CREATE_SUPPLY_BOXES_FAILURE";
export const FETCH_SUPPLY_BOXES_LIST_REQUEST = "FETCH_SUPPLY_BOXES_LIST_REQUEST";
export const FETCH_SUPPLY_BOXES_LIST_SUCCESS = "FETCH_SUPPLY_BOXES_LIST_SUCCESS";
export const FETCH_SUPPLY_BOXES_LIST_FAILURE = "FETCH_SUPPLY_BOXES_LIST_FAILURE";
export const DELETE_SUPPLY_BOX_REQUEST = "DELETE_SUPPLY_BOX_REQUEST";
export const DELETE_SUPPLY_BOX_SUCCESS = "DELETE_SUPPLY_BOX_SUCCESS";
export const DELETE_SUPPLY_BOX_FAILURE = "DELETE_SUPPLY_BOX_FAILURE";
export const FETCH_BOX_STICKERS_REQUEST = "FETCH_BOX_STICKERS_REQUEST";
export const FETCH_BOX_STICKERS_SUCCESS = "FETCH_BOX_STICKERS_SUCCESS";
export const FETCH_BOX_STICKERS_FAILURE = "FETCH_BOX_STICKERS_FAILURE";
export const REMOVE_ORDER_FROM_BOX_REQUEST = "REMOVE_ORDER_FROM_BOX_REQUEST";
export const REMOVE_ORDER_FROM_BOX_SUCCESS = "REMOVE_ORDER_FROM_BOX_SUCCESS";
export const REMOVE_ORDER_FROM_BOX_FAILURE = "REMOVE_ORDER_FROM_BOX_FAILURE";
