import React, { JSX, FC, useEffect, useState } from "react";
import * as AccountAPI from "../../../api/account.api";

import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as RuFlagIcon } from "../../../assets/icons/RU.svg";
import InputMask from "react-input-mask";

// email в формате email@mail.ru только латиница
export const EMAIL_FORMAT: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;

interface IRegistrationContactsFormProps {
  name: string;
  surname: string;
  email: string;
  inn: string;
  phone: string;
  companyName: string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setSurname: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setInn: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
}

const RegistrationContactsForm: FC<IRegistrationContactsFormProps> = ({
  inn,
  name,
  email,
  phone,
  surname,
  companyName,
  setStep,
  setName,
  setSurname,
  setEmail,
  setInn,
  setPhone,
  setCompanyName,
}): JSX.Element => {
  const [form] = Form.useForm();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isOfferChecked, setIsOfferChecked] = useState<boolean>(false);
  const [isPolicyChecked, setIsPolicyChecked] = useState<boolean>(false);
  const [maskedPhone, setMaskedPhone] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("+7");

  useEffect(() => {
    // удаляем лишние пробелы и берем первые 10 символов
    const clearedPhone: string = maskedPhone.replace(/\D+/g, "").slice(0, 10)?.toString();

    setPhone(`${countryCode}${clearedPhone}`);

    const hasErrors: boolean = form.getFieldsError().some((field) => field.errors.length > 0);

    setIsDisabled(
      hasErrors
      || clearedPhone?.length !== 10
      || !parseInt(maskedPhone)
      || !isOfferChecked
      || !isPolicyChecked
      || !name
      || !surname
      || !inn
      || !email
      || !companyName,
    );
  }, [
    inn,
    email,
    name,
    surname,
    isDisabled,
    maskedPhone,
    companyName,
    isOfferChecked,
    isPolicyChecked,
    form.getFieldsError()
  ]);

  const renderInput = (
    value: string,
    placeholder: string,
    handler: React.Dispatch<React.SetStateAction<string>>,
    className: string = "",
  ): JSX.Element => {
    return (
      <Input
        value={value}
        defaultValue={value}
        className={`h-12 ${className}`}
        placeholder={placeholder}
        onChange={(e) => handler(e.target.value?.trim())}
      />
    );
  };

  const getRequiredRule = (message: string): { required: boolean; message: string } => {
    return { required: true, message };
  };

  const handleSubmit = (): void => {
    form.validateFields()
      .then(async () => {
        if (isOfferChecked && isPolicyChecked) { // дополнительно проверяем наличие акцептов
          await AccountAPI.registerUser({
        inn: inn.trim(),
        email: email.trim(),
        phone: phone.trim(),
        last_name: surname.trim(),
        first_name: name.trim(),
        company_name: companyName.trim(),
            acceptance_of_the_offer: isOfferChecked,
            consent_to_processing_personal_data: isPolicyChecked
          })
            .then(() => setStep(3))
            .catch((err) => {
              const errorMsg = err.response?.data?.error;

              if (errorMsg) {
                if (errorMsg.email) {
                  message.error(errorMsg.email);
                } else if (errorMsg.phone) {
                  message.error(errorMsg.phone);
                } else {
                  message.error(`При регистрации произошла ошибка. 
              Возможно, такой ИНН или email уже зарегистрирован`, 4);
                }
              } else {
                message.error("Произошла непредвиденная ошибка. Повторите попытку позднее");
              }
            });
        }
      })
      .catch(() => message.error("Пожалуйста, заполните корректно все поля формы"));
  };

  return (
    <div>
      <Form form={form}>
        <div className="flex">
          <Form.Item
            name="name"
            rules={[
              getRequiredRule("Пожалуйста, введите ваше имя!"),
              {
                pattern: /^[^0-9]+$/,
                message: "Имя не может содержать числа"
              }
            ]}
          >
            {renderInput(name, "Имя", setName, "w-72 mr-3")}
          </Form.Item>
          <Form.Item
            name="surname"
            rules={[
              getRequiredRule("Пожалуйста, введите вашу фамилию!"),
              {
                pattern: /^[^0-9]+$/,
                message: "Фамилия не может содержать числа"
              }
            ]}
          >
            {renderInput(surname, "Фамилия", setSurname, "w-72")}
          </Form.Item>
        </div>
        <div className="flex">
          <Form.Item
            name="email"
            rules={[
              getRequiredRule("Пожалуйста, введите ваш email!"),
              {
                pattern: EMAIL_FORMAT,
                message: "Введите email в формате email@mail.ru"
              }
            ]}
          >
            {renderInput(email, "Email", setEmail, "w-72 mr-3")}
          </Form.Item>
          <Form.Item
            name="inn"
            rules={[
              getRequiredRule("Пожалуйста, введите ваш ИНН!"),
              {
                pattern: /^[0-9]+$/,
                message: "ИНН может содержать только цифры",
              },
            ]}
          >
            {renderInput(inn, "ИНН компании", setInn, "w-72")}
          </Form.Item>
        </div>
        <Form.Item
          name="companyName"
          rules={[getRequiredRule("Пожалуйста, введите название организации!")]}
        >
          {renderInput(companyName, "Наименование организации", setCompanyName)}
        </Form.Item>

        <div className="flex">
          <Form.Item name="country">
            <Input
              readOnly
              className="mr-3 h-12"
              style={{maxWidth: "4rem"}}
              prefix={<RuFlagIcon />}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setCountryCode((e.target as HTMLInputElement).value)}
              defaultValue={countryCode}
            />
          </Form.Item>

          <Form.Item name="phone">
            <InputMask
              mask="999 999 99 99"
              value={maskedPhone}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setMaskedPhone((e.target as HTMLInputElement).value)}
            >
              <Input
                className="w-full h-12"
                type="tel"
                style={{minWidth: "32rem"}}
                placeholder="000 000 00 00"
              />
            </InputMask>
          </Form.Item>
        </div>
        <Form.Item className="mb-0">
          <Checkbox
            checked={isOfferChecked}
            className="text-14-r"
            onChange={(e: CheckboxChangeEvent) => setIsOfferChecked(e.target.checked)}
          >
            Соглашаюсь с <a href="#" className="blue-color">офертой</a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={isPolicyChecked}
            onChange={(e: CheckboxChangeEvent) => setIsPolicyChecked(e.target.checked)}
          >
            Соглашаюсь на <a href="#" className="blue-color">обработку персональных данных</a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <div className="flex items-center">
            <Button
              type="primary"
              size="large"
              disabled={isDisabled}
              htmlType="submit"
              style={{height: 44}}
              className="bg-blue-500"
              onClick={handleSubmit}
            >
              Подписать договор
            </Button>
            <p className="ml-6">
              Уже есть аккаунт?
              <Link to="/login" className="blue-color"> Войдите</Link>
            </p>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegistrationContactsForm;
