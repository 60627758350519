import { BoxType } from "app/types";
import { SupplyBoxesActionsType } from "redux/actions/supplyBoxes.actions";
import {
  CREATE_SUPPLY_BOXES_REQUEST,
  CREATE_SUPPLY_BOXES_SUCCESS,
  CREATE_SUPPLY_BOXES_FAILURE,
  FETCH_SUPPLY_BOXES_LIST_REQUEST,
  FETCH_SUPPLY_BOXES_LIST_SUCCESS,
  FETCH_SUPPLY_BOXES_LIST_FAILURE,
  DELETE_SUPPLY_BOX_REQUEST,
  DELETE_SUPPLY_BOX_SUCCESS,
  DELETE_SUPPLY_BOX_FAILURE,
} from "../../constants/actionTypes";

export type InitialSupplyBoxesStateType = {
  list: BoxType[] | null;
  count: number;
  isFetching: boolean;
  error: string | null;
};

const initialState: InitialSupplyBoxesStateType = {
  list: null,
  count: 0,
  isFetching: false,
  error: null,
};

const supplyBoxes = (
  state: InitialSupplyBoxesStateType = initialState,
  action: SupplyBoxesActionsType,
): InitialSupplyBoxesStateType => {
  switch (action.type) {
    // Список коробов
    case FETCH_SUPPLY_BOXES_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUPPLY_BOXES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.items,
        count: action.payload.count,
        isFetching: false,
        error: null,
      };
    case FETCH_SUPPLY_BOXES_LIST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };

    // создание коробов
    case CREATE_SUPPLY_BOXES_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case CREATE_SUPPLY_BOXES_SUCCESS: {
      const updatedBoxes: BoxType[] = action.payload.items.reduce((accumulator: BoxType[], newBox: BoxType) => {
        if (!state.list.some((box: BoxType) => box.id === newBox.id)) {
          accumulator.push(newBox);
        }
        return accumulator;
      }, [...state.list]);
      
      return {
        ...state,
        list: updatedBoxes,
        count: updatedBoxes.length,
        isFetching: false,
        error: null,
      };
    }
    case CREATE_SUPPLY_BOXES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };

    // удаление короба
    case DELETE_SUPPLY_BOX_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case DELETE_SUPPLY_BOX_SUCCESS: {
      const deletedBoxIds: string[] = action.payload.removed;

      const updatedBoxes: BoxType[] = state.list.filter((box) => !deletedBoxIds.includes(box.id));

      return {
        ...state,
        list: updatedBoxes,
        count: updatedBoxes.length,
        isFetching: false,
        error: null,
      };
    }
    case DELETE_SUPPLY_BOX_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export default supplyBoxes;
