import { useState, useEffect } from "react";

export interface ITimeRemaining {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface ITimeRemainingOptions {
  Days?: boolean;
  Hours?: boolean;
  Minutes?: boolean;
  Seconds?: boolean;
}

export const calculateTimeRemaining = (targetDate: Date, options: ITimeRemainingOptions = {}): ITimeRemaining => {
  const currentDate: Date = new Date();
  const timeDifference: number = targetDate.getTime() - currentDate.getTime();

  if (timeDifference > 0) {
    const days: number = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours: number = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes: number = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds: number = Math.floor((timeDifference % (1000 * 60)) / 1000);

    const result: ITimeRemaining = {
      ...(options.Days ? { days } : undefined),
      ...(options.Hours ? { hours } : undefined),
      ...(options.Minutes ? { minutes } : undefined),
      ...(options.Seconds ? { seconds } : undefined),
    };

    return result;
  } else {
    return null;
  }
};

export const useTimeRemaining = (targetDate: Date): ITimeRemaining => {
  const [timeRemaining, setTimeRemaining] = useState<ITimeRemaining>(calculateTimeRemaining(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return timeRemaining;
};

export default useTimeRemaining;