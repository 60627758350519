import React, { JSX, FC } from "react";
import SupplyWrapper from "../../components/Orders/Supply/SupplyWrapper";

interface ISupplyPageProps {}

const SupplyPage: FC<ISupplyPageProps> = (): JSX.Element => {
  return (
    <div className="p-6">
      <SupplyWrapper />
    </div>
  );
};

export default SupplyPage;
