import React, { JSX, FC, useEffect, useState } from "react";
import * as AccountAPI from "../../../../api/account.api";
import { useWatch } from "antd/es/form/Form";
import { MarketplaceType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { AppStateType } from "../../../../redux/reducers/mainReducer";
import { InitialMarketplacesStateType } from "../../../../redux/reducers/marketplaces.reducer";
import { AppDispatch } from "../../../../redux/store/store";
import { fetchMarketplaces } from "../../../../redux/thunks/marketplaces.thunks";

import { Form, Input, message, Radio } from "antd";
import Card from "../../../ui/Card/Card";
import GhostButton from "../../../ui/GhostButton/GhostButton";
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";

import css from "../style.module.css";

interface IConnectFormProps {}

const MP = {
  WB: "Wildberries",
};

const ConnectForm: FC<IConnectFormProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const { marketplaceId } = useParams();

  const [form] = Form.useForm();
  const values = useWatch([], form);

  const marketplaces: InitialMarketplacesStateType = useSelector((state: AppStateType) => state.marketplaces);

  const [selectedRadioValue, setSelectedRadioValue] = useState<string>(null);
  const [apiKey, setApiKey] = useState<string>(null);

  useEffect(() => {
    dispatch(fetchMarketplaces());
  }, []);

  useEffect(() => {
    // при первом открытии страницы
    if (marketplaces.list) {
      if (marketplaceId) { // режим редактирования
        setSelectedRadioValue(marketplaceId);
      } else {
        setSelectedRadioValue(marketplaces.list[0]?.id);
      }
    }
  }, [marketplaces.list]);

  useEffect(() => {
    if (selectedRadioValue) {
      setApiKey(marketplaces.list?.find(({ id }) => id === selectedRadioValue)?.api_key);

      form.setFieldsValue({ marketplace: selectedRadioValue });
    }
  }, [selectedRadioValue]);

  useEffect(() => {
    apiKey && form.setFieldsValue({ api_key: apiKey });
  }, [apiKey]);

  const renderRadio = (marketplace: MarketplaceType): JSX.Element => {
    return (
      <Radio
        key={marketplace.id}
        value={marketplace.id}
        onChange={(e) => setSelectedRadioValue(e.target.value)}
      >
        <div className="flex items-center">
          <img src={marketplace.logo} alt={marketplace.name} style={{ width: 18, height: 18 }} />
          <p className="ml-2">{MP[marketplace.name]}</p>
        </div>
      </Radio>
    );
  };

  const renderForm = (): JSX.Element => {
    const handleSave = async (): Promise<void> => {
      const mpName: string = marketplaces.list?.find(({ id }) => id === selectedRadioValue)?.name;

      await AccountAPI.updateApiKey(mpName, values?.api_key ?? null)
        .then(() => {
          dispatch(fetchMarketplaces());
          message.success("API токен успешно обновлен");
        })
        .catch((err) => {
          message.error(err ?? "При обновлении API токена произошла ошибка. Попробуйте еще раз");
        });
    };

    return (
      <div className="mt-6">
        <p className="text-14-m mb-2">API токен</p>
        <Form.Item name="api_key">
          <Input className="input-height" style={{ color: "#374151" }} placeholder="Ваш API токен" />
        </Form.Item>
        <div className="flex justify-end mt-6">
          <Link to="/dashboard">
            <GhostButton
              size="large"
              text="Отменить"
              onClickHandler={() => {}}
              className="mr-3"
            />
          </Link>
          <PrimaryButton
            text="Сохранить"
            isDisabled={!values?.api_key}
            onClickHandler={handleSave}
          />
        </div>
      </div>
    );
  };

  return (
    <Card className={`mt-6 p-6 ${css.form}`}>
      <h4 className="mb-3">Подключение маркетплейса</h4>
      <p className="secondary-color">
        Выберите маркетплейс, который вы хотите добавить и заполните необходимую информацию.
      </p>
      <p className="secondary-color">
        Инструкция о том, где получить необходимые данные, приведены в подсказках справа.
      </p>
      <Form form={form}>
        <div className="mt-6">
          <p className="text-14-m mb-3" style={{ color: "#374151" }}>
            Выберите маркетплейс
          </p>
          <Form.Item name="marketplace" initialValue={selectedRadioValue}>
            <Radio.Group>{marketplaces.list?.map(renderRadio)}</Radio.Group>
          </Form.Item>
        </div>
        {renderForm()}
      </Form>
    </Card>
  );
};

export default ConnectForm;
