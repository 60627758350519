import React, { FC, useEffect, useState } from "react";
import { debounce } from "lodash";

import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

interface ISearchInputProps {
  onSearch: React.Dispatch<React.SetStateAction<any>>;
  isGeneralSearch?: boolean;
  placeholder?: string;
  filters: any;
  isDisabled?: boolean;
}

export const SearchInput: FC<ISearchInputProps> = ({
  onSearch,
  isGeneralSearch = true,
  placeholder = "Искать",
  filters = {},
  isDisabled = false,
}): JSX.Element => {
  const handleGeneralSearch = debounce((search: string) => {
    const searchValue: string | null = search?.trim()?.length > 0 ? search : null;

    if (isGeneralSearch) {
      onSearch({
        ...filters, 
        search: searchValue
      });
    } else {
      onSearch({
        ...filters,
        product: { ...filters.product, search: searchValue },
      });;
    }
  }, 200);

  return (
    <Input
      size="large"
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      onChange={(event: any) => handleGeneralSearch(event.target?.value)}
      disabled={isDisabled}
    />
  );
};