import React, { FC, JSX, useEffect, useState } from "react";
import {
  DeliveryMethodType,
  MarketplaceType,
  OfficeWildberriesType,
  WarehouseRequestType,
} from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import { AppStateType } from "redux/reducers/mainReducer";
import { fetchDeliveryMethods } from "../../../redux/thunks/deliveryMethods.thunks";
import {
  fetchMarketplaceCities,
  filterMarketplaceCities,
  searchFilteredMarketplaceCities,
} from "../../../redux/thunks/marketplaceCities.thunks";
import {
  filterMarketplaceOffices
} from "redux/thunks/officesWildberries.api.thunks";
import { fetchMarketplaces } from "redux/thunks/marketplaces.thunks";
import { addWarehouse, findWarehouse, updateWarehouse } from "redux/thunks/warehouses.thunks";
import debounce from "lodash/debounce";
import { Form, Input, List, Modal, Select, Space, Spin, Tooltip, message } from "antd";
import { DefaultOptionType } from "antd/es/select";
import SubmitButton from "../../SubmitButton/SubmitButton";
import GhostButton from "../../ui/GhostButton/GhostButton";
import { ReactComponent as HelpOutlinedIcon } from "../../../assets/icons/help_outlined_icon.svg";
import css from "./style.module.css";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";

const { Item, useForm, useWatch } = Form;
const { TextArea } = Input;
const { Compact } = Space;
const { Option } = Select;

interface IModalWarehouse {
  onClose: () => void;
  warehouseId?: string | null;
}

interface IModalWarehouseForm {
  name: string | undefined,
  city: string | undefined,
  comment: string | undefined,
  delivery_method_id: string | undefined,
  marketplace: string | undefined,
  warehouse: string | undefined,
}

type LinkedWarehouseType = {
  marketplace_id: string;
  warehouse_id: string;
  warehouse_name: string;
  logo: string;
}

const SELECT_PROPS = ({
  showSearch: true,
  className: css.inputField,
  filterOption: (input: string, option: DefaultOptionType) => {
    if (!option) return false;

    const optionValues = Object.values(option).join("").toLowerCase();

    return optionValues.includes(input.toLowerCase());
  },
  filterSort: (
    optionA: DefaultOptionType,
    optionB: DefaultOptionType,
    valueName: string = "label"
  ) => (optionA[valueName] ?? "")
    .toLowerCase()
    .localeCompare((optionB[valueName] ?? "")
      .toLowerCase())
});

const ModalWarehouse: FC<IModalWarehouse> = ({ onClose, warehouseId }) => {
  const [form] = useForm<IModalWarehouseForm>();
  const values = useWatch<IModalWarehouseForm>([], form);
  const dispatch = useDispatch<AppDispatch>();

  // Стейт
  const warehouses = useSelector((state: AppStateType) => state.warehouses);
  const deliveryMethods = useSelector((state: AppStateType) => state.deliveryMethods);
  const marketplaceCities = useSelector((state: AppStateType) => state.marketplaceCities);
  const marketplaces = useSelector((state: AppStateType) => state.marketplaces);
  const officesWildberries = useSelector((state: AppStateType) => state.officesWildberries);
  const account = useSelector((state: AppStateType) => state.account);

  // Текущие связи склад-офис
  const [linkedWarehouses, setLinkedWarehouses] = useState<LinkedWarehouseType[]>([]);

  // Состояния доступности полей формы
  const isMarketplaseDisabled: boolean = !values?.marketplace
    || !!marketplaceCities.error;
  const isAddressDisabled: boolean = isMarketplaseDisabled
    || !values?.city
    || !officesWildberries.list;
  const isAddButtonDisabled: boolean = isMarketplaseDisabled
    || !values?.warehouse
    || isAddressDisabled
    || !!officesWildberries.error;

  // Логика заполнения полей формы при изменении информации о складе
  useEffect((): void => {
    if (warehouses.found) {
      form.setFieldsValue({
        name: warehouses.found?.name,
        comment: warehouses.found?.comment,
        delivery_method_id: warehouses.found?.delivery_method.id,
      });

      const newLinks: LinkedWarehouseType[] = warehouses.found.office_ids?.map(({ id, address, marketplace }) => ({
        marketplace_id: marketplace.id,
        warehouse_name: address,
        warehouse_id: id,
        logo: marketplace.logo,
      }));

      setLinkedWarehouses(newLinks);
    }
  }, [warehouses.found]);

  useEffect((): void => {
    if (warehouseId) {
      dispatch(findWarehouse(warehouseId));
    }
  }, [warehouseId]);

  // Загрузка информации о доставках, маркетплейсах
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchDeliveryMethods());
      dispatch(fetchMarketplaceCities());
      dispatch(fetchMarketplaces());
    }
  }, [dispatch]);

  const handleLinkWarehouse = (): void => {
    const selectedMarketplace: MarketplaceType = marketplaces.list
      ?.find((marketplace: MarketplaceType) => marketplace.id === values.marketplace);

    // Проверка на наличие склада с таким же типом маркетплейса
    const isDuplicateMarketplace: boolean = linkedWarehouses
      .some((link: LinkedWarehouseType) => link.marketplace_id === values?.marketplace);

    if (isDuplicateMarketplace) {
      message.error("Маркетплейс уже используется.");

      return;
    }

    // Проверка на дублирование названий складов
    const isDuplicateName: boolean = linkedWarehouses
      .some((warehouse: LinkedWarehouseType) => warehouse.warehouse_name === values?.warehouse);

    if (isDuplicateName) {
      message.error("Адрес склада уже используется.");

      return;
    }

    const newLinkedWarehouse: LinkedWarehouseType = {
      marketplace_id: selectedMarketplace.id,
      warehouse_name: officesWildberries.list.find((warehouse) => warehouse.id === values?.warehouse).address,
      warehouse_id: values?.warehouse,
      logo: selectedMarketplace.logo,
    };

    setLinkedWarehouses((prevState) => [...prevState, newLinkedWarehouse]);
  };

  const handleDeleteWarehouseLink = (name: string): void => setLinkedWarehouses(
    (prevState) => [
      ...prevState.filter((link: LinkedWarehouseType) => link.warehouse_name !== name)
    ]
  );

  const createWarehouseRequestBody = (): WarehouseRequestType => {
    const { name, delivery_method_id, comment } = values;

    const warehouse: WarehouseRequestType = {
      name,
      delivery_method_id,
      company_id: account.userInfo.company.id,
      comment: comment ?? "",
      office_ids: linkedWarehouses.map((link: LinkedWarehouseType) => link.warehouse_id),
    };

    return warehouse;
  };

  const handleAddWarehouseCard = (): void => {
    const warehouse: WarehouseRequestType = createWarehouseRequestBody();

    dispatch(addWarehouse(warehouse))
      .then(() => {
        message.success("Успешное создание склада");
        onClose();
      })
      .catch((error: any) => {
        console.error(error);
        message.error("Ошибка при создании склада");
      });
  };

  const handleEditWarehouseCard = (): void => {
    const warehouse: WarehouseRequestType = createWarehouseRequestBody();

    dispatch(updateWarehouse(warehouse, warehouseId))
      .then(() => {
        message.success("Склад успешно обновлен");
        onClose();
      })
      .catch((error: any) => {
        console.error(error);
        message.error("Ошибка при обновлении склада");
      });
  };

  const handleSelectDelivery = (): void => {
  };

  const handleSelectCity = (value: string): void => {
    form.setFieldValue("warehouse", null);

    dispatch(filterMarketplaceOffices(value));
  };

  const handleSelectMarketplace = (value: string): void => {
    const selectedMarketplaceName: string = marketplaces.list
      .find((marketplace: MarketplaceType) => marketplace.id === value)
      .name.trim().toLowerCase();

    form.setFieldsValue({
      city: null,
      warehouse: null,
    });

    dispatch(filterMarketplaceCities(selectedMarketplaceName));
  };

  const handleSearchMarketplaceCities = debounce((search: string): void => {
    const selectedMarketplaceName: string = marketplaces.list
      .find((marketplace: MarketplaceType) => marketplace.id === values.marketplace)
      .name.trim().toLowerCase();

    dispatch(searchFilteredMarketplaceCities(search, selectedMarketplaceName));
  }, 300);

  const renderDeliveryOption = (delivery: DeliveryMethodType): JSX.Element => {
    const {
      id,
      name,
      description,
    } = delivery;

    return (
      <Option key={`option-${id}`} value={id} name={name} description={description ?? ""}>
        <div className="flex row flex-row items-center">
          <p className={`mb-0 ${css.text}`}>{name}</p>
          {description && (
            <p className={`secondary-color ${css.description}`}>
              <Tooltip title={description}>
                <HelpOutlinedIcon className="ml-1" />
              </Tooltip>
            </p>
          )}
        </div>
      </Option>
    );
  };

  const renderOfficeOption = (office: OfficeWildberriesType): JSX.Element => {
    const {
      id,
      address,
      city,
      name,
    } = office;

    return (
      <Option key={`option-${id}`} value={id} city={city} address={address} name={name}>
        <div className="flex row flex-col">
          <p className={`mb-0 ${css.text}`}><b>{name}</b></p>
          <p className={`secondary-color ${css.subtitle}`}>{address}</p>
        </div>
      </Option>
    );
  };

  const renderLinkedWarehouse = (link: LinkedWarehouseType): JSX.Element => {
    return (
      <List.Item
        actions={[
          <a
            key="warehouse-delete"
            onClick={() => handleDeleteWarehouseLink(link.warehouse_name)}
          >
            Удалить
          </a>
        ]}
      >
        <Space>
          <img src={link.logo} alt="иконка" className={`flex ${css.marketplaceLogo}`} />
          {link.warehouse_name}
        </Space>
      </List.Item>
    );
  };

  return (
    <Modal
      open
      closable
      onCancel={onClose}
      title={warehouseId ? "Редактирование склада" : "Добавление нового склада"}
      footer={
        <>
          <GhostButton
            text="Отменить"
            onClickHandler={() => onClose()}
            className={css.inputField}
          />
          <SubmitButton
            form={form}
            onClick={warehouseId ? handleEditWarehouseCard : handleAddWarehouseCard}
            text={warehouseId ? "Изменить" : "Продолжить"}
            className={css.inputField}
            key={warehouseId ? "editWarehouseCard" : "addWarehouseCard"}
          />
        </>
      }
    >
      <Form form={form}>
        <Spin spinning={warehouses?.isFetching}>
          <Item
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название склада",
              },
              {
                max: 255,
                message: "Длина названия не может превышать 255 символов",
              },
              {
                validator: (_rule, value, callback) => {
                  const isDuplicate: boolean = warehouses.list?.some((warehouse) =>
                    (warehouse.name === value) && (warehouse.id !== warehouseId)
                  );

                  if (isDuplicate) {
                    callback("Склад с таким названием уже существует");
                  }

                  callback();
                }
              }
            ]}
          >
            <TextArea
              autoSize
              showCount
              maxLength={255}
              placeholder="Название"
              className={css.inputField}
            />
          </Item>

          <Item
            name="delivery_method_id"
            rules={[{
              required: true,
              message: "Пожалуйста, выберите тип доставки"
            }]}
          >
            <Select
              {...SELECT_PROPS}
              placeholder="Доставка"
              optionLabelProp="name"
              onSelect={handleSelectDelivery}
              loading={deliveryMethods.isFetching}
            >
              {deliveryMethods.list?.map(renderDeliveryOption)}
            </Select>
          </Item>

          <Item
            className="w-full"
            name="marketplace"
          >
            <Select
              {...SELECT_PROPS}
              placeholder="Маркетплейс"
              optionLabelProp="label"
              options={marketplaces.list?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
              onSelect={handleSelectMarketplace}
            />
          </Item>

          <Item name="city">
            <Select
              {...SELECT_PROPS}
              placeholder="Город"
              optionLabelProp="label"
              disabled={isMarketplaseDisabled}
              onSearch={handleSearchMarketplaceCities}
              onSelect={handleSelectCity}
              options={marketplaceCities.list?.map(({ city }) => ({
                value: city,
                label: city
              }))}
              loading={marketplaceCities.isFetching}
            />
          </Item>

          <Item>
            <p className="secondary-color">
              Выберите склад маркетплейса для связки.
              Это нужно для управления остатками и отгрузки товаров.
              Вы так же можете сделать это позже.
            </p>
          </Item>

          <Compact className="w-full">
            <Item
              className={`w-full ${css.selectAddress}`}
              name="warehouse"
            >
              <Select
                {...SELECT_PROPS}
                placeholder="Склад маркетплейса"
                optionLabelProp="address"
                disabled={isAddressDisabled}
                filterSort={(optionA, optionB) => SELECT_PROPS.filterSort(optionA, optionB, "address")}
                value={values?.warehouse}
              >
                {officesWildberries.list?.map(renderOfficeOption)}
              </Select>
            </Item>

            <PrimaryButton
              className={css.inputField}
              key="linkWarehouse"
              text="Добавить"
              onClickHandler={handleLinkWarehouse}
              isDisabled={isAddButtonDisabled}
            />
          </Compact>

          {linkedWarehouses.length > 0 && (
            <Space direction="vertical" className="flex">
              <p className="secondary-color">Связанные склады маркетплейсов</p>
              <List
                itemLayout="horizontal"
                dataSource={linkedWarehouses}
                renderItem={renderLinkedWarehouse}
              />
            </Space>
          )}

          <Space direction="vertical" className="flex">
            <p className="secondary-color">Комментарий</p>
            <Item
              name="comment"
              rules={[{
                max: 130,
                message: "Длина комментария не может превышать 130 символов"
              }]}
            >
              <TextArea
                showCount
                maxLength={130}
                placeholder="Комментарий"
              />
            </Item>
          </Space>
        </Spin>
      </Form>
    </Modal >
  );
};

export default ModalWarehouse;
