import React, { JSX, FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { fetchMarketplaces } from "redux/thunks/marketplaces.thunks";
import { clearOrders, fetchOrdersFacets, uploadNewOrders } from "redux/thunks/orders.thunks";
import { clearSupplies } from "redux/thunks/supplies.thunks";
import { useForm, useWatch } from "antd/es/form/Form";

import css from "./style.module.css";

import { Badge, Tabs } from "antd";
import OrdersTab from "../OrdersTab/OrdersTab";
import ArchiveTab from "../ArchiveTab/ArchiveTab";
import SuppliesTab from "../SuppliesTab/SuppliesTab";

import type { InitialOrdersStateType } from "redux/reducers/orders.reducer";
import type { InitialSuppliesStateType } from "redux/reducers/supplies.reducer";
import type { AppStateType } from "redux/reducers/mainReducer";
import type { TableColumnType } from "../SupplyTable/SupplyTable";
import type { FiltersDrawerValuesType } from "../../Products/Filters/FiltersDrawer/FiltersDrawer";

interface ITabsWithCountersProps { }

export enum Tab {
	new = "Новые",
	onAssembly = "На сборке",
	inDelivery = "В доставке",
	archive = "Архив",
}

const TabsWithCounters: FC<ITabsWithCountersProps> = (): JSX.Element => {
	const { tab } = useParams();
	const dispatch = useDispatch<AppDispatch>();
	const [form] = useForm<FiltersDrawerValuesType>();
	const values = useWatch<FiltersDrawerValuesType>([], form);

	const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);
	const supplies: InitialSuppliesStateType = useSelector((state: AppStateType) => state.supplies);

	const [activeTab, setActiveTab] = useState<string>(Object.values(Tab)?.[tab] ?? Tab.new);
	const [isUploading, setIsUploading] = useState<boolean>(true);

	const commonColumns: TableColumnType[] = [
		"supply_id", "name", "type", "created_at",
		"orders_count", "orders_sum",
	];

	useEffect(() => {
		dispatch(uploadNewOrders()).then(() => setIsUploading(false));
		dispatch(fetchMarketplaces());
	}, []);

	useEffect(() => {
		dispatch(fetchOrdersFacets({}));
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchOrdersFacets({}));
	}, [orders.list, supplies.list, activeTab]);

	const onChange = (key: string): void => {
		dispatch(clearSupplies());
		dispatch(clearOrders());
		setActiveTab(key);
	};

	const renderLabel = (title: string, count: number = 0): JSX.Element => {
		const countStyle: React.CSSProperties = {
			marginLeft: 4,
			color: activeTab === title ? "#0a84ff" : "#374151",
			backgroundColor: activeTab === title ? "#0A84FF1A" : "#67768C1A"
		};

		return (
			<span>
				{title}
				<Badge
					style={countStyle}
					count={count || null}
					overflowCount={999}
				/>
			</span>
		);
	};

	return (
		<div className={css.wrapper}>
			<Tabs
				className="px-6"
				activeKey={activeTab}
				defaultActiveKey={Tab.new}
				onChange={onChange}
			>
				<Tabs.TabPane
					key={Tab.new}
					tab={renderLabel(Tab.new, orders.facets?.new ?? 0)}
					disabled={orders.isFetching || supplies.isFetching}
				>
					{
						activeTab === Tab.new
						&& !isUploading
						&& <OrdersTab initFilters={{ supplier_status: "new" }} />
					}
				</Tabs.TabPane>

				<Tabs.TabPane
					key={Tab.onAssembly}
					tab={renderLabel(Tab.onAssembly, orders.facets?.confirm ?? 0)}
					disabled={orders.isFetching || supplies.isFetching}
				>
					{
						activeTab === Tab.onAssembly
						&& !isUploading
						&& (
							<SuppliesTab
								initFilters={{ is_done: false, supplier_status: "confirm" }}
								initColumns={[...commonColumns, "due_date", "actions"]}
								description={`Список поставок, которые находятся на сборке. 
                Откройте поставку для сборки и маркировки. После сборки передайте ее в доставку.`}
							/>
						)
					}
				</Tabs.TabPane>

				<Tabs.TabPane
					key={Tab.inDelivery}
					tab={renderLabel(Tab.inDelivery, orders.facets?.complete ?? 0)}
					disabled={orders.isFetching || supplies.isFetching}
				>
					{
						activeTab === Tab.inDelivery
						&& !isUploading
						&& (
							<SuppliesTab
								initFilters={{ is_done: true, supplier_status: "complete" }}
								initColumns={[...commonColumns, "closed_at", "actions"]}
								description="Список поставок для передачи на склад маркетплейса"
							/>
						)
					}
				</Tabs.TabPane>

				<Tabs.TabPane
					key={Tab.archive}
					tab={renderLabel(Tab.archive, orders.facets?.archive ?? 0)}
					disabled={orders.isFetching || supplies.isFetching}
				>
					{
						activeTab === Tab.archive
						&& !isUploading
						&& <ArchiveTab initFilters={{ supplier_status: "archive" }} />
					}
				</Tabs.TabPane>
			</Tabs>
		</div>
	);
};

export default TabsWithCounters;
