import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import * as WBCategoriesAPI from "../../../../api/wbCategories.api";
import dayjs from "dayjs";

import css from "../FiltersDrawer/style.module.css";

import { Form, type FormInstance } from "antd";
import { FilterType, FiltersDrawerValuesType } from "../FiltersDrawer/FiltersDrawer";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close_icon.svg";
import type { MarketplaceType, RequestProductsFiltersType } from "app/types";
import { formatFilters } from "utils/formatFilters";
import { ProductStatuses, ProductStatusesText } from "pages/ProductsPage";

const { useWatch } = Form;

interface IFiltersDrawerTags {
  form?: FormInstance<FiltersDrawerValuesType>;
  setFilters?: (value: RequestProductsFiltersType) => void;
  filters?: RequestProductsFiltersType;
  filterFields: FilterType[];
}

interface ITagProps {
  title?: React.ReactNode,
  text?: React.ReactNode, onDelete: () => void,
  index?: number
}

const Tag: FC<ITagProps> = ({
  title,
  text,
  onDelete,
}) => (
  <div className={css.tag}>
    <p className={css.tagText}>{title}</p>
    <p className={`flex ${css.tagValue}`}>
      {text}
    </p>
    <div className="cursor-pointer" onClick={onDelete}>
      <CloseIcon className={css.icon} />
    </div>
  </div>
);

export const FiltersDrawerTags: FC<IFiltersDrawerTags> = ({ form, setFilters, filters, filterFields }) => {
  const marketplaces = useSelector((state: AppStateType) => state.marketplaces);

  const fetchCategoryName = async (_uid: string): Promise<any> => {
    const response: any = await WBCategoriesAPI.getItem(_uid)
      .then((response) => response);

    return response?.data?.name;
  };

  const [categoryNames, setCategoryNames] = useState<string[]>([]);

  useEffect(() => {
    const fetchCategoryNames = async (): Promise<void> => {
      try {
        const names = await Promise.all(
          (filters?.categories || []).map(value => fetchCategoryName(value))
        );
        
        setCategoryNames(names);
      } catch (error) {
        console.error("Error fetching category names:", error);
      }
    };

    fetchCategoryNames();
  }, [filters?.categories]);

  const clearFilters = (): void => {
    form.resetFields();
    setFilters({});
  };

  const renderTag = (category: FilterType, idx: number): React.ReactNode => {
    const onDelete = (category: FilterType | string, value: any): void => {
      const categoryValues = filters[category];

      if (!categoryValues || !Array.isArray(categoryValues) || categoryValues.length === 0) {
        form.setFieldValue(category, null);
      } else {
        // Фильтруем массив и обнуляем, если он становится пустым
        const updatedValues = categoryValues.filter((item) => item !== value);
        const updatedCategory = updatedValues.length > 0 ? updatedValues : undefined;

        form.setFieldValue(category, updatedCategory);
      }

      setFilters(formatFilters(form.getFieldsValue()));
    };

    switch (category || "clear") {
      case "categories": {
        return (
          categoryNames?.length > 0 ? (
            categoryNames.map((name, index) => (
              <Tag
                key={`tag-category-${index}`}
                title="Категория:"
                text={name}
                onDelete={() => onDelete("categories", filters.categories[index])}
              />
            ))
          ) : null
        );
      }

      case "date_of_update":
        return (filters?.updated_after && filters?.updated_until && (
          <Tag
            key={`tag-date_of_update-${idx}`}
            title="Дата изменения:"
            text={<>
              <span>{dayjs(filters.updated_after).format("DD-MM-YYYY")}</span>-
              <span>{dayjs(filters.updated_until).format("DD-MM-YYYY")}</span>
            </>}
            onDelete={() => onDelete("date_of_update", null)}
          />
        ));

      case "colors":
        return (
          filters?.colors && filters.colors.length > 0 ? (
            filters.colors.map((name, index) => (
              <Tag
                key={`tag-colors-${index}`}
                title="Цвет:"
                text={name}
                onDelete={() => onDelete("colors", filters.colors?.[index])}
                index={index}
              />
            ))
          ) : null
        );

      case "sizes":
        return (
          filters?.sizes && filters.sizes?.length > 0 ? (
            filters.sizes.map((name, index) => (
              <Tag
                key={`tag-sizes-${index}`}
                title="Размер:"
                text={name}
                onDelete={() => onDelete("sizes", filters.sizes?.[index])}
                index={index}
              />
            ))
          ) : null
        );

      case "not_synced":
        return (
          (filters.not_synced === true || filters.not_synced === false) && (
            <Tag
              key={`tag-not_synced-${idx}`}
              text={filters.not_synced ? "Не синхронизирован" : "Синхронизирован"}
              onDelete={() => onDelete("not_synced", filters.not_synced)}
            />
          )
        );

      case "status":
        return (
          (filters?.status && !isNaN(filters.status) || filters?.status === ProductStatuses.draft) && (
            <Tag
              key={`tag-status-${idx}`}
              title="Статус:"
              text={ProductStatusesText[filters.status]}
              onDelete={() => onDelete("status", filters.status)}
            />
          )
        );

      case "barcodes": {
        return (
          filters?.barcodes?.map((name, index) => (
            <Tag
              key={`tag-barcodes-${index}`}
              title="Баркод:"
              text={name}
              onDelete={() => onDelete("barcodes", filters.barcodes[index])}
              index={index}
            />
          ))
        );
      }

      case "brands": {
        return (
          filters?.brands?.map((name, index) => (
            <Tag
              key={`tag-brands-${index}`}
              title="Бренд:"
              text={name}
              onDelete={() => onDelete("brands", filters.brands[index])}
              index={index}
            />
          ))
        );
      }

      case "card_id":
        return (
          filters?.card_id && (
            <Tag
              key={`tag-card_id-${idx}`}
              title="Card ID:"
              text={filters.card_id}
              onDelete={() => onDelete("card_id", filters.card_id)}
            />
          )
        );

      case "name":
        return (
          filters?.name && (
            <Tag
              key={`tag-name-${idx}`}
              title="Имя: "
              text={filters.card_id}
              onDelete={() => onDelete("name", filters.name)}
            />
          )
        );

      case "price_update_date":
        return (filters?.price_updated_after && filters?.price_updated_until && (
          <Tag
            key={`tag-price_update_date-${idx}`}
            title="Дата изменения цены:"
            text={<>
              <span>{dayjs(filters.price_updated_after).format("DD-MM-YYYY")}</span>-
              <span>{dayjs(filters.price_updated_until).format("DD-MM-YYYY")}</span>
            </>}
            onDelete={() => onDelete("price_update_date", filters.price_updated_after)}
          />
        ));

      case "price_synced":
        return (filters?.price_synced_after && filters?.price_synced_until && (
          <Tag
            key={`tag-price_synced-${idx}`}
            title="Дата синхронизации цены:"
            text={<>
              <span>{dayjs(filters.price_synced_after).format("DD-MM-YYYY")}</span>-
              <span>{dayjs(filters.price_synced_until).format("DD-MM-YYYY")}</span>
            </>}
            onDelete={() => onDelete("price_synced", filters.price_synced_after)}
          />
        ));
      case "price":
        return (
          <>
            {filters?.price_gte && (
              <Tag
                key={`tag-price-${idx}`}
                title="Цена от:"
                text={filters.price_gte}
                onDelete={() => onDelete("price_gte", filters.price_gte)}
              />
            )}
            {filters?.price_lte && (
              <Tag
                key={`tag-price-${idx}`}
                title="Цена до:"
                text={filters.price_lte}
                onDelete={() => onDelete("price_lte", filters.price_lte)}
              />
            )}
          </>
        );

      case "marketplace":
        return (
          filters?.marketplace && (
            <Tag
              key={`tag-marketplace-${idx}`}
              title="Маркетплейс:"
              text={marketplaces.list
                ?.find((marketplace: MarketplaceType) => (marketplace.id === filters.marketplace)).name
              }
              onDelete={() => onDelete("marketplace", filters.marketplace)}
            />
          )
        );

      case "clear": {
        const hasValues = Object.values(filters || {})
          .some((value) => (Array.isArray(value) ? value.length > 0 : value !== null && value !== undefined))
          && filterFields.includes(category);

        return (
          hasValues && (
            <Tag
              key={`tag-brands-${idx}`}
              text="Сбросить фильтры"
              onDelete={() => clearFilters()}
            />
          ));
      }

      default:
        return null;
    }
  };

  return (
    <div className={css.filtersDrawerTags}>
      <div className={css.tagsWrapper}>
        {filters && filterFields?.length > 0 && [...filterFields, "clear"].map(renderTag)}
      </div>
    </div>
  );
};
