import React, { JSX, FC, useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import * as OrdersAPI from "../../api/orders.api";
import {
  fetchSupplyBoxStickers,
  removeSupplyBoxes,
  removeOrderFromBox as removeOrder,
} from "redux/thunks/supplyBoxes.thunks";

// Components
import { Collapse, Dropdown, MenuProps, TablePaginationConfig, message } from "antd";
import ProductsTable from "components/Products/ProductsTable/ProductsTable";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import EmptyBlock from "components/EmptyBlock/EmptyBlock";
import QRList from "components/Orders/QRList/QRList";

// Icons
import { ReactComponent as EmptyIcon } from "../../assets/icons/chart_bar_square.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/three_dots_icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/trash_icon.svg";

import css from "./style.module.css";

import {
  BoxStickersResponseType,
  BoxType,
  OrderType,
  RequestOrdersFiltersType,
  RequestPaginationType,
} from "app/types";
import { AppDispatch } from "redux/store/store";

interface IPackagingBox {
  box: BoxType;
  onAddToBoxHandler: (boxId: string) => void;
  count?: number;
  className?: string;
};

type OrdersType = {
  list: OrderType[] | null;
  count: number;
  isFetching: boolean;
}

export const PackagingBox: FC<IPackagingBox> = ({ box, onAddToBoxHandler, count = 0, className = "" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { supplyId } = useParams();

  const stickerBoxRef = useRef();
  const [stickers, setStickers] = useState<string[]>(null);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [activeKeys, setActiveKeys] = useState<string | string[]>([]);
  const [orders, setOrders] = useState<OrdersType>({
    list: [],
    count: box?.orders_count ?? count,
    isFetching: false,
  });
  const [filters, setFilters] = useState<RequestOrdersFiltersType>(null);
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>(null);
  const [hasItems, setHasItems] = useState<boolean>(false);

  useEffect(() => fetchOrders(), []);

  useEffect(() => {
    setHasItems(orders?.list?.length > 0);
  }, [orders?.list]);

  useEffect(() => {
    if(!hasItems) {
      handleCollapseChange([]);
    }
  }, [hasItems]);

  useEffect(() => {
    if (stickers) {
      handlePrintStickerBox();
    }
  }, [stickers]);

  const panelStyle: React.CSSProperties = {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 24,
    borderRadius: 12,
    background: "white",
  };

  const handlePrintStickerBox = useReactToPrint({
    documentTitle: "QR-код короба",
    content: () => stickerBoxRef.current,
    onAfterPrint: () => setStickers(null),
  });

  const getImageFromBase64 = (data: string): string => {
    const binaryData = atob(data);

    const byteArray = new Uint8Array(binaryData.length);
    
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: "image/png" });

    const imageUrl = URL.createObjectURL(blob);

    return imageUrl;
  };

  const handlePrintQRBox = (): void => {
    setIsPrinting(true);

    dispatch(fetchSupplyBoxStickers(supplyId, [box.id]))
      .then((response: BoxStickersResponseType[]) => {
        const data: string[] = response?.map((file) => file.sticker_file);
        const urls: string[] = data.map(getImageFromBase64);
        
        setStickers(urls);
      })
      .catch((error: any) => {
        console.log(error);

        message.error(error?.response?.data?.error);
      })
      .finally(() => setIsPrinting(false));
  };

  const handleOnDelete = (supplyId: string): void => {
    setOrders((state) => ({
      ...state,
      isFetching: true
    }));

    dispatch(removeSupplyBoxes(supplyId, [box.id]))
      .then(() => setOrders((state) => ({ ...state, isFetching: false })));
  };

  const fetchOrders = (
    pagination: RequestPaginationType = {}
  ): void => {
    OrdersAPI.list(pagination, {
      supply_id: supplyId,
      box_id: box?.id,
    })
      .then((response) => {
        setOrders((state) => ({
          ...state,
          list: response.data.items,
          count: response.data.count,
          isFetching: false,
        }));
      })
      .catch((error) => {
        console.log("error", error);

        setOrders((state) => ({
          ...state,
          isFetching: false,
        }));
      });
  };

  const handleRemoveOrderFromBox = (orderId: string): void => {
    dispatch(removeOrder(supplyId, box.id, orderId))
      .then(() => {
        setOrders((state) => ({
          ...state,
          list: state.list.filter((order: OrderType) => order.id !== orderId),
          count: state.count - 1,
        }));

        message.success("Товар удален из коробки");
      });
  };

  const handleCollapseChange = (key: string | string[]): void => {
    setActiveKeys(key);
  };

  const handleAddToBox = (): void => {
    fetchOrders();
    onAddToBoxHandler(box?.id);
  };

  const renderPackagingBoxActions = (): JSX.Element => {
    const items: MenuProps["items"] = [
      {
        label: <a onClick={handlePrintQRBox}>Распечатать QR-код короба</a>,
        key: `label-print-${box?.trbx_id}`,
        disabled: isPrinting,
      },
      {
        label: <a onClick={() => handleOnDelete(supplyId)}>Удалить короб</a>,
        key: `label-delete-${box?.trbx_id}`,
        className: css.deleteBox,
      },
    ];

    return (
      <div className="flex flex-row gap-3">
        <PrimaryButton
          text="Добавить товары в короб"
          size="middle"
          onClickHandler={handleAddToBox}
        />
        <Dropdown menu={{ items }} trigger={["click"]}>
          <MenuIcon className="m-auto cursor-pointer secondary-color" />
        </Dropdown>
      </div>
    );
  };

  const renderOrderAction = (_value: any, order: OrderType, idx: number): React.ReactNode => {
    return (
      <a key={`m-auto remove-order-${idx}`} onClick={() => handleRemoveOrderFromBox(order.id)}>
        <DeleteIcon className={`blue-color ${css.icon}`} />
      </a>
    );
  };

  const renderEmptyBlock = (): JSX.Element => (
    <EmptyBlock
      icon={<EmptyIcon className={`mb-6 ${css.emptyIcon}`} />}
      style={{ height: "calc(100vh - 23rem)" }}
      title="Нет заданий"
      description=""
    />
  );

  return (
    <>
      <Collapse
        collapsible="header"
        expandIconPosition="start"
        ghost
        className={`${css.packagingBox} ${className}`}
        onChange={handleCollapseChange}
        activeKey={activeKeys}
        items={[
          {
            key: `collapse-${box?.id}`,
            showArrow: hasItems,
            collapsible: hasItems ? "header" : "disabled",
            label: (
              <div className="flex items-baseline gap-3 select-none">
                <h5>Короб {box?.trbx_id ?? ""}</h5>
                <div className="secondary-color text-16-r">{orders?.count ?? 0}</div>
              </div>
            ),
            children: (
              <ProductsTable
                columns={["photo", "name", "size", "color", "barcode", "actions"]}
                list={orders.list}
                count={orders.count}
                fetchList={fetchOrders}
                isFetching={!!orders?.isFetching}
                renderActions={renderOrderAction}
                filters={filters}
                setPaginationInfo={setPaginationInfo}
                emptyBlock={renderEmptyBlock()}
                isShowEmptyBlock={!hasItems}
              />
            ),
            extra: renderPackagingBoxActions(),
            style: panelStyle
          },
        ]}
      />
      <div className="hidden">
        <QRList
          title="QR-код короба"
          ref={stickerBoxRef}
          links={stickers}
        />
      </div>
    </>
  );
};

export default PackagingBox;