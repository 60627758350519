import { RequestOrdersFiltersType, RequestProductsFiltersType } from "app/types";
import { FiltersDrawerValuesType } from "components/Products/Filters/FiltersDrawer/FiltersDrawer";
import dayjs from "dayjs";
import { checkObjIsEmpty } from "./checkObjIsEmpty";

export const formatFilters = (filtersValues: FiltersDrawerValuesType): RequestProductsFiltersType => {
  const {
    search,
    status,
    categories,
    date_of_update,
    colors,
    sizes,
    card_id,
    not_synced,
    name,
    price_update_date,
    price_gte,
    price_lte,
    marketplace,
    brands,
    barcodes,
    price_synced,
  } = filtersValues ?? {};

  const filters = {
    search,
    status,
    updated_after: date_of_update?.[0] && dayjs(date_of_update?.[0]).format("YYYY-MM-DD"),
    updated_until: date_of_update?.[1] && dayjs(date_of_update?.[1]).format("YYYY-MM-DD"),
    colors: colors?.length > 0 ? colors : undefined,
    sizes: sizes?.length > 0 ? sizes : undefined,
    card_id,
    not_synced,
    name,
    price_updated_after: price_update_date?.[0] && dayjs(price_update_date?.[0]).format("YYYY-MM-DD"),
    price_updated_until: price_update_date?.[1] && dayjs(price_update_date?.[1]).format("YYYY-MM-DD"),
    price_gte: price_gte,
    price_lte: price_lte,
    marketplace: marketplace,
    categories: categories?.filter((value) => value !== null)?.length > 0
      ? categories.filter((value) => value !== null)
      : undefined,
    brands: brands?.length > 0 ? brands : undefined,
    barcodes: barcodes?.length > 0 ? barcodes : undefined,
    price_synced_after: price_synced?.[0] && dayjs(price_synced?.[0]).format("YYYY-MM-DD"),
    price_synced_until: price_synced?.[1] && dayjs(price_synced?.[1]).format("YYYY-MM-DD"),
  };

  return filters;
};

export const formatOrderFilters = (filtersValues: FiltersDrawerValuesType): RequestOrdersFiltersType => {
  const {
    warehouse_id,
    supply_id,
    box_id,
    without_box,
    wb_status,
    supplier_status,
  } = filtersValues ?? {};

  const filters = {
    warehouse_id,
    supply_id,
    box_id,
    without_box,
    wb_status,
    supplier_status,
    product: !checkObjIsEmpty(formatFilters(filtersValues)) ? formatFilters(filtersValues) : undefined,
  };

  return filters;
};