import axios from "axios";
import { ThunkAction } from "redux-thunk";
import {
	AccountActionsTypes,
	fetchUserInfoFailure,
	fetchUserInfoSuccess,
	getUserTokenDescriptionFailure,
	getUserTokenDescriptionSuccess,
	loginFailure,
	loginSuccess,
	logout,
	refreshTokenFailure,
	refreshTokenSuccess,
	tokenRevocationSuccess,
	tokenRevocationFailure,
	fetchLkUserInfoRequest,
	fetchLkUserInfoSuccess,
	fetchLkUserInfoFailure,
	resetPasswordRequest,
	resetPasswordSuccess,
	resetPasswordFailure,
	resetPasswordEmailSuccess,
	resetPasswordEmailFailure,
	resetPasswordEmailRequest,
} from "../actions/account.actions";
import { AppStateType } from "../reducers/mainReducer";

import * as AccountAPI from "../../api/account.api";
import asyncLocalStorage from "../../utils/asyncLocalStorage";

// Авторизация
export const login = (
	username: string,
	password: string
): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	AccountActionsTypes
> => async (dispatch) => {
	try {
		const response = await AccountAPI.login(username, password);

		asyncLocalStorage
			.setItem("authToken", JSON.stringify(response.data.access_token))
			.then((): void => {
				dispatch(loginSuccess(response.data.access_token, response.data.refresh_token));
				axios.defaults.headers.common.Authorization = response.data.access_token;
			});

		localStorage.setItem("refreshToken", JSON.stringify(response.data.refresh_token));

	} catch (error: any) {
		dispatch(loginFailure(error?.response?.data?.error_description));
	}
};

// Обновление токена через refresh_token
export const fetchNewToken = (refreshToken: string | null):
	ThunkAction<
		Promise<void>,
		AppStateType,
		undefined,
		AccountActionsTypes
	> => async (dispatch) => {
		try {
			const response = await AccountAPI.updateToken(refreshToken);

			asyncLocalStorage
				.setItem("authToken", JSON.stringify(response?.data?.access_token))
				.then((): void => {
					dispatch(refreshTokenSuccess(response?.data.access_token, response?.data?.refresh_token));
					axios.defaults.headers.common.Authorization = response?.data?.access_token;
				});

			localStorage.setItem("refreshToken", JSON.stringify(response?.data?.refresh_token));

		} catch (error: any) {
			dispatch(refreshTokenFailure(error?.message));
			dispatch(logout());
		}
	};

// Отзыв токена
export const tokenRevocation = ():
	ThunkAction<
		Promise<void>,
		AppStateType,
		undefined,
		AccountActionsTypes
	> => async (dispatch) => {
		try {
			await AccountAPI.logout();

			dispatch(tokenRevocationSuccess());
			dispatch(logout());
		} catch (error: any) {
			dispatch(tokenRevocationFailure(error?.message));
		};
	};

// Расшифровка токена авторизованного пользователя
export const getUserTokenDescriptionData = ():
	ThunkAction<
		Promise<void>,
		AppStateType,
		undefined,
		AccountActionsTypes
	> => async (dispatch) => {
		try {
			const response = await AccountAPI.getUserTokenDescription();

			dispatch(getUserTokenDescriptionSuccess(response?.data));
		} catch (error: any) {
			dispatch(getUserTokenDescriptionFailure(error?.message));
		}
	};

// Получение информации об авторизованном пользователе по токену
export const fetchUserInfo = ():
	ThunkAction<
		Promise<void>,
		AppStateType,
		undefined,
		AccountActionsTypes
	> => async (dispatch) => {
		try {
			const response = await AccountAPI.fetchUserInfo();

			dispatch(fetchUserInfoSuccess(response?.data));
		} catch (error: any) {
			dispatch(fetchUserInfoFailure(error?.message));
		}
	};

// Получение информации об авторизованном пользователе по токену с бэка
export const fetchLkUserInfo = ():
	ThunkAction<
		Promise<void>,
		AppStateType,
		undefined,
		AccountActionsTypes
	> => async (dispatch) => {
		dispatch(fetchLkUserInfoRequest());
		try {
			const response = await AccountAPI.fetchLkUserInfo();

			dispatch(fetchLkUserInfoSuccess(response?.data));
		} catch (error: any) {
			console.log(error);
			dispatch(fetchLkUserInfoFailure(error?.message));
		}
	};

// Восстановление пароля
export const resetPassword = (password: string):
	ThunkAction<
		Promise<void>,
		AppStateType,
		undefined,
		AccountActionsTypes
	> => async (dispatch) => {
		dispatch(resetPasswordRequest(password));
		try {
			const response = await AccountAPI.resetPassword(password);

			dispatch(resetPasswordSuccess(response?.data));

			return Promise.resolve(response?.data);
		} catch (error: any) {
			console.log(error);
			dispatch(resetPasswordFailure(error?.message));
			
			return Promise.reject(error?.message);
		}
	};

// Запрос ссылки на восстановление пароля
export const resetPasswordEmail = (email: string):
	ThunkAction<
		Promise<void>,
		AppStateType,
		undefined,
		AccountActionsTypes
	> => async (dispatch) => {
		dispatch(resetPasswordEmailRequest(email));
		try {
			const response = await AccountAPI.resetPasswordEmail(email);

			dispatch(resetPasswordEmailSuccess(response?.data));

			return Promise.resolve(response?.data);
		} catch (error: any) {
			console.log(error);
			dispatch(resetPasswordEmailFailure(error?.message));

			return Promise.reject(error?.message);
		}
	};
