import React, { JSX, FC, useState, useEffect, useMemo } from "react";
import generateSortString from "utils/generateSortString";
import dayjs from "dayjs";
import { checkObjIsEmpty } from "utils/checkObjIsEmpty";

import css from "./style.module.css";

import { Table, Tag, Tooltip } from "antd";
import Column from "antd/es/table/Column";

import type {
  OrdersFacetsType,
  RequestPaginationType,
  RequestSuppliesFiltersType,
  SupplyType
} from "app/types";
import { TableRowSelection, TablePaginationConfig, SortOrder, SorterResult } from "antd/es/table/interface";
import { ITimeRemaining, calculateTimeRemaining } from "hooks/useTimeRemaining";
import { NavigateFunction, useNavigate } from "react-router";

export type TableColumnType =
  | "orders_sum"
  | "orders_count"
  | "name"
  | "created_at"
  | "closed_at"
  | "supply_id"
  | "type"
  | "actions"
  | "due_date"
  ;

interface IOrdersTable {
  columns: TableColumnType[];
  list?: SupplyType[];
  count?: number;
  fetchList?: (pagination: RequestPaginationType, filters: RequestSuppliesFiltersType) => void;
  isFetching?: boolean;
  renderActions?: (value: any, record: any, idx: number) => React.ReactNode;
  rowSelection?: TableRowSelection<any>;
  filters?: RequestSuppliesFiltersType;
  setPaginationInfo?: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setSorterInfo?: React.Dispatch<React.SetStateAction<SorterResult<any> | SorterResult<any>[]>>;
  emptyBlock?: React.ReactNode; // Пустой блок: isFetching + (list.length = 0) + (count = 0) + (isShowEmptyBlock = true)
  isShowEmptyBlock?: boolean; // Показывать пустой блок и тут можно свою проверку || true (см. выше)
  emptyExcludedFilters?: string[]; // Какие фильтры не учитывать в пустом блоке
  className?: string;
}

const OrdersTable: FC<IOrdersTable> = ({
  columns,
  list,
  count,
  fetchList,
  isFetching,
  renderActions,
  rowSelection,
  filters,
  setPaginationInfo,
  setSorterInfo,
  emptyBlock,
  isShowEmptyBlock = false,
  emptyExcludedFilters = [],
  className = "",
}) => {
  const navigate: NavigateFunction = useNavigate();
  
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const [suppliesList, setSuppliesList] = useState<SupplyType[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: count ?? 0,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [sorter, setSorter] = useState<any>(null);

  useEffect(() => {
    if (!isFetching && !!fetchList) {
      fetchList({
        page: pagination.current,
        page_size: pagination.pageSize,
        ...(sorter ? { ordering: generateSortString(sorter) } : {}),
      }, filters);
    }
  }, [filters, pagination.current, pagination.pageSize, generateSortString(sorter)]);

  useEffect(() => {
    setPagination((prevState) => ({ ...prevState, current: 1, total: count ?? 0 }));
  }, [count]);

  // Проверка на пустой блок только при смене isFetching
  // Другие решения могут вызывать лишний re render
  useEffect(() => {
    !isFetching && setShowEmpty(
      !isFetching
      && !(list?.length > 0)
      && (count ?? 0) === 0
      && checkObjIsEmpty(filters, emptyExcludedFilters)
    );
  }, [isFetching]);

  useEffect(() => {
    setSuppliesList(
      list?.map((order: SupplyType, idx: number) => ({
        key: idx.toString(),
        ...order,
      })) || []
    );
  }, [list]);

  useEffect((): void => {
    if (setPaginationInfo && pagination) {
      setPaginationInfo(pagination);
    }
  }, [setPaginationInfo, pagination]);

  useEffect((): void => {
    if (setSorterInfo && sorter) {
      setSorterInfo(sorter);
    }
  }, [setSorterInfo, sorter]);

  const onPaginationChange = (pagination: TablePaginationConfig, _filters: any, sorter: any): void => {
    setSorter(sorter);
    setPagination(pagination);
  };

  const renderSearchNotFound = (): JSX.Element => <>Ничего не найдено</>;

  const getTagColor = (dueDate: ITimeRemaining): "success" | "error" | "warning" => {
    if (dueDate) {
      if (dueDate?.days < 1) {
        return "error";
      } else if (dueDate?.days < 2) {
        return "warning";
      }

      return "success";
    }
    return "error";
  };

  const getNavigateLink = (supply: SupplyType): string => {
    const supply_id: string = supply.id;

    return `/supply/${supply_id}`;
  };
  
  const renderTextWithTooltip = (text: string | null | undefined, length?: number): JSX.Element => {
    const maxLength = length ?? 30;

    if (text) {
      return (
        <Tooltip title={text}>
          <div className="flex text-ellipsis overflow-hidden">
            {text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text}
          </div>
        </Tooltip>
      );
    }

    return <></>;
  };
  
  const renderColumn = (column: TableColumnType, index: number): JSX.Element | null => {
    switch (column) {
      case "orders_sum":
        return (
          <Column
            key="orders_sum"
            title="Стоимость поставки"
            dataIndex="orders_sum"
            width={130}
            render={(value: number) => {
              return (
                <div>
                  {value}
                </div>
              );
            }}
          />
        );

      case "orders_count":
        return (
          <Column
            key="orders_count"
            title="Кол-во товаров"
            dataIndex="orders_count"
            width={80}
            render={(value: number) => {
              return (
                <div>
                  {value}
                </div>
              );
            }}
          />
        );

      case "name":
        return (
          <Column
            key="name"
            title="Название поставки"
            dataIndex="name"
            width={200}
            render={(value: string, entity: SupplyType) => {
              return (
                <a className="flex" onClick={() => navigate(getNavigateLink(entity))}>
                  {renderTextWithTooltip(value)}
                </a>
              );
            }}
          />
        );

      case "created_at":
        return (
          <Column
            key="created_at"
            title="Дата создания поставки"
            dataIndex="created_at"
            sorter={{ multiple: index }}
            showSorterTooltip={false}
            className="select-none"
            width={150}
            sortDirections={["ascend", "descend"]}
            render={(value: string) => {
              return (
                <div className="flex flex-col">
                  <div>{dayjs(value)?.format("DD.MM.YYYY")}</div>
                  <div className="secondary-color text-12-r">
                    {dayjs(value)?.format("HH:mm")}
                  </div>
                </div>
              );
            }}
          />
        );

      case "closed_at":
        return (
          <Column
            key="closed_at"
            title="Дата передачи в доставку"
            dataIndex="closed_at"
            sorter={{ multiple: index }}
            showSorterTooltip={false}
            className="select-none"
            width={150}
            sortDirections={["ascend", "descend"]}
            render={(_value: string, entity: SupplyType) => {
              const value: string = entity.orders?.[0]?.created_in_marketplace_at;

              return (
                <div className="flex flex-col">
                  <div>{dayjs(value)?.add(120, "h")?.format("DD.MM.YYYY")}</div>
                  <div className="secondary-color text-12-r">
                    {dayjs(value)?.add(120, "h")?.format("HH:mm")}
                  </div>
                </div>
              );
            }}
          />
        );

      case "due_date":
        return (
          <Column
            key="Due_date"
            title="Срок исполнения"
            dataIndex="created_in_marketplace_at"
            sorter={{ multiple: index }}
            showSorterTooltip={false}
            className="select-none"
            width={150}
            sortDirections={["ascend", "descend"]}
            render={(_value: string, entity: SupplyType) => {
              const value: string = entity.orders?.[0]?.created_in_marketplace_at;

              if (!value) {
                return null;
              }

              const dueDate: ITimeRemaining = calculateTimeRemaining(
                dayjs(value).add(120, "h").toDate(),
                { Days: true, Hours: true, Minutes: true }
              );

              const remainingHours = dueDate?.hours + 24 * dueDate?.days;

              return (
                <div className="flex flex-col">
                  {dueDate && <span className="secondary-color text-12-r">осталось</span>}
                  <Tag color={getTagColor(dueDate)} className="w-fit">
                    {remainingHours > 0 ? remainingHours + " ч. " : ""}
                    {dueDate?.minutes > 0 ? dueDate?.minutes + " м." : ""}
                    {!dueDate && "время истекло"}
                  </Tag>
                </div>
              );
            }}
          />
        );

      case "type":
        return (
          <Column
            key="type"
            title="Тип поставки"
            dataIndex="type"
            width={100}
            render={(value: boolean) => {
              return (
                <div> {/* TODO: переделать как только появится поле с типом поставки */}
                  {value ? "Обычная" : "Обычная"}
                </div>
              );
            }}
          />
        );

      case "supply_id":
        return (
          <Column
            key="supply_id"
            title="№ поставки"
            dataIndex="supply_id"
            width={150}
            fixed="left"
            sorter={{ multiple: index }}
            showSorterTooltip={false}
            render={(value: string, entity: SupplyType) => {
              return (
                <a className="flex" onClick={() => navigate(getNavigateLink(entity))}>
                  {renderTextWithTooltip(value)}
                </a>
              );
            }}
          />
        );

      case "actions":
        return (
          <Column
            key="Action"
            title=""
            dataIndex="action"
            width={100}
            fixed="right"
            render={renderActions}
          />
        );

      default:
        return null;
    }
  };

  return !!emptyBlock && isShowEmptyBlock && showEmpty ? (
    emptyBlock
  ) : (
    <div className={css.tableWrapper}>
      <Table
        loading={isFetching}
        dataSource={suppliesList}
        pagination={pagination}
        onChange={onPaginationChange}
        rowSelection={columns && (rowSelection || null)}
        className={`${css.table} ${className}`}
        locale={{ emptyText: renderSearchNotFound() }}
        scroll={{ y: "55vh" }}
      >
        {columns.map(renderColumn)}
      </Table>
    </div>
  );
};

export default OrdersTable;