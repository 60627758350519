import React, { JSX, FC } from "react";
import { WBCharacteristicType } from "app/types";
import { FormInstance } from "antd";

import CustomInput from "../../../ui/CustomInput/CustomInput";

interface IDimensionsBlockProps {
	form: FormInstance;
  dimensions: WBCharacteristicType[];
	updateCharc: (fieldName: string) => void;
  isDisabledFields: boolean;
}

const DimensionsBlock: FC<IDimensionsBlockProps> = ({
  form,
  dimensions,
	updateCharc,
  isDisabledFields,
}): JSX.Element => {
	return (
    <div>
			<h4 className="text-h4-b mb-3">Габариты упаковки и товара</h4>
			<p className="secondary-color mb-8">
				{`Если указанные габариты упаковки будут меньше фактических, 
				стоимость логистики и хранения увеличится.`}
			</p>
			<div className="grid grid-cols-4 gap-3">
				{dimensions?.map(({id, name, required, unit_name}) => (
					<CustomInput
						key={id}
            form={form}
						type="inputNumber"
						title={name}
            format={(value) => value?.toString()?.replace(/\./g, "")}
            required={required}
						suffix={unit_name}
						fieldName={id}
            placeholder={name}
            onBlurHandler={() => updateCharc(id)}
            isDisabled={isDisabledFields}
            tooltipTitle="Введите целое число"
					/>
				))}
			</div>
    </div>
  );
};

export default DimensionsBlock;
