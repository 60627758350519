import { message } from "antd";
import { OrderType, StickerType, WBCharacteristicValueType } from "app/types";
import * as OrdersAPI from "../../api/orders.api";
import { getFirstImage } from "../Products/getFirstImage";
import { SelectionListItemType } from "./Supply/SupplyWrapper";

export const generateSelectionListData = async (
  page_size: number,
  supply_id: string,
): Promise<SelectionListItemType[]> => {
  const ordersAllResponse = await OrdersAPI.list({ page: 1, page_size }, { supply_id });

  const ordersAll: OrderType[] = ordersAllResponse?.data?.items;
  const orderIds: string[] = ordersAll?.map((order) => order.id);

  const stickers = await OrdersAPI.getStickers({ order_ids: orderIds, type: "png", size: "size400x300" })
    .catch((error) => message.error(error?.response?.data?.error));

  return ordersAll?.map((order: OrderType) => {
    const sticker: string = stickers?.data?.find((sticker: StickerType) => sticker?.order_id === order.id)?.file;

    return {
      id_in_marketplace: order.id_in_marketplace,
      brand: order.product.characteristic_values.find((charc: WBCharacteristicValueType) =>
        charc.characteristic.name === "Бренд")?.value,
      name: order.product.name,
      vendor_code: order.product.vendor_code,
      barcode: order.barcode,
      photo: getFirstImage(order.product)?.src,
      size: `${order.product.sizes?.[0]?.wb_size ?? ""} ${order.product.sizes?.[0]?.tech_size ?? ""}`,
      color: order.product.characteristic_values
        ?.filter(({ characteristic }) => characteristic.name === "Цвет")
        ?.map(({ value }) => value)
        ?.join(", "),
      sticker: sticker ?? null,
      box: order.box?.trbx_id
    };
  });
};

// группирует товары по коробкам
export const groupOrdersByBoxes = (supplyOrders: SelectionListItemType[]): SelectionListItemType[][] => {
  return supplyOrders.reduce((accumulator: SelectionListItemType[][], order: SelectionListItemType) => {
    const foundIndex: number = accumulator.findIndex((group: SelectionListItemType[]) =>
      group?.[0]?.box === order?.box);

    if (foundIndex !== -1) {
      // Если группа существует, добавляем объект к этой группе
      accumulator[foundIndex].push(order);
    } else {
      // Если группы не существует, создаем новую группу
      accumulator.push([order]);
    }

    return accumulator;
  }, []);
};
