import React, { FC, JSX, useEffect } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import { AppDispatch } from "../../../redux/store/store";
import { loginFailure } from "../../../redux/actions/account.actions";
import { login } from "../../../redux/thunks/account.thunks";
import { Form, Input } from "antd";

// components
import SubmitButton from "../../SubmitButton/SubmitButton";
import FormTitle from "../FormTitle/FormTitle";

import css from "./style.module.css";

interface ILoginFormProps {}

const LoginForm: FC<ILoginFormProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();

  const { authToken, error } = useSelector((state: AppStateType) => state.account);

  const [form] = Form.useForm();

  const { getFieldValue } = form;

  useEffect((): void => {
    if (authToken) {
      navigate("/dashboard");
    }
  }, [authToken, navigate]);

  const handleSubmit = (): void => {
    dispatch(login(
      getFieldValue("email"),
      getFieldValue("password").replace(/\s/g, "")
      )
    );
  };

  const resetError = (): void => {
    dispatch(loginFailure(null));
  };

  const blockSpace = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const isInvalidCredentials: boolean = error === "Invalid user credentials";
  const errorStyle: string = isInvalidCredentials ? css.error : "";

  return (
    <div>
      <FormTitle title="Вход" subtitle="С возвращением! Введите ваши данные для авторизации."/>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите ваш email!"
            },
            {
              type: "email",
              message: "Введите email в формате email@mail.ru"
            }
          ]}
        >
          <Input
            className={`${errorStyle} ${css.loginFormInput}`}
            placeholder="Email"
            onInput={resetError}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите ваш пароль"
            },
          ]}
        >
          <Input.Password
            onKeyDown={blockSpace}
            className={`${errorStyle} ${css.loginFormInput}`}
            placeholder="Пароль"
            onInput={resetError}
            style={{position: "relative", }}
          />
        </Form.Item>
        <Form.Item>
          <span className={`danger-color ${css.invalidPass}`}>
          {isInvalidCredentials ? "Неверный логин или пароль" : ""}
          </span>
          <SubmitButton
            form={form}
            text="Войти"
            onClick={() => { }}
            className="relative mt-4"
          />
        </Form.Item>
      </Form>
      <Link
        to="/reset-password"
        className="blue-color mb-6 inline-block"
      >
        Не помню пароль
      </Link>
      <p>
        У вас нет аккаунта?
        <Link to="/registration" className="blue-color"> Зарегистрируйтесь</Link>
      </p>
    </div>
  );
};

export default LoginForm;
