import { SupplyType } from "app/types";
import { SuppliesActionsType } from "../actions/supplies.actions";
import {
  FETCH_SUPPLIES_LIST_REQUEST,
  FETCH_SUPPLIES_LIST_SUCCESS,
  FETCH_SUPPLIES_LIST_FAILURE,
  FETCH_SUPPLY_REQUEST,
  FETCH_SUPPLY_SUCCESS,
  FETCH_SUPPLY_FAILURE,
  CLEAR_SUPPLIES_LIST,
} from "../../constants/actionTypes";

export type InitialSuppliesStateType = {
  error: string | null;
  list: SupplyType[] | null;
  found: SupplyType | null;
  count: number;
  isFetching: boolean;
};

const initialState: InitialSuppliesStateType = {
  list: null,
  count: 0,
  found: null,
  error: null,
  isFetching: false,
};

const supplies = (
  state: InitialSuppliesStateType = initialState,
  action: SuppliesActionsType,
): InitialSuppliesStateType => {
  switch (action.type) {
    // Список поставок
    case FETCH_SUPPLIES_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUPPLIES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.items,
        count: action.payload.count,
        isFetching: false,
        error: null,
      };
    case FETCH_SUPPLIES_LIST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };

    // поставка
    case FETCH_SUPPLY_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case FETCH_SUPPLY_SUCCESS:
      return {
        ...state,
        found: action.payload.supply,
        isFetching: false,
        error: null,
      };
    case FETCH_SUPPLY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };

    // Очистка
    case CLEAR_SUPPLIES_LIST:
      return {
        ...state,
        list: action.payload,
      };

    default:
      return state;
  }
};

export default supplies;
