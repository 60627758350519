import React, { JSX, FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import { InitialOrdersStateType } from "../../../redux/reducers/orders.reducer";
import { AppDispatch } from "../../../redux/store/store";
import { fetchDashboard } from "../../../redux/thunks/orders.thunks";

import { Button } from "antd";
import ChartCard from "./ChartCard/ChartCard";

type PeriodButtonType = {
  label: string;
  value: "week" | "month" | "year";
};

interface IDashboardAnalyticsProps {
  topMode: "revenue" | "quantity";
  setPeriod: React.Dispatch<React.SetStateAction<"week" | "month" | "year">>;
}

const periodButtons: PeriodButtonType[] = [
  { label: "Неделя", value: "week" },
  { label: "Месяц", value: "month" },
  { label: "Год", value: "year" },
];

const DashboardAnalytics: FC<IDashboardAnalyticsProps> = ({topMode, setPeriod}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);

  const { dashboard } = orders;

  const [selectedOption, setSelectedOption] = useState<"week" | "month" | "year">("week");

  const handleButtonClick = (option: "week" | "month" | "year"): void => {
    setSelectedOption(option);
    setPeriod(option);

    dispatch(fetchDashboard({
      top: topMode,
      period: option
    }));
  };

  const renderPeriodButton = (option: PeriodButtonType, idx: number) => {
    return (
      <Button
        key={option.value}
        type={selectedOption === option.value ? "primary" : "default"}
        className={`${selectedOption === option.value ? "bg-blue-500" : ""} ${
          idx === periodButtons.length - 1 ? "" : "mr-2"
        }`}
        onClick={() => handleButtonClick(option.value)}
      >
        {option.label}
      </Button>
    );
  };

  return (
    <div>
      <div className="flex justify-between mb-3">
        <p className="text-18-m">Аналитика</p>
        <div>{periodButtons.map(renderPeriodButton)}</div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <ChartCard
          title="Продажи"
          data={dashboard?.sales}
        />
        <ChartCard
          title="Выручка"
          data={dashboard?.revenue ?? {}}
          suffix="руб"
        />
      </div>
    </div>
  );
};

export default DashboardAnalytics;
