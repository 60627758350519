import {
  BoxStickersRequestType as StickersRequestType,
  BoxStickersResponseType,
  BoxType, RequestPaginationType,
} from "app/types";

import {
  FETCH_SUPPLY_BOXES_LIST_REQUEST,
  FETCH_SUPPLY_BOXES_LIST_SUCCESS,
  FETCH_SUPPLY_BOXES_LIST_FAILURE,
  CREATE_SUPPLY_BOXES_REQUEST,
  CREATE_SUPPLY_BOXES_SUCCESS,
  CREATE_SUPPLY_BOXES_FAILURE,
  DELETE_SUPPLY_BOX_REQUEST,
  DELETE_SUPPLY_BOX_SUCCESS,
  DELETE_SUPPLY_BOX_FAILURE,
  FETCH_BOX_STICKERS_REQUEST,
  FETCH_BOX_STICKERS_SUCCESS,
  FETCH_BOX_STICKERS_FAILURE,
  REMOVE_ORDER_FROM_BOX_FAILURE,
  REMOVE_ORDER_FROM_BOX_REQUEST,
  REMOVE_ORDER_FROM_BOX_SUCCESS,
} from "../../constants/actionTypes";

export type SupplyBoxesActionsType =
  | CreateBoxesRequestType
  | CreateBoxesSuccessType
  | CreateBoxesFailureType
  | FetchBoxesListRequestType
  | FetchBoxesListSuccessType
  | FetchBoxesListFailureType
  | DeleteBoxRequestType
  | DeleteBoxSuccessType
  | DeleteBoxFailureType
  | FetchBoxStickersRequestType
  | FetchBoxStickersSuccessType
  | FetchBoxStickersFailureType
  | RemoveOrderFromBoxRequestType
  | RemoveOrderFromBoxSuccessType
  | RemoveOrderFromBoxFailureType
  ;

// создание коробов
type CreateBoxesRequestType = {
  type: typeof CREATE_SUPPLY_BOXES_REQUEST;
  payload: {
    supply_id: string;
    amount: number;
  };
};

export const createBoxesRequest = (supplyId: string, amount: number): CreateBoxesRequestType => ({
  type: CREATE_SUPPLY_BOXES_REQUEST,
  payload: {
    supply_id: supplyId,
    amount
  }
});

type CreateBoxesSuccessType = {
  type: typeof CREATE_SUPPLY_BOXES_SUCCESS;
  payload: {
    items: BoxType[];
  }
};

export const createBoxesSuccess = (items: BoxType[]): CreateBoxesSuccessType => ({
  type: CREATE_SUPPLY_BOXES_SUCCESS,
  payload: { items }
});

type CreateBoxesFailureType = {
  type: typeof CREATE_SUPPLY_BOXES_FAILURE;
  payload: {
    error: string;
  };
};

export const createBoxesFailure = (error: string): CreateBoxesFailureType => ({
  type: CREATE_SUPPLY_BOXES_FAILURE,
  payload: {
    error,
  },
});

// получение листинга коробов
type FetchBoxesListRequestType = {
  type: typeof FETCH_SUPPLY_BOXES_LIST_REQUEST;
  payload: {
    supply_id: string;
    pagination: RequestPaginationType;
  };
};

export const fetchBoxesListRequest = (
  supplyId: string,
  pagination: RequestPaginationType
): FetchBoxesListRequestType => ({
  type: FETCH_SUPPLY_BOXES_LIST_REQUEST,
  payload: {
    supply_id: supplyId,
    pagination
  }
});

type FetchBoxesListSuccessType = {
  type: typeof FETCH_SUPPLY_BOXES_LIST_SUCCESS;
  payload: {
    items: BoxType[];
    count: number;
  }
};

export const fetchBoxesListSuccess = ({ items, count }): FetchBoxesListSuccessType => ({
  type: FETCH_SUPPLY_BOXES_LIST_SUCCESS,
  payload: { items, count }
});

type FetchBoxesListFailureType = {
  type: typeof FETCH_SUPPLY_BOXES_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchBoxesListFailure = (error: string): FetchBoxesListFailureType => ({
  type: FETCH_SUPPLY_BOXES_LIST_FAILURE,
  payload: {
    error,
  },
});

// удаление коробов
type DeleteBoxRequestType = {
  type: typeof DELETE_SUPPLY_BOX_REQUEST;
  payload: {
    supply_id: string;
    boxes_ids: string[];
  };
};

export const deleteBoxesRequest = (supplyId: string, boxesIds: string[]): DeleteBoxRequestType => ({
  type: DELETE_SUPPLY_BOX_REQUEST,
  payload: {
    supply_id: supplyId,
    boxes_ids: boxesIds,
  }
});

type DeleteBoxSuccessType = {
  type: typeof DELETE_SUPPLY_BOX_SUCCESS;
  payload: {
    removed: string[];
  }
};

export const deleteBoxesSuccess = (boxesIds: string[]): DeleteBoxSuccessType => ({
  type: DELETE_SUPPLY_BOX_SUCCESS,
  payload: {
    removed: boxesIds,
  }
});

type DeleteBoxFailureType = {
  type: typeof DELETE_SUPPLY_BOX_FAILURE;
  payload: {
    error: string;
  };
};

export const deleteBoxesFailure = (error: any): DeleteBoxFailureType => ({
  type: DELETE_SUPPLY_BOX_FAILURE,
  payload: {
    error,
  },
});

// Стикеры QR для короба
type FetchBoxStickersRequestType = {
  type: typeof FETCH_BOX_STICKERS_REQUEST;
  payload: {
    supply_id: string;
    boxesIds: string[];
  };
};

export const fetchBoxStickersRequest = (
  supplyId: string,
  boxesIds: string[],
): FetchBoxStickersRequestType => ({
  type: FETCH_BOX_STICKERS_REQUEST,
  payload: {
    supply_id: supplyId,
    boxesIds
  }
});

type FetchBoxStickersSuccessType = {
  type: typeof FETCH_BOX_STICKERS_SUCCESS;
  payload: {
    stickers: BoxStickersResponseType[];
  }
};

export const fetchBoxStickersSuccess = (
  stickers: BoxStickersResponseType[]
): FetchBoxStickersSuccessType => ({
  type: FETCH_BOX_STICKERS_SUCCESS,
  payload: { stickers }
});

type FetchBoxStickersFailureType = {
  type: typeof FETCH_BOX_STICKERS_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchBoxStickersFailure = (error: string): FetchBoxStickersFailureType => ({
  type: FETCH_BOX_STICKERS_FAILURE,
  payload: {
    error,
  },
});

// Удаление товара из короба
type RemoveOrderFromBoxRequestType = {
  type: typeof REMOVE_ORDER_FROM_BOX_REQUEST;
  payload: {
    supplyId: string;
    boxId: string;
    orderId: string;
  }
};

export const removeOrderFromBoxRequest = (
  supplyId: string,
  boxId: string,
  orderId: string,
): RemoveOrderFromBoxRequestType => ({
  type: REMOVE_ORDER_FROM_BOX_REQUEST,
  payload: {
    supplyId,
    boxId,
    orderId,
  }
});

type RemoveOrderFromBoxSuccessType = {
  type: typeof REMOVE_ORDER_FROM_BOX_SUCCESS;
  payload: {
    response: string;
  }
};

export const removeOrderFromBoxSuccess = (
  response: string
): RemoveOrderFromBoxSuccessType => ({
  type: REMOVE_ORDER_FROM_BOX_SUCCESS,
  payload: {
    response
  }
});

type RemoveOrderFromBoxFailureType = {
  type: typeof REMOVE_ORDER_FROM_BOX_FAILURE;
  payload: {
    error: any
  }
};

export const removeOrderFromBoxFailure = (
  error: any
): RemoveOrderFromBoxFailureType => ({
  type: REMOVE_ORDER_FROM_BOX_FAILURE,
  payload: {
    error
  }
});
