import process from "process";
import axios from "../utils/axios";
import type {
  RequestCreateSupplyType,
  RequestPaginationType,
  RequestSuppliesFiltersType,
} from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const getItem = async (supplyId: string) => {
  return axios.GET(API_BASE, `/api/orders/supply/${supplyId}`);
};

// Запрос листа поставок
export const list = async (pagination: RequestPaginationType, filters: RequestSuppliesFiltersType): Promise<any> => {
  const searchParams = new URLSearchParams();

  Object.keys(pagination).forEach(key => {
    (Array.isArray(pagination[key]) ? pagination[key] : [pagination[key]]).forEach(value => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    });
  });

  return axios.POST(API_BASE, `/api/orders/supply_list?${searchParams.toString()}`, filters);
};

// Создание поставки с привязкой сборочных заданий
export const add = async (requestBody: RequestCreateSupplyType): Promise<any> => {
  return axios.POST(API_BASE, "/api/orders/supplies", requestBody);
};

// Передача поставок в доставку
export const supplyDeliver = async (supply_ids: string[]): Promise<any> => {
  return axios.POST(API_BASE, "/api/orders/supply_deliver", { supply_ids });
};