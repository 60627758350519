import * as WBCountriesAPI from "../../api/wbCountries.api";
import { ThunkAction } from "redux-thunk";
import {
  fetchWBCountrySuccess,
  fetchWBCountryFailure,
  fetchWBCountriesListSuccess,
  fetchWBCountriesListFailure,
  WbCountriesActionsType, fetchWBCountriesListRequest, fetchWBCountryRequest,
} from "../actions/wbCountries.actions";
import { AppStateType } from "../reducers/mainReducer";

// получение списка стран
export const fetchWBCountries = (search: { search: string } | null = null):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbCountriesActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBCountriesListRequest());

    try {
      const response = await WBCountriesAPI.list(search);

      dispatch(fetchWBCountriesListSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBCountriesListFailure(error?.response?.data?.error));
    }
  };

// получение страны
export const fetchWBCountry= (countryId: string):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbCountriesActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBCountryRequest());

    try {
      const response = await WBCountriesAPI.getItem(countryId);

      dispatch(fetchWBCountrySuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBCountryFailure(error?.response?.data?.error));
    }
  };
