import * as process from "process";
import axios from "../utils/axios";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const list = async (search: { search: string } | null = null): Promise<any> => {

  return axios.GET(API_BASE, "/api/catalog/wb_countries_of_origin", search);
};

export const getItem = async (wbCountryId: string): Promise<any> => {
  return axios.GET(API_BASE, `/api/catalog/wb_countries_of_origin/${wbCountryId}`);
};
