import { OfficeWildberriesType } from "app/types";
import {
	FETCH_OFFICES_WILDBERRIES_LIST_REQUEST,
	FETCH_OFFICES_WILDBERRIES_LIST_SUCCESS,
	FETCH_OFFICES_WILDBERRIES_LIST_FAILURE,
} from "../../constants/actionTypes";

export type OfficesWildberriesActionsType =
	| FetchOfficesWildberriesListRequestType
	| FetchOfficesWildberriesListSuccessType
	| FetchOfficesWildberriesListFailureType;

// получение листинга адресов МП
type FetchOfficesWildberriesListRequestType = {
	type: typeof FETCH_OFFICES_WILDBERRIES_LIST_REQUEST;
};

export const fetchOfficesWildberriesListRequest = (
): FetchOfficesWildberriesListRequestType => ({
	type: FETCH_OFFICES_WILDBERRIES_LIST_REQUEST,
});

type FetchOfficesWildberriesListSuccessType = {
	type: typeof FETCH_OFFICES_WILDBERRIES_LIST_SUCCESS;
	payload: {
		officesWildberries: OfficeWildberriesType[];
	};
};

export const fetchOfficesWildberriesListSuccess = (
	officesWildberries: OfficeWildberriesType[]
): FetchOfficesWildberriesListSuccessType => ({
	type: FETCH_OFFICES_WILDBERRIES_LIST_SUCCESS,
	payload: {
		officesWildberries
	}
});

type FetchOfficesWildberriesListFailureType = {
	type: typeof FETCH_OFFICES_WILDBERRIES_LIST_FAILURE;
	payload: {
		error: string;
	};
};

export const fetchOfficesWildberriesListFailure = (
	error: string
): FetchOfficesWildberriesListFailureType => ({
	type: FETCH_OFFICES_WILDBERRIES_LIST_FAILURE,
	payload: {
		error
	}
});