import React, { FC, JSX } from "react";
import { Divider, Modal, Spin } from "antd";

// components
import GhostButton from "../../ui/GhostButton/GhostButton";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";

interface IConfirmModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  onOkHandler: () => void;
  onCancelHandler: () => void;
  isApplyDisabled?: boolean;
  isLoading?: boolean;
}

const ConfirmModal: FC<IConfirmModalProps> = ({
  title,
  text,
  isOpen,
  onOkHandler,
  onCancelHandler,
  isApplyDisabled = false,
  isLoading = false,
}): JSX.Element => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      centered
      onCancel={onCancelHandler}
      maskStyle={{ backgroundColor: "rgba(103, 118, 140, 0.5)" }}
    >
      <Spin spinning={isLoading}>
        <h4>{title}</h4>
        <p className="secondary-color text-16-r my-6">{text}</p>
        <Divider />
        <div className="flex justify-end">
          <GhostButton
            text="Назад"
            size="large"
            className="mr-3"
            onClickHandler={onCancelHandler}
          />
          <PrimaryButton
            text="Подтвердить"
            onClickHandler={onOkHandler}
            isDisabled={isApplyDisabled}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default ConfirmModal;
