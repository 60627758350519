import React, { JSX, FC } from "react";
import { Button, Space, Tooltip } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import css from "./style.module.css";
import { RenderFunction } from "antd/es/_util/getRenderPropValue";

interface IGhostButtonProps {
  icon?: React.ReactNode;
  text: string;
  onClickHandler: () => void;
  size?: SizeType;
  className?: string;
  isDisabled?: boolean;
  tooltip?: React.ReactNode | RenderFunction;
}

const GhostButton: FC<IGhostButtonProps> = ({
  icon,
  text,
  size,
  onClickHandler,
  className = "",
  isDisabled = false,
  tooltip = "",
}): JSX.Element => {
  return (
    <Tooltip title={tooltip}>
      <Button
        size={size || null}
        onClick={onClickHandler}
        disabled={isDisabled}
        className={`blue-ghost-bg blue-color ${css.btn} ${className}`}
      >
        <Space>{icon ?? ""}{text}</Space>
      </Button>
    </Tooltip>
  );
};

export default GhostButton;
