import * as process from "process";
import { AxiosResponse } from "axios";
import axios from "../utils/axios";
import {
	WarehouseRequestType,
	WarehouseType,
	WarehouseSyncRequestType
} from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const list = async (): Promise<AxiosResponse<WarehouseType[]>> => {
	return axios.GET(API_BASE, "/api/catalog/warehouses");
};

export const find = async (warehouseId: string): Promise<AxiosResponse<WarehouseType>> => {
	return axios.GET(API_BASE, `/api/catalog/warehouses/${warehouseId}`);
};

export const add = async (warehouse: WarehouseRequestType): Promise<any> => {
	return axios.POST(API_BASE, "/api/catalog/warehouses", warehouse);
};

export const update = async (warehouse: WarehouseRequestType, warehouseId: string): Promise<any> => {
	return axios.PUT(API_BASE, `/api/catalog/warehouses/${warehouseId}`, warehouse);
};

export const remove = async (warehouseId: string): Promise<any> => {
	return axios.DELETE(API_BASE, `/api/catalog/warehouses/${warehouseId}`);
};

export const sync = async (warehouseId: string, marketplace_id: string): Promise<any> => {
	const syncWarehouseBody: WarehouseSyncRequestType = {
		marketplace_id
	};

	return axios.POST(API_BASE, `/api/catalog/warehouses/${warehouseId}/sync`, syncWarehouseBody);
};