import {
	DELETE_WAREHOUSE_REQUEST,
	DELETE_WAREHOUSE_SUCCESS,
	DELETE_WAREHOUSE_FAILURE,
	FETCH_WAREHOUSES_LIST_REQUEST,
	FETCH_WAREHOUSES_LIST_SUCCESS,
	FETCH_WAREHOUSES_LIST_FAILURE,
	FIND_WAREHOUSE_REQUEST,
	FIND_WAREHOUSE_SUCCESS,
	FIND_WAREHOUSE_FAILURE,
	CLEAR_FOUND_WAREHOUSE,
	ADD_WAREHOUSE_REQUEST,
	ADD_WAREHOUSE_SUCCESS,
	ADD_WAREHOUSE_FAILURE,
	UPDATE_WAREHOUSE_REQUEST,
	UPDATE_WAREHOUSE_SUCCESS,
	UPDATE_WAREHOUSE_FAILURE,
	SYNC_WAREHOUSE_FAILURE,
} from "../../constants/actionTypes";

import { WarehouseType } from "app/types";
import { WarehousesActionsType } from "../actions/warehouses.actions";

export type InitialWarehousesStateType = {
	error: string | null;
	list: WarehouseType[] | null;
	isFetching: boolean;
	found: WarehouseType | null;
};

const initialState: InitialWarehousesStateType = {
	error: null,
	list: null,
	isFetching: false,
	found: null,
};

const warehouses = (
	state: InitialWarehousesStateType = initialState,
	action: WarehousesActionsType
): InitialWarehousesStateType => {
	switch (action.type) {
		case FETCH_WAREHOUSES_LIST_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_WAREHOUSES_LIST_SUCCESS:
			return {
				...state,
				isFetching: false,
				list: action.payload.warehouses,
			};
		case FETCH_WAREHOUSES_LIST_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error
			};

		case DELETE_WAREHOUSE_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case DELETE_WAREHOUSE_SUCCESS:
			return {
				...state,
				isFetching: false
			};
		case DELETE_WAREHOUSE_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error
			};

		case FIND_WAREHOUSE_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case FIND_WAREHOUSE_SUCCESS:
			return {
				...state,
				isFetching: false,
				found: action.payload.found
			};
		case FIND_WAREHOUSE_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error
			};
		case CLEAR_FOUND_WAREHOUSE:
			return {
				...state,
				found: null,
			};

		case ADD_WAREHOUSE_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case ADD_WAREHOUSE_SUCCESS:
			return {
				...state,
				isFetching: false
			};
		case ADD_WAREHOUSE_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error
			};

		case UPDATE_WAREHOUSE_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error
			};

		case SYNC_WAREHOUSE_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error
			};

		default:
			return state;
	}
};

export default warehouses;
