import { WBCountryType } from "app/types";
import { SelectOptionType } from "../../components/Products/ProductInfoCard/ProductInfoBlock/ProductInfoBlock";
import {
  FETCH_WB_COUNTRY_SUCCESS,
  FETCH_WB_COUNTRY_FAILURE,
  FETCH_WB_COUNTRIES_LIST_SUCCESS,
  FETCH_WB_COUNTRIES_LIST_FAILURE,
  FETCH_WB_COUNTRIES_LIST_REQUEST,
  FETCH_WB_COUNTRY_REQUEST,
} from "../../constants/actionTypes";
import { WbCountriesActionsType } from "../actions/wbCountries.actions";

export type InitialWBCountriesStateType = {
  error: string | null;
  list: SelectOptionType[] | null;
  found: WBCountryType | null;
  isFetching: boolean;
};

const initialState: InitialWBCountriesStateType = {
  error: null,
  list: null,
  found: null,
  isFetching: false,
};

const wbCountries = (
  state: InitialWBCountriesStateType = initialState,
  action: WbCountriesActionsType,
): InitialWBCountriesStateType => {
  switch (action.type) {
    case FETCH_WB_COUNTRIES_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_COUNTRIES_LIST_SUCCESS: {
      const formattedList: SelectOptionType[] = action.payload.countries?.map(({ name }) => {
        return { label: name, value: name };
      });

      return {
        ...state,
        error: null,
        isFetching: false,
        list: formattedList,
      };
    }
    case FETCH_WB_COUNTRIES_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    case FETCH_WB_COUNTRY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_COUNTRY_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        found: action.payload.country,
      };
    case FETCH_WB_COUNTRY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default wbCountries;
