import { DeliveryMethodType } from "app/types";
import {
	FETCH_DELIVERY_METHODS_LIST_REQUEST,
	FETCH_DELIVERY_METHODS_LIST_SUCCESS,
	FETCH_DELIVERY_METHODS_LIST_FAILURE,
} from "../../constants/actionTypes";
import { DeliveryMethodsActionsType } from "../actions/deliveryMethods.actions";

export type InitialDeliveryMethodsStateType = {
	error: string | null;
	list: DeliveryMethodType[] | null;
	isFetching: boolean;
};

const initialState: InitialDeliveryMethodsStateType = {
	error: null,
	list: null,
	isFetching: false,
};

const deliveryMethods = (
	state: InitialDeliveryMethodsStateType = initialState,
	action: DeliveryMethodsActionsType
): InitialDeliveryMethodsStateType => {
	switch (action.type) {
		case FETCH_DELIVERY_METHODS_LIST_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_DELIVERY_METHODS_LIST_SUCCESS:
			return {
				...state,
				isFetching: false,
				list: action.payload.deliveryMethods,
			};
		case FETCH_DELIVERY_METHODS_LIST_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error,
			};
		default:
			return state;
	}
};

export default deliveryMethods;
