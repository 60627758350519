import { MarketplaceType } from "app/types";
import {
	FETCH_MARKETPLACES_LIST_REQUEST,
	FETCH_MARKETPLACES_LIST_SUCCESS,
	FETCH_MARKETPLACES_LIST_FAILURE,
	FETCH_MARKETPLACE_REQUEST,
	FETCH_MARKETPLACE_SUCCESS,
	FETCH_MARKETPLACE_FAILURE,
} from "../../constants/actionTypes";

export type MarketplacesActionsType =
	| FetchMarketplacesListRequestType
	| FetchMarketplacesListSuccessType
	| FetchMarketplacesListFailureType
	| FetchMarketplaceRequestType
	| FetchMarketplaceSuccessType
	| FetchMarketplaceFailureType;

// получение маркетплейсов
type FetchMarketplacesListRequestType = {
	type: typeof FETCH_MARKETPLACES_LIST_REQUEST;
};

export const fetchMarketplacesListRequest = (): FetchMarketplacesListRequestType => ({
	type: FETCH_MARKETPLACES_LIST_REQUEST
});

type FetchMarketplacesListSuccessType = {
	type: typeof FETCH_MARKETPLACES_LIST_SUCCESS;
	payload: {
		marketplaces: MarketplaceType[];
	};
};

export const fetchMarketplacesListSuccess = (
	marketplaces: MarketplaceType[]
): FetchMarketplacesListSuccessType => ({
	type: FETCH_MARKETPLACES_LIST_SUCCESS,
	payload: {
		marketplaces
	}
});

type FetchMarketplacesListFailureType = {
	type: typeof FETCH_MARKETPLACES_LIST_FAILURE;
	payload: {
		error: string;
	};
}

export const fetchMarketplacesListFailure = (
	error: string
): FetchMarketplacesListFailureType => ({
	type: FETCH_MARKETPLACES_LIST_FAILURE,
	payload: {
		error
	}
});

// получение маркетплейcа
type FetchMarketplaceRequestType = {
	type: typeof FETCH_MARKETPLACE_REQUEST;
	payload: {
		marketplaceId: string;
	}
};

export const fetchMarketplaceRequest = (
	marketplaceId: string
): FetchMarketplaceRequestType => ({
	type: FETCH_MARKETPLACE_REQUEST,
	payload: {
		marketplaceId
	}
});

type FetchMarketplaceSuccessType = {
	type: typeof FETCH_MARKETPLACE_SUCCESS;
	payload: {
		marketplace: MarketplaceType;
	};
};

export const fetchMarketplaceSuccess = (
	marketplace: MarketplaceType
): FetchMarketplaceSuccessType => ({
	type: FETCH_MARKETPLACE_SUCCESS,
	payload: {
		marketplace
	}
});

type FetchMarketplaceFailureType = {
	type: typeof FETCH_MARKETPLACE_FAILURE;
	payload: {
		error: string;
	};
}

export const fetchMarketplaceFailure = (
	error: string
): FetchMarketplaceFailureType => ({
	type: FETCH_MARKETPLACE_FAILURE,
	payload: {
		error
	}
});

