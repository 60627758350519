import React, { JSX, FC, useState, useEffect, Key } from "react";

import { NavigateFunction, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { InitialProductsStateType } from "redux/reducers/products.reducer";
import * as ProductsAPI from "../../../api/products.api";
import { FormInstance, useWatch } from "antd/es/form/Form";
import {
  deleteProduct,
  fetchCardInfo,
  fetchProducts,
  syncProducts,
  syncProductsCard
} from "redux/thunks/products.thunks";
import generateSortString from "utils/generateSortString";

// components
import { Space, message, notification, Form } from "antd";
import { ReactComponent as Cart } from "../../../assets/icons/shopping_cart.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/page_info_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash_icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit_icon.svg";
import { FilterType, FiltersDrawer, FiltersDrawerValuesType } from "../Filters/FiltersDrawer/FiltersDrawer";
import { FiltersDrawerTags } from "../Filters/FiltersDrawerTags/FiltersDrawerTags";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import GhostButton from "components/ui/GhostButton/GhostButton";
import EmptyBlock from "../../EmptyBlock/EmptyBlock";
import ProductsTable, { TableColumnType } from "../ProductsTable/ProductsTable";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { Tab } from "../TabsWithCounters/TabsWithCounters";
import { SearchInput } from "components/ui/SearchInput/SearchInput";
import css from "./style.module.css";

import type {
  CardInfoType,
  ProductType,
  RequestProductsFiltersType,
  RequestPaginationType
} from "app/types";
import type { AppStateType } from "redux/reducers/mainReducer";
import type { InitialMarketplacesStateType } from "redux/reducers/marketplaces.reducer";
import { SorterResult, TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";

interface IProductsTab {
  form: FormInstance<FiltersDrawerValuesType>;
  btn: JSX.Element;
  currentTab?: string;
  initFilters?: RequestProductsFiltersType,
  columns: TableColumnType[];
}

/* статусы синхронизации номенклатурной позиции:
* 0 - Черновик, 1 - Архив, 2 - Синхронизирована, 3 - Ошибка интеграции, 4 - В процессе синхронизации
*/
export enum ProductStatus {
  Draft,
  Archive,
  Synchronized,
  IntegrationError,
  InProgress,
}

const ProductsTab: FC<IProductsTab> = ({
  form,
  btn,
  currentTab,
  initFilters,
  columns,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const values = useWatch<FiltersDrawerValuesType>([], form);
  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);
  const marketplaces: InitialMarketplacesStateType = useSelector((state: AppStateType) => state.marketplaces);

  const showRestoreButton = currentTab === Tab.archive;
  const showTransferButton = currentTab !== Tab.archive && currentTab !== Tab.synchronized && Tab.error;
  const [showFiltersDrawer, setShowFiltersDrawer] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteModalText, setDeleteModalText] = useState<string>("");
  const [emptyText, setEmptyText] = useState<string | JSX.Element>(null);
  const [productOnDelete, setProductOnDelete] = useState<ProductType>(null);
  const [selectedRows, setSelectedRows] = useState<ProductType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [filters, setFilters] = useState<RequestProductsFiltersType>(initFilters);
  const [excludedFilters, setExcludedFilters] = useState<string[]>();
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [sorterInfo, setSorterInfo] = useState<SorterResult<any> | SorterResult<any>[]>(null);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const filterFields: FilterType[] = [
    "categories", "barcodes", "name",
    "brands", "date_of_update", "colors", "sizes",
    "status", "price_update_date",
    "marketplace", "not_synced",
  ];

  useEffect(() => {
    setSelectedKeys(selectedRows?.map((value: ProductType) => value.key));
  }, [selectedRows]);

  useEffect((): void => {
    switch (currentTab) {
      case Tab.synchronized:
        setEmptyText("Здесь будут отображены опубликованные товары");
        setFilters((prevFilters) => ({
          ...prevFilters,
          status: ProductStatus.Synchronized,
        }));
        setExcludedFilters(["status"]);
        break;

      case Tab.draft:
        setEmptyText("Здесь будут отображены черновики товаров");
        setFilters((prevFilters) => ({
          ...prevFilters,
          status: ProductStatus.Draft,
        }));
        setExcludedFilters(["status"]);
        break;

      case Tab.error:
        setEmptyText(<>Здесь будут отображены товары с ошибкой <br /> интеграции с маркетплейсом</>);
        setFilters((prevFilters) => ({
          ...prevFilters,
          status: ProductStatus.IntegrationError,
        }));
        setExcludedFilters(["status"]);
        break;

      case Tab.archive:
        setEmptyText(<>Здесь будут отображены товары, <br /> перемещенные в архив</>);
        setFilters((prevFilters) => ({
          ...prevFilters,
          status: ProductStatus.Archive,
        }));
        setExcludedFilters(["status"]);
        break;

      case Tab.all:
        setEmptyText("Здесь будут отображены все ваши товары");
        setFilters({});
        setExcludedFilters([]);
        break;

      default:
        break;
    }
  }, [currentTab]);

  useEffect((): void => {
    if (productOnDelete) {
      if (currentTab === Tab.archive) {
        setDeleteModalText("Вы хотите удалить карточку товара. Это действие необратимо.");
      } else {
        setDeleteModalText("Карточка товара будет перемещена в архив.");
      }
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [productOnDelete]);

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows: ProductType[]) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  // For product actions
  const handleOnDelete = (product: ProductType): void => {
    setProductOnDelete(product);
  };

  const handleOnEdit = (product: ProductType): void => {
    navigate(`/edit-product/card/${product.card_id}/product/${product.id}`);
  };

  // Actions
  
  // удаление/перенос в архив по клику на иконку корзины
  const handleDeleteProduct = async (product: ProductType): Promise<void> => {
    setIsDisabled(true);

    // из архива полное удаление, из всех остальных перенос в Архив
    if (currentTab === Tab.archive) {
      await dispatch(deleteProduct(product.id));
      setProductOnDelete(null);

      message.info("Карточка товара удалена");

      await fetchProductsList(getRequestPagination(), filters);
    } else {
      await changeStatus(
        [product.id],
        1,
        "Карточка товара перемещена в архив",
        "Не удалось переместить карточку товара в архив. Попробуйте еще раз",
        () => setProductOnDelete(null)
      );
    }

    setIsDisabled(false);
  };

  const changeStatus = async (
    ids: string[],
    status: ProductStatus,
    successMsg?: string,
    errorMsg?: string,
    actionAfterSuccess?: () => void,
  ): Promise<void> => {
    await ProductsAPI.changeProductStatus({ products: ids, status })
      .then((res) => {
        if (res.status === 204) {
          successMsg && message.info(successMsg);
          fetchProductsList(getRequestPagination(), filters);

          actionAfterSuccess && actionAfterSuccess();
        }
      })
      .catch((error) => message.error(errorMsg ?? error.response?.data?.error));
  };

  const showDeleteSelectedModal = (): void => {
    if (currentTab === Tab.archive) {
      setDeleteModalText("Выбранные товары будут удалены. Это действие необратимо");
    } else {
      setDeleteModalText("Выбранные товары будут перенесены в архив");
    }
    setShowDeleteModal(true);
  };

  const fetchProductsList = async (
    pagination: RequestPaginationType,
    filters: RequestProductsFiltersType
  ): Promise<void> => {
    await dispatch(fetchProducts(pagination, filters));
  };

  const getRequestPagination = (): RequestPaginationType => ({
    page: paginationInfo.current,
    page_size: paginationInfo.pageSize,
    ...(sorterInfo ? { ordering: generateSortString(sorterInfo) } : {}),
  });

  // массовое удаление/перенос в архив
  const handleDeleteSelected = async (): Promise<void> => {
    setIsDisabled(true);

    // т.к. во вкладке Все товары можно выбрать архивные вместе с другими статусами,
    // поэтому отфильтровываем по статусу и отправляем разными запросами
    const notArchiveIds: string[] = selectedRows
      ?.filter((product) => product.status !== ProductStatus.Archive)
      ?.map((product) => product.id);

    const archiveIds: string[] = selectedRows
      ?.filter((product) => product.status === ProductStatus.Archive)
      ?.map((product) => product.id);

    // из архива полное удаление, из всех остальных перенос в Архив
    if (notArchiveIds?.length) {
      await changeStatus(
        notArchiveIds,
        ProductStatus.Archive,
        `Карточки товаров перенесены в архив. Количество: ${notArchiveIds?.length}`
      );
    }
    if (archiveIds.length) {
      archiveIds.map(async (id) => {
        await dispatch(deleteProduct(id));
      });

      await message.info(`Карточки товаров удалены`);
    }

    setSelectedRows(null);
    setShowDeleteModal(false);

    await fetchProductsList(getRequestPagination(), filters);

    setIsDisabled(false);
    setSelectedRows(null);
  };

  // TODO: Заложить логику отправки массива продуктов причастных к одной карточке
  const handleTransferToMP = async (): Promise<void> => {
    const mpId: string = marketplaces.list?.find((mp) => mp.name === "WB").id;

    selectedRows.map(async (product: ProductType) => {
      await dispatch(fetchCardInfo(product.card_id))
        .then((cardInfo: CardInfoType) => {
          if (cardInfo?.imt_id) {
            dispatch(syncProducts(mpId, [product.id]))
              .then((res: any) => {
                getResponseMessage(res, product.id);
              });
          } else {
            dispatch(syncProductsCard(mpId, [product.id]))
              .then((res: any) => {
                getResponseMessage(res, product.id);
              });
          }
        });
    });

    await fetchProductsList(getRequestPagination(), filters);

    setSelectedRows(null);
  };

  const getResponseMessage = (response: any, productId: string): void => {
    if (response?.status === 200) {
      const current = response.data?.[0]?.products?.find((product) => product.id === productId);

      if (current?.status === ProductStatus.IntegrationError) {
        notification.error({
          message: "Ошибка синхронизации",
          description: current.synch_errors?.join(", "),
          duration: 15,
          placement: "topLeft"
        });
      }
    }
  };

  // массовое восстановление из архива в статус черновик
  const handleRestoreSelected = async (): Promise<void> => {
    const ids: string[] = selectedRows.map((product) => product.id);

    await changeStatus(
      ids,
      0,
      `Карточки товаров перенесены в черновики. Количество: ${ids.length}`,
    );

    fetchProducts({ page: paginationInfo.current, page_size: paginationInfo.pageSize }, filters);

    setSelectedRows(null);
  };

  // Фильтры
  const handleOnShowFiltersDrawer = (): void => {
    setShowFiltersDrawer(true);
  };

  const handleOnCloseFiltersDrawer = (): void => {
    setShowFiltersDrawer(false);
  };

  const renderEmptyBlock = (description: JSX.Element | string): JSX.Element => (
    <EmptyBlock
      icon={<Cart className={`mb-6 ${css.emptyIcon}`} />}
      style={{ height: "calc(100vh - 23rem)" }}
      title="Товаров нет"
      description={description}
      btn={btn}
    />
  );

  // Верхняя панель с поиском
  const renderSearch = (): JSX.Element => {
    return (
      <Form form={form} className={`flex ${css.searchWrap}`}>
        <Form.Item name="search" className="mb-0 w-full">
          <SearchInput
            placeholder="Найти по баркоду, наименованию, категории"
            onSearch={(state: RequestProductsFiltersType) => setFilters(state)}
            filters={filters}
            isDisabled={products.isFetching && !products.list}
          />
        </Form.Item>
        <GhostButton
          size="large"
          text="Фильтры"
          icon={<FilterIcon />}
          onClickHandler={() => handleOnShowFiltersDrawer()}
          isDisabled={products.isFetching}
        />
        <FiltersDrawer
          form={form}
          onClose={handleOnCloseFiltersDrawer}
          open={showFiltersDrawer}
          setFilters={setFilters}
          filterFields={filterFields}
        />
      </Form>
    );
  };

  const renderPageActions = (): JSX.Element => {
    const numberOfItems: string = selectedKeys?.length ? `(${selectedKeys.length})` : "";

    return (
      <div className={css.actionsWrap}>
        {showRestoreButton && (
          <PrimaryButton
            size="large"
            text={`Восстановить как черновик ${numberOfItems}`}
            onClickHandler={handleRestoreSelected}
            isDisabled={!(selectedRows?.length > 0)}
          />
        )}
        {showTransferButton && (
          <PrimaryButton
            size="large"
            text={`Передать на маркетплейс ${numberOfItems}`}
            onClickHandler={handleTransferToMP}
            isDisabled={!(selectedRows?.length > 0)}
          />
        )}
        <PrimaryButton
          size="large"
          text={`Удалить ${numberOfItems}`}
          onClickHandler={showDeleteSelectedModal}
          isDisabled={!(selectedRows?.length > 0)}
        />
      </div>
    );
  };

  const renderProductActions = (_value: any, product: ProductType, idx: number): React.ReactNode => (
    <Space>
      <a key={`del-${idx}`} onClick={() => handleOnDelete?.(product)}>
        <DeleteIcon className={`blue-color ${css.icon}`} />
      </a>
      <a key={`edit-${idx}`} onClick={() => handleOnEdit?.(product)}>
        <EditIcon className={`blue-color ${css.icon}`} />
      </a>
    </Space>
  );

  return (
    <Space className="flex" direction="vertical">
      <ConfirmModal
        title="Вы уверены?"
        text={deleteModalText}
        isOpen={showDeleteModal}
        isApplyDisabled={isDisabled}
        onOkHandler={() => productOnDelete ? handleDeleteProduct(productOnDelete) : handleDeleteSelected()}
        onCancelHandler={() => productOnDelete ? setProductOnDelete(null) : setShowDeleteModal(false)}
      />
      <div className={css.controlsWrap}>
        {renderSearch()}
        <FiltersDrawerTags
          form={form}
          filters={filters}
          setFilters={(value: RequestProductsFiltersType) => setFilters(value)}
          filterFields={filterFields}
        />
        {renderPageActions()}
      </div>
      <ProductsTable
        columns={columns}
        list={products.list}
        count={products.count}
        fetchList={fetchProductsList}
        isFetching={products.isFetching}
        renderActions={renderProductActions}
        rowSelection={rowSelection}
        filters={filters}
        setPaginationInfo={setPaginationInfo}
        setSorterInfo={setSorterInfo}
        emptyBlock={renderEmptyBlock(emptyText)}
        isShowEmptyBlock={true}
        emptyExcludedFilters={excludedFilters}
      />
    </Space >
  );
};

export default ProductsTab;
