import * as process from "process";
import { ProductStatus } from "../components/Products/ProductsTab/ProductsTab";
import axios from "../utils/axios";
import type { 
  RequestPaginationType, 
  RequestProductsFiltersType
} from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

// создание номенклатурной позиции
export const add = async (params): Promise<any> => {
  return axios.POST(API_BASE, "/api/catalog/products", params);
};
export const list = async (pagination: RequestPaginationType, filters: RequestProductsFiltersType): Promise<any> => {
  const searchParams = new URLSearchParams();

  Object.keys(pagination).forEach(key => {
    (Array.isArray(pagination[key]) ? pagination[key] : [pagination[key]]).forEach(value => {
        if (value !== undefined && value !== null) {
            searchParams.append(key, value);
        }
    });
  });

	return axios.POST(API_BASE, `/api/catalog/product_list?${searchParams.toString()}`, filters);
};

export const getItem = async (product_id: string): Promise<any> => {
	return axios.GET(API_BASE, `/api/catalog/products/${product_id}`);
};

// редактирование конкретного поля номенклатурной позиции
export const update = async (product_id: string, params): Promise<any> => {
  return axios.PATCH(API_BASE, `/api/catalog/products/${product_id}`, params);
};

// редактирование всей номенклатурной позиции
export const updateWhole = async (product_id: string, params): Promise<any> => {
  return axios.PUT(API_BASE, `/api/catalog/products/${product_id}`, params);
};

// удаление номенклатурной позиции по id
export const remove = async (product_id: string): Promise<any> => {
  return axios.DELETE(API_BASE, `/api/catalog/products/${product_id}`);
};

// синхронизация номенклатурных позиций
export const syncProducts = async (marketplace_id: string, products: string[]): Promise<any> => {
  const syncProductsBody: { marketplace_id: string, products: string[] } = {
    marketplace_id,
    products
  };

  // TODO: Заменить на логику ручки и прокидывать массив значений
  return axios.POST(API_BASE, "/api/catalog/products/sync", [syncProductsBody]);
};

// синхронизация карточки
export const syncProductsCard = async (marketplace_id: string, products: string[]): Promise<any> => {
  const syncProductsCardBody: { marketplace_id: string, products: string[] } = {
    marketplace_id,
    products
  };

  // TODO: Заменить на логику ручки и прокидывать массив значений
  return axios.POST(API_BASE, "/api/catalog/cards/sync", [syncProductsCardBody]);
};

// Информация о карточке товара
export const getCardInfo = async (cardId: string): Promise<any> => {
  return axios.GET(API_BASE, `/api/catalog/cards/${cardId}`);
};

// Изменение статуса номенклатурной позиции
export const changeProductStatus = async (params: { products: string[], status: ProductStatus }): Promise<any> => {
  return axios.POST(API_BASE, "/api/catalog/products/change_status", params);
};

// Получение списка фасетов
export const facets = async (params: RequestProductsFiltersType): Promise<any> => {
  return axios.POST(API_BASE, "/api/catalog/product_facet", params);
};
