import { MarketplaceCityType } from "app/types";
import {
	FETCH_MARKETPLACE_CITIES_LIST_REQUEST,
	FETCH_MARKETPLACE_CITIES_LIST_SUCCESS,
	FETCH_MARKETPLACE_CITIES_LIST_FAILURE,
	FETCH_MARKETPLACE_CITY_REQUEST,
	FETCH_MARKETPLACE_CITY_SUCCESS,
	FETCH_MARKETPLACE_CITY_FAILURE,
} from "../../constants/actionTypes";

export type MarketplaceCitiesActionsType =
	| FetchMarketplaceCitiesListRequestType
	| FetchMarketplaceCitiesListSuccessType
	| FetchMarketplaceCitiesListFailureType
	| FetchMarketplaceCityRequestType
	| FetchMarketplaceCitySuccessType
	| FetchMarketplaceCityFailureType;

// получение листинга городов
type FetchMarketplaceCitiesListRequestType = {
	type: typeof FETCH_MARKETPLACE_CITIES_LIST_REQUEST;
};

export const fetchMarketplaceCitiesListRequest = (
): FetchMarketplaceCitiesListRequestType => ({
	type: FETCH_MARKETPLACE_CITIES_LIST_REQUEST,
});

type FetchMarketplaceCitiesListSuccessType = {
	type: typeof FETCH_MARKETPLACE_CITIES_LIST_SUCCESS;
	payload: {
		marketplaceCities: MarketplaceCityType[];
	};
};

export const fetchMarketplaceCitiesListSuccess = (
	marketplaceCities: MarketplaceCityType[]
): FetchMarketplaceCitiesListSuccessType => ({
	type: FETCH_MARKETPLACE_CITIES_LIST_SUCCESS,
	payload: {
		marketplaceCities
	}
});

type FetchMarketplaceCitiesListFailureType = {
	type: typeof FETCH_MARKETPLACE_CITIES_LIST_FAILURE;
	payload: {
		error: string;
	};
};

export const fetchMarketplaceCitiesListFailure = (
	error: string
): FetchMarketplaceCitiesListFailureType => ({
	type: FETCH_MARKETPLACE_CITIES_LIST_FAILURE,
	payload: {
		error
	}
});

// получение города
type FetchMarketplaceCityRequestType = {
	type: typeof FETCH_MARKETPLACE_CITY_REQUEST;
};

export const fetchMarketplaceCityRequest = (
): FetchMarketplaceCityRequestType => ({
	type: FETCH_MARKETPLACE_CITY_REQUEST,
});

type FetchMarketplaceCitySuccessType = {
	type: typeof FETCH_MARKETPLACE_CITY_SUCCESS;
	payload: {
		marketplaceCity: MarketplaceCityType;
	};
};

export const fetchMarketplaceCitySuccess = (
	marketplaceCity: MarketplaceCityType
): FetchMarketplaceCitySuccessType => ({
	type: FETCH_MARKETPLACE_CITY_SUCCESS,
	payload: {
		marketplaceCity
	}
});

type FetchMarketplaceCityFailureType = {
	type: typeof FETCH_MARKETPLACE_CITY_FAILURE;
	payload: {
		error: string;
	};
};

export const fetchMarketplaceCityFailure = (
	error: string
): FetchMarketplaceCityFailureType => ({
	type: FETCH_MARKETPLACE_CITY_FAILURE,
	payload: {
		error
	}
});
