import React, { JSX, FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import { InitialOrdersStateType } from "../../../redux/reducers/orders.reducer";
import { TopProductType } from "app/types";
import { AppDispatch } from "../../../redux/store/store";
import { fetchDashboard } from "../../../redux/thunks/orders.thunks";
import { Link } from "react-router-dom";

import CountUp from "react-countup";
import { Progress, Select, Statistic } from "antd";
import Card from "../../ui/Card/Card";
import NoData from "../../ui/NoData/NoData";

type OptionType = {
  label: string;
  value: string;
};

interface IDashboardProductsProps {
  period: "week" | "month" | "year";
  setTopMode: React.Dispatch<React.SetStateAction<"revenue" | "quantity">>;
}

type StatisticCardType = {
  title: string;
  count: number;
};

const DashboardProducts: FC<IDashboardProductsProps> = ({ period, setTopMode }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);

  const { dashboard } = orders;

  const options: OptionType[] = [
    { label: "По продажам", value: "quantity" },
    { label: "По выручке", value: "revenue"},
  ];

  // значение, являющееся 100% для шкалы прогресса
  const [fullPercent, setFullPercent] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<OptionType>(options[0]);

  const statisticCards: StatisticCardType[] = [
    { title: "Всего товаров", count: dashboard?.synced ?? 0 },
    { title: "Товары на продаже", count: dashboard?.published ?? 0 },
    { title: "Товаров продано", count: dashboard?.sold ?? 0 }
  ];

  useEffect(() => {
    if (dashboard?.top?.length) {
      const leadingPositionValue: number = +dashboard.top[0].value;
      // к 1 месту в топе прибавляем еще 15% - это будет 100% для шкалы прогресса
      setFullPercent(leadingPositionValue * 0.15 + leadingPositionValue);
    }
  }, [dashboard?.top]);

  const renderStatisticCard = (cardItem: StatisticCardType, idx: number): JSX.Element => {
    // делает анимацию цифр
    const formatter = (value: number) => <CountUp end={value} separator=" " />;

    return (
      <Card key={idx} className={`${idx === statisticCards.length - 1 ? "" : "mb-4"}`}>
        <Statistic
          title={cardItem.title}
          value={cardItem.count || "-"}
          formatter={cardItem.count && formatter}
        />
      </Card>
    );
  };

  const renderTopProduct = (product: TopProductType, index: number): JSX.Element => {
    return (
      <div key={product.barcode}>
        <p className="flex justify-between text-14-m">
          <span className="text-ellipsis overflow-hidden whitespace-nowrap">
            {index + 1} {product.product_name}
          </span>
          <span>{product.value}</span>
        </p>
        <Progress
          status="normal"
          showInfo={false}
          percent={+product.value * 100 / fullPercent}
          size={["", 4]}
          trailColor="#0A84FF1A"
          style={{margin: 0}}
        />
      </div>
    );
  };

  const handleSelect = (option: OptionType): void => {
    setSelectedValue(option);
    setTopMode(option.value as "revenue" | "quantity");

    dispatch(fetchDashboard({
      top: (option.value as "revenue" | "quantity"),
      period
    }));
  };

  return (
    <div className="mt-6">
      <div className="flex justify-between">
        <p className="text-18-m mb-3">Товары</p>
        <Link to="/products" className="blue-color text-16-r">К списку товаров</Link>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>{statisticCards.map(renderStatisticCard)}</div>

        <Card className="flex flex-col">
          <div className="flex justify-between mb-4">
            <div>
              <p className="text-16-m">Топ товаров</p>
              <p className="text-12-r secondary-color">Выберите нужные параметры</p>
            </div>
            <Select
              value={selectedValue}
              options={options}
              onSelect={(_value, option) => handleSelect(option)}
            />
          </div>
          {dashboard?.top?.length ? (
            <div>{dashboard?.top?.map(renderTopProduct)}</div>
          ) : (
            <div className="flex flex-1 justify-center items-center">
              <NoData />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default DashboardProducts;
