import React, { JSX, FC } from "react";
import { SelectionListColumnType, SelectionListItemType } from "../../Supply/SupplyWrapper";
import css from "../style.module.css";

interface ISelectionListTableProps {
  idx: number;
  data: SelectionListItemType[];
  columns: SelectionListColumnType[];
  createdAt: string;
  fontStyle: { fontFamily: string };
}

const SelectionListTable: FC<ISelectionListTableProps> = ({
  idx,
  data,
  columns,
  createdAt,
  fontStyle,
}): JSX.Element => {
  const isImg = (column: SelectionListColumnType) => {
    return column.field === "photo" || column.field === "sticker";
  };

  const renderCell = (row: SelectionListItemType, column: SelectionListColumnType, colIndex: number): JSX.Element => {
    return (
      <td key={colIndex} className={`px-1 break-words ${css.border} ${isImg(column) ? css.img : ""}`}>
        {isImg(column) ? renderImg(row[column.field]) : row[column.field]}
      </td>
    );
  };

  const renderImg = (link: string): JSX.Element | null => {
    return link ? <img src={link} alt="" className={`object-contain ${css.img}`} /> : null;
  };

  return (
    <div style={idx !== 0 ? { pageBreakBefore: "always" } : null}>
      <p className="mt-3">Дата создания поставки: {createdAt ?? ""}</p>
      {data?.[0]?.box && <p>Короб: {data[0].box}</p>}
      <p className="mb-3">Количество товара: {data?.length}</p>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {columns.map((column: SelectionListColumnType, index: number) => (
              <th key={index} style={fontStyle} className={`px-1 break-words ${css.border}`}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row: SelectionListItemType, rowIndex: number) => (
            <tr key={rowIndex} className={`px-1 break-words ${css.border}`}>
              {columns.map((column: SelectionListColumnType, colIndex: number) => renderCell(row, column, colIndex))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SelectionListTable;
