import { ThunkAction } from "redux-thunk";
import * as WBTnvedsAPI from "../../api/wbTnveds.api";
import {
  fetchWBTnvedSuccess,
  fetchWBTnvedFailure,
  fetchWBTnvedsListSuccess,
  fetchWBTnvedsListFailure,
  WbTnvedsActionsType, fetchWBTnvedRequest, fetchWBTnvedsListRequest,
} from "../actions/wbTnveds.actions";
import { AppStateType } from "../reducers/mainReducer";

// получение списка ТНВЭД
export const fetchWBTnveds = (search: { search: string } | null = null):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbTnvedsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBTnvedsListRequest());

    try {
      const response = await WBTnvedsAPI.list(search);

      dispatch(fetchWBTnvedsListSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBTnvedsListFailure(error?.response?.data?.error));
    }
  };

// получение ТНВЭД
export const fetchWBTnved = (tnvedId: string):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbTnvedsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBTnvedRequest());

    try {
      const response = await WBTnvedsAPI.getItem(tnvedId);

      dispatch(fetchWBTnvedSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBTnvedFailure(error?.response?.data?.error));
    }
  };
