import { PriceShotType } from "app/types";
import {
  CREATE_PRICE_REQUEST,
  CREATE_PRICE_SUCCESS,
  CREATE_PRICE_FAILURE,
  FETCH_SHOT_PRICE_REQUEST,
  FETCH_SHOT_PRICE_SUCCESS,
  FETCH_SHOT_PRICE_FAILURE,
} from "../../constants/actionTypes";

export type PricesActionsTypes =
  | CreatePriceRequestType
  | CreatePriceSuccessType
  | CreatePriceFailureType
  | FetchShotPriceRequestType
  | FetchShotPriceSuccessType
  | FetchShotPriceFailureType
  ;

type CreatePriceRequestType = {
  type: typeof CREATE_PRICE_REQUEST;
  payload: {
    marketplace_id: string;
    date_from: string;
    price: number;
    object_id: string;
  }
};

export const createPriceRequest = (
  marketplace_id: string,
  date_from: string,
  price: number,
  object_id: string,
): CreatePriceRequestType => ({
  type: CREATE_PRICE_REQUEST,
  payload: {
    marketplace_id,
    date_from,
    price,
    object_id,
  }
});

type CreatePriceSuccessType = {
  type: typeof CREATE_PRICE_SUCCESS;
  payload: {
    id: string;
  }
};

export const createPriceSuccess = (
  id: string
): CreatePriceSuccessType => ({
  type: CREATE_PRICE_SUCCESS,
  payload: {
    id
  }
});

type CreatePriceFailureType = {
  type: typeof CREATE_PRICE_FAILURE;
  payload: {
    error: string;
  }
};

export const createPriceFailure = (
  error: string,
): CreatePriceFailureType => ({
  type: CREATE_PRICE_FAILURE,
  payload: {
    error
  }
});

// Краткая информация по цене
type FetchShotPriceRequestType = {
	type: typeof FETCH_SHOT_PRICE_REQUEST;
  payload: {
    sizeId: string;
  }
};

export const fetchShotPriceRequest = (
  sizeId: string,
): FetchShotPriceRequestType => ({
	type: FETCH_SHOT_PRICE_REQUEST,
  payload: {
    sizeId
  }
});

type FetchShotPriceSuccessType = {
	type: typeof FETCH_SHOT_PRICE_SUCCESS;
	payload: {
    shot: PriceShotType;
	};
};

export const fetchShotPriceSuccess = (
  shot: PriceShotType
): FetchShotPriceSuccessType => ({
	type: FETCH_SHOT_PRICE_SUCCESS,
	payload: {
    shot
  }
});

type FetchShotPriceFailureType = {
	type: typeof FETCH_SHOT_PRICE_FAILURE;
	payload: {
		error: string;
	};
};

export const fetchShotPriceFailure = (
	error: string
): FetchShotPriceFailureType => ({
	type: FETCH_SHOT_PRICE_FAILURE,
	payload: {
		error
	}
});
