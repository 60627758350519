import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  GET_USER_TOKEN_DESCRIPTION_FAILURE,
  GET_USER_TOKEN_DESCRIPTION_SUCCESS,
  LOGOUT,
  TOKEN_REVOCATION_SUCCESS,
  TOKEN_REVOCATION_FAILURE,
  FETCH_LK_USER_INFO_SUCCESS,
  FETCH_LK_USER_INFO_FAILURE,
} from "../../constants/actionTypes";

import { AccountActionsTypes } from "../actions/account.actions";
import { LkUserInfoType, TokenDescriptionType, UserInfoType } from "app/types";

const token: string | null = window?.localStorage.getItem("authToken");
const refreshToken: string | null = window?.localStorage.getItem("refreshToken");

export type InitialAccountStateType = {
  error: string | null;
  userData: UserInfoType | null;
  userInfo: LkUserInfoType | null;
  authToken: string | null;
  refreshToken: string | null;
  confirmNewPasswordStatus: number,
  userTokenDescription: TokenDescriptionType | null;
  isFetching: boolean;
};

const initialState: InitialAccountStateType = {
  error: null,
  userData: null,
  userInfo: null,
  authToken: token,
  refreshToken: refreshToken,
  confirmNewPasswordStatus: 0,
  userTokenDescription: null,
  isFetching: true,
};

const account = (
  state: InitialAccountStateType = initialState,
  action: AccountActionsTypes
): InitialAccountStateType => {
  switch (action.type) {
    // Авторизация
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        authToken: null,
        refreshToken: null,
        error: action.payload.error
      };
    // Refresh
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        error: null
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        authToken: null,
        refreshToken: null,
        error: action.payload.error
      };
    // Деавторизация
    case LOGOUT:
      return {
        ...state,
        authToken: null,
        refreshToken: null,
        error: null
      };
    // Получение данных об авторизованном пользователе
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userData: action.payload.userInfo,
        error: null
      };
    case FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        userData: null,
        error: action.payload.error
      };
    // Получение данных об пользователе с бэка
    case FETCH_LK_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userInfo: action.payload.userInfo,
        error: null
      };
    case FETCH_LK_USER_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        userInfo: null,
        error: action.payload.error
      };
    // Расшифровка токена авторизованного пользователя
    case GET_USER_TOKEN_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userTokenDescription: action.payload.userTokenDescription,
        error: null
      };
    case GET_USER_TOKEN_DESCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        userTokenDescription: null,
        error: action.payload.error
      };
    case TOKEN_REVOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authToken: null,
        refreshToken: null,
        userTokenDescription: null,
        error: null,
      };
    case TOKEN_REVOCATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default account;
