import React, { JSX, FC } from "react";
import { useSelector } from "react-redux";
import type { AppStateType } from "../../../../redux/reducers/mainReducer";
import { InitialSuppliesStateType } from "../../../../redux/reducers/supplies.reducer";

import GoBackButton from "../../../ui/GoBackButton/GoBackButton";
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";

interface ISupplyHeaderProps {
  isAssemblyStatus: boolean;
  isDeliveryStatus: boolean;
  printQRSupply: () => void;
}

const SupplyHeader: FC<ISupplyHeaderProps> = ({
  isAssemblyStatus,
  isDeliveryStatus,
  printQRSupply
}): JSX.Element => {
  const supplies: InitialSuppliesStateType = useSelector((state: AppStateType) => state.supplies);

  const name: string = `Поставка №${supplies.found?.supply_id ?? ""} ${supplies.found?.name ?? ""}`;

  return (
    <div className="flex justify-between">
      <div>
        <GoBackButton link={`/orders/${isAssemblyStatus ? "1" : "2"}`} text="К списку поставок" />
        <h3 className="mb-6">{name}</h3>
      </div>
      {isDeliveryStatus && <PrimaryButton text="Распечатать QR-код поставки" onClickHandler={printQRSupply} />}
    </div>
  );
};

export default SupplyHeader;
