import React, { FC, ReactNode, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { MenuInfo } from "rc-menu/lib/interface";
import { Layout, Menu, Badge } from "antd";
import { ReactComponent as MainPageIcon } from "../../assets/icons/main_icon.svg";
import { ReactComponent as ProductsPageIcon } from "../../assets/icons/shopping_cart.svg";
import { ReactComponent as AssemblyTasksPageIcon } from "../../assets/icons/assembly_tasks_icon.svg";
import { ReactComponent as WarehousesPageIcon } from "../../assets/icons/geo_icon.svg";
import { ReactComponent as BalanceManagementPageIcon } from "../../assets/icons/chart_bar_square.svg";
import { ReactComponent as HelpPageIcon } from "../../assets/icons/help_circle.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout_icon.svg";
import { ReactComponent as Logo } from "../../assets/img/logo_sidebar.svg";
import css from "./style.module.css";
import { tokenRevocation } from "../../redux/thunks/account.thunks";
import { fetchOrdersFacets } from "redux/thunks/orders.thunks";
import { InitialOrdersStateType } from "redux/reducers/orders.reducer";
import { AppStateType } from "redux/reducers/mainReducer";

interface MenuItemProps {
  key: string;
  icon: ReactNode;
  label: string | ReactNode;
  url: string;
  onClick?: () => void;
}

interface ISidebar { }

const Sidebar: FC<ISidebar> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);
  const [current, setCurrent] = useState<string>("");

  useEffect((): void => {
    !orders.facets && dispatch(fetchOrdersFacets({}));
  }, []);

  useEffect(() => {
    const currentMenu: string = MAIN_MENU_ITEMS
      .find((item) => item.url === location.pathname)?.key
      || "";

    setCurrent(currentMenu);
  }, [location]);

  const handleSelectItem = (e: MenuInfo): void => {
    setCurrent(e.key);
  };

  const renderMenuItem: FC<MenuItemProps> = ({
    key,
    icon,
    label,
    url,
    onClick
  }): JSX.Element => (
    <Menu.Item
      key={key}
      icon={icon}
      className={`${css.menu_item} ${key === current ? css.selected : ""}`}
      onClick={() => {
        onClick && onClick();
        navigate(url);
      }}
    >
      {label}
    </Menu.Item >
  );

  const handleLogout = (): void => {
    dispatch(tokenRevocation());
  };

  const MAIN_MENU_ITEMS: MenuItemProps[] = [
    {
      key: "route-main",
      icon: <MainPageIcon className={css.menu_icon} />,
      label: "Главная",
      url: "/"
    },
    {
      key: "route-warehouses",
      icon: <WarehousesPageIcon className={css.menu_icon} />,
      label: "Склады",
      url: "/warehouses"
    },
    {
      key: "route-products",
      icon: <ProductsPageIcon className={css.menu_icon} />,
      label: "Товары",
      url: "/products"
    },
    {
      key: "route-prices-stocks-management",
      icon: <BalanceManagementPageIcon className={css.menu_icon} />,
      label: "Цены и остатки",
      url: "/prices-stocks-management"
    },
    {
      key: "route-assembly-tasks",
      icon: <AssemblyTasksPageIcon className={css.menu_icon} />,
      label: (
        <span className={css.badge}>
          Сборочные задания
          <Badge
            style={{
              color: "var(--main-badge-color)",
              backgroundColor: "var(--main-badge-bg-color)"
            }}
            className={css.counter}
            count={orders.facets?.new ?? 0}
          />
        </span>
      ),
      url: "/orders"
    },
  ];

  const BOTTOM_MENU_ITEMS: MenuItemProps[] = [
    {
      key: "route-help",
      icon: <HelpPageIcon className={css.menu_icon} />,
      label: "Помощь",
      url: "/help"
    },
    {
      key: "route-logout",
      icon: <LogoutIcon className={css.menu_icon} />,
      label: "Выйти",
      url: "/login",
      onClick: handleLogout
    }
  ];

  return (
    <Layout.Sider
      theme="light"
      className={css.left_sidebar}
      width="17rem"
      style={{ position: "sticky" }}
    >
      <div>
        {/* Logo */}
        <Link to="/"><Logo className={css.sidebar_logo} /></Link>

        {/* Menu */}
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[current]}
          style={{ borderInlineEnd: "none" }}
          className={css.sidebar_menu}
          selectedKeys={[current]}
          onClick={handleSelectItem}
        >
          {MAIN_MENU_ITEMS.map(renderMenuItem)}
        </Menu>
      </div>

      {/* Help button */}
      <Menu
        theme="light"
        mode="inline"
        style={{ borderInlineEnd: "none" }}
        className={`${css.sidebar_menu} mb-3`}
        selectedKeys={[current]}
        onClick={handleSelectItem}
      >
        {BOTTOM_MENU_ITEMS.map(renderMenuItem)}
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
