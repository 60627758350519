import { useEffect, useState } from "react";
const useCountdownTimer = (initialSeconds: number) => {
  const [seconds, setSeconds] = useState<number>(initialSeconds);
  const [timerActive, setTimerActive] = useState<boolean>(false);

  const startTimer = () => {
    setTimerActive(true);
  };

  const resetTimer = () => {
    setTimerActive(false);
    setSeconds(initialSeconds);
  };

  useEffect(() => {
    let timerInterval;

    if (timerActive && seconds > 0) {
      timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(timerInterval);
  }, [timerActive, seconds, initialSeconds]);

  return { seconds, timerActive, startTimer, resetTimer };
};

export default useCountdownTimer;
