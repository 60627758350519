import * as process from "process";
import axios from "../utils/axios";
import { CreatePriceRequestType } from "app/types";

interface IListParams {
  search?: string,
  city?: string,
}

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const create = async (
  marketplace_id: string,
  date_from: string,
  price: number,
  object_id: string
): Promise<any> => {
  const requestBody: CreatePriceRequestType = {
    marketplace_id,
    date_from,
    price,
    object_id
  };

  return axios.POST(API_BASE, "/api/catalog/prices", requestBody);
};

export const shot = async (sizeId: string): Promise<any> => {
  return axios.GET(API_BASE, `/api/catalog/prices/${sizeId}/shot`);
};
