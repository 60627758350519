import * as process from "process";
import axios from "../utils/axios";
import { FilterWBCategoriesType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const list = async (params: FilterWBCategoriesType | null = null): Promise<any> => {
	return axios.GET(API_BASE, "/api/catalog/wb_categories", params);
};

export const getItem = async (wbCategoryId: string): Promise<any> => {
	return axios.GET(API_BASE, `/api/catalog/wb_categories/${wbCategoryId}`);
};
