import React, { JSX, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/store/store";
import { clearProducts } from "../../redux/thunks/products.thunks";
import { InitialProductsStateType } from "redux/reducers/products.reducer";

// components
import PrimaryButton from "../../components/ui/PrimaryButton/PrimaryButton";
import StickyHeader from "../../components/ui/StickyHeader/StickyHeader";
import TabsWithCounters from "../../components/Products/TabsWithCounters/TabsWithCounters";

import type { AppStateType } from "redux/reducers/mainReducer";

/* статусы синхронизации номенклатурной позиции:
* 0 - Черновик, 1 - Архив, 2 - Синхронизирована, 3 - Ошибка интеграции, 4 - В процессе синхронизации
*/
export enum ProductStatuses {
  draft,
  archive,
  synchronized,
  error,
  in_progress
}
export enum ProductStatusesText {
  "Черновик",
  "Архив",
  "Синхронизировано",
  "Ошибка интеграции",
  "В процессе синхронизации",
}

interface IProductsPageProps {}

const ProductsPage: FC<IProductsPageProps> = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);

  const renderCreateProductButton = (): JSX.Element => {
    const handleOnClick = async (): Promise<void> => {
      await dispatch(clearProducts());

      navigate("/create-product");
    };

    return (
      <PrimaryButton onClickHandler={handleOnClick} text="Создать новый" isDisabled={products.isFetching}/>
    );
  };

  return (
    <div>
      <StickyHeader
        title="Товары"
        isShowDivider={false}
        btn={renderCreateProductButton()}
      />
      <TabsWithCounters btn={renderCreateProductButton()} />
    </div>
  );
};

export default ProductsPage;
