import { MarketplaceType } from "app/types";
import {
	FETCH_MARKETPLACE_REQUEST,
	FETCH_MARKETPLACE_SUCCESS,
	FETCH_MARKETPLACE_FAILURE,
	FETCH_MARKETPLACES_LIST_REQUEST,
	FETCH_MARKETPLACES_LIST_SUCCESS,
	FETCH_MARKETPLACES_LIST_FAILURE,
} from "../../constants/actionTypes";
import { MarketplacesActionsType } from "../actions/marketplaces.actions";

export type InitialMarketplacesStateType = {
	error: string | null;
	list: MarketplaceType[] | null;
	found: MarketplaceType | null;
	isFetching: boolean;
};

const initialState: InitialMarketplacesStateType = {
	error: null,
	list: null,
	found: null,
	isFetching: false,
};

const marketplaces = (
	state: InitialMarketplacesStateType = initialState,
	action: MarketplacesActionsType
): InitialMarketplacesStateType => {
	switch (action.type) {
		case FETCH_MARKETPLACES_LIST_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_MARKETPLACES_LIST_SUCCESS: {
      // TODO пока у нас только WB, оставляем только его. Как появится озон, удалить этот код
      const wb: MarketplaceType[] = action.payload.marketplaces.filter(({name}) => name === "WB");

      return {
        ...state,
        isFetching: false,
        list: wb,
      };
    }
		case FETCH_MARKETPLACES_LIST_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error,
			};
		case FETCH_MARKETPLACE_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_MARKETPLACE_SUCCESS:
			return {
				...state,
				isFetching: false,
				found: action.payload.marketplace,
			};
		case FETCH_MARKETPLACE_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error,
			};
		default:
			return state;
	}
};

export default marketplaces;
