import { WarehouseRequestType, WarehouseType } from "app/types";
import {
	FETCH_WAREHOUSES_LIST_REQUEST,
	FETCH_WAREHOUSES_LIST_SUCCESS,
	FETCH_WAREHOUSES_LIST_FAILURE,
	FIND_WAREHOUSE_REQUEST,
	FIND_WAREHOUSE_SUCCESS,
	FIND_WAREHOUSE_FAILURE,
	CLEAR_FOUND_WAREHOUSE,
	ADD_WAREHOUSE_REQUEST,
	ADD_WAREHOUSE_SUCCESS,
	ADD_WAREHOUSE_FAILURE,
	UPDATE_WAREHOUSE_REQUEST,
	UPDATE_WAREHOUSE_SUCCESS,
	UPDATE_WAREHOUSE_FAILURE,
	DELETE_WAREHOUSE_REQUEST,
	DELETE_WAREHOUSE_SUCCESS,
	DELETE_WAREHOUSE_FAILURE,
	SYNC_WAREHOUSE_REQUEST,
	SYNC_WAREHOUSE_SUCCESS,
	SYNC_WAREHOUSE_FAILURE,
} from "../../constants/actionTypes";

export type WarehousesActionsType =
	| FetchWarehousesListRequestType
	| FetchWarehousesListSuccessType
	| FetchWarehousesListFailureType
	| FindWarehouseRequestType
	| FindWarehouseSuccessType
	| FindWarehouseFailureType
	| FindWarehouseClearType
	| AddWarehouseRequestType
	| AddWarehouseSuccessType
	| AddWarehouseFailureType
	| UpdateWarehouseRequestType
	| UpdateWarehouseSuccessType
	| UpdateWarehouseFailureType
	| DeleteWarehouseRequestType
	| DeleteWarehouseSuccessType
	| DeleteWarehouseFailureType
	| SyncWarehouseRequestType
	| SyncWarehouseSuccessType
	| SyncWarehouseFailureType;

// получение листинга складов
type FetchWarehousesListRequestType = {
	type: typeof FETCH_WAREHOUSES_LIST_REQUEST;
};

export const fetchWarehousesListRequest = (): FetchWarehousesListRequestType => ({
	type: FETCH_WAREHOUSES_LIST_REQUEST
});

type FetchWarehousesListSuccessType = {
	type: typeof FETCH_WAREHOUSES_LIST_SUCCESS;
	payload: {
		warehouses: WarehouseType[];
	};
};

export const fetchWarehousesListSuccess = (warehouses: WarehouseType[]): FetchWarehousesListSuccessType => ({
	type: FETCH_WAREHOUSES_LIST_SUCCESS,
	payload: {
		warehouses
	}
});

type FetchWarehousesListFailureType = {
	type: typeof FETCH_WAREHOUSES_LIST_FAILURE;
	payload: {
		error: string;
	};
};

export const fetchWarehousesListFailure = (error: string): FetchWarehousesListFailureType => ({
	type: FETCH_WAREHOUSES_LIST_FAILURE,
	payload: {
		error
	}
});

// добавление склада
type AddWarehouseRequestType = {
	type: typeof ADD_WAREHOUSE_REQUEST;
	payload: {
		warehouse: WarehouseRequestType;
	}
};

export const addWarehouseRequest = (warehouse: WarehouseRequestType): AddWarehouseRequestType => ({
	type: ADD_WAREHOUSE_REQUEST,
	payload: {
		warehouse
	}
});

type AddWarehouseSuccessType = {
	type: typeof ADD_WAREHOUSE_SUCCESS;
};

export const addWarehouseSuccess = (): AddWarehouseSuccessType => ({
	type: ADD_WAREHOUSE_SUCCESS,
});

type AddWarehouseFailureType = {
	type: typeof ADD_WAREHOUSE_FAILURE;
	payload: {
		error: string;
	};
};

export const addWarehousesFailure = (error: string): AddWarehouseFailureType => ({
	type: ADD_WAREHOUSE_FAILURE,
	payload: {
		error
	}
});

// поиск склада
type FindWarehouseRequestType = {
	type: typeof FIND_WAREHOUSE_REQUEST;
	payload: {
		warehouseId: string;
	};
};

export const findWarehouseRequest = (warehouseId: string): FindWarehouseRequestType => ({
	type: FIND_WAREHOUSE_REQUEST,
	payload: {
		warehouseId
	}
});

type FindWarehouseSuccessType = {
	type: typeof FIND_WAREHOUSE_SUCCESS;
	payload: {
		found: WarehouseType;
	};
};

export const findWarehouseSuccess = (warehouse: WarehouseType): FindWarehouseSuccessType => ({
	type: FIND_WAREHOUSE_SUCCESS,
	payload: {
		found: warehouse,
	}
});

type FindWarehouseFailureType = {
	type: typeof FIND_WAREHOUSE_FAILURE;
	payload: {
		error: string;
	};
};

export const findWarehouseFailure = (error: string): FindWarehouseFailureType => ({
	type: FIND_WAREHOUSE_FAILURE,
	payload: {
		error
	}
});

type FindWarehouseClearType = {
	type: typeof CLEAR_FOUND_WAREHOUSE;
	payload: null
};

export const findWarehouseClear = (): FindWarehouseClearType => ({
	type: CLEAR_FOUND_WAREHOUSE,
	payload: null
});

// обновление склада
type UpdateWarehouseRequestType = {
	type: typeof UPDATE_WAREHOUSE_REQUEST;
	payload: {
		warehouse: WarehouseRequestType;
		warehouseId: string;
	}
};

export const updateWarehouseRequest = (
	warehouse: WarehouseRequestType,
	warehouseId: string
): UpdateWarehouseRequestType => ({
	type: UPDATE_WAREHOUSE_REQUEST,
	payload: {
		warehouse,
		warehouseId,
	}
});

type UpdateWarehouseSuccessType = {
	type: typeof UPDATE_WAREHOUSE_SUCCESS;
};

export const updateWarehouseSuccess = (): UpdateWarehouseSuccessType => ({
	type: UPDATE_WAREHOUSE_SUCCESS,
});

type UpdateWarehouseFailureType = {
	type: typeof UPDATE_WAREHOUSE_FAILURE;
	payload: {
		error: string;
	};
};

export const updateWarehousesFailure = (error: string): UpdateWarehouseFailureType => ({
	type: UPDATE_WAREHOUSE_FAILURE,
	payload: {
		error
	}
});

// удаление склада
type DeleteWarehouseRequestType = {
	type: typeof DELETE_WAREHOUSE_REQUEST;
	payload: {
		warehouseId: string
	}
};

export const deleteWarehouseRequest = (
	warehouseId: string
): DeleteWarehouseRequestType => ({
	type: DELETE_WAREHOUSE_REQUEST,
	payload: {
		warehouseId
	}
});

type DeleteWarehouseSuccessType = {
	type: typeof DELETE_WAREHOUSE_SUCCESS;
};

export const deleteWarehouseSuccess = (): DeleteWarehouseSuccessType => ({
	type: DELETE_WAREHOUSE_SUCCESS,
});

type DeleteWarehouseFailureType = {
	type: typeof DELETE_WAREHOUSE_FAILURE;
	payload: {
		error: string;
	};
};

export const deleteWarehousesFailure = (error: string): DeleteWarehouseFailureType => ({
	type: DELETE_WAREHOUSE_FAILURE,
	payload: {
		error
	}
});

// синхронизация склада
type SyncWarehouseRequestType = {
	type: typeof SYNC_WAREHOUSE_REQUEST;
	payload: {
		warehouseId: string;
		marketplaceId: string;
	}
};

export const syncWarehouseRequest = (
	warehouseId: string,
	marketplaceId: string
): SyncWarehouseRequestType => ({
	type: SYNC_WAREHOUSE_REQUEST,
	payload: {
		warehouseId,
		marketplaceId
	}
});

type SyncWarehouseSuccessType = {
	type: typeof SYNC_WAREHOUSE_SUCCESS;
};

export const syncWarehouseSuccess = (): SyncWarehouseSuccessType => ({
	type: SYNC_WAREHOUSE_SUCCESS,
});

type SyncWarehouseFailureType = {
	type: typeof SYNC_WAREHOUSE_FAILURE;
	payload: {
		error: string;
	};
};

export const syncWarehousesFailure = (error: string): SyncWarehouseFailureType => ({
	type: SYNC_WAREHOUSE_FAILURE,
	payload: {
		error
	}
});
