import {
  RequestPaginationType,
  RequestSuppliesFiltersType,
  SupplyType,
} from "app/types";

import {
  FETCH_SUPPLIES_LIST_REQUEST,
  FETCH_SUPPLIES_LIST_SUCCESS,
  FETCH_SUPPLIES_LIST_FAILURE,
  FETCH_SUPPLY_REQUEST,
  FETCH_SUPPLY_SUCCESS,
  FETCH_SUPPLY_FAILURE,
  SUPPLY_DELIVER_REQUEST,
  SUPPLY_DELIVER_SUCCESS,
  SUPPLY_DELIVER_FAILURE,
  CLEAR_SUPPLIES_LIST,
} from "../../constants/actionTypes";

export type SuppliesActionsType =
  | FetchSuppliesListRequestType
  | FetchSuppliesListSuccessType
  | FetchSuppliesListFailureType
  | FetchSupplyRequestType
  | FetchSupplySuccessType
  | FetchSupplyFailureType
  | SupplyDeliverRequestType
  | SupplyDeliverSuccessType
  | SupplyDeliverFailureType
  | ClearSuppliesListType
  ;

// Получение листинга поставок
type FetchSuppliesListRequestType = {
  type: typeof FETCH_SUPPLIES_LIST_REQUEST,
  payload: {
    pagination: RequestPaginationType;
    filters?: RequestSuppliesFiltersType;
  }
}

export const fetchSuppliesListRequest = (
  pagination: RequestPaginationType,
  filters: RequestSuppliesFiltersType,
): FetchSuppliesListRequestType => ({
  type: FETCH_SUPPLIES_LIST_REQUEST,
  payload: {
    pagination,
    filters,
  }
});

type FetchSuppliesListSuccessType = {
  type: typeof FETCH_SUPPLIES_LIST_SUCCESS;
  payload: {
    items: SupplyType[];
    count: number;
  };
}

export const fetchSuppliesListSuccess = ({
  items,
  count,
}): FetchSuppliesListSuccessType => ({
  type: FETCH_SUPPLIES_LIST_SUCCESS,
  payload: {
    items,
    count,
  }
});

type FetchSuppliesListFailureType = {
  type: typeof FETCH_SUPPLIES_LIST_FAILURE;
  payload: {
    error: string;
  };
}

export const fetchSuppliesListFailure = (
  error: string
): FetchSuppliesListFailureType => ({
  type: FETCH_SUPPLIES_LIST_FAILURE,
  payload: {
    error,
  },
});

// Получение поставки
type FetchSupplyRequestType = {
  type: typeof FETCH_SUPPLY_REQUEST;
};

export const fetchSupplyRequest = (): FetchSupplyRequestType => ({
  type: FETCH_SUPPLY_REQUEST,
});

type FetchSupplySuccessType = {
  type: typeof FETCH_SUPPLY_SUCCESS;
  payload: {
    supply: SupplyType;
  }
};

export const fetchSupplySuccess = (supply: SupplyType): FetchSupplySuccessType => ({
  type: FETCH_SUPPLY_SUCCESS,
  payload: {
    supply
  }
});

type FetchSupplyFailureType = {
  type: typeof FETCH_SUPPLY_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchSupplyFailure = (error: string): FetchSupplyFailureType => ({
  type: FETCH_SUPPLY_FAILURE,
  payload: {
    error,
  },
});

// Передача поставок в доставку
type SupplyDeliverRequestType = {
  type: typeof SUPPLY_DELIVER_REQUEST;
  payload: {
    supply_ids: string[]
  };
};

export const supplyDeliverRequest = (supply_ids: string[]): SupplyDeliverRequestType => ({
  type: SUPPLY_DELIVER_REQUEST,
  payload: {
    supply_ids
  }
});

type SupplyDeliverSuccessType = {
  type: typeof SUPPLY_DELIVER_SUCCESS;
  payload: {
    response: string
  }
};

export const supplyDeliverSuccess = (response: string): SupplyDeliverSuccessType => ({
  type: SUPPLY_DELIVER_SUCCESS,
  payload: { response }
});

type SupplyDeliverFailureType = {
  type: typeof SUPPLY_DELIVER_FAILURE;
  payload: {
    error: string;
  };
};

export const supplyDeliverFailure = (error: string): SupplyDeliverFailureType => ({
  type: SUPPLY_DELIVER_FAILURE,
  payload: {
    error,
  },
});

// очистка
type ClearSuppliesListType = {
  type: typeof CLEAR_SUPPLIES_LIST;
  payload: null;
};

export const clearSuppliesList = (): ClearSuppliesListType => ({
  type: CLEAR_SUPPLIES_LIST,
  payload: null,
});