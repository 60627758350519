import React, { JSX, FC } from "react";
import css from "./style.module.css";

interface IFormTitleProps {
  title: string;
  subtitle: string;
}

const FormTitle: FC<IFormTitleProps> = ({title, subtitle}): JSX.Element => {
  return (
    <div className="mb-8">
      <h1 className="text-h1-b mb-3">{title}</h1>
      <p className={`secondary-color ${css.subtitle}`}>{subtitle}</p>
    </div>
  );
};

export default FormTitle;
