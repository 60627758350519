import { BasicEntityType } from "app/types";
import {
  FETCH_WB_SEASON_FAILURE,
  FETCH_WB_SEASON_REQUEST,
  FETCH_WB_SEASON_SUCCESS,
  FETCH_WB_SEASONS_LIST_FAILURE,
  FETCH_WB_SEASONS_LIST_REQUEST,
  FETCH_WB_SEASONS_LIST_SUCCESS
} from "../../constants/actionTypes";

export type WbSeasonsActionsType =
  | FetchWBSeasonsListRequestType
  | FetchWBSeasonsListSuccessType
  | FetchWBSeasonsListFailureType
  | FetchWBSeasonRequestType
  | FetchWBSeasonSuccessType
  | FetchWBSeasonFailureType;

// получение списка сезонов
type FetchWBSeasonsListRequestType = {
  type: typeof FETCH_WB_SEASONS_LIST_REQUEST
};

export const fetchWBSeasonsListRequest = (): FetchWBSeasonsListRequestType => ({
  type: FETCH_WB_SEASONS_LIST_REQUEST
});

type FetchWBSeasonsListSuccessType = {
  type: typeof FETCH_WB_SEASONS_LIST_SUCCESS;
  payload: {
    seasons: BasicEntityType[];
  };
};

export const fetchWBSeasonsListSuccess = (
  seasons: BasicEntityType[],
): FetchWBSeasonsListSuccessType => ({
  type: FETCH_WB_SEASONS_LIST_SUCCESS,
  payload: {
    seasons,
  },
});

type FetchWBSeasonsListFailureType = {
  type: typeof FETCH_WB_SEASONS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBSeasonsListFailure = (error: string): FetchWBSeasonsListFailureType => ({
  type: FETCH_WB_SEASONS_LIST_FAILURE,
  payload: {
    error,
  },
});

// получение сезона
type FetchWBSeasonRequestType = {
  type: typeof FETCH_WB_SEASON_REQUEST
};

export const fetchWBSeasonRequest = (): FetchWBSeasonRequestType => ({
  type: FETCH_WB_SEASON_REQUEST
});

type FetchWBSeasonSuccessType = {
  type: typeof FETCH_WB_SEASON_SUCCESS;
  payload: {
    season: BasicEntityType;
  };
};

export const fetchWBSeasonSuccess = (
  season: BasicEntityType,
): FetchWBSeasonSuccessType => ({
  type: FETCH_WB_SEASON_SUCCESS,
  payload: {
    season,
  },
});

type FetchWBSeasonFailureType = {
  type: typeof FETCH_WB_SEASON_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBSeasonFailure = (error: string): FetchWBSeasonFailureType => ({
  type: FETCH_WB_SEASON_FAILURE,
  payload: {
    error,
  },
});
