import React, { JSX, FC } from "react";

import AuthContainer from "../../components/Authentication/AuthContainer/AuthContainer";
import LoginForm from "../../components/Authentication/LoginForm/LoginForm";

const LoginPage: FC = (): JSX.Element => {
  return <AuthContainer authForm={<LoginForm />} />;
};

export default LoginPage;
