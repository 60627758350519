import React, { JSX, FC } from "react";
import { Divider, Modal } from "antd";
import GhostButton from "../../ui/GhostButton/GhostButton";
import ImageContainer from "../../ui/ImageContainer/ImageContainer";
import InfoCard from "../../ui/InfoCard/InfoCard";

import css from "./style.module.css";

interface ILabelingRulesModalProps {
  isOpen: boolean;
  onCancelHandler: () => void;
}

const LabelingRulesModal: FC<ILabelingRulesModalProps> = ({ isOpen, onCancelHandler }): JSX.Element => {
  return (
    <Modal
      width={1020}
      open={isOpen}
      footer={null}
      centered
      onCancel={onCancelHandler}
      maskStyle={{ backgroundColor: "rgba(103, 118, 140, 0.5)" }}
    >
      <h4 className="mb-6">Правила маркировки товара</h4>
      <p className="text-16-b mb-3">Маркировка товара</p>
      <p>
        1. Распечатайте лист подбора и маркировку наклейками из раздела “на сборке” сборочных заданий или из карточки
        поставки
      </p>
      <p>2. Согласно листу подбора наклейте маркировку на товар</p>
      <div className="mt-6 grid grid-cols-2 gap-6">
        <div>
          <p className="text-14-b mb-3">Лист подбора</p>
          <p>
            Лист подбора призван упростить Вам подбор и подготовку товара к отправке на склад WB. Он содержит заказанные
            товары и эскиз этикетки, которую необходимо наклеить на товар.
          </p>
          <div className={`rounded-xl p-4 mt-3 flex justify-center ${css.bgGrey}`}>
            <ImageContainer src="" alt="Примерный вид листа подбора" filename="selection-list.png" />
          </div>
        </div>

        <div>
          <p className="text-14-b mb-3">Маркировочные QR-коды</p>
          <p>
            На упаковке товара должна быть только одна маркировка, по которой сотрудники маркетплейса и смогут
            идентифицировать ваш товар. Будьте внимательны перед наклеиванием.
          </p>
          <InfoCard
            text={`Для товара следует печатать маркировку на самоклеящейся бумаге, 
            чтобы затем наклеить маркировку на товар`}
            className="p-3"
          />
          <div className={`rounded-xl p-3 ${css.bgGrey}`}>
            <div className="flex justify-evenly items-center gap-3">
              <ImageContainer src="" alt="Примерный вид маркировочного QR" filename="qr.png" />
              <ImageContainer src="" alt="Примерный вид маркировочного QR" filename="qr.png" />
            </div>
          </div>
        </div>
      </div>
      <Divider/>
      <div className="flex justify-end">
        <GhostButton text="Назад" onClickHandler={onCancelHandler} />
      </div>
    </Modal>
  );
};

export default LabelingRulesModal;
