import { RestType } from "app/types";
import {
  FETCH_RESTS_LIST_FAILURE,
  FETCH_RESTS_LIST_REQUEST,
  FETCH_RESTS_LIST_SUCCESS
} from "../../constants/actionTypes";
import { RestsActionsType } from "../actions/rests.actions";

export type InitialRestsStateType = {
  error: string | null;
  list: RestType[] | null;
  found: RestType | null;
  count: number;
  isFetching: boolean;
};

const initialState: InitialRestsStateType = {
  error: null,
  list: null,
  found: null,
  isFetching: false,
  count: 0,
};

const rests = (
  state: InitialRestsStateType = initialState,
  action: RestsActionsType
): InitialRestsStateType => {
  switch (action.type) {
    case FETCH_RESTS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_RESTS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.items,
        count: action.payload.count,
        error: null,
      };
    case FETCH_RESTS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default rests;
