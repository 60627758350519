import { ProductsFacetsType, ProductType } from "app/types";
import {
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  CLEAR_PRODUCTS_LIST,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCTS_LIST_FAILURE,
  FETCH_PRODUCTS_LIST_REQUEST,
  FETCH_PRODUCTS_LIST_SUCCESS,
  SYNC_PRODUCTS_CARD_FAILURE,
  SYNC_PRODUCTS_CARD_REQUEST,
  SYNC_PRODUCTS_CARD_SUCCESS,
  SYNC_PRODUCTS_FAILURE,
  SYNC_PRODUCTS_REQUEST,
  SYNC_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_WHOLE_PRODUCT_FAILURE,
  UPDATE_WHOLE_PRODUCT_REQUEST,
  UPDATE_WHOLE_PRODUCT_SUCCESS,
  FETCH_PRODUCTS_FACETS_SUCCESS,
  FETCH_PRODUCTS_FACETS_FAILURE,
} from "../../constants/actionTypes";
import { ProductsActionsType } from "../actions/products.actions";

export type InitialProductsStateType = {
  error: string | null;
  list: ProductType[] | null;
  count: number;
  found: ProductType | null;
  facets: ProductsFacetsType | null;
  isFetching: boolean;
};

const initialState: InitialProductsStateType = {
  error: null,
  list: null,
  count: 0,
  found: null,
  facets: null,
  isFetching: false,
};

const products = (
  state: InitialProductsStateType = initialState,
  action: ProductsActionsType,
): InitialProductsStateType => {
  const updateList = (list: ProductType[], product: ProductType) => {
    const index = list?.findIndex((p: ProductType) => p.id === product?.id);

    if (index !== -1) {
      // Если НП с таким id уже существует, заменяем ее на обновленную
      const newArray: ProductType[] = [...list];

      newArray[index] = product;

      return newArray;
    } else {
      // Если объекта с таким id нет, добавляем его в конец массива
      return [...list, product];
    }
  };

  switch (action.type) {
    // Список продуктов
    case FETCH_PRODUCTS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.items,
        count: action.payload.count,
        error: null,
      };
    case FETCH_PRODUCTS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    // Получение продукта
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        found: action.payload.product,
        list: state.list
          ? updateList(state.list, action.payload.product)
          : [action.payload.product],
        error: null,
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    // Очистка
    case CLEAR_PRODUCTS_LIST:
      return {
        ...state,
        list: action.payload,
      };

    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        list: state.list
          ? updateList(state.list, action.payload.product)
          : [action.payload.product],
      };
    case ADD_PRODUCT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        list: updateList(state.list, action.payload.product),
      };
    case UPDATE_PRODUCT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }
    case UPDATE_WHOLE_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_WHOLE_PRODUCT_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        list: updateList(state.list, action.payload.product),
      };
    case UPDATE_WHOLE_PRODUCT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_PRODUCT_SUCCESS: {
      const updatedList: ProductType[] = state.list
        ?.filter((product) => product.id !== action.payload.productId);

      return {
        ...state,
        error: null,
        isFetching: false,
        list: updatedList,
      };
    }
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    case SYNC_PRODUCTS_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case SYNC_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case SYNC_PRODUCTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };

    case SYNC_PRODUCTS_CARD_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case SYNC_PRODUCTS_CARD_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case SYNC_PRODUCTS_CARD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };

    case FETCH_PRODUCTS_FACETS_SUCCESS:
      return {
        ...state,
        facets: {
          ...state.facets,
          ...(action.payload.tab
            ? { [action.payload.tab]: action.payload.facets[action.payload.tab] }
            : action.payload.facets
          )
        }
      };
    case FETCH_PRODUCTS_FACETS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export default products;
