import React, { JSX, FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { InitialProductsStateType } from "redux/reducers/products.reducer";
import { AppDispatch } from "../../../redux/store/store";
import { fetchWarehouses } from "../../../redux/thunks/warehouses.thunks";

import { Tabs } from "antd";
import PricesTab from "../PricesTab/PricesTab";
import StocksTab from "../StocksTab/StocksTab";

interface IPricesAndStocksTab {
  className?: React.CSSProperties | string;
}

enum Tab {
  Prices = "Цены",
  Stocks = "Остатки",
}

const PricesAndStocksTab: FC<IPricesAndStocksTab> = () => {
  const { tab } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);

  const [activeTab, setActiveTab] = useState<string>(Object.values(Tab)?.[tab] ?? Tab.Prices);

  useEffect(() => {
    dispatch(fetchWarehouses());
  }, [dispatch]);

  const onChange = (key: string): void => {
    setActiveTab(key);
  };

  const renderTabPane = (tabKey: Tab, tab: JSX.Element): JSX.Element => (
    <Tabs.TabPane key={tabKey} tab={tabKey} disabled={products.isFetching}>
      {activeTab === tabKey && tab}
    </Tabs.TabPane>
  );

  return (
    <div className="px-6">
      <Tabs activeKey={activeTab} onChange={onChange}>
        {renderTabPane(Tab.Prices, <PricesTab />)}
        {renderTabPane(Tab.Stocks, <StocksTab />)}
      </Tabs>
    </div>
  );
};

export default PricesAndStocksTab;
