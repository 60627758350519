import React, { JSX, FC, useState } from "react";

import { MarketplaceType, MarketplaceWarehouseType, OfficeIdType, WBSyncStatusType } from "app/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import {
  clearFoundWarehouse,
  deleteWarehouse,
  syncWarehouse
} from "../../../redux/thunks/warehouses.thunks";

import { SyncOutlined as InProgressStatusIcon } from "@ant-design/icons";
import { ReactComponent as SuccessStatusIcon } from "../../../assets/icons/check_circle_fill_icon.svg";
import { ReactComponent as FailStatusIcon } from "../../../assets/icons/warning_circle_icon.svg";
import { ReactComponent as PlannedStatusIcon } from "../../../assets/icons/timelapse_icon.svg";

// components
import { Space, Tooltip, message } from "antd";
import WarehouseModal from "../../Modals/ModalWarehouse/ModalWarehouse";
import ConfirmModal from "../../Modals/ConfirmModal/ConfirmModal";
import Card from "../../ui/Card/Card";
import GhostButton from "../../ui/GhostButton/GhostButton";

import useTimeRemaining, { ITimeRemaining } from "hooks/useTimeRemaining";

import css from "./style.module.css";

interface IWarehouseCardProps {
  id: string;
  title: string;
  offices: OfficeIdType[];
  comment?: string;
  sync_status?: WBSyncStatusType;
  sync_at?: string;
}

// Статусы синхронизации склада ВБ
enum WBSyncStatus {
  InProgress = 0,
  Success = 1,
  Fail = 2,
  Planned = 3,
}

const WarehouseCard: FC<IWarehouseCardProps> = ({
  id,
  title,
  comment,
  offices,
  sync_status,
  sync_at,
}): JSX.Element => {
  const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false);
  const [isShowWarehouseModal, setIsShowWarehouseModal] = useState<boolean>(false);

  const synchDate: Date = new Date(sync_at);
  synchDate.setDate(synchDate.getDate() + 1);

  const availableSynchDate: ITimeRemaining = useTimeRemaining(synchDate);

  const dispatch = useDispatch<AppDispatch>();

  const deleteWarehouseHandler = (): void => {
    setIsShowCancelModal(false);

    dispatch(deleteWarehouse(id));
  };

  const syncWarehouseHandler = (marketplaceId: string): void => {
    dispatch(syncWarehouse(id, marketplaceId))
      .then((value: any) => {
        (value?.response?.status === 404) && message.error("ошибка");
      });
  };

  const renderIcon = (marketplace: MarketplaceType): JSX.Element => {
    switch (sync_status) {
      case WBSyncStatus.InProgress:
        return (
          <Tooltip title="Склад в процессе привязки">
            <InProgressStatusIcon spin className="cursor-pointer blue-color" />
          </Tooltip>
        );

      case WBSyncStatus.Success:
        return (
          <Tooltip title="Склад успешно подключен">
            <SuccessStatusIcon className={`${css.icon} ${css.success} cursor-pointer`} />
          </Tooltip>
        );

      case WBSyncStatus.Fail:
        return (
          <Space>
            <Tooltip title="Ошибка привязки склада">
              <FailStatusIcon className={`${css.icon} ${css.warning} cursor-pointer`} />
            </Tooltip>
            <p className="cursor-pointer blue-color" onClick={() => syncWarehouseHandler(marketplace.id)}>Повторить</p>
          </Space>
        );

      case WBSyncStatus.Planned: {
        const text: string = availableSynchDate
          ? `Синхронизируется через 
              ${availableSynchDate.days > 0 ? availableSynchDate?.days + " д." : ""} 
              ${availableSynchDate.hours > 0 ? availableSynchDate?.hours + " ч." : ""}
              ${availableSynchDate.minutes > 0 ? availableSynchDate?.minutes + " м." : ""}`
          : "";

        return (
          <Tooltip title={text}>
            <PlannedStatusIcon className={`${css.icon} ${css.planned} cursor-pointer`} />
          </Tooltip >
        );
      }

      default:
        return null;
    }
  };

  const renderOffice: FC<OfficeIdType> = ({
    id,
    marketplace,
    address,
  }) => {
    return (
      <div key={id} className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <img src={marketplace.logo} alt="Лого" />
          <span className="text-14-m pl-2">{address}</span>
        </div>
        {renderIcon(marketplace)}
      </div>
    );
  };

  return (
    <>
      <Card className="flex flex-col justify-between h-full">
        <div className="flex flex-col flex-1">
          <h5>{title}</h5>
          <p className="text-14-r secondary-color mb-3">
            Связанные склады маркетплейсов
          </p>
          <div className="flex flex-col">
            {offices?.map((renderOffice))}
          </div>
          {comment && (
            <div className="mt-3">
              <span className="secondary-color text-14-r pb-1">Комментарий</span>
              <p>{comment}</p>
            </div>
          )}
        </div>
        <div className="items-end">
          <GhostButton
            text="Редактировать"
            className={`mr-3 mt-6 ${css.editButton}`}
            isDisabled={!!availableSynchDate}
            onClickHandler={() => setIsShowWarehouseModal(true)}
            tooltip={!!availableSynchDate &&
              "Изменения можно будет внести через 24 часа после последнего редактирования"
            }
          />
          <GhostButton
            text="Удалить"
            className="mt-6"
            onClickHandler={() => setIsShowCancelModal(true)}
          />
        </div>
      </Card>

      {isShowCancelModal && (
        <ConfirmModal
          isOpen={isShowCancelModal}
          title="Вы уверены?"
          text="Вы хотите удалить склад. Это действие необратимо."
          onOkHandler={deleteWarehouseHandler}
          onCancelHandler={() => setIsShowCancelModal(false)}
        />
      )}

      {isShowWarehouseModal && (
        <WarehouseModal
          onClose={() => {
            setIsShowWarehouseModal(false);
            dispatch(clearFoundWarehouse());
          }}
          warehouseId={id}
        />
      )}
    </>
  );
};

export default WarehouseCard;
