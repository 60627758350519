import React, { FC, JSX } from "react";
import { Link } from "react-router-dom";
import css from "./style.module.css";

import logo from "../../../assets/img/logo.svg";

interface IAuthContainerProps {
  authForm: JSX.Element
  btn?: JSX.Element;
}

const AuthContainer: FC<IAuthContainerProps> = ({
  btn,
  authForm
}) => {
  return (
    <div className="flex gap-12 min-h-screen">
      <div className={`basis-1/2 flex items-end ${css.leftBlock}`} />
      <div className="mt-12">
        <div className="flex justify-between">
          <Link to="/login">
            <img className="mb-40 cursor-pointer" src={logo} />
          </Link>
          <span>{btn}</span>
        </div>
        <div className="mr-6">{authForm}</div>
      </div>
    </div>
  );
};

export default AuthContainer;
