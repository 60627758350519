import React, { FC, JSX, useEffect, useState } from "react";
import { fetchLkUserInfo, fetchUserInfo } from "./redux/thunks/account.thunks";
import { AppStateType } from "./redux/reducers/mainReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./redux/store/store";
import { Route, Routes, useLocation } from "react-router";
import { NavigateFunction, useNavigate } from "react-router-dom";

// components
import StubPage from "./pages/StubPage";
import MainLayout from "./components/MainLayout/MainLayout";
import RegistrationPage from "./pages/RegistrationPage";
import LoginPage from "./pages/LoginPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import DashboardPage from "./pages/DashboardPage";
import ConnectPage from "./pages/ConnectPage";
import ProductsPage from "./pages/ProductsPage";
import CreateAndEditProductPage from "./pages/CreateAndEditProductPage";
import WarehousesPage from "./pages/WarehousesPage";
import PricesAndStocksPage from "./pages/PricesAndStocksPage";
import OrdersPage from "./pages/OrdersPage";
import SupplyPage from "./pages/SupplyPage";

const App: FC = (): JSX.Element => {
  const { authToken } = useSelector((state: AppStateType) => state.account);

  const navigate: NavigateFunction = useNavigate();
  const params = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [isResetFormOpened, setResetFormOpened] = useState<boolean>(false);

  useEffect((): void => {
    authToken && dispatch(fetchUserInfo());
    authToken && dispatch(fetchLkUserInfo());
  }, [authToken, dispatch]);

  useEffect(() => {
    if (!authToken
      && !isResetFormOpened
      && params.pathname !== "/confirm-password"
      && params.pathname !== "/reset-password"
      && params.pathname !== "/registration"
    ) {
      navigate("/login");
    }
  }, [navigate, authToken, isResetFormOpened, params.pathname]);

  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<StubPage />} />
        {/* Форма входа */}
        <Route path="/login" element={<LoginPage />} />
        {/* Форма сброса пароля */}
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        {/* Форма создания нового пароля */}
        <Route path="/confirm-password" element={<NewPasswordPage />} />
        {/* Форма регистрации */}
        <Route path="/registration" element={<RegistrationPage />} />
        {authToken && (
          <>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/connect" element={<ConnectPage />} />
            <Route path="/connect/:marketplaceId" element={<ConnectPage />} />
            <Route path="/products/:tab?" element={<ProductsPage />} />
            <Route path="/create-product" element={<CreateAndEditProductPage />} />
            <Route path="/edit-product/card/:cardId/product/:productId" element={<CreateAndEditProductPage />} />
            <Route path="/warehouses" element={<WarehousesPage />} />
            <Route path="/prices-stocks-management/:tab?" element={<PricesAndStocksPage />} />
            <Route path="/orders/:tab?" element={<OrdersPage />}/>
            <Route path="/supply/:supplyId" element={<SupplyPage />} />
          </>
        )}
      </Routes>
    </MainLayout>
  );
};

export default App;
