import React, { JSX, FC, useState, useMemo, Key } from "react";
import debounce from "lodash/debounce";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppDispatch } from "redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import * as SuppliesAPI from "../../../api/supplies.api";
import { fetchOrders } from "redux/thunks/orders.thunks";

import css from "./style.module.css";

import { Dropdown, MenuProps, Space, message } from "antd";
import ProductsTable, { TableColumnType } from "../../../components/Products/ProductsTable/ProductsTable";
import PrimaryButton from "../../../components/ui/PrimaryButton/PrimaryButton";
import GhostButton from "../../../components/ui/GhostButton/GhostButton";
import CreateSupplyModal from "../../Modals/CreateSupplyModal/CreateSupplyModal";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import { SearchInput } from "components/ui/SearchInput/SearchInput";
import { ReactComponent as FilterIcon } from "../../../assets/icons/page_info_icon.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/three_dots_icon.svg";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/chart_bar_square.svg";

import type { AppStateType } from "../../../redux/reducers/mainReducer";
import type { InitialOrdersStateType } from "../../../redux/reducers/orders.reducer";
import type { InitialAccountStateType } from "../../../redux/reducers/account.reducer";
import { OrderType, RequestOrdersFiltersType, RequestPaginationType } from "app/types";
import { TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";

interface IOrdersTab {
  initFilters?: RequestOrdersFiltersType;
}

const OrdersTab: FC<IOrdersTab> = ({ initFilters }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();

  const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);
  const account: InitialAccountStateType = useSelector((state: AppStateType) => state.account);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<RequestOrdersFiltersType>(initFilters);
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [selectedRows, setSelectedRows] = useState<OrderType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [selectedOrders, setSelectedOrders] = useState<OrderType[]>(null);
  const showAddToDeliveryButton: boolean = true;

  const commonColumns: TableColumnType[] = [
    "photo", "order_number", "created_at", "name", "size",
    "color", "barcode", "warehouse", "due_date", "actions"
  ];

  const fetchOrdersList = (pagination: RequestPaginationType, filters: RequestOrdersFiltersType): void => {
    dispatch(fetchOrders(pagination, filters));
  };

  const createSupply = async (name: string): Promise<void> => {
    setIsLoading(true);

    const orderIds: string[] = selectedOrders?.map(({ id }) => id);

    await SuppliesAPI.add({
      company_id: account?.userInfo?.company?.id,
      name: name,
      order_ids: orderIds,
    })
      .then((res) => {
        if (res.status === 200) {
          message.success(`Поставка ${name} успешно создана`);

          setSelectedOrders(null);

          navigate("/orders/1");
          navigate(0);
        }
      })
      .catch((error) => message.error(error.response?.data?.error))
      .finally(() => setIsLoading(false));
  };

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows: OrderType[]) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  // Actions

  // TODO: Доделать как будут ручки
  const handleMenuCancelTheTask = (order: OrderType): void => {
    console.log("click", order);
  };

  const renderSearch = (): JSX.Element => {
    return (
      <div className={css.searchWrap}>
        <SearchInput
          placeholder="Найти по артикулу или названию"
          onSearch={(state: RequestOrdersFiltersType) => setFilters(state)}
          filters={filters}
          isGeneralSearch={false}
          isDisabled={orders.isFetching && !orders.list}
        />
        <GhostButton
          size="large"
          text="Фильтры"
          icon={<FilterIcon />}
          onClickHandler={() => { }} // TODO: Доделать как будут фильтры по сборочным заданиям
          isDisabled={orders.isFetching}
        />
      </div>
    );
  };

  const renderPageActions = (): JSX.Element => {
    const numberOfItems: string = selectedKeys?.length ? `(${selectedKeys.length})` : "";

    return (
      <div className={css.actionsWrap}>
        {showAddToDeliveryButton && (
          <PrimaryButton
            size="large"
            text={`Добавить товары в поставку ${numberOfItems}`}
            onClickHandler={() => setSelectedOrders(selectedRows)}
            isDisabled={!(selectedRows?.length > 0)}
          />
        )}
      </div>
    );
  };

  const renderOrderAction = (_value: any, order: OrderType, idx: number): React.ReactNode => {
    const items: MenuProps["items"] = [
      {
        label: <a onClick={() => setSelectedOrders([order])}>Собрать в поставку</a>,
        key: `assemble-${idx}`,
      },
      {
        label: <a onClick={() => handleMenuCancelTheTask(order)}>Отменить задание</a>,
        key: `cancle-${idx}`,
        className: css.cancelTask,
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  const renderEmptyBlock = (): JSX.Element => (
    <EmptyBlock
      icon={<EmptyIcon className={`mb-6 ${css.emptyIcon}`} />}
      style={{ height: "calc(100vh - 23rem)" }}
      title="Новых заданий нет"
      description={
        <>Здесь появятся новые сборочные задания,<br /> поступающие из маркетплейса.</>
      }
    />
  );

  return (
    <Space className="flex" direction="vertical">
      <CreateSupplyModal
        isOpen={!!selectedOrders}
        isLoading={isLoading}
        onCancelHandler={() => setSelectedOrders(null)}
        createSupply={createSupply}
      />
      <p className="text-14-r secondary-color mb-3">
        В этом разделе будут появляться новые заказы. Выберите товары, которые готовы взять на сборку.
      </p>
      <div className={css.controlsWrap}>
        {renderSearch()}
        {renderPageActions()}
      </div>
      <ProductsTable
        columns={commonColumns}
        list={orders.list}
        count={orders.count}
        fetchList={fetchOrdersList}
        isFetching={orders.isFetching}
        renderActions={renderOrderAction}
        rowSelection={rowSelection}
        filters={filters}
        setPaginationInfo={setPaginationInfo}
        emptyBlock={renderEmptyBlock()}
        isShowEmptyBlock={true}
        emptyExcludedFilters={Object.keys(initFilters)}
      />
    </Space>
  );
};

export default OrdersTab;
