import React, { JSX, FC } from "react";

// components
import StickyHeader from "../../components/ui/StickyHeader/StickyHeader";
import TabsWithCounters from "components/Orders/TabsWithCounters/TabsWithCounters";

interface IOrdersPageProps { }

const OrdersPage: FC<IOrdersPageProps> = (): JSX.Element => {
  return (
    <div>
      <StickyHeader title="Сборочные задания" isShowDivider={false} />
      <TabsWithCounters />
    </div>
  );
};

export default OrdersPage;
