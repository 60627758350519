import {
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  FETCH_LK_USER_INFO_SUCCESS,
  FETCH_LK_USER_INFO_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILURE,
  GET_USER_TOKEN_DESCRIPTION_FAILURE,
  GET_USER_TOKEN_DESCRIPTION_SUCCESS,
  TOKEN_REVOCATION_SUCCESS,
  TOKEN_REVOCATION_FAILURE,
  FETCH_LK_USER_INFO_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_EMAIL_REQUEST,
  RESET_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_EMAIL_FAILURE,
} from "../../constants/actionTypes";
import { LkUserInfoType, TokenDescriptionType, UserInfoType } from "app/types";

export type AccountActionsTypes =
  | LogoutType
  | LoginSuccessType
  | LoginFailureType
  | RefreshTokenSuccessType
  | RefreshTokenFailureType
  | FetchUserInfoSuccessType
  | FetchUserInfoFailureType
  | FetchLkUserInfoRequestType
  | FetchLkUserInfoSuccessType
  | FetchLkUserInfoFailureType
  | GetUserTokenDescriptionSuccessType
  | GetUserTokenDescriptionFailureType
  | TokenRevocationSuccessType
  | TokenRevocationFailureType
  | ResetPasswordRequestType
  | ResetPasswordSuccessType
  | ResetPasswordFailureType
  | ResetPasswordEmailRequestType
  | ResetPasswordEmailSuccessType
  | ResetPasswordEmailFailureType
  ;

// Авторизация
type LoginSuccessType = {
  type: typeof LOGIN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
  };
};

export const loginSuccess = (access_token: string, refresh_token: string): LoginSuccessType => ({
  type: LOGIN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

type LoginFailureType = {
  type: typeof LOGIN_FAILURE;
  payload: {
    error: string;
  };
};

export const loginFailure = (error: string): LoginFailureType => ({
  type: LOGIN_FAILURE,
  payload: {
    error
  }
});

// Обновление токена через refresh_token
type RefreshTokenSuccessType = {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
  };
};

export const refreshTokenSuccess = (access_token: string, refresh_token: string): RefreshTokenSuccessType => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

type RefreshTokenFailureType = {
  type: typeof REFRESH_TOKEN_FAILURE;
  payload: {
    error: string;
  };
};

export const refreshTokenFailure = (error: string): RefreshTokenFailureType => ({
  type: REFRESH_TOKEN_FAILURE,
  payload: {
    error
  }
});

// Расшифровка токена авторизованного пользователя
type GetUserTokenDescriptionSuccessType = {
  type: typeof GET_USER_TOKEN_DESCRIPTION_SUCCESS;
  payload: {
    userTokenDescription: TokenDescriptionType;
  };
};

export const getUserTokenDescriptionSuccess = (userTokenDescription: TokenDescriptionType)
  : GetUserTokenDescriptionSuccessType => ({
    type: GET_USER_TOKEN_DESCRIPTION_SUCCESS,
    payload: {
      userTokenDescription
    }
  });

type GetUserTokenDescriptionFailureType = {
  type: typeof GET_USER_TOKEN_DESCRIPTION_FAILURE;
  payload: {
    error: string;
  };
};

export const getUserTokenDescriptionFailure = (error: string): GetUserTokenDescriptionFailureType => ({
  type: GET_USER_TOKEN_DESCRIPTION_FAILURE,
  payload: {
    error
  }
});

// Получение информации об авторизованном пользователе по токену
type FetchUserInfoSuccessType = {
  type: typeof FETCH_USER_INFO_SUCCESS;
  payload: {
    userInfo: UserInfoType;
  };
};

export const fetchUserInfoSuccess = (userInfo: UserInfoType): FetchUserInfoSuccessType => ({
  type: FETCH_USER_INFO_SUCCESS,
  payload: {
    userInfo
  }
});

type FetchUserInfoFailureType = {
  type: typeof FETCH_USER_INFO_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchUserInfoFailure = (error: string): FetchUserInfoFailureType => ({
  type: FETCH_USER_INFO_FAILURE,
  payload: {
    error
  }
});

// Получение информации об авторизованном пользователе по токену
type FetchLkUserInfoRequestType = {
  type: typeof FETCH_LK_USER_INFO_REQUEST;
};

export const fetchLkUserInfoRequest = (): FetchLkUserInfoRequestType => ({
  type: FETCH_LK_USER_INFO_REQUEST,
});

type FetchLkUserInfoSuccessType = {
  type: typeof FETCH_LK_USER_INFO_SUCCESS;
  payload: {
    userInfo: LkUserInfoType;
  };
};

export const fetchLkUserInfoSuccess = (userInfo: LkUserInfoType): FetchLkUserInfoSuccessType => ({
  type: FETCH_LK_USER_INFO_SUCCESS,
  payload: {
    userInfo
  }
});

type FetchLkUserInfoFailureType = {
  type: typeof FETCH_LK_USER_INFO_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchLkUserInfoFailure = (error: string): FetchLkUserInfoFailureType => ({
  type: FETCH_LK_USER_INFO_FAILURE,
  payload: {
    error
  }
});

// Отзыв токена
type TokenRevocationSuccessType = {
  type: typeof TOKEN_REVOCATION_SUCCESS;
};

export const tokenRevocationSuccess = (): TokenRevocationSuccessType => ({
  type: TOKEN_REVOCATION_SUCCESS,
});

type TokenRevocationFailureType = {
  type: typeof TOKEN_REVOCATION_FAILURE;
  payload: {
    error: string;
  };
};

export const tokenRevocationFailure = (error: string): TokenRevocationFailureType => ({
  type: TOKEN_REVOCATION_FAILURE,
  payload: {
    error
  }
});

// Деавторизация
type LogoutType = {
  type: typeof LOGOUT;
};

export const logout = (): LogoutType => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");

  return { type: LOGOUT };
};

// Восстановление пароля
type ResetPasswordRequestType = {
  type: typeof RESET_PASSWORD_REQUEST;
  payload: {
    password: string;
  }
};

export const resetPasswordRequest = (password: string): ResetPasswordRequestType => ({
  type: RESET_PASSWORD_REQUEST,
  payload: {
    password
  }
});

type ResetPasswordSuccessType = {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: {
    response: string;
  }
};

export const resetPasswordSuccess = (response: string): ResetPasswordSuccessType => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: {
    response
  }
});

type ResetPasswordFailureType = {
  type: typeof RESET_PASSWORD_FAILURE;
  payload: {
    error: any;
  };
};

export const resetPasswordFailure = (error: any): ResetPasswordFailureType => ({
  type: RESET_PASSWORD_FAILURE,
  payload: {
    error
  }
});

// Запрос ссылки на восстановление пароля
type ResetPasswordEmailRequestType = {
  type: typeof RESET_PASSWORD_EMAIL_REQUEST;
  payload: {
    email: string;
  }
};

export const resetPasswordEmailRequest = (email: string): ResetPasswordEmailRequestType => ({
  type: RESET_PASSWORD_EMAIL_REQUEST,
  payload: {
    email
  }
});

type ResetPasswordEmailSuccessType = {
  type: typeof RESET_PASSWORD_EMAIL_SUCCESS;
  payload: {
    response: string;
  }
};

export const resetPasswordEmailSuccess = (response: string): ResetPasswordEmailSuccessType => ({
  type: RESET_PASSWORD_EMAIL_SUCCESS,
  payload: {
    response
  }
});

type ResetPasswordEmailFailureType = {
  type: typeof RESET_PASSWORD_EMAIL_FAILURE;
  payload: {
    error: any;
  };
};

export const resetPasswordEmailFailure = (error: any): ResetPasswordEmailFailureType => ({
  type: RESET_PASSWORD_EMAIL_FAILURE,
  payload: {
    error
  }
});
