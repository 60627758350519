import React, { FC, JSX } from "react";
import { Divider } from "antd";

import useScreenHeight from "../../../hooks/useScreenHeight";
import useScrollingHeader from "../../../hooks/useScrollingHeader";

interface IStickyHeaderProps {
  title: string;
  btn?: JSX.Element;
  description?: string;
  isShowDivider?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const StickyHeader: FC<IStickyHeaderProps> = ({
  title,
  description,
  btn,
  children,
  isShowDivider = true,
  className = ""
}): JSX.Element => {
  const isHeaderVisible: "down" | "up" = useScrollingHeader();
  const screenHeight: number = useScreenHeight();

  const headerStickyStyle: number = isHeaderVisible === "down" ? -screenHeight : 0;

  return (
    <div
      className={`px-6 pt-6 sticky bg-white h-${screenHeight} transition-all ease-in-out z-10 ${className}`}
      style={{top: headerStickyStyle}}
    >
      <header className="flex justify-between">
        <div>
          <h3 className="mb-3">{title}</h3>
          <p className="text-14-r secondary-color">{description}</p>
        </div>
        {btn}
      </header>
      {children}
      {isShowDivider && <Divider className="mb-0" />}
    </div>
  );
};

export default StickyHeader;
