import React, { JSX, FC } from "react";
import { useLocation, useNavigate } from "react-router";
import { useWatch } from "antd/es/form/Form";
import { useDispatch } from "react-redux";
import { resetPassword as requestResetPassword } from "redux/thunks/account.thunks";

import { Form, Input, message } from "antd";
import FormTitle from "../FormTitle/FormTitle";
import SubmitButton from "../../SubmitButton/SubmitButton";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";

import css from "./style.module.css";

import { AppDispatch } from "redux/store/store";
import { Rule } from "antd/es/form";

type RequestType = {
  password: string;
  confirm: string;
}

interface INewPasswordForm {
  password: string | undefined,
  confirm: string | undefined,
}

interface INewPasswordFormProps { }

const PASSWORD_RULES: Rule[] = [
  {
    pattern: /(?=\S*?[A-Z])/,
    message: "Пароль должен содержать хотя бы одну заглавную букву.",
  },
  {
    pattern: /(?=\S*?[a-z])/,
    message: "Пароль должен содержать хотя бы одну строчную букву.",
  },
  {
    pattern: /(?=\S*?[0-9])/,
    message: "Пароль должен содержать хотя бы одну цифру.",
  },
  {
    pattern: /^[^\s]+$/,
    message: "Пароль не должен содержать пробелов.",
  },
  {
    pattern: /^[a-zA-Z0-9\s]*$/,
    message: "Пароль должен содержать только латинские буквы и цифры.",
  },
  {
    pattern: /.{8,}/,
    message: "Пароль должен быть длиной как минимум 8 символов.",
  }
];

const NewPasswordForm: FC<INewPasswordFormProps> = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const queryParams: URLSearchParams = new URLSearchParams(location.search);
  const isComplete: boolean = queryParams.get("complete") === "true";

  const [form] = Form.useForm();
  const values = useWatch<INewPasswordForm>([], form);

  const resetSubtitle: string = `
    Придумайте новый пароль. 
    Он должен быть не менее 8 символов,
    содержать прописные и заглавные буквы и цифры.`;

  const completeSubtitle: string = `
    Пароль успешно изменен, 
    теперь вы можете войти в свой 
    аккаунт, используя новый пароль.`;

  const resetPassword = (request: RequestType): void => {
    form.validateFields()
      .then(() => {
        const { password, confirm } = request;

        dispatch(requestResetPassword(password || confirm))
          .then(() => navigate("?complete=true"))
          .catch((error: any) => message.error(error ?? "Ошибка"));
      });
  };

  const handleOnSubmit = (): void => resetPassword({
    password: values.password,
    confirm: values.confirm
  });

  return (
    <div className={css.form}>
      <FormTitle
        title="Восстановление пароля"
        subtitle={
          isComplete
            ? completeSubtitle
            : resetSubtitle
        }
      />
      {isComplete ? (
        <PrimaryButton
          className={`mb-3 mt-0`}
          text="Войти"
          isDisabled={!isComplete}
          onClickHandler={() => navigate("/login")}
        />
      ) : (
        <Form form={form} name="passwordRecovery">
          <Form.Item
            name="password"
            rules={PASSWORD_RULES}
          >
            <Input.Password
              className={css.passwordInput}
              placeholder="Придумайте пароль"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["password"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
                message: "Пароли не совпадают"
              }),
            ]}
          >
            <Input.Password
              className={css.passwordInput}
              placeholder="Повторите пароль"
            />
          </Form.Item>
          <Form.Item>
            <SubmitButton form={form} text="Установить пароль" onClick={handleOnSubmit} />
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default NewPasswordForm;
