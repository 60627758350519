import { WBCountryType } from "app/types";
import {
  FETCH_WB_COUNTRY_FAILURE,
  FETCH_WB_COUNTRY_SUCCESS,
  FETCH_WB_COUNTRIES_LIST_FAILURE,
  FETCH_WB_COUNTRIES_LIST_SUCCESS,
  FETCH_WB_COUNTRIES_LIST_REQUEST,
  FETCH_WB_COUNTRY_REQUEST
} from "../../constants/actionTypes";

export type WbCountriesActionsType =
  | FetchWBCountriesListRequestType
  | FetchWBCountriesListSuccessType
  | FetchWBCountriesListFailureType
  | FetchWBCountryRequestType
  | FetchWBCountrySuccessType
  | FetchWBCountryFailureType;

// получение списка стран
type FetchWBCountriesListRequestType = {
  type: typeof FETCH_WB_COUNTRIES_LIST_REQUEST
};

export const fetchWBCountriesListRequest = (): FetchWBCountriesListRequestType => ({
  type: FETCH_WB_COUNTRIES_LIST_REQUEST
});

type FetchWBCountriesListSuccessType = {
  type: typeof FETCH_WB_COUNTRIES_LIST_SUCCESS;
  payload: {
    countries: WBCountryType[];
  };
};

export const fetchWBCountriesListSuccess = (
  countries: WBCountryType[],
): FetchWBCountriesListSuccessType => ({
  type: FETCH_WB_COUNTRIES_LIST_SUCCESS,
  payload: {
    countries,
  },
});

type FetchWBCountriesListFailureType = {
  type: typeof FETCH_WB_COUNTRIES_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBCountriesListFailure = (error: string): FetchWBCountriesListFailureType => ({
  type: FETCH_WB_COUNTRIES_LIST_FAILURE,
  payload: {
    error,
  },
});

// получение страны
type FetchWBCountryRequestType = {
  type: typeof FETCH_WB_COUNTRY_REQUEST
};

export const fetchWBCountryRequest = (): FetchWBCountryRequestType => ({
  type: FETCH_WB_COUNTRY_REQUEST
});

type FetchWBCountrySuccessType = {
  type: typeof FETCH_WB_COUNTRY_SUCCESS;
  payload: {
    country: WBCountryType;
  };
};

export const fetchWBCountrySuccess = (
  country: WBCountryType,
): FetchWBCountrySuccessType => ({
  type: FETCH_WB_COUNTRY_SUCCESS,
  payload: {
    country,
  },
});

type FetchWBCountryFailureType = {
  type: typeof FETCH_WB_COUNTRY_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBCountryFailure = (error: string): FetchWBCountryFailureType => ({
  type: FETCH_WB_COUNTRY_FAILURE,
  payload: {
    error,
  },
});
