import * as MarketplaceOfficesAPI from "../../api/officesWildberries.api";
import { ThunkAction } from "redux-thunk";
import {
  fetchOfficesWildberriesListRequest,
  fetchOfficesWildberriesListSuccess,
  fetchOfficesWildberriesListFailure,
  OfficesWildberriesActionsType
} from "../actions/officesWildberries.actions";
import { AppStateType } from "../reducers/mainReducer";

export const fetchMarketplaceOffices = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OfficesWildberriesActionsType
> => async (dispatch) => {
  dispatch(fetchOfficesWildberriesListRequest());
  try {
    const response = await MarketplaceOfficesAPI.list();

    dispatch(fetchOfficesWildberriesListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchOfficesWildberriesListFailure(error.response?.data?.error));
  };
};

export const filterMarketplaceOffices = (city: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OfficesWildberriesActionsType
> => async (dispatch) => {
  dispatch(fetchOfficesWildberriesListRequest());
  try {
    const response = await MarketplaceOfficesAPI.filter(city);

    dispatch(fetchOfficesWildberriesListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchOfficesWildberriesListFailure(error.response.data.detail));
  };
};

export const searchMarketplacesOffices = (search: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OfficesWildberriesActionsType
> => async (dispatch) => {
  try {
    const response = await MarketplaceOfficesAPI.search(search);

    dispatch(fetchOfficesWildberriesListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchOfficesWildberriesListFailure(error.response?.data?.error));
  };
};

export const searchFilteredMarketplaceOffices = (search: string, marketplace: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OfficesWildberriesActionsType
> => async (dispatch) => {
  try {
    const response = await MarketplaceOfficesAPI.filter(marketplace, search);

    dispatch(fetchOfficesWildberriesListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchOfficesWildberriesListFailure(error.response?.data?.error));
  };
};

