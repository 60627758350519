import React, { JSX, FC } from "react";

import { Divider, Modal } from "antd";
import GhostButton from "../../ui/GhostButton/GhostButton";
import ImageContainer from "../../ui/ImageContainer/ImageContainer";

import css from "../LabelingRulesModal/style.module.css";

interface IShippingRulesModalProps {
  isOpen: boolean;
  onCancelHandler: () => void;
}

const ShippingRulesModal: FC<IShippingRulesModalProps> = ({ isOpen, onCancelHandler }): JSX.Element => {
  return (
    <Modal
      width={1020}
      open={isOpen}
      footer={null}
      centered
      onCancel={onCancelHandler}
      maskStyle={{backgroundColor: "rgba(103, 118, 140, 0.5)"}}
    >
      <h4 className="mb-6">Правила отгрузки в ПВЗ</h4>
      <p className="text-16-b mb-3">Стоимость услуги приемки товара в ПВЗ по России и СНГ — 15 рублей</p>
      <div className="flex">
        <div className={`rounded-xl p-4 mt-3 mr-3 flex justify-center items-center ${css.bgGrey}`}>
          <ImageContainer src="" alt="Габариты короба и формат QR" filename="dimensions.png"/>
        </div>
        <div className="mt-3">
          <p className="text-14-b mb-3">Правила упаковки</p>
          <ul className="list-disc ml-5">
            <li>Не более 1 коробки в день</li>
            <li>
              Могут быть отгружены товары и транспортировочные короба, которые не превышают указанные габариты:
              60x40x40; вес до 5 кг; сумма трех сторон не более 120 см в упаковке
            </li>
            <li>Короб закрыт со всех сторон</li>
            <li>На коробе должен быть приклеен QR короба</li>
            <li>При сдаче товаров в ПВЗ не требуется распечатывать ШК поставки</li>
            <li>
              Необходимо распечатать и приклеить QR-код в формате WB-MP для ПВЗ, так как товар без этого кода не будет
              принят в ПВЗ
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-6">
        <p className="text-14-b">
          Товары и транспортировочные короба с данными габаритами недопустимы для отгрузки в ПВ3:
        </p>
        <ul className="list-disc ml-5">
          <li>более 5 кг</li>
          <li>более 60 см по одной из сторон</li>
        </ul>
      </div>
      <Divider/>
      <div className="flex justify-end">
        <GhostButton text="Назад" onClickHandler={onCancelHandler}/>
      </div>
    </Modal>
  );
};

export default ShippingRulesModal;
