import * as process from "process";
import axios from "../utils/axios";
import {
  RequestPaginationType,
  RequestOrdersFiltersType,
  RequestStickersType,
  RequestDashboardType,
  RequestAddOrdersToBox,
} from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const list = async (pagination: RequestPaginationType, filters: RequestOrdersFiltersType): Promise<any> => {
  const searchParams = new URLSearchParams();

  Object.keys(pagination).forEach(key => {
    (Array.isArray(pagination[key]) ? pagination[key] : [pagination[key]]).forEach(value => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    });
  });

  return axios.POST(API_BASE, `/api/orders/order_list?${searchParams.toString()}`, filters);
};

export const getStickers = async (params: RequestStickersType): Promise<any> => {
  return axios.POST(API_BASE, "/api/orders/order/sticker-list", params);
};

export const uploadNew = async (): Promise<any> => {
  return axios.GET(API_BASE, "/api/orders/upload-new");
};

export const getDashboard = async (params: RequestDashboardType): Promise<any> => {
  return axios.GET(API_BASE, "/api/orders/dashboard", params);
};

// Получение списка фасетов
export const facets = async (params: RequestOrdersFiltersType): Promise<any> => {
  return axios.POST(API_BASE, "/api/orders/order_facet", params);
};