import { FilterWBCategoriesType, WBCategoryType } from "app/types";

import {
	FETCH_WB_CATEGORIES_LIST_REQUEST,
	FETCH_WB_CATEGORY_SUCCESS,
	FETCH_WB_CATEGORY_FAILURE,
	FETCH_WB_CATEGORIES_LIST_FAILURE,
	FETCH_WB_CATEGORIES_LIST_SUCCESS,
	FETCH_WB_CATEGORY_REQUEST,
} from "../../constants/actionTypes";

export type WBCategoriesActionsType =
	| FetchWBCategoriesListRequestType
	| FetchWBCategoriesListSuccessType
	| FetchWBCategoriesListFailureType
	| FetchWBCategoryRequestType
	| FetchWBCategorySuccessType
	| FetchWBCategoryFailureType

// получение списка категорий
type FetchWBCategoriesListRequestType = {
	type: typeof FETCH_WB_CATEGORIES_LIST_REQUEST;
	payload: {
		params: FilterWBCategoriesType | null;
	}
};

export const fetchWBCategoriesListRequest = (
	params: FilterWBCategoriesType | null = null
): FetchWBCategoriesListRequestType => ({
	type: FETCH_WB_CATEGORIES_LIST_REQUEST,
	payload: {
		params
	}
});

type FetchWBCategoriesListSuccessType = {
	type: typeof FETCH_WB_CATEGORIES_LIST_SUCCESS;
	payload: {
		categories: WBCategoryType[];
	};
};

export const fetchWBCategoriesListSuccess = (
	categories: WBCategoryType[]
): FetchWBCategoriesListSuccessType => ({
	type: FETCH_WB_CATEGORIES_LIST_SUCCESS,
	payload: {
		categories
	}
});

type FetchWBCategoriesListFailureType = {
	type: typeof FETCH_WB_CATEGORIES_LIST_FAILURE;
	payload: {
		error: string;
	};
};

export const fetchWBCategoriesListFailure = (
	error: string
): FetchWBCategoriesListFailureType => ({
	type: FETCH_WB_CATEGORIES_LIST_FAILURE,
	payload: {
		error
	}
});

// получение категории
type FetchWBCategoryRequestType = {
	type: typeof FETCH_WB_CATEGORY_REQUEST;
	payload: {
		categoryId: string
	}
};

export const fetchWBCategoryRequest = (
	categoryId: string
): FetchWBCategoryRequestType => ({
	type: FETCH_WB_CATEGORY_REQUEST,
	payload: {
		categoryId
	}
});

type FetchWBCategorySuccessType = {
	type: typeof FETCH_WB_CATEGORY_SUCCESS;
	payload: {
		category: WBCategoryType;
	};
};

export const fetchWBCategorySuccess = (
	category: WBCategoryType
): FetchWBCategorySuccessType => ({
	type: FETCH_WB_CATEGORY_SUCCESS,
	payload: {
		category
	}
});

type FetchWBCategoryFailureType = {
	type: typeof FETCH_WB_CATEGORY_FAILURE;
	payload: {
		error: string;
	};
};

export const fetchWBCategoryFailure = (
	error: string
): FetchWBCategoryFailureType => ({
	type: FETCH_WB_CATEGORY_FAILURE,
	payload: {
		error
	}
});
