import React, { JSX, FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { InitialProductsStateType } from "redux/reducers/products.reducer";
import { fetchMarketplaces } from "redux/thunks/marketplaces.thunks";
import { clearProductsList } from "redux/actions/products.actions";
import { AppDispatch } from "redux/store/store";
import * as ProductsAPI from "../../../api/products.api";
import { formatFilters } from "utils/formatFilters";

import { Badge, Tabs } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import ProductsTab, { ProductStatus } from "../ProductsTab/ProductsTab";

import css from "./style.module.css";

import { FiltersDrawerValuesType } from "../Filters/FiltersDrawer/FiltersDrawer";
import { fetchProductsFacets } from "redux/thunks/products.thunks";

interface ITabsWithCountersProps {
	btn: JSX.Element;
}

export enum Tab {
	synchronized = "Опубликованы",
	draft = "Черновик",
	error = "Ошибка интеграции",
	archive = "Архив",
	all = "Все товары",
}

const TabsWithCounters: FC<ITabsWithCountersProps> = ({
	btn,
}): JSX.Element => {
	const { tab } = useParams();
	const dispatch = useDispatch<AppDispatch>();
	const [form] = useForm<FiltersDrawerValuesType>();
	const values = useWatch<FiltersDrawerValuesType>([], form);

	const [activeTab, setActiveTab] = useState<string>(Object.values(Tab)?.[tab] ?? Tab.all);

	const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);

	const commonColumns: string[] = [
		"photo", "barcode", "category",
		"name", "brand", "updated_at",
		"color", "size",
	];

	useEffect(() => {
		dispatch(fetchMarketplaces());
		dispatch(fetchProductsFacets(formatFilters(values)));
	}, []);

	useEffect(() => {
		const activeKey = Object.keys(Tab).find((key) => Tab[key] === activeTab) || "";

		// Актуальные фасеты для всех табов (обновляем счетчики)
		dispatch(fetchProductsFacets(formatFilters({})));
		// Точечно фасет для выбранного таба с фильтрами
		dispatch(fetchProductsFacets(formatFilters(values), activeKey));
	}, [products.list, activeTab]);

	const renderLabel = (title: string, count: number = 0): JSX.Element => {
		const countStyle: React.CSSProperties = {
			marginLeft: 4,
			color: activeTab === title ? "#0a84ff" : "#374151",
			backgroundColor: activeTab === title ? "#0A84FF1A" : "#67768C1A"
		};

		return (
			<span>
				{title}
				<Badge
					style={countStyle}
					count={count || null}
					overflowCount={999}
				/>
			</span>
		);
	};

	const onChange = (key: string): void => {
		form.resetFields();
		dispatch(clearProductsList());
		setActiveTab(key);
	};

	const renderTabPane = (tabKey: Tab, columns: any, count: number, initFilters = {}): JSX.Element => (
		<Tabs.TabPane
			key={tabKey}
			tab={renderLabel(tabKey, count)}
			disabled={products.isFetching}
		>
			{activeTab === tabKey && (
				<ProductsTab
					form={form}
					btn={btn}
					currentTab={activeTab}
					initFilters={initFilters}
					columns={columns}
				/>
			)}
		</Tabs.TabPane>
	);

	return (
		<div className={css.wrapper}>
			<Tabs
				className="px-6"
				activeKey={activeTab}
				onChange={onChange}
			>
				{renderTabPane(
					Tab.synchronized, [
					...commonColumns,
					"integrations",
					"actions"
				], products.facets?.synchronized, ({ status: 2 }))}

				{renderTabPane(
					Tab.draft, [
					...commonColumns,
					"actions"
				], products.facets?.draft, ({ status: 0 }))}

				{renderTabPane(
					Tab.error, [
					...commonColumns,
					"integrations",
					"actions"
				], products.facets?.error, ({ status: 3 }))}

				{renderTabPane(
					Tab.archive, [
					...commonColumns,
					"actions"
				], products.facets?.archive, ({ status: 1 }))}

				{renderTabPane(
					Tab.all, [
					...commonColumns,
					"integrations",
					"actions"
				], products.facets?.all, ({}))}
			</Tabs>
		</div>
	);
};

export default TabsWithCounters;
