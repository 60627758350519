import * as process from "process";
import axios from "../utils/axios";
import {CreateRestsParamsType, RequestPaginationType, RequestRestsFiltersType, RestlessListParamsType} from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

// Создание остатка для номенклатурной позиции
export const add = async (params: CreateRestsParamsType): Promise<any> => {
  return axios.POST(API_BASE, "/api/catalog/rests", params);
};

// Листинг НП, остатков которых нет в выбранном складе
export const restlessList = (params: RestlessListParamsType): Promise<any> => {
  return axios.POST(API_BASE, "/api/catalog/restless_list", params);
};

// Листинг остатков
export const list = (pagination: RequestPaginationType, filters: RequestRestsFiltersType): Promise<any> => {
  const searchParams: URLSearchParams = new URLSearchParams();

  Object.keys(pagination).forEach((key) => {
    (Array.isArray(pagination[key]) ? pagination[key] : [pagination[key]]).forEach((value) => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    });
  });

  return axios.POST(API_BASE, `/api/catalog/rest_list?${searchParams?.toString()}`, filters);
};
