import * as SupplyBoxesAPI from "../../api/supplyBoxes.api";
import { ThunkAction } from "redux-thunk";
import {
  createBoxesRequest,
  createBoxesSuccess,
  createBoxesFailure,
  fetchBoxesListRequest,
  fetchBoxesListSuccess,
  fetchBoxesListFailure,
  deleteBoxesRequest,
  deleteBoxesSuccess,
  deleteBoxesFailure,
  SupplyBoxesActionsType,
  fetchBoxStickersRequest,
  fetchBoxStickersSuccess,
  fetchBoxStickersFailure,
  removeOrderFromBoxRequest,
  removeOrderFromBoxSuccess,
  removeOrderFromBoxFailure,
} from "redux/actions/supplyBoxes.actions";

import {
  RequestPaginationType,
} from "app/types";
import { AppStateType } from "../reducers/mainReducer";

// список коробов
export const fetchSupplyBoxesList = (supplyId: string, pagination: RequestPaginationType): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SupplyBoxesActionsType
> => async (dispatch) => {
  dispatch(fetchBoxesListRequest(supplyId, pagination));

  try {
    const response = await SupplyBoxesAPI.list(supplyId, pagination);

    dispatch(fetchBoxesListSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    console.log("error", error);
    dispatch(fetchBoxesListFailure(error?.response?.data));

    return Promise.reject(error.response?.data);
  }
};

// создание коробов
export const createSupplyBoxes = (supplyId: string, amount: number): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SupplyBoxesActionsType
> => async (dispatch) => {
  dispatch(createBoxesRequest(supplyId, amount));
  try {
    const response = await SupplyBoxesAPI.create(supplyId, amount);

    dispatch(createBoxesSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(createBoxesFailure(error?.response?.data?.error));

    return Promise.reject(error.response?.data?.error);
  }
};

// удаление короба
export const removeSupplyBoxes = (supplyId: string, boxesIds: string[]): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  SupplyBoxesActionsType
> => async (dispatch) => {
  dispatch(deleteBoxesRequest(supplyId, boxesIds));
  try {
    await SupplyBoxesAPI.remove(supplyId, boxesIds);

    dispatch(deleteBoxesSuccess(boxesIds));

    return Promise.resolve(boxesIds);
  } catch (error: any) {
    dispatch(deleteBoxesFailure(error));

    return Promise.reject(error);
  }
};

// Получение QR Коробов
export const fetchSupplyBoxStickers = (supplyId: string, boxesIds: string[]): ThunkAction<
Promise<any>,
AppStateType,
undefined,
SupplyBoxesActionsType
> => async (dispatch) => {
  dispatch(fetchBoxStickersRequest(supplyId, boxesIds));
  try {
    const response: any = await SupplyBoxesAPI.fetchBoxStickers(supplyId, "png", boxesIds);

    dispatch(fetchBoxStickersSuccess(response?.data));
    
    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchBoxStickersFailure(error));

    return Promise.reject(error);
  }
};

// Удаление заданий из короба
export const removeOrderFromBox = (supplyId: string, boxeId: string, orderId: string): ThunkAction<
Promise<any>,
AppStateType,
undefined,
SupplyBoxesActionsType
> => async (dispatch) => {
  dispatch(removeOrderFromBoxRequest(supplyId, boxeId, orderId));
  try {
    const response: any = await SupplyBoxesAPI.removeOrderFromBox(supplyId, boxeId, orderId);

    dispatch(removeOrderFromBoxSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(removeOrderFromBoxFailure(error));

    return Promise.reject(error);
  }
};