import React, { JSX, FC, useState, useEffect } from "react";

import dayjs from "dayjs";
import { NavigateFunction, useNavigate } from "react-router-dom";
import useCountdownTimer from "../../../hooks/useCountdownTimer";

import { Button } from "antd";
import FormTitle from "../FormTitle/FormTitle";
import RegistrationContactsForm from "./RegistrationContactsForm";
import RegistrationSmsForm from "./RegistrationSmsForm";

interface IRegistrationFormProps {}

const RegistrationForm: FC<IRegistrationFormProps> = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [attemptsCount, setAttemptsCount] = useState<number>(3);
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [inn, setInn] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [isTimerStart, setIsTimerStart] = useState<boolean>(false);

  const storedTime: string = localStorage.getItem("storedTime");
  const diff: number = dayjs(storedTime).add(1, "hour").diff(dayjs(), "second");

  const { seconds, startTimer, resetTimer } = useCountdownTimer(diff);

  useEffect(() => {
    storedTime && startTimer();
  }, []);

  useEffect(() => {
    if (isTimerStart && storedTime) {
      startTimer();
    }
  }, [isTimerStart]);

  useEffect(() => {
    if (storedTime) {
      if (diff >= 0) {
        setAttemptsCount(0);
      } else {
        localStorage.removeItem("storedTime");
        setAttemptsCount(3);
        setIsTimerStart(false);
        resetTimer();
      }
    }
  }, [seconds]);

  const getSubtitle = (): string => {
    switch (step) {
      case 1: {
        return "Заполните ваши контактные данные и ИНН вашей компании.";
      }
      case 2: {
        return `Для завершения регистрации, пожалуйста, подтвердите условия оказания услуги, 
          тарифы и правильность предоставленной информации с помощью СМС.`;
      }
      case 3: {
        // TODO вернуть текст после добавления регистрации по смс
        // return "Вы успешно зарегистрировались, теперь вы можете войти используя свои данные.";
        return "Мы отправили письмо с подтверждением на указанный при регистрации email";
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div style={{ maxWidth: "37rem" }}>
      {/*TODO  Т.к. второй формы пока не будет, не отображаем шаги, вернуть, когда сделаем регистрацию по смс*/}
      {/*{step !== 3 && <span className="secondary-color">{`Шаг ${step} из 2`}</span>}*/}
      <FormTitle
        title={`Регистрация ${step === 3 ? "завершена" : "продавца"}`}
        subtitle={getSubtitle()}
      />
      {step === 1 && (
        <RegistrationContactsForm
          inn={inn}
          name={name}
          phone={phone}
          email={email}
          surname={surname}
          companyName={companyName}
          setInn={setInn}
          setStep={setStep}
          setName={setName}
          setEmail={setEmail}
          setPhone={setPhone}
          setSurname={setSurname}
          setCompanyName={setCompanyName}
        />
      )}
      {/*TODO вернуть, когда сделаем регистрацию по смс*/}
      {/*{step === 2 && (*/}
      {/*  <RegistrationSmsForm*/}
      {/*    phone={phone}*/}
      {/*    setStep={setStep}*/}
      {/*    attemptsCount={attemptsCount}*/}
      {/*    setAttemptsCount={setAttemptsCount}*/}
      {/*    setIsTimerStart={setIsTimerStart}*/}
      {/*  />*/}
      {/*)}*/}
      {step === 3 && (
        <Button
          type="primary"
          size="large"
          style={{ height: 44 }}
          className="bg-blue-500 w-32"
          onClick={() => navigate("/login")}
        >
          Войти
        </Button>
      )}
    </div>
  );
};

export default RegistrationForm;
