import React, { FC, useEffect, useState } from "react";
import { Input } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

interface NumericInputProps {
  onChange?: (value: string) => void;
  currentValue?: string;
  style?: React.CSSProperties;
  className?: string;
  suffix?: string;
  placeholder?: string;
  size?: SizeType;
  name?: string;
  maxLength?: number;
}

const NumericInput: FC<NumericInputProps> = ({
  onChange,
  currentValue,
  style,
  className,
  suffix,
  placeholder = "Введите число",
  size = "large",
  name = "",
  maxLength = 16,
}) => {
  const [value, setValue] = useState<string>(currentValue ?? null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value: inputValue } = e.target;
      setValue(inputValue.length > 0 ? inputValue : null);
    const reg: RegExp = /^(0|[1-9]\d*)$/;

    if (reg.test(inputValue) || inputValue === "") {
      onChange(inputValue.length > 0 ? inputValue : null);
    }
  };

  return (
    <Input
      inputMode="numeric"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
      style={style}
      maxLength={maxLength}
      size={size}
      suffix={suffix}
      onInput={handleChange}
      name={name}
    />
  );
};

export default NumericInput;