import React, { JSX, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWBCategories } from "redux/thunks/wbCategories.thunks";
import { fetchMarketplaces } from "redux/thunks/marketplaces.thunks";
import { fetchWBColors } from "redux/thunks/wbColors.thunks";
import * as WBCategoriesAPI from "../../../../api/wbCategories.api";
import dayjs from "dayjs";

import {
  Drawer,
  Form,
  TreeSelect,
  TreeSelectProps,
  Select,
  DatePicker,
} from "antd";
import GhostButton from "components/ui/GhostButton/GhostButton";
import NumericInput from "components/ui/NumericInput/NumericInput";

import type { FormInstance } from "antd";
import type { RequestProductsFiltersType, WBCategoryType } from "app/types";
import type { AppDispatch } from "redux/store/store";
import type { AppStateType } from "redux/reducers/mainReducer";
import type { InitialWBCategoriesStateType } from "redux/reducers/wbCategories.reducer";
import type { InitialMarketplacesStateType } from "redux/reducers/marketplaces.reducer";
import type { InitialWBColorsStateType } from "redux/reducers/wbColors.reducer";
import type { DefaultOptionType } from "antd/es/select";
import { ProductStatus } from "components/Products/ProductsTab/ProductsTab";
import { formatCategoriesList } from "utils/formatCategoriesList";
import { formatFilters } from "utils/formatFilters";
import { RangePickerProps } from "antd/es/date-picker";

export type FilterType =
  | "search"
  | "status"
  | "marketplace"
  | "not_synced"
  | "categories"
  | "colors"
  | "sizes"
  | "barcodes"
  | "brands"
  | "name"
  | "card_id"
  | "date_of_update" // | "updated_after" | "updated_until";
  | "price"// | "price_gte" | "price_lte";
  | "price_synced" // | "price_synced_after" | "price_synced_until"; 
  | "price_update_date"; // | "price_updated_after" | "price_updated_until"; 

const { useWatch } = Form;

export type FiltersDrawerValuesType = {
  search?: string;
  status?: number;
  updated_after?: string; // "2023-12-06"
  updated_until?: string; // "2023-12-06"
  colors?: string[];
  sizes?: string[];
  card_id?: string;
  not_synced?: boolean;
  barcodes?: string[];
  name?: string;
  price_updated_after?: string;
  price_updated_until?: string;
  price_gte?: number;
  price_lte?: number;
  marketplace?: string;
  categories?: string[];
  brands?: string[];
  price_synced_after?: string;// "2023-12-06",
  price_synced_until?: string;// "2023-12-06",
  date_of_update?: string[];
  price_update_date?: string[];
  price_synced?: string[];
  warehouse_id?: string;
  supply_id?: string;
  box_id?: string;
  without_box?: boolean;
  wb_status?: string;
  supplier_status?: string;
}

interface IFiltersDrawer {
  form: FormInstance<FiltersDrawerValuesType>;
  onClose: () => void;
  open: boolean;
  setFilters: React.Dispatch<React.SetStateAction<RequestProductsFiltersType>>;
  filterFields: FilterType[];
}

export const FiltersDrawer: FC<IFiltersDrawer> = ({
  form,
  onClose,
  open,
  setFilters,
  filterFields,
}) => {
  const values = useWatch<FiltersDrawerValuesType>([], form);
  const dispatch = useDispatch<AppDispatch>();
  const wbCategories: InitialWBCategoriesStateType = useSelector((state: AppStateType) => state.wbCategories);
  const marketplaces: InitialMarketplacesStateType = useSelector((state: AppStateType) => state.marketplaces);
  const colors: InitialWBColorsStateType = useSelector((state: AppStateType) => state.wbColors);

  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>(null);
  const [marketplacesOptions, setMarketplacesOptions] = useState<DefaultOptionType[]>(null);

  useEffect(() => {
    // TODO: Хардкод фильтрация на WB
    marketplaces.list &&
      setMarketplacesOptions(
        marketplaces.list
          ?.map(({ id, name }) => ({
            value: id,
            label: name,
            disabled: name !== "WB"
          }))
      );
  }, [marketplaces.list]);

  useEffect(() => {
    if (marketplacesOptions?.length > 0) {
      form.setFieldValue("marketplace", marketplacesOptions?.find((option) => option.label === "WB").value);
    }
  }, [marketplacesOptions]);

  useEffect(() => {
    !wbCategories.list && !wbCategories.isFetching && dispatch(fetchWBCategories());
    !colors.list && !colors.isFetching && dispatch(fetchWBColors());
    !marketplaces.list && !marketplaces.isFetching && dispatch(fetchMarketplaces());
  }, [dispatch]);

  useEffect(() => {
    setTreeData(formatCategoriesList(wbCategories?.list));
  }, [wbCategories?.list]);

  const clearFilters = (): void => {
    form.resetFields();
    setFilters({});
    onClose();
  };

  const applyFiltersHandler = (): void => {
    setFilters((prevFilters) => ({ ...prevFilters, ...formatFilters(values) }));
    onClose();
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current: dayjs.Dayjs) => {
    return current > dayjs();
  };

  const renderFilter = (filterName: FilterType, idx: number): JSX.Element => {
    switch (filterName) {
      case "categories": {
        const onLoadData: TreeSelectProps["loadData"] = async ({ id }): Promise<void> => {
          try {
            const response = await WBCategoriesAPI.list({ parent: id });

            setTreeData((prevTreeData) =>
              prevTreeData.map((node) =>
                node.value === id
                  ? {
                    ...node,
                    children: formatCategoriesList(response.data)
                  }
                  : node
              )
            );
          } catch (error) {
            console.error(error);
          }
        };

        return (
          <div className="flex flex-col" key={`categories-${idx}`}>
            <p className="mb-1">По категории</p>
            <Form.Item name="categories" className="mb-4">
              <TreeSelect
                placeholder="Выберите категорию"
                notFoundContent="Категория не найдена"
                multiple
                allowClear
                treeData={treeData}
                loadData={onLoadData}
                filterTreeNode={(input, treeNode) =>
                  treeNode.title?.toString().toLowerCase().includes(input.toLowerCase())
                }
                className="w-full"
                size="large"
              />
            </Form.Item>
          </div>
        );
      }

      case "date_of_update":
        return (
          <div className="flex flex-col" key={`date_of_update-${idx}`}>
            <p className="mb-1">По дате изменения</p>
            <Form.Item name="date_of_update" className="mb-4">
              <DatePicker.RangePicker
                className="w-full"
                size="large"
                placeholder={["С даты", "По дату"]}
                defaultPickerValue={[dayjs().add(-1, "M"), dayjs()]}
                disabledDate={disabledDate}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </div>
        );

      case "colors":
        return (
          <div className="flex flex-col" key={`colors-${idx}`}>
            <p className="mb-1">По цвету</p>
            <Form.Item name="colors" className="mb-4" >
              <Select
                mode="tags"
                placeholder="Введите цвет товара"
                notFoundContent="Введите значение"
                showSearch
                allowClear
                options={colors.list}
                size="large"
              />
            </Form.Item>
          </div>
        );

      case "sizes":
        return (
          <div className="flex flex-col" key={`sizes-${idx}`}>
            <p className="mb-1">По размеру</p>
            <Form.Item name="sizes" className="mb-4" >
              <Select
                mode="tags"
                placeholder="Введите цвет товара"
                notFoundContent="Введите значение"
                showSearch
                allowClear
                size="large"
              />
            </Form.Item>
          </div>
        );

      case "not_synced":
        return (
          <div className="flex flex-col" key={`not_synced-${idx}`}>
            <p className="mb-1">По синхронизации</p>
            <Form.Item name="not_synced" className="mb-4" >
              <Select
                placeholder="Выберите статус синхронизации"
                options={[
                  { value: false, label: "Синхронизирован" },
                  { value: true, label: "Не синхронизирован" },
                ]}
                size="large"
              />
            </Form.Item>
          </div>
        );

      case "status": {
        return (
          <div className="flex flex-col" key={`status-${idx}`}>
            <p className="mb-1">По статусу</p>
            <Form.Item name="status" className="mb-4" >
              <Select
                placeholder="Выберите статус товара"
                allowClear
                options={[
                  { value: ProductStatus.Draft, label: "Черновик" },
                  { value: ProductStatus.Archive, label: "Архив" },
                  { value: ProductStatus.Synchronized, label: "Опубликован" },
                  { value: ProductStatus.IntegrationError, label: "Ошибка интеграции" },
                ]}
                value={values?.status}
                size="large"
              />
            </Form.Item>
          </div>
        );
      }

      case "barcodes":
        return (
          <div className="flex flex-col" key={`barcodes-${idx}`}>
            <p className="mb-1">По баркоду</p>
            <Form.Item name="barcodes" className="mb-4" >
              <Select
                mode="tags"
                placeholder="Введите баркод"
                notFoundContent="Введите значение"
                showSearch
                allowClear
                size="large"
              />
            </Form.Item>
          </div>
        );

      case "brands":
        return (
          <div className="flex flex-col" key={`brands-${idx}`}>
            <p className="mb-1">По брэнду</p>
            <Form.Item name="brands" className="mb-4" >
              <Select
                mode="tags"
                placeholder="Введите бренд"
                notFoundContent="Введите значение"
                showSearch
                allowClear
                size="large"
              />
            </Form.Item>
          </div>
        );

      // TODO: Публикация зависит от того, когда была синхронизирована цена?
      case "price_update_date":
        return (
          <div className="flex flex-col" key={`price_update_date-${idx}`}>
            <p className="mb-1">По дате публикации цены</p>
            <Form.Item name="price_update_date" className="mb-4">
              <DatePicker.RangePicker
                className="w-full"
                size="large"
                placeholder={["С даты", "По дату"]}
                defaultPickerValue={[dayjs().add(-1, "M"), dayjs()]}
                disabledDate={disabledDate}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </div>
        );

      case "price":
        return (
          <div className="flex flex-col" key={`price-${idx}`}>
            <p className="mb-1">По цене</p>
            <Form.Item name="price_gte" className="mb-2">
              <NumericInput placeholder="От" size="large" name="price_gte" />
            </Form.Item>
            <Form.Item name="price_lte" className="mb-4" >
              <NumericInput placeholder="До" size="large" name="price_lte" />
            </Form.Item>
          </div>
        );

      case "price_synced":
        return (
          <div className="flex flex-col" key={`price_synced-${idx}`}>
            <p className="mb-1">По дате синхронизации цены</p>
            <Form.Item name="price_synced" className="mb-4">
              <DatePicker.RangePicker
                className="w-full"
                size="large"
                placeholder={["С даты", "По дату"]}
                defaultPickerValue={[dayjs().add(-1, "M"), dayjs()]}
                disabledDate={disabledDate}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </div>
        );

      case "marketplace":
        return (
          <div className="flex flex-col" key={`marketplace-${idx}`}>
            <p className="mb-1">По маркетплейсу</p>
            <Form.Item name="marketplace">
              <Select
                size="large"
                placeholder="Выберите маркетплейс"
                options={marketplacesOptions ?? null}
                loading={marketplaces.isFetching || !marketplaces.list}
                allowClear
              />
            </Form.Item>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Drawer
      title="Фильтры"
      placement="right"
      closable={false}
      onClose={() => { onClose(); }}
      open={open}
      footer={
        <div className="flex flex-row justify-between items-center">
          <GhostButton
            size="large"
            text="Сбросить"
            onClickHandler={clearFilters}
          />
          <GhostButton
            size="large"
            text="Применить"
            onClickHandler={applyFiltersHandler}
          />
        </div>
      }
    >
      <Form form={form}>
        {filterFields.map(renderFilter)}
      </Form>
    </Drawer>
  );
};
