import process from "process";
import axios from "../utils/axios";
import type {
  RequestAddOrdersToBox,
  RequestPaginationType,
} from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

// Запрос листа коробов
export const list = async (supplyId: string, pagination: RequestPaginationType): Promise<any> => {
  const searchParams = new URLSearchParams();

  Object.keys(pagination).forEach(key => {
    (Array.isArray(pagination[key]) ? pagination[key] : [pagination[key]]).forEach(value => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    });
  });

  return axios.GET(API_BASE, `/api/orders/supply/${supplyId}/boxes?${searchParams.toString()}`);
};

// Создание коробов
export const create = async (supplyId: string, amount: number): Promise<any> => {
  return axios.POST(API_BASE, `/api/orders/supply/${supplyId}/boxes`, { amount });
};

// Удаление коробов из поставки
export const remove = async (supplyId: string, boxesIds: string[]): Promise<any> => {
  return axios.DELETE(API_BASE, `/api/orders/supply/${supplyId}/boxes`, {
    boxes_ids: boxesIds
  });
};

// Добавление сборочных заданий Wildberries в короб
export const addOrdersToBox = async (params: RequestAddOrdersToBox): Promise<any> => {
  return axios.POST(API_BASE, "/api/orders/orders_to_box", params);
};

// Удаление сборочного задания Wildberries из короба
export const removeOrderFromBox = async (supplyId: string, boxId: string, orderId: string): Promise<any> => {
  return axios.DELETE(API_BASE, "/api/orders/orders_from_box", {
    supply_id: supplyId,
    box_id: boxId,
    order_id: orderId
  });
};

export const fetchBoxStickers = async (
  supplyId: string,
  type: "svg" | "zplv" | "zplh" | "png",
  boxesIds: string[],
): Promise<any> => {
  return axios.POST(API_BASE, `/api/orders/supply/${supplyId}/box-stickers`, {
    type,
    boxes_ids: boxesIds
  });
};
