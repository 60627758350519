import { MarketplaceCityType } from "app/types";
import {
  FETCH_MARKETPLACE_CITIES_LIST_REQUEST,
  FETCH_MARKETPLACE_CITIES_LIST_SUCCESS,
  FETCH_MARKETPLACE_CITIES_LIST_FAILURE,
	FETCH_MARKETPLACE_CITY_REQUEST,
	FETCH_MARKETPLACE_CITY_SUCCESS,
	FETCH_MARKETPLACE_CITY_FAILURE,
} from "../../constants/actionTypes";
import { MarketplaceCitiesActionsType } from "../actions/marketplaceCities.actions";

export type InitialMarketplaceCitiesStateType = {
	error: string | null;
	list: MarketplaceCityType[] | null;
	found: MarketplaceCityType | null;
	isFetching: boolean;
};

const initialState: InitialMarketplaceCitiesStateType = {
	error: null,
	list: null,
	found: null,
	isFetching: false,
};

const marketplaceCities = (
	state: InitialMarketplaceCitiesStateType = initialState,
	action: MarketplaceCitiesActionsType
): InitialMarketplaceCitiesStateType => {
	switch (action.type) {
		case FETCH_MARKETPLACE_CITIES_LIST_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_MARKETPLACE_CITIES_LIST_SUCCESS:
			return {
				...state,
				error: null,
				isFetching: false,
        list: action.payload.marketplaceCities,
			};
		case FETCH_MARKETPLACE_CITIES_LIST_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error,
			};

		case FETCH_MARKETPLACE_CITY_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_MARKETPLACE_CITY_SUCCESS:
			return {
				...state,
				error: null,
				isFetching: false,
        found: action.payload.marketplaceCity,
			};
		case FETCH_MARKETPLACE_CITY_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error,
			};
		default:
			return state;
	}
};

export default marketplaceCities;
