import { WBColorType } from "app/types";
import { SelectOptionType } from "../../components/Products/ProductInfoCard/ProductInfoBlock/ProductInfoBlock";
import {
  FETCH_WB_COLOR_REQUEST,
  FETCH_WB_COLOR_SUCCESS,
  FETCH_WB_COLOR_FAILURE,
  FETCH_WB_COLORS_LIST_SUCCESS,
  FETCH_WB_COLORS_LIST_FAILURE,
  FETCH_WB_COLORS_LIST_REQUEST,
} from "../../constants/actionTypes";
import { WbColorsActionsType } from "../actions/wbColors.actions";

export type InitialWBColorsStateType = {
  error: string | null;
  list: SelectOptionType[] | null;
  found: WBColorType | null;
  isFetching: boolean;
};

const initialState: InitialWBColorsStateType = {
  error: null,
  list: null,
  found: null,
  isFetching: false,
};

const wbColors = (
  state: InitialWBColorsStateType = initialState,
  action: WbColorsActionsType,
): InitialWBColorsStateType => {
  switch (action.type) {
    case FETCH_WB_COLORS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_COLORS_LIST_SUCCESS: {
      const formattedList: SelectOptionType[] = action.payload.colors?.map(({ name }) => {
        return { label: name, value: name };
      });

      return {
        ...state,
        error: null,
        isFetching: false,
        list: formattedList,
      };
    }
    case FETCH_WB_COLORS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    case FETCH_WB_COLOR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_COLOR_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        found: action.payload.color,
      };
    case FETCH_WB_COLOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default wbColors;
