import React, { JSX, FC } from "react";
import { ProductType } from "app/types";
import { FolderOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import ImageContainer from "../../ui/ImageContainer/ImageContainer";
import { getFirstImage } from "../getFirstImage";
import { ProductStatus } from "../ProductsTab/ProductsTab";

import css from "./style.module.css";

interface INomenclatureCardProps {
  product: ProductType;
  resetForm: () => void;
  setNomenclatureId: React.Dispatch<React.SetStateAction<string>>;
  selectedNomenclature: string;
  setSelectedNomenclature: React.Dispatch<React.SetStateAction<string>>
}

const NomenclatureCard: FC<INomenclatureCardProps> = ({
  product,
  resetForm,
  setNomenclatureId,
  selectedNomenclature,
  setSelectedNomenclature
}): JSX.Element => {
  const colors: string = product.characteristic_values
    ?.filter((charc) => charc.characteristic.name === "Цвет")
    ?.map((charc) => charc.value)
    ?.join(", ");

  const wbSize: string = product.sizes && product.sizes[0]?.wb_size;
  const techSize: string = product.sizes && product.sizes[0]?.tech_size;

  const onSelectCard = (cardId: string): void => {
    setSelectedNomenclature(cardId);
    setNomenclatureId(cardId);
    resetForm();
  };

  const renderCharc = (title: string, value: string | string[]): JSX.Element => {
    return (
      <div className={css.item}>
        <div className={`secondary-color ${css.title}`}>{title}</div>
        <div className={`text-14-m ${css.value}`}>{value}</div>
      </div>
    );
  };

  return (
    <div
      className={`
        flex flex-col bg-white card-radius px-4 py-3 mb-3 cursor-pointer
        ${css.card} 
        ${product.id === selectedNomenclature ? css.selected : ""}
      `}
      onClick={() => product.id !== selectedNomenclature && onSelectCard(product.id)}
      id={product.id}
    >
      <div className={`flex justify-between items-start ${css.header}`}>
        <div className="flex items-center">
          <div className={css.logo}>
            <ImageContainer
              alt="Фото"
              src={getFirstImage(product)?.src}
              filename={getFirstImage(product)?.filename}
            />
          </div>
          <div className={`ml-3 ${css.body}`}>
            <div className="secondary-color">{product.vendor_code}</div>
            <div className="text-14-m">{product.name}</div>
          </div>
        </div>
        {product.status === ProductStatus.Archive && (
          <Tooltip title="Товар находится в архиве">
            <FolderOutlined />
          </Tooltip>
        )}
      </div>
      {(!!wbSize || !!techSize || !!colors) && (
        <div className={`flex justify-between items-start ${css.body}`}>
          {(!!wbSize || !!techSize) && (
            <div className={css.item} style={{minWidth: 70}}>
              <div className={`secondary-color ${css.title}`}>Размер</div>
              {!!techSize && <div className={`text-14-m ${css.value}`}>{techSize}</div>}
              {!!wbSize && <div className={`text-14-m ${css.value}`}>{`${wbSize}\u00A0RUS`}</div>}
            </div>
          )}
          {!!colors && renderCharc("Цвета", colors)}
          {/*{renderCharc("Цена", "125453\u00A0RUB")}*/}
        </div>
      )}
    </div>
  );
};

export default NomenclatureCard;
