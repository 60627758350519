import { DeliveryMethodType } from "app/types";
import {
	FETCH_DELIVERY_METHODS_LIST_REQUEST,
	FETCH_DELIVERY_METHODS_LIST_SUCCESS,
	FETCH_DELIVERY_METHODS_LIST_FAILURE,
} from "../../constants/actionTypes";

export type DeliveryMethodsActionsType =
	| FetchDeliveryMethodsListRequestType
	| FetchDeliveryMethodsListSuccessType
	| FetchDeliveryMethodsListFailureType;

// получение листинга доставок
type FetchDeliveryMethodsListRequestType = {
	type: typeof FETCH_DELIVERY_METHODS_LIST_REQUEST;
};

export const fetchDeliveryMethodsListRequest = (
): FetchDeliveryMethodsListRequestType => ({
	type: FETCH_DELIVERY_METHODS_LIST_REQUEST,
});

type FetchDeliveryMethodsListSuccessType = {
	type: typeof FETCH_DELIVERY_METHODS_LIST_SUCCESS;
	payload: {
		deliveryMethods: DeliveryMethodType[];
	};
};

export const fetchDeliveryMethodsListSuccess = (
	deliveryMethods: DeliveryMethodType[]
): FetchDeliveryMethodsListSuccessType => ({
	type: FETCH_DELIVERY_METHODS_LIST_SUCCESS,
	payload: {
		deliveryMethods
	}
});

type FetchDeliveryMethodsListFailureType = {
	type: typeof FETCH_DELIVERY_METHODS_LIST_FAILURE;
	payload: {
		error: string;
	};
};

export const fetchDeliveryMethodsListFailure = (
	error: string
): FetchDeliveryMethodsListFailureType => ({
	type: FETCH_DELIVERY_METHODS_LIST_FAILURE,
	payload: {
		error
	}
});
