import React, { JSX, FC, useMemo, useState, Key, useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { TableRowSelection } from "antd/es/table/interface";
import { OrderType, RequestOrdersFiltersType, RequestPaginationType, StickerType } from "app/types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as OrdersAPI from "../../../../api/orders.api";
import type { AppStateType } from "../../../../redux/reducers/mainReducer";
import type { InitialOrdersStateType } from "../../../../redux/reducers/orders.reducer";
import { InitialSuppliesStateType } from "../../../../redux/reducers/supplies.reducer";
import { InitialSupplyBoxesStateType } from "../../../../redux/reducers/supplyBoxes.reducer";
import { generateSelectionListData, groupOrdersByBoxes } from "../../generateSelectionListData";
import { SelectionListItemType } from "../SupplyWrapper";

// icons
import { ReactComponent as MenuIcon } from "../../../../assets/icons/three_dots_icon.svg";

// components
import { Dropdown, Form, Input, MenuProps, message } from "antd";
import ProductsTable, { TableColumnType } from "../../../Products/ProductsTable/ProductsTable";
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";
import { SearchInput } from "components/ui/SearchInput/SearchInput";

import css from "../style.module.css";

interface ISupplyOrdersListProps {
  filters: RequestOrdersFiltersType;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setFilters: React.Dispatch<React.SetStateAction<RequestOrdersFiltersType>>;
  fetchOrdersList: (pagination: RequestPaginationType, filters: RequestOrdersFiltersType) => Promise<void>;
  setIsPrinting: React.Dispatch<React.SetStateAction<boolean>>;
  setQROrderLinks: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectionListData: React.Dispatch<React.SetStateAction<SelectionListItemType[]>>;
  setSelectionListDataByBoxes: React.Dispatch<React.SetStateAction<SelectionListItemType[][]>>;
}

const commonColumns: TableColumnType[] = [
  "photo", "order_number", "created_at", "name", "size",
  "color", "barcode", "warehouse", "due_date", "actions",
];

const SupplyOrdersList: FC<ISupplyOrdersListProps> = ({
  filters,
  setFilters,
  setIsLoading,
  fetchOrdersList,
  setIsPrinting,
  setQROrderLinks,
  setSelectionListData,
  setSelectionListDataByBoxes,
}): JSX.Element => {
  const [form] = useForm<any>();

  const { supplyId } = useParams();

  const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);
  const supplies: InitialSuppliesStateType = useSelector((state: AppStateType) => state.supplies);
  const supplyBoxes: InitialSupplyBoxesStateType = useSelector((state: AppStateType) => state.supplyBoxes);

  const [selectedRows, setSelectedRows] = useState<OrderType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);

  useEffect(() => {
    setSelectedKeys(selectedRows?.map((value) => value.key));
  }, [selectedRows]);

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    },
  };

  const renderSearch = (): JSX.Element => {
    return (
      <Form form={form} layout="inline" className={css.searchWrap}>
        <Form.Item className={css.searchInput} name="search" style={{ marginRight: 0 }}>
          <SearchInput
            placeholder="Найти по номеру задания, названию товара или баркоду"
            onSearch={(state: RequestOrdersFiltersType) => setFilters(state)}
            filters={filters}
            isGeneralSearch={false}
            isDisabled={false}
          />
        </Form.Item>

        {/*Временно скрываем фильтры*/}
        {/*<GhostButton*/}
        {/*  size="large"*/}
        {/*  text="Фильтры"*/}
        {/*  icon={<FilterIcon />}*/}
        {/*  onClickHandler={() => {}}*/}
        {/*  isDisabled={true}*/}
        {/*/>*/}
      </Form>
    );
  };

  const renderPrintButtons = (): JSX.Element => {
    return (
      <div className="mt-3">
        <PrimaryButton
          className="mr-3"
          text="Распечатать лист подбора"
          isDisabled={!orders.list || orders.list?.length === 0}
          onClickHandler={supplyBoxes.list?.length === 0
            ? printSelectionList
            : printSelectionListByBoxes
          }
        />
        <PrimaryButton
          text={`Распечатать маркировку (${selectedRows?.length ?? 0})`}
          isDisabled={!(selectedRows?.length > 0)}
          onClickHandler={() => printQROrders(selectedRows?.map(({ id }) => id))}
        />
      </div>
    );
  };

  const renderOrderAction = (_value: any, order: OrderType): React.ReactNode => {
    const items: MenuProps["items"] = [
      {
        label: <a onClick={() => printQROrders([order.id])}>Распечатать маркировку</a>,
        key: `label-${order.id}`,
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  const printQROrders = async (orderIds: string[]) => {
    setIsLoading(true);

    await fetchStickers(orderIds);

    setIsPrinting(true);
  };

  const printSelectionList = async () => {
    setIsLoading(true);

    await generateSelectionListData(supplies.found?.orders_count, supplyId)
      .then((res: SelectionListItemType[]) => {
        setSelectionListData(res);
        setIsPrinting(true);
      })
      .catch(() => setIsLoading(false));
  };

  const printSelectionListByBoxes = async (): Promise<void> => {
    setIsLoading(true);

    await generateSelectionListData(supplies.found?.orders_count, supplyId)
      .then((res: SelectionListItemType[]) => {
        setSelectionListDataByBoxes(groupOrdersByBoxes(res));
        setIsPrinting(true);
      })
      .catch(() => setIsLoading(false));
  };

  const fetchStickers = async (orderIds: string[]): Promise<void> => {
    const response = await OrdersAPI.getStickers({ order_ids: orderIds, type: "png", size: "size400x300" }).catch(
      (error) => {
        setIsLoading(false);
        message.error(error?.response?.data?.error);
      },
    );

    setQROrderLinks(
      response?.data?.filter((sticker: StickerType) => !sticker.error)?.map((sticker: StickerType) => sticker.file),
    );
  };

  return (
    <>
      <div className={css.controlsWrap}>
        {renderSearch()}
        {renderPrintButtons()}
      </div>
      <ProductsTable
        columns={commonColumns}
        list={orders.list}
        count={orders.count}
        fetchList={fetchOrdersList}
        isFetching={orders.isFetching}
        renderActions={renderOrderAction}
        rowSelection={rowSelection}
        filters={filters}
      />
    </>
  );
};

export default SupplyOrdersList;
