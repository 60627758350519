import React, { JSX, FC, useRef, useState, useEffect } from "react";
import { SelectOptionType } from "../../Products/ProductInfoCard/ProductInfoBlock/ProductInfoBlock";
import { DatePicker, Form, FormInstance, Input, InputNumber, Select, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ReactComponent as HelpOutlinedIcon } from "../../../assets/icons/help_outlined_icon.svg";

import css from "./style.module.css";

const { Item } = Form;

type PatternType = {
  pattern: RegExp;
  message: string;
};

type RequiredType = {
  required: boolean;
  message: string;
};

interface ICustomInputProps {
  type: "input" | "inputNumber" | "textArea" | "datePicker" | "select";
  title: string;
  form: FormInstance;
  fieldName: string;
  min?: number;
  max?: number;
  note?: string;
  isDisabled?: boolean;
  options?: SelectOptionType[];
  selectMode?: "multiple" | "tags";
  placeholder?: string;
  tooltipTitle?: string;
  pattern?: PatternType;
  format?: (value: string | number) => string;
  suffix?: string | JSX.Element;
  maxLength?: number;
  required?: boolean;
  showCount?: boolean;
  onBlurHandler?: () => void;
  className?: string;
}

const CustomInput: FC<ICustomInputProps> = ({
  max,
  type,
  form,
  title,
  suffix,
  fieldName,
  maxLength,
  note,
  format,
  options,
  pattern,
  tooltipTitle,
  onBlurHandler,
  min = 0,
  isDisabled = false,
  placeholder = "",
  selectMode = null,
  showCount = false,
  required = false,
  className = "",
}): JSX.Element => {
  const inputRef = useRef(null);

  const [valueCount, setValueCount] = useState<number>(0);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  // устанавливает актуальное количество тегов в селектор
  useEffect(() => {
    if (selectedTags?.length) {
      form.setFieldsValue({
        [fieldName]: selectedTags,
      });
    }
  }, [selectedTags]);

  const renderSuffix = (): JSX.Element => <span className={css.suffix}>{suffix}</span>;

  // логика при снятии фокуса с поля
  const handleBlur = (): void => {
    onBlurHandler && onBlurHandler();
  };

  // проверяет лимит тегов для поля, обрезает до лимита и устанавливает значение в счетчик
  const handleSelectChange = (selectedValues: string[]): void => {
    const values: string[] = (selectedValues)?.length >= maxLength
      ? selectedValues?.slice(0, maxLength)
      : selectedValues;

    setSelectedTags(values);
    setValueCount(values?.length);
  };

  // для поиска в селекторе
  const filterOptions = (input: string, option: SelectOptionType): boolean => {
    return (option?.label?.toString()?.toLowerCase() ?? "")?.includes(input);
  };

  const renderInputType = (): JSX.Element => {
    switch (type) {
      case "inputNumber":
        return (
          <InputNumber
            min={min}
            max={max}
            onBlur={handleBlur}
            disabled={isDisabled}
            suffix={suffix ? renderSuffix() : null}
            controls={false}
            required={required}
            formatter={format}
            maxLength={maxLength}
            className={`input-height w-full flex items-center ${className}`}
            placeholder={placeholder}
          />
        );
      case "textArea":
        return (
          <TextArea
            ref={inputRef}
            disabled={isDisabled}
            onBlur={handleBlur}
            required={required}
            showCount={showCount}
            maxLength={maxLength}
            placeholder={placeholder}
            className={className}
          />
        );
      case "datePicker":
        return (
          <DatePicker
            onBlur={handleBlur}
            format="DD.MM.YYYY"
            className={`input-height w-full ${className}`}
            placeholder={placeholder}
            disabled={isDisabled}
          />
        );
      case "select":
        return (
          <Select
            mode={selectMode}
            size="large"
            onBlur={handleBlur}
            value={selectedTags?.length ? selectedTags : null}
            disabled={isDisabled}
            options={options}
            filterOption={(input, option) => filterOptions(input, option)}
            onChange={handleSelectChange}
            allowClear
            showSearch
            className={className}
            placeholder={placeholder}
            notFoundContent={selectMode === "tags" ? "Введите значения" : "Нет данных"}
            tokenSeparators={selectMode ? [" ,"] : null}
            {...(maxLength && valueCount >= maxLength && { open: false })}
          />
        );
      default:
        return (
          <Input
            ref={inputRef}
            onBlur={handleBlur}
            suffix={suffix ? renderSuffix() : null}
            required={required}
            disabled={isDisabled}
            maxLength={maxLength}
            className={`input-height ${className}`}
            placeholder={placeholder}
          />
        );
    }
  };

  const rulePattern: PatternType | null = pattern ? { pattern: pattern?.pattern, message: pattern?.message } : null;

  const ruleRequired: RequiredType | null = required
    ? { required: true, message: "Это поле обязательно для заполнения" }
    : null;

  return (
    <div className="flex flex-col justify-between">
      <div className={`flex items-center mb-2 ${css.title}`}>
        <Tooltip title="Является обязательным полем">
          <span className="text-red-500 pr-1 cursor-default">{required ? "*" : ""}</span>
        </Tooltip>
        <span>{title}</span>
        {tooltipTitle && (
          <Tooltip className="ml-1" title={tooltipTitle} placement="top">
            <HelpOutlinedIcon />
          </Tooltip>
        )}
      </div>
      <Item name={fieldName} rules={[rulePattern, ruleRequired]}>
        {renderInputType()}
      </Item>
      {showCount && type !== "textArea" && (
        <div className="flex justify-end">
          <span className={`text-14-r secondary-color ${css.hint}`}>
            {form.getFieldValue(fieldName)?.length ?? 0}
            {maxLength ? `/${maxLength}` : ""}
          </span>
        </div>
      )}
      {note && <span className={`text-12-r secondary-color mb-4 ${css.hint}`}>{note}</span>}
    </div>
  );
};

export default CustomInput;
