import { WBTnvedType } from "app/types";
import {
  FETCH_WB_TNVED_FAILURE,
  FETCH_WB_TNVED_REQUEST,
  FETCH_WB_TNVED_SUCCESS,
  FETCH_WB_TNVEDS_LIST_FAILURE,
  FETCH_WB_TNVEDS_LIST_REQUEST,
  FETCH_WB_TNVEDS_LIST_SUCCESS
} from "../../constants/actionTypes";

export type WbTnvedsActionsType =
  | FetchWBTnvedsListRequestType
  | FetchWBTnvedsListSuccessType
  | FetchWBTnvedsListFailureType
  | FetchWBTnvedRequestType
  | FetchWBTnvedSuccessType
  | FetchWBTnvedFailureType;

// получение списка ТНВЭД
type FetchWBTnvedsListRequestType = {
  type: typeof FETCH_WB_TNVEDS_LIST_REQUEST
};

export const fetchWBTnvedsListRequest = (): FetchWBTnvedsListRequestType => ({
  type: FETCH_WB_TNVEDS_LIST_REQUEST
});

type FetchWBTnvedsListSuccessType = {
  type: typeof FETCH_WB_TNVEDS_LIST_SUCCESS;
  payload: {
    tnveds: WBTnvedType[];
  };
};

export const fetchWBTnvedsListSuccess = (
  tnveds: WBTnvedType[],
): FetchWBTnvedsListSuccessType => ({
  type: FETCH_WB_TNVEDS_LIST_SUCCESS,
  payload: {
    tnveds,
  },
});

type FetchWBTnvedsListFailureType = {
  type: typeof FETCH_WB_TNVEDS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBTnvedsListFailure = (error: string): FetchWBTnvedsListFailureType => ({
  type: FETCH_WB_TNVEDS_LIST_FAILURE,
  payload: {
    error,
  },
});

// получение ТНВЭД
type FetchWBTnvedRequestType = {
  type: typeof FETCH_WB_TNVED_REQUEST
};

export const fetchWBTnvedRequest = (): FetchWBTnvedRequestType => ({
  type: FETCH_WB_TNVED_REQUEST
});

type FetchWBTnvedSuccessType = {
  type: typeof FETCH_WB_TNVED_SUCCESS;
  payload: {
    tnved: WBTnvedType;
  };
};

export const fetchWBTnvedSuccess = (
  tnved: WBTnvedType,
): FetchWBTnvedSuccessType => ({
  type: FETCH_WB_TNVED_SUCCESS,
  payload: {
    tnved,
  },
});

type FetchWBTnvedFailureType = {
  type: typeof FETCH_WB_TNVED_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBTnvedFailure = (error: string): FetchWBTnvedFailureType => ({
  type: FETCH_WB_TNVED_FAILURE,
  payload: {
    error,
  },
});
