import React, { JSX, FC } from "react";

import AuthContainer from "../../components/Authentication/AuthContainer/AuthContainer";
import ResetPasswordForm from "../../components/Authentication/ResetPasswordForm/ResetPasswordForm";

const ResetPasswordPage: FC = (): JSX.Element => {
  return <AuthContainer authForm={<ResetPasswordForm />} />;
};

export default ResetPasswordPage;
