import React, {FC, JSX, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { InitialAccountStateType } from "../../redux/reducers/account.reducer";
import { AppStateType } from "../../redux/reducers/mainReducer";
import { InitialMarketplacesStateType } from "../../redux/reducers/marketplaces.reducer";
import { InitialProductsStateType } from "../../redux/reducers/products.reducer";
import { InitialWBCharacteristicsStateType } from "../../redux/reducers/wbCharacteristics.reducer";
import { AppDispatch } from "../../redux/store/store";
import { fetchMarketplaces } from "../../redux/thunks/marketplaces.thunks";
import {
  deleteProduct,
  fetchCardInfo,
  fetchProducts,
  syncProducts,
  syncProductsCard,
  updateWholeProduct
} from "../../redux/thunks/products.thunks";
import { CardInfoType, ProductMediaType, ProductType, WBCategoryType, WBCharacteristicValueType } from "app/types";
import { transformLoadedFiles } from "../../components/DragAndDrop/transformLoadedFiles";
import { fetchWBCategory } from "../../redux/thunks/wbCategories.thunks";
import { ProductStatus } from "../../components/Products/ProductsTab/ProductsTab";

// components
import { Form, message, notification, Spin } from "antd";
import ButtonsPanel from "../../components/Products/ButtonsPanel/ButtonsPanel";
import NomenclatureItemsCard from "../../components/Products/NomenclatureItemsWrapper/NomenclatureItemsWrapper";
import ProductCardWrapper from "../../components/Products/ProductCardWrapper/ProductCardWrapper";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";

import css from "./style.module.css";

type ModalType = {
  type: "cancel" | "back";
  text: string;
  title: string;
  onOkHandler: () => void;
};

interface ICreateAndEditProductPageProps {}

const CreateAndEditProductPage: FC<ICreateAndEditProductPageProps> = (): JSX.Element => {
  const [form] = Form.useForm();

  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const { cardId, productId } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const values = Form.useWatch([], form);
  const { getFieldValue, getFieldsValue, setFieldsValue } = form;

  const [productCardId, setProductCardId] = useState<string>(null);
  const [nomenclatureId, setNomenclatureId] = useState<string>(null);
  const [isResetFileList, setIsResetFileList] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isCategoriesRequested, setIsCategoriesRequested] = useState<boolean>(false);
  const [categoryIds, setCategoryIds] = useState<string[]>(null);

  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);
  const currentProduct: ProductType = products.list?.find((product) => product.id === nomenclatureId);
  const account: InitialAccountStateType = useSelector((state: AppStateType) => state.account);
  const wbCharacteristics: InitialWBCharacteristicsStateType = useSelector(
    (state: AppStateType) => state.wbCharacteristics
  );
  const marketplaces: InitialMarketplacesStateType = useSelector((state: AppStateType) => state.marketplaces);

  const error: string = products.error;
  const productInArchive: boolean = currentProduct?.status === ProductStatus.Archive;
  // т.к. state обновляется не сразу, используем обычную константу для сохранения промежуточного результата
  const ids: string[] = [];

  useEffect(() => {
    dispatch(fetchMarketplaces());
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/edit-product/")) {
      setIsLoading(true);
      setIsEditMode(true);
      setProductCardId(cardId);
      setNomenclatureId(productId);
      dispatch(fetchProducts(
      { page: 1, page_size: 30 },
      { card_id: cardId }
      ));
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isEditMode && products.list?.length && !isCategoriesRequested) {
      setCategoryIds([products.list[0].category.id]);
      getCategories(categoryIds, products.list[0].category.id);
    }
  }, [products.list]);

  useEffect(() => {
    if (isEditMode && products.list?.length) {
      setFieldsValue({ category: categoryIds });
    }
  }, [categoryIds?.length]);

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  useEffect(() => {
    // при выборе НП заполняем форму данными этой НП
    if (nomenclatureId) {
      setIsResetFileList(false);
      setProductValues();
    }
  }, [nomenclatureId, currentProduct]);

  const getCategories = (categoryIds: string[], categoryId: string): void => {
    setIsCategoriesRequested(true);

    // условие для выхода из рекурсии
    let exit: boolean = false;

    ids.length === 0 && ids.push(categoryId);

    dispatch(fetchWBCategory(categoryId))
      .then((res: any) => {
        if (res?.status === 200) {
          const data: WBCategoryType = res?.data;

          if (data?.parent?.name) {
            if (!ids?.some((category) => category === data.parent.id)) {
              setCategoryIds(ids);
            }

            ids.unshift(data.parent.id);
            getCategories(categoryIds, data.parent.id);
          } else {
            exit = true;
          }
        }
      });

    if (exit) return;
  };

  const setProductValues = () => {
    const barcode: string = currentProduct?.sizes[0]?.barcodes[0]?.barcode;
    const techSize: string = currentProduct?.sizes[0]?.tech_size;
    const wbSize: string = currentProduct?.sizes[0]?.wb_size;

    const photos: ProductMediaType[] = currentProduct?.medias?.filter(({filename}) => /\.(jpg|png)$/.test(filename));
    const videos: ProductMediaType[] = currentProduct?.medias?.filter(({filename}) => /\.(mov|mp4)$/.test(filename));

    setFieldsValue({
      name: currentProduct?.name,
      description: currentProduct?.description,
      vendor_code: currentProduct?.vendor_code || undefined,
      barcode: barcode || undefined,
      tech_size: techSize,
      wb_size: wbSize,
      photo: transformLoadedFiles(photos),
      video: transformLoadedFiles(videos)
    });

    formatProductsData(currentProduct?.characteristic_values)?.forEach((charc) => {
      const isDate: boolean = charc?.characteristic?.name?.toLowerCase().includes("дата");

      setFieldsValue({
        [charc?.characteristic?.id]: isDate ? dayjs(charc?.value) : charc?.value || undefined,
      });
    });
  };

  // TODO перенести в reducer
  const formatProductsData = (list: WBCharacteristicValueType[]) => {
    const resultArray = list?.reduce((accumulator, currentItem) => {
      const existingItem = accumulator.find(item => item.characteristic.id === currentItem.characteristic.id);

      if (existingItem) {
        // Если уже есть объект с таким id, добавляем значение в массив value (без повторений)
        existingItem.value.push(currentItem.value);
      } else {
        // Если объекта с таким id еще нет, добавляем его в аккумулятор
        const newValue: string | string[] = currentItem.characteristic.max_count > 1
          ? [currentItem.value]
          : currentItem.value;

        accumulator.push({
          characteristic: { ...currentItem.characteristic },
          value: newValue,
        });
      }

      return accumulator;
    }, []);

    // Убедимся, что значения в массивах value уникальны
    resultArray?.forEach(item => {
      if (item.characteristic.max_count > 1) {
        item.value = Array.from(new Set(item.value));
      }
    });

    return resultArray;
  };

  // блокируем категорию, если в каком-либо поле кроме категории установлено значение
  const isDisabledCategory = (): boolean => {
    const keys: string[] = Object.keys(getFieldsValue());
    const hasOnlyCategory: boolean = keys?.every((key) => key === "category");
    const keysWithoutCategory: string[] = keys?.filter((key) => key !== "category");

    return !hasOnlyCategory && keysWithoutCategory?.some((key: string) => {
      // исключаем пустой массив
      if (Array.isArray(getFieldValue(key))) {
        return getFieldValue(key).length > 0;
      }

      return !!getFieldValue(key);
    });
  };

  const [
    typeConfirmModal,
    setTypeConfirmModal
  ] = useState<"cancel" | "back">(null);

  const modalData: ModalType[] = [
    {
      type: "cancel",
      title: "Отменить добавление товара?",
      text: "Карточка товара не будет сохранена, все введенные данные будут утеряны. Вы уверены?",
      onOkHandler: async () => {
        await dispatch(deleteProduct(nomenclatureId));
        navigate("/products");
      }
    },
    {
      type: "back",
      title: "Вы уверены?",
      text: `Если вы не заполнили все данные, вы можете сделать это позже. 
      Карточка товара будет перемещена в черновики.`,
      onOkHandler: () => navigate("/products")
    }
  ];

  const renderModal = () => {
    const typeModal: ModalType = modalData.find(({type}) => type === typeConfirmModal);

    return (
      <ConfirmModal
        text={typeModal.text}
        title={typeModal.title}
        isOpen={!!typeConfirmModal}
        onOkHandler={typeModal.onOkHandler}
        isApplyDisabled={typeModal.type === "cancel" && !nomenclatureId}
        onCancelHandler={() => setTypeConfirmModal(null)}
      />
    );
  };

  const onSubmit = async (): Promise<void> => {
    try {
      await form.validateFields({recursive: true});

      await updateProduct(true);
    } catch (err) {
      message.error("Для синхронизации карточки необходимо заполнить обязательные поля");
    }
  };

  // сохранение сразу всей НП
  const updateProduct = async (sync: boolean = false): Promise<void> => {
    const params = {
      company_id: account?.userInfo?.company?.id,
    };
    const mpId: string = marketplaces.list?.find((mp) => mp.name === "WB").id;

    setProductParams(params);

    await dispatch(updateWholeProduct(nomenclatureId, params))
      .then((res: any) => {
        if (res?.status === 200) {
          if (!sync) {// если клик по кнопке "Сохранить как черновик"
            message.success("Черновик товара сохранен");
          } else {
            // проверяем, была ли карточка синхронизирована ранее
            products.list.map(async (product) => {
              await dispatch(fetchCardInfo(product.card_id))
                .then((cardInfo: CardInfoType) => {
                  /* если карточка уже была синхронизирована, то синхронизируем только НП,
                     иначе синхронизируем всю карточку */
                  if (cardInfo?.imt_id) {
                    if (product.status !== ProductStatus.Synchronized) {
                      dispatch(syncProducts(mpId, [product.id]))
                        .then((res: any) => {
                          getResponseMessage(res, product.id);
                        });
                    } else {
                      message.success("Карточка сохранена");
                      navigate("/products");
                    }
                  } else {
                    dispatch(syncProductsCard(mpId, [product.id]))
                      .then((res: any) => {
                        getResponseMessage(res, product.id);
                      });
                  }
                });
            });
          }
        } else {
          message.error(`Не удалось сохранить черновик. ${error ?? ""}`);
        }
      });
  };

  const getResponseMessage = (response: any, productId: string): void => {
    if (response?.status === 200) {
      const current = response.data?.[0]?.products?.find((product) => product.id === productId);

      if (current?.status === ProductStatus.IntegrationError) {
        notification.error({
          message: "Ошибка синхронизации",
          description: current.synch_errors?.join(", "),
          duration: 15,
          placement: "topLeft"
        });
      } else {
        navigate("/products");
      }
    }
  };

  const setProductParams = (params: any ) => {
    Object.keys(values)?.forEach((value) => {
      const fieldValue = getFieldValue(value);

      switch (value) {
        case "category": {
          params["category_id"] = fieldValue && fieldValue[fieldValue?.length - 1];

          break;
        }
        case "name": {
          if (fieldValue) {
            const nameFieldId: string = wbCharacteristics.list
              ?.find((charc) => charc.name === "Наименование")?.id;

            params["name"] = fieldValue;
            params["characteristic_values"] = [{
              characteristic_id: nameFieldId,
              value: getFieldValue(nameFieldId).trim(),
            }];
          }

          break;
        }
        case "description": {
          const descriptionFieldId: string = wbCharacteristics.list
            ?.find((charc) => charc.name === "Описание")?.id;

          params["description"] = fieldValue?.trim() ?? "";
          params["characteristic_values"] = [{
            characteristic_id: descriptionFieldId,
            value: getFieldValue(descriptionFieldId)?.trim() ?? "",
          }];

          break;
        }
        case "vendor_code": {
          params["vendor_code"] = fieldValue?.trim();

          break;
        }
        case "barcode": {
          const barcodes = [{ [value]: fieldValue?.toString() }];

          if (fieldValue) {
            if (params.sizes) {
              if (params.sizes?.[0]?.barcodes) {
                params.sizes[0].barcodes[0] = barcodes;
              } else if (params.sizes) {
                params.sizes[0] = { ...params.sizes[0], barcodes };
              }
            } else {
              params["sizes"] = [{ barcodes }];
            }
          } else {
            if (params.sizes) {
              params.sizes[0] = {...params.sizes[0], barcodes: []};
            } else {
              params["sizes"] = [{barcodes: []}];
            }
          }

          break;
        }
        case "wb_size":
        case "tech_size": {
          const size = {[value]: fieldValue?.trim() ?? ""};

          if (params.sizes) {
            if (params.sizes?.[0]?.[value]) {
              params.sizes[0][value] = fieldValue;
            } else if (params.sizes) {
              params.sizes[0] = { ...params.sizes[0], ...size };
            }
          } else {
            params["sizes"] = [{ ...size }];
          }

          break;
        }
        case "photo":
        case "video": {
          const preloadIds = fieldValue
            ?.filter((file) => file.loadingType === "preload")
            ?.map((file) => file.responseId);
          const loadedIds = fieldValue
            ?.filter((file) => file.loadingType === "loaded")
            ?.map((file) => file.responseId);

          // preload_files отправляем только если такие файлы есть
          if (params.preload_files && preloadIds.length) {
            params.preload_files = [...params.preload_files, ...preloadIds];
          } else if (preloadIds.length) {
            params["preload_files"] = preloadIds;
          }

          // loaded_files отправляем, даже если пустой массив
          if (params.loaded_files) {
            params.loaded_files = [...params.loaded_files, ...loadedIds];
          } else {
            params["loaded_files"] = loadedIds;
          }

          break;
        }
        default: {
          if (fieldValue !== null && fieldValue !== undefined) {
            const characteristicValues = Array.isArray(fieldValue)
              ? fieldValue.map(val => ({ characteristic_id: value, value: val.toString() }))
              : [{ characteristic_id: value, value: fieldValue?.toString() ?? "" }];

            if (dayjs.isDayjs(fieldValue) && typeof fieldValue === "object") {
              characteristicValues[0].value = fieldValue.toISOString() ?? "";
            }

            if (params.characteristic_values) {
              params.characteristic_values = params.characteristic_values.concat(characteristicValues);
            } else {
              params.characteristic_values = characteristicValues;
            }
          }
        }
      }
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="flex flex-col justify-between bg-main">
        <Form form={form} onFinish={onSubmit}>
          <ButtonsPanel
            title="Добавление товара"
            isDisabled={!getFieldValue("name")}
            cancelText="Отменить"
            applyText="Сохранить как черновик"
            cancelHandler={() => setTypeConfirmModal("cancel")}
            applyHandler={updateProduct}
          />
          <div className={`flex justify-between p-6 ${css.content}`}>
            <NomenclatureItemsCard
              form={form}
              values={values}
              isLoading={isLoading}
              productInArchive={productInArchive}
              nomenclatureId={nomenclatureId}
              setNomenclatureId={setNomenclatureId}
              setIsResetFileList={setIsResetFileList}
            />
            <div className={`bg-white h-full p-6 card-radius ${css.wrapper}`}>
              <ProductCardWrapper
                form={form}
                values={values}
                isEditMode={isEditMode}
                productCardId={productCardId}
                setIsLoading={setIsLoading}
                setProductCardId={setProductCardId}
                nomenclatureId={nomenclatureId}
                setNomenclatureId={setNomenclatureId}
                isResetFileList={isResetFileList}
                isDisabledCategory={isDisabledCategory()}
              />
            </div>
          </div>
          <ButtonsPanel
            isDisabled={
              !getFieldValue("name")
                || getFieldValue("photo")?.length < 1
                || !getFieldValue("vendor_code")
                || !getFieldValue("barcode")
                || productInArchive
            }
            cancelText="К списку товаров"
            applyText="Сохранить товар и завершить"
            cancelHandler={() => setTypeConfirmModal("back")}
            applyHandler={onSubmit}
          />
        </Form>
        {!!typeConfirmModal && renderModal()}
      </div>
    </Spin>
  );
};

export default CreateAndEditProductPage;
