import axios, { Canceler, CancelTokenStatic } from "axios";
import { fetchNewToken } from "../redux/thunks/account.thunks";
import { logout } from "../redux/actions/account.actions";

const getToken = (): string | null => {
  const token: string | null = window?.localStorage.getItem("authToken");

  axios.defaults.headers.common.Authorization = token ? JSON.parse(token) : undefined;

  return token;
};

const checkErrorMessage = (error: string): boolean => {
  return error === "Срок действия токена истек."
    || error === "Token verification failed"
    || error.includes("User session not found")
    || error.includes("Session expired");
};

export const setupResponseInterceptor = (store: any): void => {
  const refreshToken: string | null = window?.localStorage?.getItem("refreshToken");

  axios.interceptors.response.use(null, (error) => {
    if (error?.response && error?.response?.status === 401) {
      store.dispatch(logout());

      if (checkErrorMessage(error?.response?.data?.error)) {
        store.dispatch(fetchNewToken(refreshToken));
      }
    }

    return Promise.reject(error);
  });
};

const CancelToken: CancelTokenStatic = axios.CancelToken;
export let cancel: Canceler;

const get = (host: string | undefined, path: string, params?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "get",
    url: `${host}${path}`,
    params,
    cancelToken: new CancelToken(function executor(canceler: Canceler) {
      cancel = canceler;
    })
  });
};

const put = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "put",
    url: `${host}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const post = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "post",
    url: `${host}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const getKeyCloak = (host: string | undefined, path: string, params?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "get",
    url: `${host}${path}`,
    params,
    cancelToken: new CancelToken(function executor(canceler: Canceler) {
      cancel = canceler;
    })
  });
};

const putKeyCloak = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json", //application/x-www-form-urlencoded
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "put",
    url: `${host}${path}`,
    data: body,
  });
};

const postKeyCloakLogin = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "post",
    url: `${host}${path}`,
    data: body,
  });
};

const getBackendLogin = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "get",
    url: `${host}${path}`,
    data: body,
  });
};

const patch = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "patch",
    url: `${host}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const deleted = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(String(getToken()))}`,
    },
    method: "delete",
    url: `${host}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

export default {
  GET: get,
  PUT: put,
  POST: post,
  PATCH: patch,
  DELETE: deleted,
  POST_LOGIN: postKeyCloakLogin,
  GET_LOGIN: getBackendLogin,
  GET_KEYCLOACK: getKeyCloak,
  PUT_KEYCLOACK: putKeyCloak,
};

