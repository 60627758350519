import React, { JSX, FC } from "react";
import { ReactComponent as ClockIcon } from "../../../assets/icons/clock.svg";

interface INoDataProps {}

const NoData: FC<INoDataProps> = (): JSX.Element => {
  return (
    <div className="flex flex-col justify-center items-center">
      <ClockIcon/>
      <p className="secondary-color">Данных еще нет</p>
    </div>
  );
};

export default NoData;
