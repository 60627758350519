import React, { JSX, FC } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store/store";
import { clearProducts } from "../../../redux/thunks/products.thunks";
import { ProductOptionType, SelectedValueType } from "../StocksTab/StocksTab";

// components
import { Checkbox, Form, Select } from "antd";
import ImageContainer from "../../ui/ImageContainer/ImageContainer";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";

import css from "../StocksTab/style.module.css";

interface ICustomSelectProps {
  productsOptions: ProductOptionType[];
  dropdownVisible: boolean;
  selectedValues: SelectedValueType[];
  setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedValues: React.Dispatch<React.SetStateAction<SelectedValueType[]>>;
}

const CustomSelect: FC<ICustomSelectProps> = ({
  productsOptions,
  dropdownVisible,
  selectedValues,
  setDropdownVisible,
  setSelectedValues,
}): JSX.Element => {
  const { Item } = Form;

  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (optionValue: string): void => {
    if (selectedValues?.find(({value}) => value === optionValue)) {
      setSelectedValues(selectedValues.filter((key) => key.value !== optionValue));
    } else {
      const label: string = productsOptions?.find(({value}) => value === optionValue)?.label;

      setSelectedValues([...selectedValues, { key: optionValue, value: optionValue, label }]);
    }
  };

  const searchByNameAndBarcode = (input: string, option: SelectedValueType): boolean => {
    const getMatch = (value: string | number): boolean =>
      value?.toString()?.toLowerCase()?.includes(input.toLowerCase());

    return getMatch(option.label) || getMatch(option.key);
  };

  const handleSelect = (options: SelectedValueType[]): void => {
    // select добавляет пустые объекты, поэтому их отфильтровываем
    const notEmptyOptions: SelectedValueType[] = options.filter((option: SelectedValueType) =>
      Object.keys(option)?.length > 0);

    setSelectedValues(notEmptyOptions);
  };

  return (
    <Item className={css.searchInput} name="products" style={{ marginRight: 0 }}>
      <Select
        size="large"
        placeholder="Найти товар по названию или баркоду"
        showSearch
        tagRender={() => <>{null}</>}
        defaultOpen={dropdownVisible}
        onChange={(_value, option: SelectedValueType[]) => handleSelect(option)}
        suffixIcon={<p className="text-14-r">Выбрано: {selectedValues?.length}</p>}
        onDropdownVisibleChange={(open: boolean) => setDropdownVisible(open)}
        filterOption={(input: string, option: SelectedValueType) => searchByNameAndBarcode(input, option)}
        mode="multiple"
        notFoundContent={
          <div className="flex flex-col items-center my-7">
            <h5>Товаров нет</h5>
            <p className="text-14-r secondary-color mb-3">У вас нет товаров, необходимо создать новый</p>
            <PrimaryButton
              text="Создать новый"
              onClickHandler={() => {
                navigate("/create-product");
                dispatch(clearProducts());
              }}
            />
          </div>
        }
      >
        {productsOptions?.map((option: ProductOptionType) => (
          <Select.Option key={option.value} value={option.value} label={option.label}>
            <div className="flex items-center">
              <Checkbox
                className="mr-2"
                checked={!!selectedValues?.find(({value}) => value === option.value)}
                onChange={() => handleChange(option.value)}
              />
              <ImageContainer
                src={option.img?.src}
                alt="Фото"
                filename={option.img?.filename}
                imageContainerClass={css.img}
              />
              <div className="flex flex-col">
                <div className="ml-2">{option.label}</div>
                <div className="ml-2 secondary-color">{option.value}</div>
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </Item>
  );
};

export default CustomSelect;
