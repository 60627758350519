import React, { JSX, FC } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { resetPasswordEmail } from "redux/thunks/account.thunks";

import { Form, Input, message } from "antd";
import SubmitButton from "../../SubmitButton/SubmitButton";
import FormTitle from "../FormTitle/FormTitle";

import css from "./style.module.css";

import { AppDispatch } from "redux/store/store";

interface IResetPasswordFormProps { }

const ResetPasswordForm: FC<IResetPasswordFormProps> = (): JSX.Element => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const handleOnSubmit = (): void => {
    form.validateFields()
      .then(() => {
        const email: string = form.getFieldValue("email");

        dispatch(resetPasswordEmail(email))
          .finally(() => {
            navigate("/login");
            message.info("Если вы зарегистрированы, то письмо придет на указанный емейл");
          });
      });
  };

  return (
    <div>
      <FormTitle
        title="Восстановление пароля"
        subtitle="Введите адрес электронной почты, который вы используете для входа в аккаунт."
      />
      <Form form={form} name="passwordRecovery">
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "Введите email в формате email@mail.ru!",
            },
            {
              required: true,
              message: "Пожалуйста, введите ваш email!"
            }
          ]}
        >
          <Input
            className={css.emailInput}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item>
          <SubmitButton form={form} text="Сбросить пароль" onClick={handleOnSubmit} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;