import React, { JSX, FC, useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import {
  BoxType,
  RequestOrdersFiltersType,
  RequestPaginationType,
} from "app/types";
import { useDispatch, useSelector } from "react-redux";
import type { AppStateType } from "../../../redux/reducers/mainReducer";
import { AppDispatch } from "../../../redux/store/store";
import { fetchOrders } from "../../../redux/thunks/orders.thunks";
import { fetchSupply } from "../../../redux/thunks/supplies.thunks";

import { ReactComponent as ExclamationDarkIcon } from "../../../assets/icons/exclamation_dark.svg";

//components
import SelectionList from "../SelectionList/SelectionList";
import QRList from "../QRList/QRList";
import { message, Spin, Tabs } from "antd";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";
import LabelingRulesModal from "../../Modals/LabelingRulesModal/LabelingRulesModal";
import ShippingRulesModal from "../../Modals/ShippingRulesModal/ShippingRulesModal";
import EmptyBlock from "../../EmptyBlock/EmptyBlock";
import PackagingBox from "components/PackagingBox/PackagingBox";
import AddBoxModal from "../../Modals/AddBoxModal/AddBoxModal";
import SupplyHeader from "./SupplyHeader/SupplyHeader";
import SupplyInfoCards from "./SupplyInfoCards/SupplyInfoCards";
import SupplyInfoPanel from "./SupplyInfoPanel/SupplyInfoPanel";
import SupplyOrdersList from "./SupplyOrdersList/SupplyOrdersList";
import { createSupplyBoxes, fetchSupplyBoxesList } from "redux/thunks/supplyBoxes.thunks";
import { InitialSuppliesStateType } from "../../../redux/reducers/supplies.reducer";
import { InitialSupplyBoxesStateType } from "redux/reducers/supplyBoxes.reducer";

export enum SupplyStatuses {
  assemblyStatus = "На сборке",
  deliveryStatus = "В доставке"
}

export type SelectionListColumnType = {
  label: string;
  field: string;
};

export type SelectionListItemType = {
  id_in_marketplace: number;
  brand: string;
  name: string;
  vendor_code: string;
  barcode: string;
  photo: string;
  size: string;
  color: string;
  sticker: string;
  box: string;
};

enum SupplyTab {
  orders = "Список заданий",
  packaging = "Упаковка"
}

interface ISupplyWrapperProps { }

const columns: SelectionListColumnType[] = [
  { label: "Номер задания", field: "id_in_marketplace" },
  { label: "Фото товара", field: "photo" },
  { label: "Бренд", field: "brand" },
  { label: "Название товара", field: "name" },
  { label: "Размер", field: "size" },
  { label: "Цвет", field: "color" },
  { label: "Артикул поставщика", field: "vendor_code" },
  { label: "ШК товара (этикетка)", field: "sticker" },
  { label: "Баркод", field: "barcode" },
];

const SupplyWrapper: FC<ISupplyWrapperProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { supplyId } = useParams();

  const supplies: InitialSuppliesStateType = useSelector((state: AppStateType) => state.supplies);
  const supplyBoxes: InitialSupplyBoxesStateType = useSelector((state: AppStateType) => state.supplyBoxes);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<RequestOrdersFiltersType>({ supply_id: supplyId });
  const [isShowLabelingRulesModal, setIsShowLabelingRulesModal] = useState<boolean>(false);
  const [isShowShippingRulesModal, setIsShowShippingRulesModal] = useState<boolean>(false);
  const [isShowAddBoxModal, setIsShowAddBoxModal] = useState<boolean>(false);
  const [addBoxModalTitle, setAddBoxModalTitle] = useState<string>("asdasd");
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [selectionListData, setSelectionListData] = useState<SelectionListItemType[]>(null);
  const [selectionListDataByBoxes, setSelectionListDataByBoxes] = useState<SelectionListItemType[][]>(null);
  const [QROrderLinks, setQROrderLinks] = useState<string[]>(null);
  const [QRSupplyLinks, setQRSupplyLinks] = useState<string[]>(null);
  const [activeTab, setActiveTab] = useState<string>(SupplyTab.orders);
  const [currentBoxId, setCurrentBoxId] = useState<string>(null);

  const selectionListRef = useRef();
  const selectionListByBoxesRef = useRef();
  const qrOrderRef = useRef();
  const qrSupplyRef = useRef();

  const name: string = `Поставка №${supplies.found?.supply_id ?? ""} ${supplies.found?.name ?? ""}`;

  useEffect(() => {
    dispatch(fetchSupply(supplyId));
  }, []);

  useEffect(() => {
    if (activeTab === SupplyTab.orders) {
      fetchOrdersList({ page: 1, page_size: 10 }, filters);
      supplyId && dispatch(fetchSupplyBoxesList(supplyId, { page: 1, page_size: 100 }));
    }
  }, [activeTab]);

  useEffect(() => {
    if (selectionListData && isPrinting) {
      handlePrintSelectionList();
    }
  }, [selectionListData, isPrinting]);

  useEffect(() => {
    if (selectionListDataByBoxes && isPrinting) {
      handlePrintSelectionListByBoxes();
    }
  }, [selectionListDataByBoxes, isPrinting]);

  useEffect(() => {
    if (QROrderLinks && isPrinting) {
      handlePrintQROrder();
    }
  }, [QROrderLinks]);

  useEffect(() => {
    if (QRSupplyLinks && isPrinting) {
      handlePrintQRSupply();
    }
  }, [QRSupplyLinks]);

  const isAssemblyStatus: boolean = !supplies.found?.is_done;
  const isDeliveryStatus: boolean = supplies.found?.is_done;

  const fetchOrdersList = async (
    pagination: RequestPaginationType,
    filters: RequestOrdersFiltersType
  ): Promise<void> => {
    await dispatch(fetchOrders(pagination, filters));
  };

  const printQRSupply = () => {
    setIsLoading(true);
    setQRSupplyLinks(supplies.found?.supply_qr?.map(({ file }) => file));
    setIsPrinting(true);
  };

  const resetPrintStates = (): void => {
    setIsPrinting(false);
    setIsLoading(false);
  };

  const handleOnCancle = (): void => {
    setIsShowAddBoxModal(false);
    setCurrentBoxId(null);
  };

  const handlePrintSelectionList = useReactToPrint({
    documentTitle: "Лист подбора",
    content: () => selectionListRef.current,
    onAfterPrint: () => {
      resetPrintStates();
      setSelectionListData(null);
    },
  });

  const handlePrintSelectionListByBoxes = useReactToPrint({
    documentTitle: "Лист подбора",
    content: () => selectionListByBoxesRef.current,
    onAfterPrint: () => {
      resetPrintStates();
      setSelectionListDataByBoxes(null);
    },
  });

  const handlePrintQROrder = useReactToPrint({
    documentTitle: "Маркировочные QR-коды",
    content: () => qrOrderRef.current,
    onAfterPrint: () => {
      resetPrintStates();
      setQROrderLinks(null);
    },
  });

  const handlePrintQRSupply = useReactToPrint({
    documentTitle: "QR-код поставки",
    content: () => qrSupplyRef.current,
    onAfterPrint: () => resetPrintStates(),
  });

  const handleCreateBox = (): void => {
    dispatch(createSupplyBoxes(supplyId, 1))
      .then((res: any) => {
        setCurrentBoxId(res?.[0]?.id);

        message.success("Короб успешно создан");

        fetchOrdersList(
          { page: 1, page_size: 10 },
          { supply_id: supplyId, without_box: true }
        )
          .then(() => {
            setAddBoxModalTitle("Добавление заданий в новый короб");
            setIsShowAddBoxModal(true);
          });
      })
      .catch(() => message.error("Произошла ошибка при создании короба, пожалуйста, повторите позднее"));
  };

  return (
    <Spin spinning={isLoading}>
      <SupplyHeader
        isAssemblyStatus={isAssemblyStatus}
        isDeliveryStatus={isDeliveryStatus}
        printQRSupply={printQRSupply}
      />
      <SupplyInfoCards
        isAssemblyStatus={isAssemblyStatus}
        isDeliveryStatus={isDeliveryStatus}
        setIsShowLabelingRulesModal={setIsShowLabelingRulesModal}
        setIsShowShippingRulesModal={setIsShowShippingRulesModal}
      />
      <SupplyInfoPanel isAssemblyStatus={isAssemblyStatus} />

      <Tabs
        className="mt-4"
        activeKey={activeTab}
        defaultActiveKey={SupplyTab.orders}
        onChange={(key) => setActiveTab(key)}
      >
        <Tabs.TabPane
          key={SupplyTab.orders}
          tab={SupplyTab.orders}
        >
          <SupplyOrdersList
            filters={filters}
            setFilters={setFilters}
            setIsLoading={setIsLoading}
            fetchOrdersList={fetchOrdersList}
            setIsPrinting={setIsPrinting}
            setQROrderLinks={setQROrderLinks}
            setSelectionListData={setSelectionListData}
            setSelectionListDataByBoxes={setSelectionListDataByBoxes}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={SupplyTab.packaging}
          tab={SupplyTab.packaging}
          disabled={!isAssemblyStatus}
        >
          {
            !!(supplyBoxes.count > 0) && !supplyBoxes.isFetching
              ? supplyBoxes.list?.map((box: BoxType, idx: number) => (
                <PackagingBox
                  key={`supplyBox${idx}`}
                  box={box}
                  onAddToBoxHandler={(boxId: string) => {
                    setCurrentBoxId(boxId);
                    setIsShowAddBoxModal(true);
                    setAddBoxModalTitle(`Добавление заданий в короб ${box.trbx_id}`);
                  }}
                />
              ))
              : (
                <div className="flex flex-1 justify-center items-center">
                  <EmptyBlock
                    style={{ height: "calc(100dvh - 25rem)" }}
                    icon={<ExclamationDarkIcon />}
                    title="Подготовленных упаковок нет"
                    description={<>Создайте новый короб для поставки <br /> и добавьте в нее ваши сборочные задания</>}
                    btn={
                      <PrimaryButton
                        text="Добавить новый короб"
                        onClickHandler={handleCreateBox}
                      />
                    }
                  />
                </div>
              )
          }

          <PrimaryButton
            text="Добавить новый короб"
            onClickHandler={handleCreateBox}
          />
        </Tabs.TabPane>
      </Tabs>

      <LabelingRulesModal
        isOpen={isShowLabelingRulesModal}
        onCancelHandler={() => setIsShowLabelingRulesModal(false)}
      />
      <ShippingRulesModal
        isOpen={isShowShippingRulesModal}
        onCancelHandler={() => setIsShowShippingRulesModal(false)}
      />
      {isShowAddBoxModal && (
        <AddBoxModal
          title={addBoxModalTitle}
          onCancelHandler={handleOnCancle}
          fetchOrders={fetchOrdersList}
          currentBoxId={currentBoxId}
          onSuccessHandler={() => {
            dispatch(fetchSupplyBoxesList(supplyId, ({
              page: 1,
              page_size: 100
            })));
          }}
        />
      )}

      <div className="hidden">
        {selectionListData && (
          <SelectionList
            ref={selectionListRef}
            columns={columns}
            data={[selectionListData]}
            createdAt={dayjs(supplies.found?.created_at).format("DD.MM.YY")}
            name={name}
          />
        )}
        {!!selectionListDataByBoxes?.length && (
          <SelectionList
            ref={selectionListByBoxesRef}
            columns={columns}
            data={selectionListDataByBoxes}
            createdAt={dayjs(supplies.found?.created_at).format("DD.MM.YY")}
            name={name}
          />
        )}
        <QRList
          title={QROrderLinks ? "Маркировочные QR-коды" : "QR-код поставки"}
          ref={QROrderLinks ? qrOrderRef : qrSupplyRef}
          links={QROrderLinks ?? QRSupplyLinks}
        />
      </div>
    </Spin>
  );
};

export default SupplyWrapper;
