import React, { JSX, FC } from "react";
import dayjs from "dayjs";

import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import CountUp from "react-countup";
import NoData from "../../../ui/NoData/NoData";
import Card from "../../../ui/Card/Card";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow_upward.svg";

import css from "../style.module.css";

type ChartDataType = {
  date: string,
  value: number;
};

interface IChartCardProps {
  title: string;
  data: Record<string, string>;
  suffix?: string;
}

const ChartCard: FC<IChartCardProps> = ({ title, data, suffix }): JSX.Element => {
  const chartData: ChartDataType[] = data && Object.keys(data)
    ?.map((date: string) => ({
      date,
      value: parseFloat(data[date]),
    }));

  const lastValue: number = chartData && chartData[chartData?.length - 1]?.value;
  const firstValue: number = chartData && chartData[0]?.value;

  const getColorAndDirection = (): {
    numberColor: string,
    areaColor: string,
    direction: string
  } => {
    let numberColor: string;
    let areaColor: string;
    let direction: string;

    if (firstValue) {
      const isUpward: boolean = lastValue >= firstValue;

      numberColor = isUpward ? "#35C759" : "#FF3B30";
      areaColor = isUpward ? "#35C75980" : "#FF3B3080";
      direction = isUpward ? "up" : "down";
    } else {
      numberColor = "#35C759";
      areaColor = "#35C75980";
      direction = "up";
    }

    return { numberColor, areaColor, direction };
  };

  const renderHeader = (): JSX.Element => {
    const isUp: boolean = getColorAndDirection().direction === "up";

    return (
      <div className="p-4">
        <p className="text-16-m mb-1">{title}</p>
        {lastValue ? (
          <div className="flex items-center" style={{ color: getColorAndDirection().numberColor }}>
            <ArrowIcon className={isUp ? css.rotate : ""} />
            <p className="text-h3-m ml-2">
              <CountUp end={lastValue} separator=" " />
              {suffix && <span className="ml-2">{suffix}</span>}
            </p>
          </div>
        ) : (
          <span className="text-h3-m">-</span>
        )}
      </div>
    );
  };

  const renderCustomTooltip = ({ active, payload, label }): JSX.Element | null => {
    if (active && payload && payload.length) {
      return (
        <div className={`custom-tooltip bg-white p-2 rounded ${css.tooltip}`}>
          <p className="text-14-b">{payload[0].value}</p>
          <p className="secondary-color">{dayjs(label).format("DD.MM.YY")}</p>
        </div>
      );
    }
    return null;
  };

  const renderChart = (): JSX.Element => {
    return (
      <div className="dashboard-chart">
        <ResponsiveContainer width="100%" height={200}>
          <AreaChart
            data={chartData}
            margin={{ top: 5, right: 0, left: -60, bottom: -30 }}
          >
            <XAxis dataKey="date" tick={false} axisLine={false} />
            <YAxis tick={false} axisLine={false} />
            <Tooltip content={renderCustomTooltip} />
            <Area
              type="monotone"
              dataKey="value"
              stroke={getColorAndDirection().areaColor}
              fill={getColorAndDirection().areaColor}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <Card className="flex flex-col" style={{ padding: 0 }}>
      {renderHeader()}
      {chartData?.length ? (
        renderChart()
      ) : (
        <div className={`flex flex-1 justify-center items-center ${css.noData}`}>
          <NoData />
        </div>
      )}
    </Card>
  );
};

export default ChartCard;
