import React, { JSX, FC, useState } from "react";
import { DraggerFileType, ProductMediaType, ProductType } from "app/types";
import { Divider, FormInstance } from "antd";
import { ReactComponent as HelpOutlinedIcon } from "../../../../assets/icons/help_outlined_icon.svg";
import { transformLoadedFiles } from "../../../DragAndDrop/transformLoadedFiles";

// components
import DragAndDrop from "../../../DragAndDrop/DragAndDrop";
import MediaRequirementsModal from "../../../Modals/MediaRequirementsModal/MediaRequirementsModal";

interface IPhotoAndVideoBlockProps {
  form: FormInstance;
  addMedia: (file: DraggerFileType) => DraggerFileType;
  deleteMedia: (fileList: DraggerFileType[]) => DraggerFileType[];
  currentProduct: ProductType;
  resetFileList: boolean;
  isDisabledFields: boolean;
}

const PhotoAndVideoBlock: FC<IPhotoAndVideoBlockProps> = ({
  form,
  addMedia,
  deleteMedia,
  resetFileList,
  currentProduct,
  isDisabledFields
}): JSX.Element => {
  const [
    isShowRequirementsModal,
    setIsShowRequirementsModal
  ] = useState<boolean>(false);

  const { getFieldValue, setFieldsValue } = form;

  const photos: ProductMediaType[] = currentProduct?.medias?.filter(({filename}) => /\.(jpg|png)/.test(filename));
  const videos: ProductMediaType[] = currentProduct?.medias?.filter(({filename}) => /\.(mov|mp4)/.test(filename));

  const filterFilesFromDragger = (fileUid: string, valueName: string): DraggerFileType[] => {
    if ("fileList" in getFieldValue(valueName)) {
      return getFieldValue(valueName)?.fileList?.filter(i => i.uid !== fileUid);
    }

    return getFieldValue(valueName)?.filter(i => i.uid !== fileUid);
  };

  const handleAfterUpload = (file: DraggerFileType, fieldName: string): void => {
    const files: DraggerFileType[] = filterFilesFromDragger(file.uid, fieldName);

    setFieldsValue({[fieldName]: [...files, file]});
  };

  const handleOnRemove = (file: DraggerFileType, fieldName: string): void => {
    const files: DraggerFileType[] = filterFilesFromDragger(file.uid, fieldName);

    setFieldsValue({[fieldName]: files,});
  };

  return (
    <div>
      <Divider />
      <div className="flex justify-between">
        <h4 className="text-h4-b mb-3">Фото и видео</h4>
        <p className="flex items-center cursor-pointer blue-color" onClick={() => setIsShowRequirementsModal(true)}>
          Требования к медиа
          <HelpOutlinedIcon />
        </p>
      </div>
      <p className="secondary-color mb-8">
        {`Учитывайте требования маркетплейса к медиафайлам. 
				Первое фото в списке будет основным изображением карточки товара.`}
      </p>
      <DragAndDrop
        type="photo"
        form={form}
        isMultiple
        action="/api/catalog/temp_documents"
        title="Фото товара"
        placeholder="Переместите свои изображения сюда или загрузите"
        showCounter
        required
        isDisabled={isDisabledFields}
        maxQuantity={30}
        additionalAddRequest={addMedia}
        additionalDeleteRequest={deleteMedia}
        loadedFilelist={photos?.length
          ? transformLoadedFiles(photos)
          : []
        }
        resetFilesList={resetFileList}
        acceptedFileType={{ extensions: ["jpg", "png"], maxItemSize: 10 * 1024 * 1024 }}
        afterUpload={(file: DraggerFileType) => handleAfterUpload(file, "photo")}
        onRemove={(file) => handleOnRemove(file, "photo")}
      />
      <DragAndDrop
        type="video"
        form={form}
        isMultiple={false}
        action="/api/catalog/temp_documents"
        title="Видео товара"
        placeholder="Переместите свои видео сюда или загрузите"
        showCounter
        isDisabled={isDisabledFields}
        maxFilesSize={50}
        maxQuantity={1}
        resetFilesList={resetFileList}
        additionalAddRequest={addMedia}
        additionalDeleteRequest={deleteMedia}
        loadedFilelist={videos?.length
          ? transformLoadedFiles(videos)
          : []
        }
        acceptedFileType={{ extensions: ["mov", "mp4"], maxItemSize: 50 * 1024 * 1024 }}
        afterUpload={(file: DraggerFileType) => handleAfterUpload(file, "video")}
        onRemove={(file) => handleOnRemove(file, "video")}
      />
      {isShowRequirementsModal && (
        <MediaRequirementsModal
          isOpen={isShowRequirementsModal}
          onCancelHandler={() => setIsShowRequirementsModal(false)}
        />
      )}
    </div>
  );
};

export default PhotoAndVideoBlock;
