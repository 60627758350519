import * as DeliveryMethodsAPI from "../../api/deliveryMethods.api";
import { ThunkAction } from "redux-thunk";
import {
  fetchDeliveryMethodsListRequest,
  fetchDeliveryMethodsListSuccess,
  fetchDeliveryMethodsListFailure,
  DeliveryMethodsActionsType
} from "../actions/deliveryMethods.actions";
import { AppStateType } from "../reducers/mainReducer";

export const fetchDeliveryMethods = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  DeliveryMethodsActionsType
> => async (dispatch) => {
  dispatch(fetchDeliveryMethodsListRequest());
  try {
    const response = await DeliveryMethodsAPI.list();

    dispatch(fetchDeliveryMethodsListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchDeliveryMethodsListFailure(error.response?.data?.error));
  };
};