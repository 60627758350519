import * as SuppliesAPI from "../../api/supplies.api";
import { ThunkAction } from "redux-thunk";
import {
  clearSuppliesList,
  fetchSuppliesListRequest,
  fetchSuppliesListSuccess,
  fetchSuppliesListFailure,
  fetchSupplyRequest,
  fetchSupplySuccess,
  fetchSupplyFailure,
  SuppliesActionsType,
  supplyDeliverSuccess,
  supplyDeliverFailure,
  supplyDeliverRequest,
} from "../actions/supplies.actions";

import {
  RequestPaginationType,
  RequestSuppliesFiltersType,
} from "app/types";
import { AppStateType } from "../reducers/mainReducer";

// Получение списка поставок
export const fetchSuppliesList = (
  pagination: RequestPaginationType,
  filters?: RequestSuppliesFiltersType
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliesActionsType
> => async (dispatch) => {
  dispatch(fetchSuppliesListRequest(pagination, filters ?? {}));
  try {
    const response = await SuppliesAPI.list(pagination, filters ?? {});

    dispatch(fetchSuppliesListSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchSuppliesListFailure(error?.response?.data?.error));
  }
};

// Получение поставки
export const fetchSupply = (supplyId: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliesActionsType
> => async (dispatch) => {
  dispatch(fetchSupplyRequest());
  try {
    const response = await SuppliesAPI.getItem(supplyId);

    dispatch(fetchSupplySuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchSupplyFailure(error?.response?.data?.error));
  }
};

export const fetchSupplyDeliver = (supply_ids: string[]): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliesActionsType
> => async (dispatch) => {
  dispatch(supplyDeliverRequest(supply_ids));
  try {
    const response = await SuppliesAPI.supplyDeliver(supply_ids);

    dispatch(supplyDeliverSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(supplyDeliverFailure(error?.response?.data?.error));

    return Promise.reject(error?.response?.data?.error);
  }
};

// Очистка списка поставок
export const clearSupplies = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliesActionsType
> => async (dispatch) => {
  dispatch(clearSuppliesList());
};
