import React, { JSX, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";

import { ReactComponent as WildberriesIcon } from "../../../assets/icons/wildberries_icon.svg";

import { AppStateType } from "../../../redux/reducers/mainReducer";
import { InitialMarketplacesStateType } from "../../../redux/reducers/marketplaces.reducer";
import type { InitialOrdersStateType } from "../../../redux/reducers/orders.reducer";
import { AppDispatch } from "../../../redux/store/store";
import { fetchMarketplaces } from "../../../redux/thunks/marketplaces.thunks";
import { clearProducts } from "../../../redux/thunks/products.thunks";
import Card from "../../ui/Card/Card";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";
import { Tag, Tooltip } from "antd";

import css from "./style.module.css";

interface IDashboardHeaderProps {}

type MarketplacePanelItemType = {
  title: string;
  value: string | number;
  isTag: boolean;
};

const DashboardHeader: FC<IDashboardHeaderProps> = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const marketplaces: InitialMarketplacesStateType = useSelector((state: AppStateType) => state.marketplaces);
  const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);

  const { dashboard } = orders;

  const items: MarketplacePanelItemType[] = [
    { title: "Кол-во товаров", value: dashboard?.sold ?? 0, isTag: false },
    { title: "Выручка", value: `${dashboard?.total_revenue ?? 0} руб`, isTag: false },
    { title: "Статус интеграции", value: dashboard?.sync_status, isTag: true },
  ];

  // TODO сейчас только WB + нет ручки, временное решение
  const wbId: string = marketplaces.list?.find(({name}) => name === "WB")?.id;

  useEffect(() => {
    dispatch(fetchMarketplaces());
  }, []);

  const renderMarketplacePanel = (item: MarketplacePanelItemType, idx: number): JSX.Element => {
    return (
      <p key={`mp-${idx}`} className={`text-16-r ${css.item}`}>
        <span className="block secondary-color">{item.title}</span>
        {item.isTag ? (
          <Tooltip title={item.value === "Ошибка" ? "Необходимо обновить API токен" : ""}>
            <Tag
              color={item.value === "Подключен" ? "success" : "error"}
              className="text-16-r"
            >
              {item.value}
            </Tag>
          </Tooltip>
        ) : (
          <span className="block">{item.value}</span>
        )}
      </p>
    );
  };

  const handleClick = (): void => {
    dispatch(clearProducts());

    navigate("/create-product");
  };

  return (
    <div className="mb-6">
      <p className="text-18-m mb-3">Подключенные маркетплейсы</p>
      <Card className="grid grid-cols-2 gap-5">
        <div>
          <div className="flex">
            <WildberriesIcon/>
            <p className="text-18-m ml-2">Wildberries</p>
          </div>
          <div className="mt-8 flex flex-wrap items-center">
            <PrimaryButton
              text="Добавить товары"
              onClickHandler={handleClick}
              className="mr-5"
            />
            <Link to={`/connect/${wbId}`} className="blue-color text-16-r">Изменить API-токен</Link>
          </div>
        </div>

        <div className={`flex flex-wrap justify-between rounded-lg p-6 ${css.info}`}>
          {items.map(renderMarketplacePanel)}
        </div>
      </Card>
    </div>
  );
};

export default DashboardHeader;
