import { WBCharacteristicType } from "app/types";
import {
  FETCH_WB_CHARACTERISTIC_REQUEST,
  FETCH_WB_CHARACTERISTIC_SUCCESS,
  FETCH_WB_CHARACTERISTIC_FAILURE,
  FETCH_WB_CHARACTERISTICS_LIST_REQUEST,
  FETCH_WB_CHARACTERISTICS_LIST_SUCCESS,
  FETCH_WB_CHARACTERISTICS_LIST_FAILURE,
} from "../../constants/actionTypes";
import { WBCharacteristicsActionsType } from "../actions/wbCharacteristics.actions";

export type InitialWBCharacteristicsStateType = {
  error: string | null;
  list: WBCharacteristicType[] | null;
  found: WBCharacteristicType | null;
  isFetching: boolean;
};

const initialState: InitialWBCharacteristicsStateType = {
  error: null,
  list: null,
  found: null,
  isFetching: false,
};

const wbCharacteristics = (
  state: InitialWBCharacteristicsStateType = initialState,
  action: WBCharacteristicsActionsType,
): InitialWBCharacteristicsStateType => {
  switch (action.type) {
    case FETCH_WB_CHARACTERISTICS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_CHARACTERISTICS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.characteristics,
      };
    case FETCH_WB_CHARACTERISTICS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    case FETCH_WB_CHARACTERISTIC_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_CHARACTERISTIC_SUCCESS:
      return {
        ...state,
        isFetching: false,
        found: action.payload.characteristic,
      };
    case FETCH_WB_CHARACTERISTIC_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default wbCharacteristics;
