import { Spin } from "antd";
import React, { JSX, FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers/mainReducer";
import { AppDispatch } from "../../redux/store/store";
import { fetchWarehouses } from "../../redux/thunks/warehouses.thunks";

//components
import ModalWarehouse from "../../components/Modals/ModalWarehouse/ModalWarehouse";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import PrimaryButton from "../../components/ui/PrimaryButton/PrimaryButton";
import StickyHeader from "../../components/ui/StickyHeader/StickyHeader";
import WarehouseCardList from "../../components/Warehouses/WarehouseCardList/WarehouseCardList";

import { ReactComponent as GeoIcon } from "../../assets/icons/geo_icon.svg";
import css from "./style.module.css";

const WarehousesPage: FC = (): JSX.Element => {
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const { isFetching, list } = useSelector((state: AppStateType) => state.warehouses);

  useEffect(() => {
    dispatch(fetchWarehouses());
  }, [dispatch]);

  const renderAddWarehouseButton = (): JSX.Element => {
    return (
      <PrimaryButton
        text="Добавить склад"
        onClickHandler={() => setIsShowCreateModal(true)}
      />
    );
  };

  const renderWarehousesList = (): JSX.Element => {
    if (list?.length) {
      return <WarehouseCardList className="px-6" />;
    }

    return (
      <EmptyBlock
        icon={<GeoIcon className={`mb-6 ${css.icon}`} />}
        title="Складов нет"
        style={{ height: "87vh" }}
        description={<>Добавьте склад, чтобы отгружать заказы <br /> для доставки</>}
        btn={renderAddWarehouseButton()}
      />
    );
  };

  return (
    <Spin size="large" spinning={isFetching}>
      <StickyHeader
        title="Склады"
        description="Список складов, с которых вы осуществляете отгрузку заказов для последующей доставки."
        btn={renderAddWarehouseButton()}
      />

      {renderWarehousesList()}
      {isShowCreateModal && <ModalWarehouse onClose={() => setIsShowCreateModal(false)} />}
    </Spin>
  );
};

export default WarehousesPage;
