import * as process from "process";
import axios from "../utils/axios";

import { LoginDataType, RequestRegisterUserType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();
const realm: string | undefined = process.env.REACT_APP_REALM_DEV;
const clientSecret: string | undefined = process.env.REACT_APP_CLIENT_SECRET_USER;
const clientId: string = "bk-market-dev";
const grantType: string = "password";
const grantTypeRefresh: string = "refresh_token";
const scope: string = "openid";

const getToken = (): string | null => {
  return JSON.parse(window?.localStorage.getItem("authToken"));
};

const userName: string | null = window?.localStorage?.getItem("userName");

// Авторизация
export const login = async (username: string, password: string): Promise<any> => {
  const loginData: LoginDataType = {
    client_id: clientId,
    grant_type: grantType,
    client_secret: clientSecret,
    scope,
    username,
    password
  };

  return axios.POST_LOGIN(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/token`, loginData);
};

// Refresh
export const updateToken = async (refreshToken: string | null): Promise<any> => {
  const loginData: LoginDataType = {
    client_id: clientId,
    grant_type: grantTypeRefresh,
    client_secret: clientSecret,
    scope,
    refresh_token: refreshToken,
    username: userName
  };

  return axios.POST_LOGIN(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/token`, loginData);
};

// Отзыв текущего токена
export const logout = async (): Promise<any> => {
  const logoutData = {
    token: getToken(),
    client_id: clientId,
    grant_type: grantType,
    client_secret: clientSecret,
    scope,
  };

  return axios.POST_LOGIN(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/revoke`, logoutData);
};

// Расшифровка токена
export const getUserTokenDescription = async (): Promise<any> => {
  return axios.GET_KEYCLOACK(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/certs`);
};

// Получение данных об авторизованном пользователе
export const fetchUserInfo = async (): Promise<any> => {
  return axios.POST_LOGIN(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/userinfo`);
};

// Получение данных с бэка об авторизованном пользователе
export const fetchLkUserInfo = async (): Promise<any> => {
  return axios.GET_LOGIN(API_BASE, "/api/lk/userinfo");
};

// Обновление API_KEY
export const updateApiKey = async (marketplace_name: string, api_key: string): Promise<any> => {
  return axios.PUT(API_BASE, "/api/lk/marketplace_api_key", { marketplace_name, api_key });
};

// Регистрация нового пользователя
export const registerUser = async (requestBody: RequestRegisterUserType): Promise<any> => {
  return axios.POST(API_BASE, "/api/lk/register", requestBody);
};

// Сброс пароля
// characters: >= 6 matches: ^[a-zA-Z0-9@$!%*?&]+$
export const resetPassword = async (password: string): Promise<any> => {
  return axios.PUT(API_BASE, "/api/lk/reset_password", { password });
};

// Запрос ссылки восстановления пароля
export const resetPasswordEmail = async (email: string): Promise<any> => {
  return axios.PUT(API_BASE, "/api/lk/reset_password_email", { email });
};
