import {
  CardInfoType,
  ProductType,
  ProductsFacetsType,
  RequestPaginationType,
  RequestProductsFiltersType
} from "app/types";

import {
  FETCH_PRODUCTS_LIST_REQUEST,
  FETCH_PRODUCTS_LIST_SUCCESS,
  FETCH_PRODUCTS_LIST_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  CLEAR_PRODUCTS_LIST,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_REQUEST,
  UPDATE_WHOLE_PRODUCT_REQUEST,
  UPDATE_WHOLE_PRODUCT_SUCCESS,
  UPDATE_WHOLE_PRODUCT_FAILURE,
  SYNC_PRODUCTS_CARD_FAILURE,
  SYNC_PRODUCTS_CARD_SUCCESS,
  SYNC_PRODUCTS_CARD_REQUEST,
  SYNC_PRODUCTS_FAILURE,
  SYNC_PRODUCTS_SUCCESS,
  SYNC_PRODUCTS_REQUEST,
  FETCH_CARD_INFO_REQUEST,
  FETCH_CARD_INFO_SUCCESS,
  FETCH_CARD_INFO_FAILURE,
  FETCH_PRODUCTS_FACETS_REQUEST,
  FETCH_PRODUCTS_FACETS_SUCCESS,
  FETCH_PRODUCTS_FACETS_FAILURE,
} from "../../constants/actionTypes";

export type ProductsActionsType =
  | FetchProductsListRequestType
  | FetchProductsListSuccessType
  | FetchProductsListFailureType
  | FetchProductRequestType
  | FetchProductSuccessType
  | FetchProductFailureType
  | ClearProductsListType
  | AddProductRequestType
  | AddProductSuccessType
  | AddProductFailureType
  | UpdateProductRequestType
  | UpdateProductSuccessType
  | UpdateProductFailureType
  | UpdateWholeProductRequestType
  | UpdateWholeProductSuccessType
  | UpdateWholeProductFailureType
  | DeleteProductRequestType
  | DeleteProductSuccessType
  | DeleteProductFailureType
  | SyncProductsRequestType
  | SyncProductsSuccessType
  | SyncProductsFailureType
  | SyncProductsCardRequestType
  | SyncProductsCardSuccessType
  | SyncProductsCardFailureType
  | FetchCardInfoRequestType
  | FetchCardInfoSuccessType
  | FetchCardInfoFailureType
  | FetchProductsFacetsRequestType
  | FetchProductsFacetsSuccessType
  | FetchProductsFacetsFailureType
  ;

// получение списка номенклатурных позиций
type FetchProductsListRequestType = {
  type: typeof FETCH_PRODUCTS_LIST_REQUEST,
  payload: {
    pagination: RequestPaginationType;
    filters?: RequestProductsFiltersType;
  }
};

export const fetchProductsListRequest = (
  pagination: RequestPaginationType,
  filters: RequestProductsFiltersType
): FetchProductsListRequestType => ({
  type: FETCH_PRODUCTS_LIST_REQUEST,
  payload: {
    pagination,
    filters
  }
});

type FetchProductsListSuccessType = {
  type: typeof FETCH_PRODUCTS_LIST_SUCCESS;
  payload: {
    items: ProductType[];
    count: number;
  };
};

export const fetchProductsListSuccess = ({
  items,
  count,
}): FetchProductsListSuccessType => ({
  type: FETCH_PRODUCTS_LIST_SUCCESS,
  payload: {
    items,
    count,
  },
});

type FetchProductsListFailureType = {
  type: typeof FETCH_PRODUCTS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchProductsListFailure = (error: string): FetchProductsListFailureType => ({
  type: FETCH_PRODUCTS_LIST_FAILURE,
  payload: {
    error,
  },
});

// создание номенклатурной позиции
type AddProductRequestType = {
  type: typeof ADD_PRODUCT_REQUEST;
};

export const addProductRequest = (): AddProductRequestType => ({
  type: ADD_PRODUCT_REQUEST
});

type AddProductSuccessType = {
  type: typeof ADD_PRODUCT_SUCCESS;
  payload: {
    product: ProductType;
  };
};

export const addProductSuccess = (
  product: ProductType
): AddProductSuccessType => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: {
    product,
  },
});

type AddProductFailureType = {
  type: typeof ADD_PRODUCT_FAILURE;
  payload: {
    error: string;
  }
};

export const addProductFailureType = (error: string): AddProductFailureType => ({
  type: ADD_PRODUCT_FAILURE,
  payload: {
    error,
  }
});

// редактирование номенклатурной позиции
type UpdateProductRequestType = {
  type: typeof UPDATE_PRODUCT_REQUEST;
  payload: {
    product_id: string;
    params: any;
  }
};

export const updateProductRequest = (
  product_id: string,
  params: any
): UpdateProductRequestType => ({
  type: UPDATE_PRODUCT_REQUEST,
  payload: {
    product_id,
    params
  }
});

type UpdateProductSuccessType = {
  type: typeof UPDATE_PRODUCT_SUCCESS;
  payload: {
    product: ProductType;
  };
};

export const updateProductSuccess = (
  product: ProductType
): UpdateProductSuccessType => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: {
    product,
  },
});

type UpdateProductFailureType = {
  type: typeof UPDATE_PRODUCT_FAILURE;
  payload: {
    error: string;
  }
};

export const updateProductFailure = (error: string): UpdateProductFailureType => ({
  type: UPDATE_PRODUCT_FAILURE,
  payload: {
    error,
  }
});

// редактирование номенклатурной позиции целиком
type UpdateWholeProductRequestType = {
  type: typeof UPDATE_WHOLE_PRODUCT_REQUEST;
  payload: {
    product_id: string;
    params: any;
  }
};

export const updateWholeProductRequest = (
  product_id: string,
  params
): UpdateWholeProductRequestType => ({
  type: UPDATE_WHOLE_PRODUCT_REQUEST,
  payload: {
    product_id,
    params
  }
});

type UpdateWholeProductSuccessType = {
  type: typeof UPDATE_WHOLE_PRODUCT_SUCCESS;
  payload: {
    product: ProductType;
  };
};

export const updateWholeProductSuccess = (
  product: ProductType
): UpdateWholeProductSuccessType => ({
  type: UPDATE_WHOLE_PRODUCT_SUCCESS,
  payload: {
    product,
  },
});

type UpdateWholeProductFailureType = {
  type: typeof UPDATE_WHOLE_PRODUCT_FAILURE;
  payload: {
    error: string;
  }
};

export const updateWholeProductFailureType = (error: string): UpdateWholeProductFailureType => ({
  type: UPDATE_WHOLE_PRODUCT_FAILURE,
  payload: {
    error,
  }
});

// удаление номенклатурной позиции
type DeleteProductRequestType = {
  type: typeof DELETE_PRODUCT_REQUEST;
  payload: {
    product_id: string
  }
};

export const deleteProductRequest = (product_id: string): DeleteProductRequestType => ({
  type: DELETE_PRODUCT_REQUEST,
  payload: {
    product_id
  }
});

type DeleteProductSuccessType = {
  type: typeof DELETE_PRODUCT_SUCCESS;
  payload: {
    productId: string;
  };
};

export const deleteProductSuccess = (
  productId: string
): DeleteProductSuccessType => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: {
    productId,
  },
});

type DeleteProductFailureType = {
  type: typeof DELETE_PRODUCT_FAILURE;
  payload: {
    error: string;
  }
};

export const deleteProductFailureType = (error: string): DeleteProductFailureType => ({
  type: DELETE_PRODUCT_FAILURE,
  payload: {
    error,
  }
});

// получение номенклатурной позиции
type FetchProductRequestType = {
  type: typeof FETCH_PRODUCT_REQUEST,
  payload: {
    product_id: string
  }
};

export const fetchProductRequest = (product_id: string): FetchProductRequestType => ({
  type: FETCH_PRODUCT_REQUEST,
  payload: {
    product_id
  }
});

type FetchProductSuccessType = {
  type: typeof FETCH_PRODUCT_SUCCESS;
  payload: {
    product: ProductType;
  };
};

export const fetchProductSuccess = (
  product: ProductType,
): FetchProductSuccessType => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: {
    product,
  },
});

type FetchProductFailureType = {
  type: typeof FETCH_PRODUCT_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchProductFailure = (error: string): FetchProductFailureType => ({
  type: FETCH_PRODUCT_FAILURE,
  payload: {
    error,
  },
});

// синхронизация номенклатурных позиций
type SyncProductsRequestType = {
  type: typeof SYNC_PRODUCTS_REQUEST;
};

export const syncProductsRequest = (): SyncProductsRequestType => ({
  type: SYNC_PRODUCTS_REQUEST,
});

type SyncProductsSuccessType = {
  type: typeof SYNC_PRODUCTS_SUCCESS;
};

export const syncProductsSuccess = (): SyncProductsSuccessType => ({
  type: SYNC_PRODUCTS_SUCCESS,
});

type SyncProductsFailureType = {
  type: typeof SYNC_PRODUCTS_FAILURE;
  payload: {
    error: string;
  };
};

export const syncProductsFailure = (error: string): SyncProductsFailureType => ({
  type: SYNC_PRODUCTS_FAILURE,
  payload: {
    error
  }
});

// синхронизация карточки
type SyncProductsCardRequestType = {
  type: typeof SYNC_PRODUCTS_CARD_REQUEST;
};

export const syncProductsCardRequest = (): SyncProductsCardRequestType => ({
  type: SYNC_PRODUCTS_CARD_REQUEST,
});

type SyncProductsCardSuccessType = {
  type: typeof SYNC_PRODUCTS_CARD_SUCCESS;
};

export const syncProductsCardSuccess = (): SyncProductsCardSuccessType => ({
  type: SYNC_PRODUCTS_CARD_SUCCESS,
});

type SyncProductsCardFailureType = {
  type: typeof SYNC_PRODUCTS_CARD_FAILURE;
  payload: {
    error: string;
  };
};

export const syncProductsCardFailure = (error: string): SyncProductsCardFailureType => ({
  type: SYNC_PRODUCTS_CARD_FAILURE,
  payload: {
    error
  }
});

// Информация по карточке
type FetchCardInfoRequestType = {
  type: typeof FETCH_CARD_INFO_REQUEST;
  payload: {
    cardId: string;
  }
};

export const fetchCardInfoRequest = (cardId: string): FetchCardInfoRequestType => ({
  type: FETCH_CARD_INFO_REQUEST,
  payload: {
    cardId
  }
});

type FetchCardInfoSuccessType = {
  type: typeof FETCH_CARD_INFO_SUCCESS;
  payload: {
    cardInfo: CardInfoType;
  }
};

export const fetchCardInfoSuccess = (cardInfo: CardInfoType): FetchCardInfoSuccessType => ({
  type: FETCH_CARD_INFO_SUCCESS,
  payload: {
    cardInfo
  }
});

type FetchCardInfoFailureType = {
  type: typeof FETCH_CARD_INFO_FAILURE;
  payload: {
    error: string;
  }
};

export const fetchCardInfoFailure = (error: string): FetchCardInfoFailureType => ({
  type: FETCH_CARD_INFO_FAILURE,
  payload: {
    error
  }
});

// очистка
type ClearProductsListType = {
  type: typeof CLEAR_PRODUCTS_LIST;
  payload: null;
};

export const clearProductsList = (): ClearProductsListType => ({
  type: CLEAR_PRODUCTS_LIST,
  payload: null,
});

// фасеты
type FetchProductsFacetsRequestType = {
  type: typeof FETCH_PRODUCTS_FACETS_REQUEST;
  payload: {
    filters: RequestProductsFiltersType;
    tab: string
  }
};

export const fetchProductsFacetsRequest = (
  filters: RequestProductsFiltersType,
  tab?: string
): FetchProductsFacetsRequestType => ({
  type: FETCH_PRODUCTS_FACETS_REQUEST,
  payload: {
    filters,
    tab
  }
});

type FetchProductsFacetsSuccessType = {
  type: typeof FETCH_PRODUCTS_FACETS_SUCCESS;
  payload: {
    facets: ProductsFacetsType;
    tab: string;
  }
};

export const fetchProductsFacetsSuccess = (
  facets: ProductsFacetsType,
  tab?: string
): FetchProductsFacetsSuccessType => ({
  type: FETCH_PRODUCTS_FACETS_SUCCESS,
  payload: {
    facets,
    tab,
  }
});

type FetchProductsFacetsFailureType = {
  type: typeof FETCH_PRODUCTS_FACETS_FAILURE;
  payload: {
    error: string
  }
};

export const fetchProductsFacetsFailure = (
  error: string
): FetchProductsFacetsFailureType => ({
  type: FETCH_PRODUCTS_FACETS_FAILURE,
  payload: {
    error
  }
});