import React, { JSX, FC, useState, useEffect } from "react";
import * as RestsAPI from "../../../api/rests";
import { useForm } from "antd/es/form/Form";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import { fetchRests } from "../../../redux/thunks/rests.thunks";
import { ProductStatus } from "../../Products/ProductsTab/ProductsTab";

// components
import { Form, message, Select } from "antd";
import EmptyBlock from "../../EmptyBlock/EmptyBlock";
import ProductsTable, { ProductColumnType } from "../../Products/ProductsTable/ProductsTable";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";
import CustomSelect from "../CustomSelect/CustomSelect";
import { SearchInput } from "components/ui/SearchInput/SearchInput";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/chart_bar_square.svg";

import css from "../StocksTab/style.module.css";

import {
  ProductMediaType,
  RequestPaginationType,
  RequestProductsFiltersType, RequestRestsFiltersType,
  RestlessProductType, RestType
} from "app/types";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import { InitialAccountStateType } from "../../../redux/reducers/account.reducer";
import { InitialProductsStateType } from "../../../redux/reducers/products.reducer";
import { InitialRestsStateType } from "../../../redux/reducers/rests.reducer";
import { InitialWarehousesStateType } from "../../../redux/reducers/warehouses.reducer";

type WarehouseOptionType = {
  value: string;
  label: string;
};

export type ProductOptionType = {
  value: string;
  label: string;
  img: { src: string, filename: string };
};

export type SelectedValueType = {
  value: string;
  label: string;
  key?: string;
};

interface IStocksTabForm {
  warehouse: WarehouseOptionType | undefined;
  search: string | undefined;
  products: string[];
}

interface IStocksTabProps { }

const StocksTab: FC<IStocksTabProps> = (): JSX.Element => {
  const [form] = useForm<IStocksTabForm>();
  const { Item } = Form;
  const dispatch = useDispatch<AppDispatch>();
  const { setFieldsValue } = form;

  const warehouses: InitialWarehousesStateType = useSelector((state: AppStateType) => state.warehouses);
  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);
  const account: InitialAccountStateType = useSelector((state: AppStateType) => state.account);
  const rests: InitialRestsStateType = useSelector((state: AppStateType) => state.rests);

  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseOptionType | null>(null);
  const [warehousesOptions, setWarehousesOptions] = useState<WarehouseOptionType[] | null>(null);
  const [productsOptions, setProductsOptions] = useState<ProductOptionType[]>(null);
  const [isShowSelectProducts, setIsShowSelectProducts] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(true);
  const [selectedValues, setSelectedValues] = useState<SelectedValueType[]>([]);
  const [restlessProductsList, setRestlessProductsList] = useState<RestlessProductType[]>(null);
  const [filters, setFilters] = useState<RequestProductsFiltersType>({});
  const columns: ProductColumnType[] = [
    "photo",
    "barcode",
    "name",
    "integrations",
    "updated_at",
    "update_rest"
  ];

  useEffect(() => {
    warehouses.list &&
      setWarehousesOptions(
        warehouses.list.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      );
  }, [warehouses.list]);

  useEffect(() => {
    restlessProductsList &&
      setProductsOptions(
        restlessProductsList?.map(({ barcode, product_name, medias }) => {
          const photo: ProductMediaType = medias?.find(({ filename }) => /\.(jpg|png)/.test(filename));

          return {
            value: barcode,
            label: product_name,
            img: { src: photo?.attachment, filename: photo?.filename },
          };
        }),
      );
  }, [restlessProductsList]);

  useEffect(() => {
    if (!selectedWarehouse && warehousesOptions) {
      setSelectedWarehouse(warehousesOptions?.[0]);
      setFieldsValue({ warehouse: warehousesOptions?.[0] });
    }
  }, [warehouses.list]);

  useEffect(() => {
    selectedWarehouse && fetchRestlessList();
    selectedWarehouse && fetchRestsList({ page: 1, page_size: 10 }, filters);
  }, [selectedWarehouse]);

  const addProducts = async (): Promise<void> => {
    await fetchRestlessList();
    setIsShowSelectProducts(true);
  };

  const fetchRestsList = (pagination: RequestPaginationType, filters: RequestRestsFiltersType): void => {
    const warehouseId: string = selectedWarehouse?.value;
    const restsFilters = { ...filters, warehouse_id: warehouseId };

    warehouseId && dispatch(fetchRests(pagination, restsFilters));
  };

  const fetchRestlessList = async (): Promise<void> => {
    const warehouseId: string = selectedWarehouse?.value;

    await RestsAPI.restlessList({ status: ProductStatus.Synchronized, warehouse_id: warehouseId })
      .then((res) => res?.data && setRestlessProductsList(res.data));
  };

  const createStocks = async (): Promise<void> => {
    if (selectedWarehouse?.value) {
      const restsCalls = [];

      selectedValues.map(async ({ value, label }) => {
        const barcode: string = restlessProductsList?.find((product) => product.barcode === value)?.barcode;

        if (barcode) {
          restsCalls.push(RestsAPI.add({
            barcode,
            warehouse_id: selectedWarehouse.value,
            company_id: account?.userInfo?.company?.id,
            send_to_marketplace: false,
            rest: 0
          }));
        } else {
          message.error(`При добавлении товара "${label}" произошла ошибка. Попробуйте еще раз`);
        }
      });

      await Promise.all([...restsCalls])
        .then(() => {
          message.success("Товары успешно добавлены в список");
        })
        .catch(() => "При добавлении части товаров произошла ошибка");

      cancelChanges();
      fetchRestsList({ page: 1, page_size: 10 }, filters);
    } else {
      message.error("Выберите склад");
    }
  };

  const updateStock = async (product: RestType, rest: string): Promise<void> => {
    if (selectedWarehouse?.value && product.barcode) {
      await RestsAPI.add({
        barcode: product.barcode,
        warehouse_id: selectedWarehouse.value,
        company_id: account?.userInfo?.company?.id,
        send_to_marketplace: true,
        rest: parseInt(rest)
      })
        .then((res) => {
          if (res.status === 200) {
            message.success("Остаток успешно обновлен");
          }
        })
        .catch(() => message.error("Произошла ошибка при обновлении остатка, повторите позднее"));
    } else {
      message.error("Выберите склад");
    }
  };

  const cancelChanges = (): void => {
    setSelectedValues([]);
    setProductsOptions(null);
    setIsShowSelectProducts(false);
    setDropdownVisible(true);
    setFieldsValue({ products: [] });
  };

  const renderSearch = (): JSX.Element => {
    return (
      <Form form={form} layout="inline" className={css.searchWrap}>
        <Item name="warehouse" className={css.warehouses} style={{ marginRight: 0 }}>
          <Select
            size="large"
            options={warehousesOptions}
            placeholder="Выберите склад"
            onSelect={(_value: string, option: WarehouseOptionType) => setSelectedWarehouse(option)}
            value={warehousesOptions?.[0]?.value}
            loading={warehouses.isFetching || products.isFetching}
          />
        </Item>
        {!isShowSelectProducts ? (
          <Item className={css.searchInput} name="search" style={{ marginRight: 0 }}>
            <SearchInput
              placeholder="Найти по названию или баркоду"
              onSearch={(state: RequestProductsFiltersType) => setFilters(state)}
              filters={filters}
              isDisabled={!selectedWarehouse}
            />
          </Item>
        ) : (
          <CustomSelect
            productsOptions={productsOptions}
            dropdownVisible={dropdownVisible}
            selectedValues={selectedValues}
            setDropdownVisible={setDropdownVisible}
            setSelectedValues={setSelectedValues}
          />
        )}
        <PrimaryButton
          text={!isShowSelectProducts ? "Добавить товары" : "Подтвердить"}
          onClickHandler={() => (!isShowSelectProducts ? addProducts() : createStocks())}
          isDisabled={warehouses.isFetching
            || !selectedWarehouse
            || (isShowSelectProducts && selectedValues?.length === 0)
          }
        />
        {/* TODO: Вернуть как будут фильтры */}
        {/* <GhostButton
          size="large"
          text={!isShowSelectProducts ? "Фильтры" : "Отменить"}
          {...(!isShowSelectProducts ? { icon: <FilterIcon /> } : {})}
          onClickHandler={() => (isShowSelectProducts ? cancelChanges() : {})}
          isDisabled={warehouses.isFetching || !isShowSelectProducts}
        /> */}
      </Form>
    );
  };

  const renderEmptyBlock = (): JSX.Element => (
    <EmptyBlock
      icon={<EmptyIcon className={`mb-6 ${css.emptyIcon}`} />}
      style={{ height: "calc(100vh - 23rem)" }}
      title="Товаров нет"
      description="Для управления остатками необходимо добавить товары"
    />
  );

  return <div className="flex flex-col w-full h-full">
    <div className={css.controlsWrap}>{renderSearch()}</div>
    <ProductsTable
      columns={columns}
      list={rests.list}
      count={rests.count}
      fetchList={fetchRestsList}
      isFetching={rests.isFetching}
      filters={filters}
      onUpdateRest={updateStock}
      emptyBlock={renderEmptyBlock()}
      isShowEmptyBlock={true}
      emptyExcludedFilters={["status", "marketplace"]}
    />
  </div>;
};

export default StocksTab;
