import { DashboardType, OrderType, OrdersFacetsType } from "app/types";
import {
  FETCH_ORDERS_LIST_FAILURE,
  FETCH_ORDERS_LIST_REQUEST,
  FETCH_ORDERS_LIST_SUCCESS,
  FETCH_ORDERS_FACETS_SUCCESS,
  FETCH_ORDERS_FACETS_FAILURE,
  CLEAR_ORDERS_LIST,
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from "../../constants/actionTypes";
import { OrdersActionsType } from "../actions/orders.actions";

export type InitialOrdersStateType = {
  error: string | null;
  list: OrderType[] | null;
  count: number;
  found: OrderType | null;
  dashboard: DashboardType;
  facets: OrdersFacetsType | null;
  isFetching: boolean;
};

const initialState: InitialOrdersStateType = {
  error: null,
  list: null,
  count: 0,
  found: null,
  dashboard: null,
  facets: null,
  isFetching: false,
};

const orders = (
  state: InitialOrdersStateType = initialState,
  action: OrdersActionsType,
): InitialOrdersStateType => {
  switch (action.type) {
    // Список сборочных заданий
    case FETCH_ORDERS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.items,
        count: action.payload.count,
        error: null,
      };
    case FETCH_ORDERS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    case FETCH_DASHBOARD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        dashboard: action.payload.dashboard
      };
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    // Очистка
    case CLEAR_ORDERS_LIST:
      return {
        ...state,
        list: action.payload,
      };

    // Фасеты
    case FETCH_ORDERS_FACETS_SUCCESS:
      return {
        ...state,
        facets: {
          ...state.facets,
          ...(action.payload.tab
            ? { [action.payload.tab]: action.payload.facets[action.payload.tab] }
            : action.payload.facets
          )
        }
      };
    case FETCH_ORDERS_FACETS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export default orders;
