import React, { JSX, FC, useState, useEffect, Key, useRef } from "react";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { AppDispatch } from "redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchSuppliesList, fetchSupplyDeliver } from "redux/thunks/supplies.thunks";
import * as OrdersAPI from "../../../api/orders.api";
import { generateSelectionListData } from "../generateSelectionListData";
import { SelectionListColumnType, SelectionListItemType } from "../Supply/SupplyWrapper";
import generateSortString from "utils/generateSortString";

import css from "./style.module.css";

// icons
import { ReactComponent as FilterIcon } from "../../../assets/icons/page_info_icon.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/three_dots_icon.svg";

// components
import { Dropdown, MenuProps, message, Space } from "antd";
import PrimaryButton from "../../../components/ui/PrimaryButton/PrimaryButton";
import GhostButton from "../../../components/ui/GhostButton/GhostButton";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/chart_bar_square.svg";
import SupplyTable, { TableColumnType } from "../SupplyTable/SupplyTable";
import QRList from "../QRList/QRList";
import SelectionList from "../SelectionList/SelectionList";
import EmptyBlock from "components/EmptyBlock/EmptyBlock";
import { Tab } from "../TabsWithCounters/TabsWithCounters";
import { SearchInput } from "components/ui/SearchInput/SearchInput";

import type { AppStateType } from "../../../redux/reducers/mainReducer";
import type { InitialSuppliesStateType } from "redux/reducers/supplies.reducer";
import {
  OrderType,
  RequestPaginationType,
  RequestSuppliesFiltersType,
  StickerType,
  SupplyType
} from "app/types";
import { SorterResult, TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";

type CurrentSupplyType = {
  supplyId: string;
  name: string;
  createdAt: string;
};

interface ISuppliesTab {
  selectedTab?: Tab;
  initFilters?: RequestSuppliesFiltersType;
  initColumns?: TableColumnType[];
  description?: string;
}

const columns: SelectionListColumnType[] = [
  { label: "Номер задания", field: "id_in_marketplace" },
  { label: "Фото товара", field: "photo" },
  { label: "Бренд", field: "brand" },
  { label: "Название товара", field: "name" },
  { label: "Размер", field: "size" },
  { label: "Цвет", field: "color" },
  { label: "Артикул поставщика", field: "vendor_code" },
  { label: "ШК товара (этикетка)", field: "sticker" },
  { label: "Баркод", field: "barcode" },
];

const SuppliesTab: FC<ISuppliesTab> = ({
  selectedTab,
  initFilters,
  initColumns,
  description
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const supplies: InitialSuppliesStateType = useSelector((state: AppStateType) => state.supplies);

  const selectionListRef = useRef();
  const qrOrderRef = useRef();
  const qrSupplyRef = useRef();

  const [filters, setFilters] = useState<RequestSuppliesFiltersType>(initFilters);
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>(null);
  const [sorterInfo, setSorterInfo] = useState<SorterResult<any> | SorterResult<any>[]>(null);
  const [selectedRows, setSelectedRows] = useState<SupplyType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectionListData, setSelectionListData] = useState<SelectionListItemType[]>(null);
  const [QROrderLinks, setQROrderLinks] = useState<string[]>(null);
  const [QRSupplyLinks, setQRSupplyLinks] = useState<string[]>(null);
  const [currentSupply, setCurrentSupply] = useState<CurrentSupplyType>({
    supplyId: "",
    name: "",
    createdAt: ""
  });

  const showAddToDeliveryButton: boolean = !initFilters?.is_done;
  const showPrintQRButton: boolean = initFilters?.is_done;
  const [emptyText, setEmptyText] = useState<string>(null);

  useEffect(() => {
    const text: string = "Здесь появятся сборочные задания в статусе";

    if (selectedTab === Tab.onAssembly) {
      setEmptyText(`${text} сборки`);
    } else {
      setEmptyText(`${text} доставки`);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (selectionListData && isPrinting) {
      handlePrintSelectionList();
    }
  }, [selectionListData, isPrinting]);

  useEffect(() => {
    if (QROrderLinks && isPrinting) {
      handlePrintQROrder();
    }
  }, [QROrderLinks]);

  useEffect(() => {
    if (QRSupplyLinks && isPrinting) {
      handlePrintQRSupply();
    }
  }, [QRSupplyLinks]);

  const fetchSupplies = (pagination: RequestPaginationType, filters: RequestSuppliesFiltersType = {}): void => {
    dispatch(fetchSuppliesList(pagination, filters));
  };

  const printSelectionList = async (supply: SupplyType) => {
    setIsLoading(true);

    const selectionListItems: SelectionListItemType[] = await generateSelectionListData(supply.orders_count, supply.id);

    setCurrentSupply({
      supplyId: supply.supply_id,
      name: supply.name,
      createdAt: supply.created_at
    });

    setSelectionListData(selectionListItems);
    setIsPrinting(true);
  };

  const printQROrders = async (page_size: number, supply_id: string) => {
    setIsLoading(true);

    const ordersAllResponse = await OrdersAPI.list({ page: 1, page_size }, { supply_id });

    const ordersAll: OrderType[] = ordersAllResponse?.data?.items;
    const orderIds: string[] = ordersAll?.map((order) => order.id);

    await fetchStickers(orderIds);

    setIsPrinting(true);
  };

  const printQRSupply = (supplyIds: string[]) => {
    setIsLoading(true);
    setQRSupplyLinks(supplyIds);
    setIsPrinting(true);
  };

  const fetchStickers = async (orderIds: string[]): Promise<void> => {
    const response = await OrdersAPI.getStickers({ order_ids: orderIds, type: "png", size: "size400x300" })
      .catch((error) => {
        setIsLoading(false);
        message.error(error?.response?.data?.error);
      });

    setQROrderLinks(response?.data
      ?.filter((sticker: StickerType) => !sticker.error)
      ?.map((sticker: StickerType) => sticker.file)
    );
  };

  const handlePrintSelectionList = useReactToPrint({
    documentTitle: "Лист подбора",
    content: () => selectionListRef.current,
    onAfterPrint: () => {
      setIsPrinting(false);
      setIsLoading(false);
      setSelectionListData(null);
      setCurrentSupply({ supplyId: "", name: "", createdAt: "" });
    },
  });

  const handlePrintQROrder = useReactToPrint({
    documentTitle: "Маркировочные QR-коды",
    content: () => qrOrderRef.current,
    onAfterPrint: () => {
      setIsPrinting(false);
      setIsLoading(false);
      setQROrderLinks(null);
    },
  });

  const handlePrintQRSupply = useReactToPrint({
    documentTitle: "QR-код поставки",
    content: () => qrSupplyRef.current,
    onAfterPrint: () => {
      setIsPrinting(false);
      setIsLoading(false);
      setQRSupplyLinks(null);
    },
  });

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: Key[], selectedRows: SupplyType[]) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  // Actions

  // TODO: Доделать как будут ручки
  const handleMenuCancelTheTask = (supply: SupplyType): void => {
    console.log("click", supply);
  };

  const handleSupplyDeliver = (supplies: SupplyType[]): void => {
    try {
      dispatch(fetchSupplyDeliver(
        supplies.map((supply: SupplyType) => supply?.id)
      ))
        .catch((error) => message.error(error));

      dispatch(fetchSuppliesList({
        page: paginationInfo.current,
        page_size: paginationInfo.pageSize,
        ...(sorterInfo ? { ordering: generateSortString(sorterInfo) } : {}),
      }, filters));
    } catch (error) {
      console.log(error);
    }
  };

  const renderSearch = (): JSX.Element => {
    return (
      <div className={css.searchWrap}>
        <SearchInput
          placeholder="Найти по артикулу или названию"
          onSearch={(state: RequestSuppliesFiltersType) => setFilters(state)}
          filters={filters}
          isDisabled={supplies.isFetching && !supplies.list}
        />
        <GhostButton
          size="large"
          text="Фильтры"
          icon={<FilterIcon />}
          onClickHandler={() => { }} // TODO: Доделать как будут фильтры по сборочным заданиям
          isDisabled={supplies.isFetching}
        />
      </div>
    );
  };

  const renderPageActions = (): JSX.Element => {
    const numberOfItems: string = selectedKeys?.length ? `(${selectedKeys.length})` : "";

    const hasIds: boolean = selectedRows?.every((supply: SupplyType) => !!supply?.id) ?? false;
    const isDisabled: boolean = !(selectedRows?.length > 0) || !hasIds;

    return (
      <div className={css.actionsWrap}>
        {showAddToDeliveryButton && (
          <PrimaryButton
            size="large"
            text={`Передать в доставку ${numberOfItems}`}
            onClickHandler={() => handleSupplyDeliver(selectedRows)}
            isDisabled={isDisabled}
          />
        )}
        {showPrintQRButton && (
          <PrimaryButton
            size="large"
            text={`Распечатать QR-код поставки ${numberOfItems}`}
            onClickHandler={() => {
              const ids: string[] = selectedRows.map(({ supply_qr }) =>
                supply_qr?.map(({ file }) => file))?.flat();

              printQRSupply(ids);
            }}
            isDisabled={isDisabled}
          />
        )}
      </div>
    );
  };

  const renderOrderAction = (_value: any, record: SupplyType, idx: number): React.ReactNode => {
    const isDisabled: boolean = !record?.id || isLoading || record.orders_count === 0;
    const qrSupplyLinks: string[] = record?.supply_qr?.map(({ file }) => file);

    const items: MenuProps["items"] = initFilters?.is_done ? [
      {
        label: (
          <a onClick={() => printQRSupply(qrSupplyLinks)}>
            Распечатать QR-код поставки
          </a>
        ),
        key: `print-QR-${idx}`,
        disabled: isDisabled
      },
    ] : [
      {
        label: <a onClick={() => handleSupplyDeliver([record])}>Передать в доставку</a>,
        key: `assemble-${idx}`,
        disabled: isDisabled
      },
      {
        label: <a onClick={() => printSelectionList(record)}>Распечатать лист подбора</a>,
        key: `print-sheet-${idx}`,
        disabled: isDisabled
      },
      {
        label: <a onClick={() => printQROrders(record.orders_count, record.id)}>Распечатать маркировку</a>,
        key: `print-marking-${idx}`,
        disabled: isDisabled
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  const renderEmptyBlock = (): JSX.Element => (
    <EmptyBlock
      icon={<EmptyIcon className={`mb-6 ${css.emptyIcon}`} />}
      style={{ height: "calc(100vh - 23rem)" }}
      title={`Заданий ${selectedTab?.toLowerCase() ?? ""} нет`}
      description={emptyText}
    />
  );

  const cursorStyle: string = isLoading ? "cursor-wait" : "cursor-default";

  return (
    <Space className={`flex ${cursorStyle}`} direction="vertical">
      {description && (
        <p className="text-14-r secondary-color mb-3">{description}</p>
      )}
      <div className={css.controlsWrap}>
        {renderSearch()}
        {renderPageActions()}
      </div>
      <SupplyTable
        columns={initColumns}
        list={supplies.list}
        count={supplies.count}
        fetchList={fetchSupplies}
        isFetching={supplies.isFetching}
        rowSelection={rowSelection}
        renderActions={renderOrderAction}
        filters={filters}
        setPaginationInfo={setPaginationInfo}
        setSorterInfo={setSorterInfo}
        emptyBlock={renderEmptyBlock()}
        isShowEmptyBlock={true}
        emptyExcludedFilters={Object.keys(initFilters)}
      />
      <div className="hidden">
        {selectionListData && (
          <SelectionList
            ref={selectionListRef}
            columns={columns}
            data={[selectionListData]}
            createdAt={dayjs(currentSupply?.createdAt).format("DD.MM.YY")}
            name={`Поставка №${currentSupply?.supplyId ?? ""} ${currentSupply?.name ?? ""}`}
          />
        )}
        <QRList
          ref={QROrderLinks ? qrOrderRef : qrSupplyRef}
          links={QROrderLinks ?? QRSupplyLinks}
          title={QROrderLinks ? "Маркировочные QR-коды" : "QR-код поставки"}
        />
      </div>
    </Space>
  );
};

export default SuppliesTab;
