// Проверка на наличие выбранных фильтров (переданные string[] будет пропускать при проверке)
export const checkObjIsEmpty = (obj: any, excludedKeys: string[] = []): boolean => {
  if (!obj) {
    return true;
  }

  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)
      && obj[prop] !== null
      && obj[prop] !== undefined
      && obj[prop] !== ""
      && !excludedKeys.includes(prop)
    ) {
      return false;
    }
  }
  return true;
};