import React, { JSX, FC } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import WarehouseCard from "../WarehouseCard/WarehouseCard";

interface IWarehouseCardListProps {
  className?: string;
}

const WarehouseCardList: FC<IWarehouseCardListProps> = ({
  className = ""
}): JSX.Element => {
  const { list } = useSelector((state: AppStateType) => state.warehouses);

  return (
    <div
      className={`grid grid-cols-3 mt-8 mb-36 ${className}`}
      style={{ gap: "18px" }}
    >
      {list?.map(({ id, name, comment, office_ids, sync_status, sync_at }) => (
        <WarehouseCard
          key={id}
          id={id}
          title={name}
          offices={office_ids}
          comment={comment}
          sync_status={sync_status}
          sync_at={sync_at}
        />
      ))}
    </div>
  );
};

export default WarehouseCardList;
