import React, { JSX, FC, useEffect } from "react";
import { ProductType, WBCharacteristicType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance, notification } from "antd";
import { InitialAccountStateType } from "../../../../redux/reducers/account.reducer";
import { AppStateType } from "../../../../redux/reducers/mainReducer";
import { InitialProductsStateType } from "../../../../redux/reducers/products.reducer";
import { InitialWBColorsStateType } from "../../../../redux/reducers/wbColors.reducer";
import { InitialWBCountriesStateType } from "../../../../redux/reducers/wbCountries.reducer";
import { InitialWBKindsStateType } from "../../../../redux/reducers/wbKinds.reducer";
import { InitialWBSeasonsStateType } from "../../../../redux/reducers/wbSeasons.reducer";
import { InitialWBTnvedsStateType } from "../../../../redux/reducers/wbTnveds.reducer";
import { AppDispatch } from "../../../../redux/store/store";
import { addProduct } from "../../../../redux/thunks/products.thunks";
import { fetchWBColors } from "../../../../redux/thunks/wbColors.thunks";
import { fetchWBCountries } from "../../../../redux/thunks/wbCountries.thunks";
import { fetchWBKinds } from "../../../../redux/thunks/wbKinds.thunks";
import { fetchWBSeasons } from "../../../../redux/thunks/wbSeasons.thunks";
import { fetchWBTnveds } from "../../../../redux/thunks/wbTnveds.thunks";

import CustomInput from "../../../ui/CustomInput/CustomInput";
import Characteristic from "./Characteristic";

interface IProductInfoBlockProps {
  form: FormInstance;
  characteristics: WBCharacteristicType[];
  isDisabledFields: boolean;
  productCardId: string;
  setProductCardId: React.Dispatch<React.SetStateAction<string>>;
  nomenclatureId: string;
  setNomenclatureId: React.Dispatch<React.SetStateAction<string>>;
  setIsDisabledFields: React.Dispatch<React.SetStateAction<boolean>>;
  updateCharc: (fieldName: string) => void;
}

export type SelectOptionType = {
  label: React.ReactNode,
  value: React.ReactNode
};

const ProductInfoBlock: FC<IProductInfoBlockProps> = ({
  form,
  characteristics,
  isDisabledFields,
  productCardId,
  setProductCardId,
  nomenclatureId,
  setNomenclatureId,
  setIsDisabledFields,
  updateCharc,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const wbCountries: InitialWBCountriesStateType = useSelector((state: AppStateType) => state.wbCountries);
  const wbKinds: InitialWBKindsStateType = useSelector((state: AppStateType) => state.wbKinds);
  const wbSeasons: InitialWBSeasonsStateType = useSelector((state: AppStateType) => state.wbSeasons);
  const wbTnveds: InitialWBTnvedsStateType = useSelector((state: AppStateType) => state.wbTnveds);
  const wbColors: InitialWBColorsStateType = useSelector((state: AppStateType) => state.wbColors);
  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);
  const account: InitialAccountStateType = useSelector((state: AppStateType) => state.account);

  const currentProduct: ProductType = products.list?.find((product) => product.id === nomenclatureId);

  useEffect(() => {
    !wbCountries?.list && dispatch(fetchWBCountries());
  }, [dispatch]);

  useEffect(() => {
    !wbKinds?.list && dispatch(fetchWBKinds());
  }, [dispatch]);

  useEffect(() => {
    !wbSeasons?.list && dispatch(fetchWBSeasons());
  }, [dispatch]);

  useEffect(() => {
    !wbTnveds?.list && dispatch(fetchWBTnveds());
  }, [dispatch]);

  useEffect(() => {
    !wbColors?.list && dispatch(fetchWBColors());
  }, [dispatch]);

  const hasSize: boolean = characteristics?.some((charc) => charc.name === "Размер");
  const hasRusSize: boolean = characteristics?.some((charc) => charc.name === "Рос. размер");
  const category = form.getFieldValue("category");
  const categoryId = category && category[category?.length - 1];

  const nameFieldOnBlurHandler = (): void => {
    const name = form.getFieldValue("name");

    if (name && !nomenclatureId) {
      // запрос на создание НП
      dispatch(addProduct({
        name,
        ...(productCardId ? {card_id: productCardId} : {}),
        category_id: categoryId,
        company_id: account?.userInfo?.company?.id,
      }))
        .then((res: any) => {
          if (res) {
            setProductCardId(res?.card_id);
            setNomenclatureId(res?.id);
            if (!productCardId) {
              notification.success({
                message: "Карточка товара создана и сохранена в черновики",
                duration: 5,
                placement: "topRight"
              });
            }
            setIsDisabledFields(false);

          }
        });
    } else if (nomenclatureId) {
      updateCharc("name");
    } else {
      // если удалили название
      setIsDisabledFields(true);
    }
  };

  return (
    <div>
      <CustomInput
        type="input"
        title="Наименование товара"
        form={form}
        showCount
        maxLength={60}
        required
        fieldName="name"
        placeholder="Введите наименование товара"
        onBlurHandler={nameFieldOnBlurHandler}
      />
      <CustomInput
        type="input"
        title="Артикул продавца"
        form={form}
        required
        isDisabled={isDisabledFields}
        fieldName="vendor_code"
        placeholder="Введите артикул продавца"
        onBlurHandler={() => updateCharc("vendor_code")}
      />
      <CustomInput
        type="textArea"
        title="Описание товара"
        form={form}
        showCount
        isDisabled={isDisabledFields}
        maxLength={2000}
        fieldName="description"
        placeholder="Введите описание товара"
        onBlurHandler={() => updateCharc("description")}
      />
      {characteristics
        ?.filter((charc: WBCharacteristicType) => charc.name !== "Размер" && charc.name !== "Рос. размер")
        ?.map((charc: WBCharacteristicType) => (
          <Characteristic
            key={charc.id}
            charc={charc}
            form={form}
						updateCharc={updateCharc}
            nomenclatureId={nomenclatureId}
            isDisabledFields={isDisabledFields}
          />
        ))}

      <div className={`grid ${!hasSize && !hasRusSize ? "grid-cols-1" : "grid-cols-3"} gap-3`}>
        {hasSize && (
          <CustomInput
            type="input"
            title="Размер"
            form={form}
            fieldName="tech_size"
            placeholder="Размер"
            isDisabled={isDisabledFields}
            onBlurHandler={() => updateCharc("tech_size")}
          />
        )}
        {hasRusSize && (
          <CustomInput
            type="input"
            title="Рос. размер"
            form={form}
            fieldName="wb_size"
            placeholder="Рос. размер"
            isDisabled={isDisabledFields}
            onBlurHandler={() => updateCharc("wb_size")}
          />
        )}
        <CustomInput
          type="input"
          form={form}
          title="Баркод"
          required
          fieldName="barcode"
          showCount
          isDisabled={isDisabledFields}
          maxLength={13}
          onBlurHandler={() => updateCharc("barcode")}
          placeholder="0000000000000"
          tooltipTitle={`При создании баркода убедитесь, что он состоит из английских букв, 
        цифр и следующих символов: / * - + @ № % & $ ! = ( ) { } [ ]. Максимальная длина баркода - 13 символов`}
          pattern={{
            pattern: /^[a-zA-Z0-9/*+\-@№%&$!=(){}[\]]+$/,
            message: `Баркод может содержать английские буквы, цифры и
            символы: / * - + @ № % & $ ! = ( ) { } [ ]`
          }}
        />
      </div>
    </div>
  );
};

export default ProductInfoBlock;
