import {
  RestType,
  RequestPaginationType,
  RequestRestsFiltersType
} from "app/types";
import {
  FETCH_RESTS_LIST_FAILURE,
  FETCH_RESTS_LIST_REQUEST,
  FETCH_RESTS_LIST_SUCCESS
} from "../../constants/actionTypes";

export type RestsActionsType =
  | FetchRestsListRequestType
  | FetchRestsListSuccessType
  | FetchRestsListFailureType

// Получение списка остатков по складу
type FetchRestsListRequestType = {
  type: typeof FETCH_RESTS_LIST_REQUEST,
  payload: {
    pagination: RequestPaginationType;
    filters?: RequestRestsFiltersType;
  }
};

export const fetchRestsListRequest = (
  pagination: RequestPaginationType,
  filters: RequestRestsFiltersType
): FetchRestsListRequestType => ({
  type: FETCH_RESTS_LIST_REQUEST,
  payload: {
    pagination,
    filters
  }
});

type FetchRestsListSuccessType = {
  type: typeof FETCH_RESTS_LIST_SUCCESS,
  payload: {
    items: RestType[];
    count: number;
  }
};

export const fetchRestsListSuccess = ({
  items,
  count,
}): FetchRestsListSuccessType => ({
  type: FETCH_RESTS_LIST_SUCCESS,
  payload: {
    items,
    count,
  },
});

type FetchRestsListFailureType = {
  type: typeof FETCH_RESTS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchRestsListFailure = (error: string): FetchRestsListFailureType => ({
  type: FETCH_RESTS_LIST_FAILURE,
  payload: {
    error,
  },
});
