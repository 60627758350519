import * as WBCharacteristicsAPI from "../../api/wbCharacteristics.api";
import { ThunkAction } from "redux-thunk";
import {
  fetchWBCharacteristicRequest,
  fetchWBCharacteristicSuccess,
  fetchWBCharacteristicFailure,
  fetchWBCharacteristicsListRequest,
  fetchWBCharacteristicsListSuccess,
  fetchWBCharacteristicsListFailure,
  WBCharacteristicsActionsType,
} from "../actions/wbCharacteristics.actions";
import { AppStateType } from "../reducers/mainReducer";
import { FilterWBCharacteristicsType } from "app/types";

// получение списка WB характеристик
export const fetchWBCharacteristics = (params: FilterWBCharacteristicsType | null = null):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WBCharacteristicsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBCharacteristicsListRequest());
    try {
      const response = await WBCharacteristicsAPI.list(params);

      dispatch(fetchWBCharacteristicsListSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBCharacteristicsListFailure(error?.response?.data?.error));
    }
  };

// получение WB характеристики
export const fetchWBCharacteristic = (characteristicId: string):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WBCharacteristicsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBCharacteristicRequest());
    try {
      const response = await WBCharacteristicsAPI.getItem(characteristicId);

      dispatch(fetchWBCharacteristicSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBCharacteristicFailure(error?.response?.data?.error));
    }
  };
