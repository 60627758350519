import * as OrdersAPI from "../../api/orders.api";
import { ThunkAction } from "redux-thunk";
import {
  OrdersActionsType,
  fetchOrdersListRequest,
  fetchOrdersListSuccess,
  fetchOrdersListFailure,
  clearOrdersList,
  uploadNewOrdersRequest,
  uploadNewOrdersSuccess,
  uploadNewOrdersFailure,
  fetchDashboardRequest,
  fetchDashboardSuccess,
  fetchDashboardFailure,
  fetchOrdersFacetsFailure,
  fetchOrdersFacetsRequest,
  fetchOrdersFacetsSuccess,
} from "../actions/orders.actions";

import type { AppStateType } from "../reducers/mainReducer";
import type {
  RequestDashboardType,
  RequestOrdersFiltersType,
  RequestPaginationType
} from "app/types";

// получение списка сборочных заданий с пагинацией, фильтрацией и поиском
export const fetchOrders = (
  pagination: RequestPaginationType,
  filters?: RequestOrdersFiltersType,
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsType
> => async (dispatch) => {
  dispatch(fetchOrdersListRequest(pagination, filters ?? {}));
  try {
    const response = await OrdersAPI.list(pagination, filters ?? {});

    dispatch(fetchOrdersListSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchOrdersListFailure(error?.response?.data?.error));
  }
};

// Очистка списка сборочных заданий
export const clearOrders = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsType
> => async (dispatch) => {
  dispatch(clearOrdersList());
};

// Загрузка новых сборочных заданий из маркетплейсов
export const uploadNewOrders = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsType
> => async (dispatch) => {
  dispatch(uploadNewOrdersRequest());

  try {
    await OrdersAPI.uploadNew();

    dispatch(uploadNewOrdersSuccess());
  } catch (error: any) {
    dispatch(uploadNewOrdersFailure(error));
  }
};

// Получение данных для дашборда
export const fetchDashboard = (params: RequestDashboardType): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsType
> => async (dispatch) => {
  dispatch(fetchDashboardRequest());

  try {
    const response = await OrdersAPI.getDashboard(params);

    dispatch(fetchDashboardSuccess(response?.data));

  } catch (error: any) {
    dispatch(fetchDashboardFailure(error));
  }
};

// Фасеты
export const fetchOrdersFacets = (
  filters: RequestOrdersFiltersType,
  tab?: string
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsType
> => async (dispatch) => {
  dispatch(fetchOrdersFacetsRequest(filters, tab));
  try {
    const response = await OrdersAPI.facets(filters);

    dispatch(fetchOrdersFacetsSuccess(response?.data, tab));
  } catch (error) {

    dispatch(fetchOrdersFacetsFailure(error));
  }
};