import { useState, useEffect } from "react";

const useScrollingHeader = (): "down" | "up" => {
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">(null);

  useEffect(() => {
    let lastScrollY: number = window.scrollY;

    const updateScrollDirection = (): void => {
      const scrollY: number = window.scrollY;
      const direction: "down" | "up" = scrollY > lastScrollY ? "down" : "up";

      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  return scrollDirection;
};

export default useScrollingHeader;
