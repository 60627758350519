import React, { JSX, FC, useState, useEffect, useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import { calculateTimeRemaining, ITimeRemaining } from "hooks/useTimeRemaining";
import generateSortString from "utils/generateSortString";
import { checkObjIsEmpty } from "utils/checkObjIsEmpty";

import { getFirstImage } from "../getFirstImage";
import css from "./style.module.css";

// components
import { Input, Space, Table, Tag, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import GhostButton from "components/ui/GhostButton/GhostButton";
import ImageContainer from "components/ui/ImageContainer/ImageContainer";
import { ReactComponent as DraftStatusIcon } from "../../../assets/icons/draft_icon.svg";
import { ReactComponent as WildberriesIcon } from "../../../assets/icons/wildberries_icon.svg";
import { SyncOutlined as InProgressStatusIcon } from "@ant-design/icons";
import { ReactComponent as FailStatusIcon } from "../../../assets/icons/warning_circle_icon.svg";

import { ProductStatus } from "../ProductsTab/ProductsTab";
import { TableRowSelection, TablePaginationConfig, SorterResult } from "antd/es/table/interface";
import {
  BasicEntityType,
  RestType,
  ProductType,
  RequestPaginationType,
  RequestProductsFiltersType,
  RequestRestsFiltersType,
  WBCharacteristicValueType,
  OrderType,
  WBStatusesType,
} from "app/types";

const wbStatuses = {
  waiting: "В работе",
  sorted: "Отсортировано",
  sold: "Продано",
  canceled: "Отмена",
  canceled_by_client: "Отказ покупателем",
  defect: "Брак",
  ready_for_pickup: "Прибыл на ПВЗ"
};

// Типы колонок у таблицы
export type ProductColumnType =
  | "photo"
  | "barcode"
  | "category"
  | "name"
  | "brand"
  | "updated_at"
  | "color"
  | "size"
  | "integrations"
  | "update_price"
  | "update_rest"
  | "actions"
  ;

export type OrderColumnType =
  | "due_date"
  | "created_at"
  | "order_number"
  | "warehouse"
  | "cost"
  | "shipping_method"
  | "sticker_number"
  | "time_spent"
  | "status"
  ;

export type TableColumnType = ProductColumnType | OrderColumnType;

interface IRenderProduct extends ProductType {
  key: string;
}

interface IRenderOrder extends OrderType {
  key: string;
}

type ItemRenderType = IRenderProduct | IRenderOrder;

type FiltersType = RequestProductsFiltersType | RequestRestsFiltersType;

type ProductEntityType = ProductType | RestType | OrderType;

interface IProductsTableProps {
  columns: TableColumnType[];
  list?: ProductEntityType[];
  count?: number;
  fetchList?: (pagination: RequestPaginationType, filters: FiltersType) => void;
  isFetching?: boolean;
  renderActions?: (value: any, record: any, idx: number) => React.ReactNode;
  rowSelection?: TableRowSelection<any>;
  filters?: FiltersType;
  setPaginationInfo?: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setSorterInfo?: React.Dispatch<React.SetStateAction<SorterResult<any> | SorterResult<any>[]>>;
  onUpdatePrice?: (product: ProductType, price: string) => void; // 
  onUpdateRest?: (product: RestType, rest: string) => void;
  emptyBlock?: React.ReactNode; // Пустой блок: isFetching + (list.length = 0) + (count = 0) + (isShowEmptyBlock = true)
  isShowEmptyBlock?: boolean; // Показывать пустой блок и тут можно свою проверку || true (см. выше)
  emptyExcludedFilters?: string[]; // Какие фильтры не учитывать в пустом блоке
  className?: string;
}

const ProductsTable: FC<IProductsTableProps> = ({
  columns,
  list,
  count,
  fetchList,
  isFetching,
  rowSelection,
  renderActions,
  filters,
  setPaginationInfo,
  setSorterInfo,
  onUpdatePrice, // TODO: По хорошему вынести дополнительные действия в renderActions
  onUpdateRest,
  emptyBlock,
  isShowEmptyBlock = false,
  emptyExcludedFilters = [],
  className = "",
}) => {
  const navigate: NavigateFunction = useNavigate();

  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const [productsList, setProductsList] = useState<ItemRenderType[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: count || 0,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [sorter, setSorter] = useState<SorterResult<any> | SorterResult<any>[]>(null);
  const [inputPrices, setInputPrices] = useState<{ [productId: string]: string }>({});
  const [inputStocks, setInputStocks] = useState<{ [productId: string]: string }>({});

  const getRequestPagination = (): RequestPaginationType => ({
    page: pagination.current,
    page_size: pagination.pageSize,
    ...(sorter ? { ordering: generateSortString(sorter) } : {}),
  });

  useEffect(() => {
    if (!isFetching && !!fetchList) {
      fetchList(getRequestPagination(), filters);
    }
  }, [filters, pagination.current, pagination.pageSize, generateSortString(sorter)]);

  useEffect(() => {
    setPagination((prevState) => ({ ...prevState, current: 1, total: count ?? 0 }));
  }, [count]);

  // Проверка на пустой блок только при смене isFetching
  // Другие решения могут вызывать лишний re render
  useEffect(() => {
    !isFetching && setShowEmpty(
      !isFetching
      && !(list?.length > 0)
      && (count ?? 0) === 0
      && checkObjIsEmpty(filters, emptyExcludedFilters)
    );
  }, [isFetching]);

  useEffect(() => {
    setProductsList(
      list?.map((entity: ProductEntityType, idx: number): ItemRenderType => ({
        key: idx.toString(),
        ...entity,
      })) || []
    );
    setInputPrices({});
    setInputStocks({});
  }, [list]);

  useEffect((): void => {
    if (setPaginationInfo && pagination) {
      setPaginationInfo(pagination);
    }
  }, [setPaginationInfo, pagination]);

  useEffect((): void => {
    if (sorter && !!setSorterInfo) {
      setSorterInfo(sorter);
    }
  }, [setSorterInfo, sorter]);

  const onPaginationChange = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ): void => {
    setSorter(sorter);
    setPagination(pagination);
  };

  const handlePriceChange = (productId: string, price: string): void => {
    setInputPrices((prevInputPrices) => ({
      ...prevInputPrices,
      [productId]: price,
    }));
  };

  const handleStockChange = (productId: string, rest: string): void => {
    setInputStocks((prevInputStocks) => ({
      ...prevInputStocks,
      [productId]: rest,
    }));
  };

  const delayedUpdatePrice = useMemo(() => debounce((product: ProductType, inputValue: string) => {
    onUpdatePrice(product, inputValue);
  }, 300), []);

  // Get values

  const getCharacteristicValue = (
    product: ProductType,
    fieldName: "Бренд" | "Цвет"
  ): string | null => {
    switch (fieldName) {
      case "Бренд": {
        const characteristicValue: WBCharacteristicValueType = product.characteristic_values
          ?.find(({ characteristic }) => characteristic.name === fieldName);

        return characteristicValue?.value ?? null;
      }

      case "Цвет": {
        const colors: string[] = product.characteristic_values
          ?.filter(({ characteristic }) => characteristic.name === fieldName)
          ?.map(({ value }) => value);

        return colors?.length > 0 ? colors?.join(", ") : null;
      }

      default:
        return null;
    }
  };

  const getSizesValue = (
    product: ProductType,
    fieldName: "Баркод" | "Размер" | "Цена" | "id"
  ): string | undefined => {
    switch (fieldName) {
      case "Баркод":
        return product.sizes?.[0]?.barcodes?.[0]?.barcode;

      case "Размер":
        return product.sizes?.[0]?.wb_size;

      case "Цена":
        return parseInt(product.sizes?.[0]?.actual_price?.[0]?.price ?? "0")?.toString();

      case "id":
        return product.sizes?.[0]?.id;

      default:
        return null;
    }
  };

  const getProduct = (entity: ProductEntityType): ProductType => {
    if ("product" in entity) {
      return (entity as OrderType).product;
    }

    return entity as ProductType;
  };

  const getNavigateLink = (entity: ProductEntityType): string => {
    const cardId: string = (entity as RestType)?.product_card_id ?? getProduct(entity).card_id;
    const productId: string = (entity as RestType)?.product_id ?? getProduct(entity).id;

    return `/edit-product/card/${cardId}/product/${productId}`;
  };

  const getTagColor = (dueDate: ITimeRemaining): "success" | "error" | "warning" => {
    if (dueDate) {
      if (dueDate?.days < 1) {
        return "error";
      } else if (dueDate?.days < 2) {
        return "warning";
      }

      return "success";
    }
    return "error";
  };

  const getColorStatus = (status: WBStatusesType): "success" | "error" | "processing" => {
    if (status === "sold") {
      return "success";
    } else if (
      status === "canceled"
      || status === "canceled_by_client"
      || status === "defect"
    ) {
      return "error";
    }

    return "processing";
  };

  // Renders

  const renderTextWithTooltip = (text: string | null | undefined, length?: number): JSX.Element => {
    const maxLength = length ?? 30;

    if (text) {
      return (
        <Tooltip title={text}>
          <div className="flex text-ellipsis overflow-hidden">
            {text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text}
          </div>
        </Tooltip>
      );
    }

    return <></>;
  };

  const renderIcon = (product: ProductEntityType): JSX.Element => {
    const status: number = (product as ProductType).status ?? (product as RestType).product_status;
    const syncAt: string = (product as ProductType).synch_at ?? (product as RestType).sync_at;

    switch (status) {
      case ProductStatus.Draft:
        return (
          <Tooltip title="Товар находится в черновике">
            <DraftStatusIcon className={`${css.icon} ${css.error}`} />
          </Tooltip>
        );

      case ProductStatus.Archive:
        return (
          <Tooltip title="Товар находится в архиве">
            <DraftStatusIcon className={`${css.icon} ${css.error}`} />
          </Tooltip>
        );

      case ProductStatus.Synchronized:
        return (
          <Tooltip
            title={
              syncAt
                ? `Последняя синхронизация с маркетплейсом: 
                  ${dayjs(syncAt).format("DD.MM.YYYY HH:mm")}`
                : "Товар успешно синхронизирован"
            }
          >
            <WildberriesIcon className={css.icon} />
          </Tooltip>
        );

      case ProductStatus.IntegrationError:
        return (
          <Tooltip title="Ошибка интеграции с маркетплейсом Wildberries">
            <FailStatusIcon className={`${css.icon} ${css.error}`} />
          </Tooltip>
        );

      case ProductStatus.InProgress:
        return (
          <Tooltip title="Товар синхронизируется">
            <InProgressStatusIcon spin className="blue-color" />
          </Tooltip>
        );

      default: return null;
    }
  };

  const renderSearchNotFound = (): JSX.Element => <>Ничего не найдено</>;

  const renderColumnTitle = (title: string): JSX.Element => <span className="secondary-color">{title}</span>;

  const renderColumn = (column: TableColumnType, index: number): JSX.Element | null => {
    switch (column) {
      case "photo":
        return (
          <Column
            key="Photo"
            title={renderColumnTitle("Фото")}
            dataIndex="photo"
            width={65}
            fixed="left"
            className={css.cellPhoto}
            render={(_image, entity: ProductEntityType) => (
              <div className={css.photoWrapper}>
                <ImageContainer
                  src={getFirstImage(getProduct(entity))?.src}
                  alt="Фото"
                  imageContainerClass={css.photo}
                  filename={getFirstImage(getProduct(entity))?.filename}
                  onClick={() => navigate(getNavigateLink(getProduct(entity)))}
                />
              </div>
            )}
          />
        );

      case "barcode":
        return (
          <Column
            key="Barcode"
            title={renderColumnTitle("Баркод товара")}
            dataIndex="barcode"
            width={150}
            render={(_value, entity: ProductEntityType) => (
              <a className="flex" onClick={() => navigate(getNavigateLink(entity))}>
                {renderTextWithTooltip(getProduct(entity).sizes
                  ? getSizesValue(getProduct(entity), "Баркод")
                  : (entity as RestType).barcode
                )}
              </a>
            )}
          />
        );

      case "category":
        return (
          <Column
            key="Category"
            title={renderColumnTitle("Категория")}
            dataIndex="category"
            width={200}
            render={(value: BasicEntityType) => renderTextWithTooltip(value?.name) || ""}
          />
        );

      case "name":
        return (
          <Column
            key="Name"
            title={renderColumnTitle("Наименование")}
            dataIndex="name"
            width={200}
            render={(_text: string, entity: ProductEntityType) => {
              const name: string = getProduct(entity).name ?? (entity as RestType)?.product_name;

              return (
                <a className="flex" onClick={() => navigate(getNavigateLink(entity))}>
                  {renderTextWithTooltip(name)}
                </a>
              );
            }}
          />
        );

      case "brand":
        return (
          <Column
            key="Brand"
            title={renderColumnTitle("Бренд")}
            dataIndex="brands"
            width={170}
            sorter={{ multiple: 4 }}
            sortDirections={["ascend", "descend"]}
            showSorterTooltip={false}
            render={(_value, entity: ProductEntityType) => renderTextWithTooltip(
              getCharacteristicValue(getProduct(entity), "Бренд")
            )}
          />
        );

      case "updated_at":
        return (
          <Column
            key="Date changes"
            title={renderColumnTitle("Дата изменения")}
            dataIndex="updated_at"
            width={150}
            sorter={{ multiple: 3 }}
            sortDirections={["ascend", "descend"]}
            showSorterTooltip={false}
            render={(value) => <>{dayjs(value).format("DD.MM.YYYY")}</>}
          />
        );

      case "color":
        return (
          <Column
            key="Color"
            title={renderColumnTitle("Цвет")}
            dataIndex="color"
            width={200}
            render={(_value, entity: ProductEntityType) =>
              renderTextWithTooltip(getCharacteristicValue(getProduct(entity), "Цвет"))
            }
          />
        );

      case "size":
        return (
          <Column
            key="Size"
            title={renderColumnTitle("Размер")}
            dataIndex="size"
            width={150}
            render={(_value, entity: ProductEntityType) => getSizesValue(getProduct(entity), "Размер") || ""}
          />
        );

      case "integrations":
        return (
          <Column
            key="Integration"
            title={renderColumnTitle("Интеграция")}
            dataIndex="status"
            width={105}
            render={(_value, entity: ProductEntityType) => renderIcon(getProduct(entity))}
          />
        );

      case "update_price":
        return (
          <Column
            key="Update_price"
            title={renderColumnTitle("Цена")}
            dataIndex="price"
            width={200}
            fixed="right"
            render={(_value, entity: ProductEntityType) => {
              const product: ProductType = getProduct(entity);
              const isDisabled: boolean = product.status === ProductStatus.Archive
                || product.status === ProductStatus.Draft;
              const tooltipText: string = isDisabled
                ? "Цена товара сохранится в сервисе и не будет передана на маркетплейс"
                : null;

              return (
                <Space>
                  <Tooltip title={!getSizesValue(product, "id") ? "Установите значение баркода" : tooltipText}>
                    <Input
                      disabled={!getSizesValue(product, "id")}
                      inputMode="numeric"
                      suffix="руб"
                      value={inputPrices[product.id] ?? getSizesValue(product, "Цена")}
                      style={{ minWidth: "80px" }}
                      onChange={(e) => {
                        const { value: inputValue } = e.target;
                        const reg: RegExp = /^(0|[1-9]\d*)$/;

                        if (reg.test(inputValue) || inputValue === "") {
                          handlePriceChange(product.id, inputValue);
                        }

                        if (isDisabled) {
                          delayedUpdatePrice(product, inputValue);
                        }
                      }}
                    />
                  </Tooltip>
                  <GhostButton
                    size="middle"
                    text="Обновить"
                    isDisabled={
                      isDisabled
                      || getSizesValue(product, "Цена") === (inputPrices[product.id] ?? getSizesValue(product, "Цена"))
                    }
                    onClickHandler={() => onUpdatePrice(product, inputPrices[product.id] || "")}
                  />
                </Space>
              );
            }}
          />
        );

      case "update_rest":
        return (
          <Column
            key="Update_rest"
            title={renderColumnTitle("Остаток")}
            dataIndex="rest"
            width={200}
            fixed="right"
            render={(_value, product: RestType) => {
              const isDisabled: boolean = product.product_status === ProductStatus.Archive
                || product.product_status === ProductStatus.Draft;

              return (
                <Space>
                  <Input
                    inputMode="numeric"
                    value={inputStocks[product.id] ?? parseInt((product as RestType).rest?.toString() ?? "0")}
                    style={{ minWidth: "80px" }}
                    onChange={(e) => {
                      const { value: inputValue } = e.target;
                      const reg: RegExp = /^(0|[1-9]\d*)$/;

                      if (reg.test(inputValue) || inputValue === "") {
                        handleStockChange(product.id, inputValue);
                      }
                    }}
                  />
                  <GhostButton
                    size="middle"
                    text="Обновить"
                    isDisabled={isDisabled}
                    onClickHandler={() => onUpdateRest(product, inputStocks[product.id] || "0")}
                  />
                </Space>
              );
            }}
          />
        );

      case "created_at":
        return (
          <Column
            key="Created_at"
            title={renderColumnTitle("Дата создания")}
            dataIndex="created_at"
            width={110}
            className={css.wrap}
            render={(value) => (
              <div className="flex flex-col">
                <div>{dayjs(value).format("DD.MM.YYYY")}</div>
                <div className="secondary-color text-12-r">
                  {dayjs(value).format("HH:mm")}
                </div>
              </div>
            )}
          />
        );

      case "due_date":
        return (
          <Column
            key="Due_date"
            title={renderColumnTitle("Срок исполнения")}
            dataIndex="created_in_marketplace_at"
            width={145}
            sorter={{ multiple: index }}
            sortDirections={["ascend", "descend"]}
            showSorterTooltip={false}
            className="select-none"
            render={(value) => {
              if (!value) {
                return null;
              }

              const dueDate: ITimeRemaining = calculateTimeRemaining(
                dayjs(value).add(120, "h").toDate(),
                { Days: true, Hours: true, Minutes: true }
              );

              const remainingHours = dueDate?.hours + 24 * dueDate?.days;

              return (
                <Tooltip title={`Этот период включает в себя не только отгрузку на склад и сортировку, 
                  но и время до поступления заказа в ПВЗ.`}
                >
                  <div className="flex flex-col">
                    {dueDate && <span className="secondary-color text-12-r">осталось</span>}
                    <Tag color={getTagColor(dueDate)} className="w-fit">
                      {remainingHours > 0 ? remainingHours + " ч. " : ""}
                      {dueDate?.minutes > 0 ? dueDate?.minutes + " м." : ""}
                      {!dueDate && "время истекло"}
                    </Tag>
                  </div>
                </Tooltip>
              );
            }}
          />
        );

      case "order_number":
        return (
          <Column
            key="OrderNumber"
            title={renderColumnTitle("№ задания")}
            width={115}
            sorter={{ multiple: 3 }}
            sortDirections={["ascend", "descend"]}
            showSorterTooltip={false}
            dataIndex="id_in_marketplace"
            render={(value: string) => (
              <p>{value}</p>
            )}
          />
        );

      case "warehouse":
        return (
          <Column
            key="Warehouse"
            title={renderColumnTitle("Склад продавца")}
            dataIndex="warehouse"
            width={220}
            render={(_value, order: OrderType) => (
              <div className="flex flex-col">
                <div>{renderTextWithTooltip(order.warehouse.name)}</div>
                <div className="secondary-color text-12-r">
                  {renderTextWithTooltip(order.warehouse.wb_address)}
                </div>
              </div>
            )}
          />
        );

      case "cost":
        return (
          <Column
            key="Cost"
            title={renderColumnTitle("Стоимость")}
            dataIndex="price"
            width={200}
            render={(value: string) => value && <p>{value}₽</p>}
          />
        );

      case "shipping_method":
        return (
          <Column
            key="ShippingMethod"
            title={renderColumnTitle("Способ отгрузки")}
            dataIndex="shipping_method"
            width={150}
            render={(_value, order: OrderType) => (
              <p>{order.warehouse.delivery_method.name}</p>
            )}
          />
        );

      case "sticker_number":
        return (
          <Column
            key="StickerNumber"
            title={renderColumnTitle("Номер стикера")}
            dataIndex="sticker"
            width={120}
            className={css.wrap}
            render={(value: string) => <p>{value}</p>}
          />
        );

      case "time_spent":
        return (
          <Column
            key="TimeSpent"
            title={renderColumnTitle("Время, затраченное на доставку")}
            dataIndex="time_spent"
            width={120}
            className={css.wrap}
            render={(value) => {
              if (!value) {
                return null;
              }
            }}
          // TODO доделать, когда будет понятно, от каких дат отталкиваться
          />
        );

      case "status":
        return (
          <Column
            key="Status"
            title={renderColumnTitle("Статус")}
            dataIndex="status"
            width={170}
            render={(_value, order: OrderType) => {
              return (
                order.wb_status && (
                  <Tag color={getColorStatus(order.wb_status)} className="w-fit">
                    {wbStatuses[order.wb_status]}
                  </Tag>
                )
              );
            }}
          />
        );

      case "actions":
        return (
          <Column
            key="Action"
            title=""
            dataIndex="action"
            width={100}
            fixed="right"
            render={renderActions}
          />
        );

      default:
        return null;
    }
  };

  return !!emptyBlock && isShowEmptyBlock && showEmpty ? (
    emptyBlock
  ) : (
    <div className={css.tableWrapper}>
      <Table
        loading={isFetching}
        dataSource={productsList}
        pagination={pagination}
        onChange={onPaginationChange}
        rowSelection={columns && (rowSelection || null)}
        className={`${css.table} ${className}`}
        locale={{ emptyText: renderSearchNotFound() }}
        scroll={{ y: "55vh" }}
      >
        {columns && columns.map(renderColumn)}
      </Table>
    </div>
  );
};

export default ProductsTable;
