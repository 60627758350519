import { WBTnvedType } from "app/types";
import { SelectOptionType } from "../../components/Products/ProductInfoCard/ProductInfoBlock/ProductInfoBlock";
import {
  FETCH_WB_TNVED_SUCCESS,
  FETCH_WB_TNVED_FAILURE,
  FETCH_WB_TNVEDS_LIST_SUCCESS,
  FETCH_WB_TNVEDS_LIST_FAILURE,
  FETCH_WB_TNVEDS_LIST_REQUEST,
  FETCH_WB_TNVED_REQUEST,
} from "../../constants/actionTypes";
import { WbTnvedsActionsType } from "../actions/wbTnveds.actions";

export type InitialWBTnvedsStateType = {
  error: string | null;
  list: SelectOptionType[] | null;
  found: WBTnvedType | null;
  isFetching: boolean;
};

const initialState: InitialWBTnvedsStateType = {
  error: null,
  list: null,
  found: null,
  isFetching: false,
};

const wbTnveds = (
  state: InitialWBTnvedsStateType = initialState,
  action: WbTnvedsActionsType,
): InitialWBTnvedsStateType => {
  switch (action.type) {
    case FETCH_WB_TNVEDS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_TNVEDS_LIST_SUCCESS: {
      const formattesList = action.payload.tnveds?.map(({id, tnved_name, description}) => {
        return {label: `${tnved_name}${description ? ` - ${description}` : ""}`, value: id};
      });
      return {
        ...state,
        error: null,
        isFetching: false,
        list: formattesList,
      };
    }
    case FETCH_WB_TNVEDS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
      
    case FETCH_WB_TNVED_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_TNVED_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        found: action.payload.tnved,
      };
    case FETCH_WB_TNVED_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default wbTnveds;
