import * as MarketplaceCitiesAPI from "../../api/marketplaceCities.api";
import { ThunkAction } from "redux-thunk";
import {
  fetchMarketplaceCitiesListRequest,
  fetchMarketplaceCitiesListSuccess,
  fetchMarketplaceCitiesListFailure,
  MarketplaceCitiesActionsType
} from "../actions/marketplaceCities.actions";
import { AppStateType } from "../reducers/mainReducer";

export const fetchMarketplaceCities = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  MarketplaceCitiesActionsType
> => async (dispatch) => {
  dispatch(fetchMarketplaceCitiesListRequest());
  try {
    const response = await MarketplaceCitiesAPI.list();

    dispatch(fetchMarketplaceCitiesListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchMarketplaceCitiesListFailure(error.response?.data?.error));
  };
};

export const filterMarketplaceCities = (marketplace: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  MarketplaceCitiesActionsType
> => async (dispatch) => {
  dispatch(fetchMarketplaceCitiesListRequest());
  try {
    const response = await MarketplaceCitiesAPI.filter(marketplace);

    dispatch(fetchMarketplaceCitiesListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchMarketplaceCitiesListFailure(error.response.data.detail));
  };
};

export const searchMarketplacesCities = (search: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  MarketplaceCitiesActionsType
> => async (dispatch) => {
  dispatch(fetchMarketplaceCitiesListRequest());
  try {
    const response = await MarketplaceCitiesAPI.search(search);

    dispatch(fetchMarketplaceCitiesListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchMarketplaceCitiesListFailure(error.response?.data?.error));
  };
};

export const searchFilteredMarketplaceCities = (search: string, marketplace: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  MarketplaceCitiesActionsType
> => async (dispatch) => {
  dispatch(fetchMarketplaceCitiesListRequest());
  try {
    const response = await MarketplaceCitiesAPI.filter(marketplace, search);

    dispatch(fetchMarketplaceCitiesListSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchMarketplaceCitiesListFailure(error.response?.data?.error));
  };
};