import React, { JSX, FC } from "react";

import Card from "../../../ui/Card/Card";
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";

import wbAPIFormImg from "../../../../assets/img/image 775.png";

import css from "../style.module.css";

interface IConnectInfoCardProps {}

const ConnectInfoCard: FC<IConnectInfoCardProps> = (): JSX.Element => {
  const renderList = (): JSX.Element => {
    return (
      <ol className="list-decimal ml-4" style={{color: "#374151"}}>
        <li>Войдите в существующий аккаунт Wildberries или создайте новый.</li>
        <li>
          Перейдите в раздел
          <a
            href="https://seller.wildberries.ru/supplier-settings/access-to-api"
            target="_blank"
            rel="noreferrer"
            className="blue-color"
          >
            {` Доступ к API `}
          </a>
          в настройках Личного кабинета Wildberries. <br/> Профиль — Настройки — Доступ к API.
        </li>
        <li>Задайте произвольное название токена.</li>
        <li>
          Выберите все методы API из предложенного списка: контент, маркетплейс, статистика, аналитика, продвижение,
          рекомендации, вопросы и отзывы, цены и скидки.
        </li>
        <li>Создайте и скопируйте токен, затем вставьте его в поле «API токен» слева и сохраните.</li>
      </ol>
    );
  };

  return (
    <Card className={`mt-6 p-6 ${css.infoCard}`}>
      <p className="text-16-m mb-3">Как подключить маркетплейс</p>
      <p className="secondary-color mb-4">Для подключения маркетплейса следуйте шагам описанным ниже.</p>
      {renderList()}
      <a
        href="https://seller.wildberries.ru/supplier-settings/access-to-api"
        target="_blank"
        rel="noreferrer"
      >
        <PrimaryButton
          className="my-4"
          text="Перейти в WB для получения токена"
          onClickHandler={() => {}}
        />
      </a>
      <p className="secondary-color mb-3">
        Перейдя в личный кабинет Wildberries вы увидите такую форму создания API токена
      </p>
      <div className="rounded-xl flex justify-center" style={{backgroundColor: "#67768C0D"}}>
        <img src={wbAPIFormImg} alt="Форма на WB"/>
      </div>
    </Card>
  );
};

export default ConnectInfoCard;
