import * as PricesAPI from "../../api/prices.api";
import { ThunkAction } from "redux-thunk";
import {
  PricesActionsTypes,
  createPriceRequest,
  createPriceSuccess,
  createPriceFailure,
  fetchShotPriceRequest,
  fetchShotPriceSuccess,
  fetchShotPriceFailure,
} from "../actions/prices.actions";
import { AppStateType } from "../reducers/mainReducer";

export const createPrice = (
  marketplace_id: string, date_from: string, price: number, object_id: string
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  PricesActionsTypes
> => async (dispatch) => {
  dispatch(createPriceRequest(
    marketplace_id, date_from, price, object_id,
  ));

  try {
    const response = await PricesAPI.create(
      marketplace_id, date_from, price, object_id,
    );

    dispatch(createPriceSuccess(response.data.id));

    return Promise.resolve(response.data);
  } catch (error: any) {
    console.log(error);
    dispatch(createPriceFailure(error.response?.data?.error));

    return Promise.reject(error.response?.data?.error);
  }
};

export const fetchShot = (
  sizeId: string
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  PricesActionsTypes
> => async (dispatch) => {
  dispatch(fetchShotPriceRequest(sizeId));

  try {
    const response = await PricesAPI.shot(sizeId);

    dispatch(fetchShotPriceSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    console.log(error);
    dispatch(fetchShotPriceFailure(error.response?.data));

    return Promise.reject(error.response?.data);
  }
};
