import { SizeType } from "antd/es/config-provider/SizeContext";
import React, { FC, useState, useEffect } from "react";
import { Button, Form } from "antd";
import type { FormInstance } from "antd";

interface ISubmitButtonProps {
  form: FormInstance,
  text: string,
  onClick: () => void,
  size?: SizeType;
  className?: string;
}

const SubmitButton: FC<ISubmitButtonProps> = ({
  form,
  text,
  onClick,
  size = "large",
  className = ""
}) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => setSubmittable(true),
      () => setSubmittable(false),
    );
  }, [values, form]);

  return (
    <Button
      type="primary"
      size={size}
      className={`bg-blue-500 ${className}`}
      htmlType="submit"
      disabled={!submittable}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default SubmitButton;
