import { WBCharacteristicType } from "app/types";
import {
  FETCH_WB_CHARACTERISTIC_REQUEST,
  FETCH_WB_CHARACTERISTIC_SUCCESS,
  FETCH_WB_CHARACTERISTIC_FAILURE,
  FETCH_WB_CHARACTERISTICS_LIST_REQUEST,
  FETCH_WB_CHARACTERISTICS_LIST_SUCCESS,
  FETCH_WB_CHARACTERISTICS_LIST_FAILURE,
} from "../../constants/actionTypes";

export type WBCharacteristicsActionsType =
  | FetchWBCharacteristicsListRequestType
  | FetchWBCharacteristicsListSuccessType
  | FetchWBCharacteristicsListFailureType
  | FetchWBCharacteristicRequestType
  | FetchWBCharacteristicSuccessType
  | FetchWBCharacteristicFailureType;

// получение списка характеристик
type FetchWBCharacteristicsListRequestType = {
  type: typeof FETCH_WB_CHARACTERISTICS_LIST_REQUEST;
};

export const fetchWBCharacteristicsListRequest = (
): FetchWBCharacteristicsListRequestType => ({
  type: FETCH_WB_CHARACTERISTICS_LIST_REQUEST,
});

type FetchWBCharacteristicsListSuccessType = {
  type: typeof FETCH_WB_CHARACTERISTICS_LIST_SUCCESS;
  payload: {
    characteristics: WBCharacteristicType[];
  };
};

export const fetchWBCharacteristicsListSuccess = (
  characteristics: WBCharacteristicType[],
): FetchWBCharacteristicsListSuccessType => ({
  type: FETCH_WB_CHARACTERISTICS_LIST_SUCCESS,
  payload: {
    characteristics,
  },
});

type FetchWBCharacteristicsListFailureType = {
  type: typeof FETCH_WB_CHARACTERISTICS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBCharacteristicsListFailure = (error: string): FetchWBCharacteristicsListFailureType => ({
  type: FETCH_WB_CHARACTERISTICS_LIST_FAILURE,
  payload: {
    error,
  },
});

// получение характеристики
type FetchWBCharacteristicRequestType = {
  type: typeof FETCH_WB_CHARACTERISTIC_REQUEST;
};

export const fetchWBCharacteristicRequest = (
): FetchWBCharacteristicRequestType => ({
  type: FETCH_WB_CHARACTERISTIC_REQUEST,
});

type FetchWBCharacteristicSuccessType = {
  type: typeof FETCH_WB_CHARACTERISTIC_SUCCESS;
  payload: {
    characteristic: WBCharacteristicType;
  };
};

export const fetchWBCharacteristicSuccess = (
  characteristic: WBCharacteristicType,
): FetchWBCharacteristicSuccessType => ({
  type: FETCH_WB_CHARACTERISTIC_SUCCESS,
  payload: {
    characteristic,
  },
});

type FetchWBCharacteristicFailureType = {
  type: typeof FETCH_WB_CHARACTERISTIC_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBCharacteristicFailure = (error: string): FetchWBCharacteristicFailureType => ({
  type: FETCH_WB_CHARACTERISTIC_FAILURE,
  payload: {
    error,
  },
});
