import { BasicEntityType } from "app/types";
import { SelectOptionType } from "../../components/Products/ProductInfoCard/ProductInfoBlock/ProductInfoBlock";
import {
  FETCH_WB_SEASON_SUCCESS,
  FETCH_WB_SEASON_FAILURE,
  FETCH_WB_SEASONS_LIST_SUCCESS,
  FETCH_WB_SEASONS_LIST_FAILURE,
  FETCH_WB_SEASONS_LIST_REQUEST,
  FETCH_WB_SEASON_REQUEST,
} from "../../constants/actionTypes";
import { WbSeasonsActionsType } from "../actions/wbSeasons.actions";

export type InitialWBSeasonsStateType = {
  error: string | null;
  list: SelectOptionType[] | null;
  found: BasicEntityType | null;
  isFetching: boolean;
};

const initialState: InitialWBSeasonsStateType = {
  error: null,
  list: null,
  found: null,
  isFetching: false,
};

const wbSeasons = (
  state: InitialWBSeasonsStateType = initialState,
  action: WbSeasonsActionsType,
): InitialWBSeasonsStateType => {
  switch (action.type) {
    case FETCH_WB_SEASONS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_SEASONS_LIST_SUCCESS: {
      const formattedList: SelectOptionType[] = action.payload.seasons?.map(({ name }) => {
        return { label: name, value: name };
      });

      return {
        ...state,
        error: null,
        isFetching: false,
        list: formattedList,
      };
    }
    case FETCH_WB_SEASONS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
      
    case FETCH_WB_SEASON_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_SEASON_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        found: action.payload.season,
      };
    case FETCH_WB_SEASON_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default wbSeasons;
