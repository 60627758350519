import React, {JSX, FC, useState, useEffect} from "react";
import { FormInstance, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeProductStatus } from "../../../api/products.api";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import { InitialProductsStateType } from "../../../redux/reducers/products.reducer";
import { AppDispatch } from "../../../redux/store/store";
import { fetchProduct } from "../../../redux/thunks/products.thunks";
import { ProductCardFormType } from "../ProductCardWrapper/ProductCardWrapper";
import { ReactComponent as LayersIcon } from "../../../assets/icons/layers.svg";

// components
import GhostButton from "../../ui/GhostButton/GhostButton";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";
import NomenclatureCard from "./NomenclatureCard";
import ConfirmModal from "../../Modals/ConfirmModal/ConfirmModal";

import css from "./style.module.css";

interface INomenclatureItemsWrapperProps {
  form: FormInstance;
  values: ProductCardFormType;
  isLoading: boolean;
  nomenclatureId: string;
  productInArchive: boolean;
  setNomenclatureId: React.Dispatch<React.SetStateAction<string>>;
  setIsResetFileList: React.Dispatch<React.SetStateAction<boolean>>;
}

const NomenclatureItemsWrapper: FC<INomenclatureItemsWrapperProps> = ({
  form,
  values,
  isLoading,
  nomenclatureId,
  productInArchive,
  setNomenclatureId,
  setIsResetFileList,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);

  const [selectedNomenclature, setSelectedNomenclature] = useState<string>(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    setSelectedNomenclature(nomenclatureId);
    setIsResetFileList(true);
  }, [nomenclatureId]);

  useEffect(() => {
    if (products.list?.length > 0) {
      setSelectedNomenclature(nomenclatureId ?? products.list[products.list?.length - 1]?.id);
    } else if (!isLoading) {
      setNomenclatureId(null);
      resetProductFormExcludingCategory();
    }
  }, [products.list?.length]);

  const onAddHandler = () => {
    setNomenclatureId(null);
    setSelectedNomenclature(null);

    resetProductFormExcludingCategory();
  };

  const onDeleteHandler = async (): Promise<void> => {
    await changeProductStatus({ products: [selectedNomenclature], status: 1 })
      .then((res) => {
        if (res.status === 204) {
          dispatch(fetchProduct(selectedNomenclature))
            .then((res: any) => {
              if (res.status === 200) {
                message.info("Карточка товара перемещена в архив");
              }
            })
            .catch(() => message.error("Не удалось переместить карточку товара в архив. Попробуйте еще раз"));
        }
      });

    setIsShowDeleteModal(false);
  };

  const resetProductFormExcludingCategory = (): void => {
    const category = values?.category;

    form.resetFields();
    form.setFieldsValue({category});
  };

  return (
    <div className={`h-full ${css.wrapper}`}>
      <div className="flex justify-between items-center bg-white px-4 py-3 mb-3 text-14-m card-radius">
        <p className="flex items-center">
          <LayersIcon className="mr-1" />
          Варианты товара
        </p>
        <span className="secondary-color">{products.list?.length || 0}/30</span>
      </div>
      <div className={css.options_block}>
        {!!(values?.category as string[])?.length && products.list?.map((product) => (
          <NomenclatureCard
            key={product.id}
            product={product}
            setNomenclatureId={setNomenclatureId}
            selectedNomenclature={selectedNomenclature}
            setSelectedNomenclature={setSelectedNomenclature}
            resetForm={resetProductFormExcludingCategory}
          />
        ))}
      </div>
      <PrimaryButton
        className={`w-full mb-3 ${products.list?.length ? "mt-3" : "mt-0"}`}
        text="Добавить вариант"
        onClickHandler={onAddHandler}
        isDisabled={!products.list || products.list?.length === 0 || products.list?.length === 30}
      />
      {!!products.list?.length && !!(values?.category as string[]) && (
        <GhostButton
          className="w-full"
          text="Переместить в архив"
          isDisabled={!selectedNomenclature || productInArchive}
          onClickHandler={() => setIsShowDeleteModal(true)}
        />
      )}
      <ConfirmModal
        title="Вы уверены?"
        text="Карточка товара будет перемещена в архив."
        isOpen={!!isShowDeleteModal}
        onOkHandler={onDeleteHandler}
        onCancelHandler={() => setIsShowDeleteModal(false)}
      />
    </div>
  );
};

export default NomenclatureItemsWrapper;
