import React, { JSX, FC, RefAttributes, forwardRef } from "react";

interface IQRListProps {
 title: string;
 links: string[];
}

const QRList: FC<IQRListProps & RefAttributes<HTMLButtonElement>> = forwardRef((props, ref): JSX.Element => {
  const { links, title } = props;

  return (
    <div ref={ref as any} className="m-4">
      <h1 style={{fontFamily: "sans-serif"}}>{title}</h1>
      <div className="flex flex-wrap mt-5">
        {links?.map((link: string, idx: number) => <img key={idx} alt="" src={link} className="mr-3"/>)}
      </div>
    </div>
  );
});

export default QRList;
