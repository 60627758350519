import {ThunkAction} from "redux-thunk";
import * as WBKindsAPI from "../../api/wbKinds.api";
import {
  fetchWBKindSuccess,
  fetchWBKindFailure,
  fetchWBKindsListSuccess,
  fetchWBKindsListFailure,
  WbKindsActionsType, fetchWBKindsListRequest, fetchWBKindRequest,
} from "../actions/wbKinds.actions";
import { AppStateType } from "../reducers/mainReducer";

// получение списка полов
export const fetchWBKinds = (search: { search: string } | null = null):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbKindsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBKindsListRequest());

    try {
      const response = await WBKindsAPI.list(search);

      dispatch(fetchWBKindsListSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBKindsListFailure(error?.response?.data?.error));
    }
  };

// получение пола
export const fetchWBKind = (kindId: string):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbKindsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBKindRequest());

    try {
      const response = await WBKindsAPI.getItem(kindId);

      dispatch(fetchWBKindSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBKindFailure(error?.response?.data?.error));
    }
  };
