import React, { FC, useState, useEffect, useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { InitialProductsStateType } from "redux/reducers/products.reducer";
import { InitialMarketplacesStateType } from "redux/reducers/marketplaces.reducer";
import { fetchMarketplaces } from "redux/thunks/marketplaces.thunks";
import { clearProducts } from "redux/thunks/products.thunks";
import { createPrice, fetchShot } from "redux/thunks/price.thunks";
import { fetchProducts } from "../../../redux/thunks/products.thunks";
import dayjs from "dayjs";

// components
import GhostButton from "components/ui/GhostButton/GhostButton";
import { Form, Select, message } from "antd";
import { ReactComponent as FilterIcon } from "../../../assets/icons/page_info_icon.svg";
import { FiltersDrawer, FiltersDrawerValuesType, FilterType } from "../../Products/Filters/FiltersDrawer/FiltersDrawer";
import ProductsTable, { ProductColumnType } from "../../Products/ProductsTable/ProductsTable";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/chart_bar_square.svg";
import EmptyBlock from "components/EmptyBlock/EmptyBlock";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import { FiltersDrawerTags } from "../../../components/Products/Filters/FiltersDrawerTags/FiltersDrawerTags";
import { SearchInput } from "components/ui/SearchInput/SearchInput";

import css from "./style.module.css";

import type { AppStateType } from "redux/reducers/mainReducer";
import type { DefaultOptionType } from "antd/es/select";
import type {
  RequestPaginationType,
  PriceShotType,
  ProductType,
  RequestProductsFiltersType
} from "app/types";

const { Item, useForm, useWatch } = Form;

interface IPricesTabProps { }

const PricesTab: FC<IPricesTabProps> = () => {
  const navigate: NavigateFunction = useNavigate();
  const [form] = useForm<FiltersDrawerValuesType>();
  const values = useWatch<FiltersDrawerValuesType>([], form);
  const dispatch = useDispatch<AppDispatch>();
  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);
  const marketplaces: InitialMarketplacesStateType = useSelector((state: AppStateType) => state.marketplaces);

  const [showFiltersDrawer, setShowFiltersDrawer] = useState<boolean>(false);
  const [marketplacesOptions, setMarketplacesOptions] = useState<DefaultOptionType[]>(null);
  const [filters, setFilters] = useState<RequestProductsFiltersType>({});

  const columns: ProductColumnType[] = [
    "photo",
    "barcode",
    "name",
    "integrations",
    "updated_at",
    "update_price",
  ];

  const filterFields: FilterType[] = [
    "barcodes", "categories", "name",
    "price_update_date", "price_synced",
    "status", "price", "not_synced",
  ];

  useEffect(() => {
    dispatch(fetchMarketplaces());
  }, [dispatch]);

  useEffect(() => {
    // TODO: Хардкод фильтрация на WB
    marketplaces.list &&
      setMarketplacesOptions(
        marketplaces.list
          ?.map(({ id, name }) => ({
            value: id,
            label: name,
            disabled: name !== "WB"
          }))
      );
  }, [marketplaces.list]);

  const getFirstSizeId = (product: ProductType): string => {
    const firstSize = product.sizes?.[0];

    return firstSize ? firstSize.id : null;
  };

  const fetchProductsList = (pagination: RequestPaginationType, filters: RequestProductsFiltersType): void => {
    dispatch(fetchProducts(pagination, filters));
  };

  const onUpdatePrice = async (product: ProductType, price: string): Promise<void> => {
    const sizeId: string = getFirstSizeId(product);

    const createPriceHandler = (): void => {
      const date_from: string = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      dispatch(createPrice(form.getFieldValue("marketplace"), date_from, Number.parseInt(price), sizeId))
        .then(() => message.success("Цена обновлена"))
        .catch(() => message.error("Ошибка при обновлении цены"));
    };

    if (form.getFieldValue("marketplace")) {
      await dispatch(fetchShot(sizeId))
        .then((shot: PriceShotType) => {
          if (Number.parseInt(shot?.price) !== Number.parseInt(price)) {
            createPriceHandler();
          } else {
            message.info("Цена должна отличаться от предыдущей");
          }
        })
        .catch(() => {
          createPriceHandler();
        });

    } else {
      message.info("Выберите маркетплейс");
    }
  };

  const renderCreateProductButton = (): JSX.Element => {
    const handleOnClick = async (): Promise<void> => {
      await dispatch(clearProducts());

      navigate("/create-product");
    };

    return (
      <PrimaryButton onClickHandler={handleOnClick} text="Создать новый" isDisabled={products.isFetching} />
    );
  };

  // Фильтры
  const handleOnShowFiltersDrawer = (): void => {
    setShowFiltersDrawer(true);
  };

  const handleOnCloseFiltersDrawer = (): void => {
    setShowFiltersDrawer(false);
  };

  const renderSearch = (): JSX.Element => {
    return (
      <Form form={form} layout="inline" className={css.searchWrap}>
        <Item name="marketplace" className={css.marketplaces}>
          <Select
            size="large"
            placeholder="Выберите маркетплейс"
            options={marketplacesOptions ?? null}
            loading={marketplaces.isFetching || !marketplaces.list}
          />
        </Item>
        <Item className={css.searchInput} name="search">
          <SearchInput
            placeholder="Найти по баркоду или наименованию"
            onSearch={(state: RequestProductsFiltersType) => setFilters(state)}
            filters={filters}
            isDisabled={!values?.marketplace}
          />
        </Item>
        <GhostButton
          size="large"
          text="Фильтры"
          icon={<FilterIcon />}
          onClickHandler={() => handleOnShowFiltersDrawer()}
          isDisabled={products.isFetching}
        />
        <FiltersDrawer
          form={form}
          onClose={handleOnCloseFiltersDrawer}
          open={showFiltersDrawer}
          setFilters={setFilters}
          filterFields={filterFields}
        />
      </Form>
    );
  };

  const renderEmptyIcon = (): JSX.Element => (
    <EmptyBlock
      icon={<EmptyIcon className={`mb-6 ${css.emptyIcon}`} />}
      style={{ height: "calc(100vh - 23rem)" }}
      title="Товаров нет"
      description="У вас нет товаров, необходимо создать новый"
      btn={renderCreateProductButton()}
    />
  );

  return (
    <div className="flex flex-col w-full h-full">
      <div className={css.controlsWrap}>
        {renderSearch()}
        <FiltersDrawerTags
          form={form}
          filters={filters}
          setFilters={(value: RequestProductsFiltersType) => setFilters(value)}
          filterFields={filterFields}
        />
      </div>
      <ProductsTable
        columns={columns}
        list={products.list}
        count={products.count}
        fetchList={fetchProductsList}
        isFetching={products.isFetching}
        filters={filters}
        onUpdatePrice={onUpdatePrice}
        emptyBlock={renderEmptyIcon()}
        isShowEmptyBlock={true}
        className={css.table}
      />
    </div>
  );
};

export default PricesTab;
