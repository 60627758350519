import * as process from "process";
import axios from "../utils/axios";
import { FilterWBCharacteristicsType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const list = async (params: FilterWBCharacteristicsType | null = null): Promise<any> => {
  return axios.GET(API_BASE, "/api/catalog/wb_characteristics", params);
};

export const getItem = async (wbCharacteristicId: string): Promise<any> => {
  return axios.GET(API_BASE, `/api/catalog/wb_characteristics/${wbCharacteristicId}`);
};
