import { Form, FormInstance } from "antd";
import { WBCharacteristicType } from "app/types";
import React, { JSX, FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/reducers/mainReducer";
import CustomInput from "../../../ui/CustomInput/CustomInput";
import { getSelectMode, getType } from "./getContent";
import { SelectOptionType } from "./ProductInfoBlock";

interface ICharacteristicProps {
  form: FormInstance;
  charc: WBCharacteristicType;
  updateCharc: (fieldName: string) => void;
  nomenclatureId: string;
  isDisabledFields: boolean;
}

const dictionaryNames: string[] = ["Цвет", "Страна производства", "Пол", "Сезон", "ТНВЭД"];

const Characteristic: FC<ICharacteristicProps> = ({
  form,
  charc,
  updateCharc,
  isDisabledFields,
}): JSX.Element => {
  const { id, name, max_count, unit_name, required } = charc;

  const wbCountries = useSelector((state: AppStateType) => state.wbCountries);
  const wbKinds = useSelector((state: AppStateType) => state.wbKinds);
  const wbSeasons = useSelector((state: AppStateType) => state.wbSeasons);
  const wbTnveds = useSelector((state: AppStateType) => state.wbTnveds);
  const wbColors = useSelector((state: AppStateType) => state.wbColors);

  const isDescription: boolean = name === "Описание";
  const isName: boolean = name === "Наименование";
  const hasDictionary: boolean = dictionaryNames.includes(name);
  const values = Form.useWatch([], form);

  useEffect(() => {
    // наименование записываем также в характеристики WB
    if (isName) {
      form.setFieldsValue({ [id]: form.getFieldValue("name") });
    }
  }, [values?.name]);

  useEffect(() => {
    // описание записываем также в характеристики WB
    if (isDescription) {
      form.setFieldsValue({ [id]: form.getFieldValue("description") });
    }
  }, [values?.description]);

  const getOptions = (): SelectOptionType[] => {
    switch (name) {
      case "Пол":
        return wbKinds?.list;
      case "Сезон":
        return wbSeasons?.list;
      case "ТНВЭД":
        return wbTnveds?.list;
      case "Страна производства":
        return wbCountries?.list;
      case "Цвет":
        return wbColors?.list;
      default:
        return null;
    }
  };

  return (
    <div className={isName || isDescription ? "hidden" : ""}>
      {/*оставляем наименование и описание в DOM, но прячем от пользователя*/}
      <CustomInput
        key={id}
        form={form}
        type={getType(charc, hasDictionary)}
        title={name}
        suffix={unit_name}
        options={getOptions()}
        required={required}
        fieldName={id}
        isDisabled={isDisabledFields}
        showCount={max_count > 1}
        maxLength={max_count > 1 ? max_count : null}
        placeholder={name}
        selectMode={getSelectMode(max_count, hasDictionary)}
        onBlurHandler={() => updateCharc(id)}
        tooltipTitle={max_count > 1 && !hasDictionary
          ? `Введите слово или словосочетание и нажмите клавишу Enter`
          : null
        }
      />
    </div>
  );
};

export default Characteristic;
