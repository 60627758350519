import React, { JSX, FC, forwardRef, RefAttributes } from "react";
import { SelectionListColumnType, SelectionListItemType } from "../Supply/SupplyWrapper";
import SelectionListTable from "./SelectionListTable/SelectionListTable";

interface ISelectionListProps {
  name: string;
  data: SelectionListItemType[][];
  columns: SelectionListColumnType[];
  createdAt: string;
}

const SelectionList: FC<ISelectionListProps & RefAttributes<HTMLButtonElement>> = forwardRef(
  (props: ISelectionListProps, ref): JSX.Element => {
    const { name, columns, data, createdAt } = props;

    const fontStyle = { fontFamily: "sans-serif" };

    return (
      <div id="print-content" ref={ref as any} className="m-2">
        <h1 style={fontStyle}>Лист подбора</h1>
        <h3 style={fontStyle}>{name}</h3>
        {data.map((item: SelectionListItemType[], idx: number) => (
          <SelectionListTable
            key={`selection-list-item--${idx}`}
            idx={idx}
            data={item}
            columns={columns}
            createdAt={createdAt}
            fontStyle={fontStyle}
          />
        ))}
      </div>
    );
  },
);

export default SelectionList;
