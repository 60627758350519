import React, { JSX, FC } from "react";
import { Form, FormInstance } from "antd";

// components
import GhostButton from "../../ui/GhostButton/GhostButton";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";

import css from "./style.module.css";

interface IButtonsPanelProps {
  cancelText: string;
  applyText: string;
  cancelHandler: () => void;
  applyHandler: () => void;
  title?: string;
  isDisabled?: boolean;
}

const ButtonsPanel: FC<IButtonsPanelProps> = ({
  title,
  isDisabled,
  cancelText,
  applyText,
  cancelHandler,
  applyHandler,
}): JSX.Element => {
  return (
    <div className={`flex ${title ? "justify-between" : "justify-end"} items-center py-3 px-6 bg-white ${css.wrapper}`}>
      {title && <h4>{title}</h4>}
      <div className={`flex ${css.buttons}`}>
        <GhostButton
          size="large"
          text={cancelText}
          onClickHandler={cancelHandler}
          className="mr-3"
        />
        <Form.Item>
          <PrimaryButton
            text={applyText}
            htmlType="button"
            onClickHandler={applyHandler}
            isDisabled={isDisabled}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default ButtonsPanel;
