import { ProductMediaType, ProductType } from "app/types";

export const getFirstImage = (product: ProductType): {src: string, filename: string} | null => {
	const imageMedia: ProductMediaType[] = product?.medias
		?.filter(({ filename }) => filename.endsWith(".png") || filename.endsWith(".jpg"));

	if (imageMedia?.length > 0) {
		return { src: imageMedia[0].attachment, filename: imageMedia[0].filename };
	}

	return null;
};
