import React, { JSX, FC } from "react";
import css from "./style.module.css";

interface ICardProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Card: FC<ICardProps> = ({
  style,
  className = "",
  children
}): JSX.Element => {
  return (
    <div className={`bg-white p-4 ${className} ${css.card}`} style={style}>
      {children}
    </div>
  );
};

export default Card;
