import { OfficeWildberriesType } from "app/types";
import {
  FETCH_OFFICES_WILDBERRIES_LIST_REQUEST,
  FETCH_OFFICES_WILDBERRIES_LIST_SUCCESS,
  FETCH_OFFICES_WILDBERRIES_LIST_FAILURE,
} from "../../constants/actionTypes";
import { OfficesWildberriesActionsType } from "../actions/officesWildberries.actions";

export type InitialOfficesWildberriesStateType = {
	error: string | null;
	list: OfficeWildberriesType[] | null;
	found: OfficeWildberriesType | null;
	isFetching: boolean;
};

const initialState: InitialOfficesWildberriesStateType = {
	error: null,
	list: null,
	found: null,
	isFetching: false,
};

const officesWildberries = (
	state: InitialOfficesWildberriesStateType = initialState,
	action: OfficesWildberriesActionsType
): InitialOfficesWildberriesStateType => {
	switch (action.type) {
		case FETCH_OFFICES_WILDBERRIES_LIST_REQUEST:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_OFFICES_WILDBERRIES_LIST_SUCCESS:
			return {
				...state,
				error: null,
				isFetching: false,
        list: action.payload.officesWildberries,
			};
		case FETCH_OFFICES_WILDBERRIES_LIST_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload.error,
			};
		default:
			return state;
	}
};

export default officesWildberries;
