import React, { JSX, FC } from "react";
import { Link } from "react-router-dom";

import { Button } from "antd";
import AuthContainer from "components/Authentication/AuthContainer/AuthContainer";
import RegistrationForm from "components/Authentication/RegistrationForm/RegistrationForm";

import css from "./style.module.css";

const RegistrationPage: FC = (): JSX.Element => {
  const renderButton = (): JSX.Element => {
    return (
      <Link to="/login">
        <Button className={`blue-color mr-6 ${css.border}`}>
          Войти
        </Button>
      </Link>
    );
  };

  return <AuthContainer authForm={<RegistrationForm />} btn={renderButton()} />;
};

export default RegistrationPage;
