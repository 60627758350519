import * as MarketplacesAPI from "../../api/marketplaces.api";
import { ThunkAction } from "redux-thunk";
import {
	fetchMarketplaceRequest,
	fetchMarketplaceSuccess,
	fetchMarketplaceFailure,
	fetchMarketplacesListRequest,
	fetchMarketplacesListSuccess,
	fetchMarketplacesListFailure,
	MarketplacesActionsType
} from "../actions/marketplaces.actions";
import { AppStateType } from "../reducers/mainReducer";

export const fetchMarketplaces = (): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	MarketplacesActionsType
> => async (dispatch) => {
	dispatch(fetchMarketplacesListRequest());
	try {
		const response = await MarketplacesAPI.list();

		dispatch(fetchMarketplacesListSuccess(response.data));
	} catch (error: any) {
		dispatch(fetchMarketplacesListFailure(error.response?.data?.error));
	}
};

// получение маркетплейса
export const fetchMarketplace = (marketplaceId: string): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	MarketplacesActionsType
> => async (dispatch) => {
	dispatch(fetchMarketplaceRequest(marketplaceId));
	try {
		const response = await MarketplacesAPI.getItem(marketplaceId);

		dispatch(fetchMarketplaceSuccess(response.data));
	} catch (error: any) {
		dispatch(fetchMarketplaceFailure(error.response?.data?.error));
	}
};
