import { WBCategoryType } from "app/types";
import {
  FETCH_WB_CATEGORIES_LIST_FAILURE,
  FETCH_WB_CATEGORIES_LIST_REQUEST,
  FETCH_WB_CATEGORIES_LIST_SUCCESS,
  FETCH_WB_CATEGORY_FAILURE,
  FETCH_WB_CATEGORY_REQUEST,
  FETCH_WB_CATEGORY_SUCCESS,
} from "../../constants/actionTypes";
import { formatCategoriesList, OptionType } from "../../utils/formatCategoriesList";

import { WBCategoriesActionsType } from "../actions/wbCategories.actions";

export type InitialWBCategoriesStateType = {
  error: string | null;
  list: WBCategoryType[] | null;
  formattedList: OptionType[] | null;
  found: WBCategoryType | null;
  isFetching: boolean;
};

const initialState: InitialWBCategoriesStateType = {
  error: null,
  list: null,
  formattedList: null,
  found: null,
  isFetching: false,
};

const wbCategories = (
  state: InitialWBCategoriesStateType = initialState,
  action: WBCategoriesActionsType,
): InitialWBCategoriesStateType => {
  switch (action.type) {
    case FETCH_WB_CATEGORIES_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.categories,
        formattedList: formatCategoriesList(action.payload.categories),
        error: null,
      };
    case FETCH_WB_CATEGORIES_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    case FETCH_WB_CATEGORY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_WB_CATEGORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        found: action.payload.category,
        error: null,
      };
    case FETCH_WB_CATEGORY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default wbCategories;
