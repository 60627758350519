import React, { JSX, FC, useState, useEffect } from "react";
import * as process from "process";
import { DraggerFileType } from "app/types";
import { Form, FormInstance, message, notification, Tooltip, Upload } from "antd";
import { ReactComponent as AddFileIcon } from "../../assets/icons/add_photo_icon.svg";

// components
import GhostButton from "../ui/GhostButton/GhostButton";
import ImageContainer from "./ImageContainer/ImageContainer";
import ProgressContainer from "./ProgressContainer/ProgressContainer";
import VideoPreviewContainer from "./VideoPreviewContainer/VideoPreviewContainer";

import css from "./style.module.css";

type AcceptedFileType = {
  extensions: string[];
  maxItemSize: number;
};

type ResponseType = {
  id: string;
  document: string;
  created_at: string;
  original_filename: string;
};

interface IDragAndDropProps {
  type: "photo" | "video";
  list?: DraggerFileType[];
  form: FormInstance;
  isMultiple: boolean;
  required?: boolean;
  onRemove?: (file: DraggerFileType) => void;
  afterUpload?: (file: DraggerFileType) => void;
  action?: string;
  placeholder: string;
  showCounter: boolean;
  maxQuantity?: number;
  title?: string;
  isDisabled?: boolean;
  maxFilesSize?: number; // максимальное количество мегабайт для общего количества файлов
  acceptedFileType?: AcceptedFileType;
  loadedFilelist?: DraggerFileType[];
  resetFilesList?: boolean;
  additionalAddRequest?: (file: DraggerFileType) => DraggerFileType;
  additionalDeleteRequest?: (fileList: DraggerFileType[]) => DraggerFileType[];
}

const amountOfFiles: number = 5;
const MAX_SIZE: number = 300 * 1024 * 1024;

const DragAndDrop: FC<IDragAndDropProps> = (props): JSX.Element => {
  const {
    action,
    title,
    placeholder,
    isMultiple,
    showCounter,
    maxQuantity,
    isDisabled = false,
    required = false
  } = props;

  const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();
  const authToken: string | null = window?.localStorage?.getItem("authToken");

  const actionLink: string = `${API_BASE}${action}`;

  const [count, setCount] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  // хранит текущий список файлов
  const [list, setList] = useState<DraggerFileType[]>(props.list || []);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [disabledDragger, setDisabledDragger] = useState<boolean>(false);

  // for set uploading status after selecting files
  let countBeforeUpload: number = 0;
  // при загрузке пачки файлов state со списком файлов не обновляется вовремя, поэтому используем временный массив
  const tempFileList: DraggerFileType[] = [];

  useEffect((): void => {
    clearErrors();
    setCount(list?.length);
  }, [list]);

  useEffect((): void => {
    if (isUploading) {
      const filteredFileList: DraggerFileType[] = list
        ?.filter((file: DraggerFileType) => file.status === "normal")
        ?.filter((file: DraggerFileType) => !file.isSent);

      const generatedGroups: DraggerFileType[][] = generateGroups(filteredFileList);

      uploadGroups(generatedGroups);
    }
  }, [isUploading]);

  useEffect((): void => {
    // при добавлении новой НП необходимо очистить список файлов
    props.resetFilesList && setList([]);
  }, [props.resetFilesList]);

  useEffect((): void => {
    if (props?.loadedFilelist?.length) {
      // чтобы не затирались файлы с ошибками сразу же после загрузки
      const errorFiles = list?.filter((file) => file.status === "exception" && !file.responseId);

      setList([...props.loadedFilelist, ...errorFiles]);
    }
  }, [props?.loadedFilelist]);

  const clearErrors = (): void => {
    if (list?.every((file: DraggerFileType) => file.status === "success")) {
      setErrorMessage("");
    }
  };

  const generateGroups = (fileList: DraggerFileType[]): DraggerFileType[][] => {
    const groups: DraggerFileType[][] = [];

    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i += amountOfFiles) {
        groups.push(fileList.slice(i, i + amountOfFiles));
      }
    }

    return groups;
  };

  const validateFileExtension = (file: DraggerFileType): {isValidExtension: boolean, fileType: string} => {
    const ext: string = file?.name?.split(".")?.pop()?.toLowerCase();
    const fileType: string = props.acceptedFileType.extensions.find((fileExt) => fileExt.includes(ext));
    const accept: string = props.acceptedFileType.extensions.join(", ");

    if (!fileType) {
      setErrorMessage(`Неверный формат файла. Допустимые форматы: ${accept}`);

      return {
        isValidExtension: false,
        fileType,
      };
    }

    return {
      isValidExtension: true,
      fileType,
    };
  };

  const validateFileSize = (file: DraggerFileType, fileType: string, tempFileList?: DraggerFileType[]): boolean => {
    const maxFilesSize: number = props.maxFilesSize * 1024 * 1024 || MAX_SIZE;

    const isNotExceptionAndNotCurrentFile = (item: DraggerFileType): boolean => {
      return item.status !== "exception" && item.uid !== file.uid;
    };

    const allFiles: DraggerFileType[] = tempFileList ? [...list, ...tempFileList] : list;

    setCount(allFiles.length);

    // общий размер всех прикрепленных ранее файлов, исключая файлы с ошибкой и текущий файл
    const currentFilesSize: number = allFiles
      ?.filter(isNotExceptionAndNotCurrentFile)
      ?.reduce((prev: number, curFile: DraggerFileType) => prev + curFile.size, 0);

    const newSize: number = +currentFilesSize + file?.size;

    const isValidFilesSize: boolean = props.acceptedFileType
      && file?.size <= props.acceptedFileType.maxItemSize
      && newSize <= maxFilesSize;

    const isValid: boolean = fileType && isValidFilesSize;

    if (!isValidFilesSize) {
      setErrorMessage(`Размер файла не должен превышать ${props.acceptedFileType.maxItemSize / 1024 / 1024}Мб`);
    }

    return isValid;
  };

  const handleFileValidate = (file: DraggerFileType, tempFileList?: DraggerFileType[]): boolean => {
    const { isValidExtension, fileType } = validateFileExtension(file);

    return isValidExtension && validateFileSize(file, fileType, tempFileList);
  };

  const addFileToList = (file: DraggerFileType): DraggerFileType => {
    const newFile: DraggerFileType = file;
    const isValid: boolean = handleFileValidate(file);

    if (list.find((item) => item.uid === file.uid)) {
      setList((prev) => prev.map((el) => (el.uid === file.uid ? file : el)));
    } else {
      newFile.isSent = false;
      newFile.status = isValid ? "normal" : "exception";

      if (maxQuantity) {
        list.length < maxQuantity && setList((prev) => [...prev, newFile]);
        list.length > 0 && setErrorMessage(`Разрешенное количество прикрепляемых файлов не более ${maxQuantity}`);
      } else {
        setList((prev) => [...prev, newFile]);
      }
    }

    return file;
  };

  const handleAfterUpload = (file: DraggerFileType): DraggerFileType => {
    let updatedFile: DraggerFileType = file;

    props.afterUpload && props.afterUpload(file);

    if (props?.additionalAddRequest && file?.responseId && file?.loadingType === "preload") {
      updatedFile = props.additionalAddRequest(file);
    }

    return updatedFile;
  };

  const removeFileFromList = (file: DraggerFileType): DraggerFileType => {
    const filterList = (filelist: DraggerFileType[]): DraggerFileType[] => {
      return filelist?.filter((item) => item.uid !== file.uid);
    };

    const filelistWithoutErrors: DraggerFileType[] = list.filter((file) =>
      file.status !== "exception" && file.responseId);

    if (props?.additionalDeleteRequest && filelistWithoutErrors?.length) {
      const filelist: DraggerFileType[] = props.additionalDeleteRequest(filterList(filelistWithoutErrors));

      setList(filelist);
    } else {
      setList((prevList) => filterList(prevList));
    }

    return file;
  };

  const handleOnRemove = (file: DraggerFileType): void => {
    props.onRemove && props.onRemove(file);

    removeFileFromList(file);
    clearErrors();
  };

  const setFileProgress = (file: DraggerFileType, info: ProgressEvent): void => {
    const done: number = info.loaded;
    const total: number = info.total;
    const perc: number = Math.floor((done / total) * 1000) / 10;
    const newFile: DraggerFileType = file;

    newFile.percent = perc;
    newFile.status = "active";

    addFileToList(newFile);
  };

  const uploadGroups = async (groups: DraggerFileType[][]): Promise<void> => {
    if (groups.length === 0) {
      setIsUploading(false);
    } else {
      await Promise.all(
        groups[0].map((file: DraggerFileType) => {
          return new Promise((resolve, reject) => {
            const newFile: DraggerFileType = file;
            const formData: FormData = new FormData();

            // @ts-expect-error по типу все правильно, но ts не дает завернуть в formData
            formData.set("file", file);

            const xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onload = (): void => {
              if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                const response: ResponseType = JSON.parse(xhr.responseText);

                newFile.status = "success";
                newFile.responseId = response.id;
                newFile.percent = 100;
                newFile.url = response.document;
                newFile.loadingType = "preload";

                addFileToList(newFile);
                handleAfterUpload(newFile);
                setIsUploading(false);

                resolve(response);
              } else {
                newFile.status = "exception";
                newFile.percent = 0;

                addFileToList(newFile);
                setIsUploading(false);

                const error = JSON.parse(xhr?.response)?.error;

                if (error?.WB) {
                  const errorMsg: string = `Wildberries: ${error?.WB?.join(", ")}`;

                  notification.error({
                    message: file.name,
                    description: errorMsg,
                    duration: 15,
                    placement: "topLeft"
                  });

                  newFile.error = errorMsg;
                } else {
                  message.error("Ошибка при загрузке файла. Попробуйте ещё раз");
                }

                reject(error);
              }
            };

            xhr.upload.onprogress = (e: ProgressEvent<EventTarget>): void => {
              setFileProgress(newFile, e);
            };

            xhr.open("POST", actionLink);
            xhr.setRequestHeader("Authorization", `Bearer ${JSON.parse(String(authToken))}`);

            newFile.isSent = true;
            setDisabledDragger(true);

            xhr.send(formData);
          });
        }),
      )
        .catch(console.error)
        .finally(() => {
          uploadGroups(groups.slice(1));
          setDisabledDragger(false);
        });
    }
  };

  const handleBeforeUpload = (file: DraggerFileType, fileList: DraggerFileType[]): boolean => {
    countBeforeUpload++;

    const newFile: DraggerFileType = file;
    const isValid: boolean = handleFileValidate(file, tempFileList);

    newFile.isSent = false;
    newFile.status = isValid ? "normal" : "exception";

    // обработка кейса, когда прикрепили сразу больше лимита
    if (maxQuantity) {
      if (list.length + tempFileList.length < maxQuantity) {
        tempFileList.push(newFile);
      } else {
        setErrorMessage(`Разрешенное количество прикрепляемых файлов не более ${maxQuantity}`);
      }
    } else {
      tempFileList.push(newFile);
    }

    if (countBeforeUpload === fileList.length) {
      setIsUploading(true);

      if (tempFileList.length > 0) {
        setList((prevList) => [...prevList, ...tempFileList]);
      }
    }

    return false;
  };

  const renderFile = (file: DraggerFileType): JSX.Element => {
    return props.type === "photo" ? (
      <ImageContainer key={file.uid} file={file} onClickHandler={handleOnRemove} />
    ) : (
      <VideoPreviewContainer key={file.uid} file={file} onClickHandler={handleOnRemove} />
    );
  };

  return (
    <div className="mb-6">
      <div className="flex items-center">
        <div className="flex items-start">
          {required && (
            <Tooltip title="Для интеграции необходимо загрузить минимум одно фото">
              <span className="text-red-500 pr-1 cursor-default text-18-m">*</span>
            </Tooltip>
          )}
          <p className="text-16-m mb-3" style={{ color: "#374151" }}>
            {title}
          </p>
        </div>
        {showCounter && (
          <p className={css.count}>
            <span>{count}</span>
            <span>{maxQuantity ? `/${maxQuantity}` : ""}</span>
          </p>
        )}
      </div>
      <div className={`grid gap-3 mb-3 ${css.filelistWrap}`}>
        {!!list?.length &&
          list.map((file: DraggerFileType): JSX.Element =>
            file.status === "success"
              ? renderFile(file)
              : (
                <ProgressContainer
                  key={file.uid}
                  file={file}
                  fileType={props.type}
                  onClickHandler={handleOnRemove}
                  isProgressError={file.status === "exception"}
                />
              ),
        )}
      </div>
      {errorMessage && <p className={css.errorMsg}>{errorMessage}</p>}
        <Form.Item name={props.type} style={{display: (count < maxQuantity ? "block" : "none")}}>
          <Upload.Dragger
            action={actionLink}
            // @ts-expect-error у анта свой тип для статуса, который нам не подходит
            fileList={list}
            multiple={isMultiple}
            disabled={disabledDragger || isDisabled}
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
            style={{ borderWidth: 2, backgroundColor: "#fff" }}
          >
            <div className="flex flex-col items-center">
              <AddFileIcon />
              <p className="secondary-color my-3">{placeholder}</p>
              <GhostButton text="Выбрать" onClickHandler={() => {}} />
            </div>
          </Upload.Dragger>
        </Form.Item>
    </div>
  );
};

export default DragAndDrop;
