import * as ProductsAPI from "../../api/products.api";
import { ThunkAction } from "redux-thunk";
import {
  ProductsActionsType,
  addProductRequest,
  addProductFailureType,
  addProductSuccess,
  fetchProductsListRequest,
  fetchProductsListSuccess,
  fetchProductsListFailure,
  fetchProductRequest,
  fetchProductSuccess,
  fetchProductFailure,
  clearProductsList,
  updateProductRequest,
  updateProductSuccess,
  updateProductFailure,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailureType,
  updateWholeProductRequest,
  updateWholeProductSuccess,
  updateWholeProductFailureType,
  syncProductsRequest,
  syncProductsSuccess,
  syncProductsFailure,
  syncProductsCardRequest,
  syncProductsCardSuccess,
  syncProductsCardFailure,
  fetchCardInfoRequest,
  fetchProductsFacetsSuccess,
  fetchProductsFacetsFailure,
  fetchProductsFacetsRequest,
} from "../actions/products.actions";
import { AppStateType } from "../reducers/mainReducer";
import type { CardInfoType, RequestPaginationType, RequestProductsFiltersType } from "app/types";

// получение списка WB продуктов с пагинацией, фильтрацией и поиском
export const fetchProducts = (
  pagination: RequestPaginationType,
  filters?: RequestProductsFiltersType
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> => async (dispatch) => {
  dispatch(fetchProductsListRequest(pagination, filters ?? {}));
  try {
    const response = await ProductsAPI.list(pagination, filters ?? {});

    dispatch(fetchProductsListSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchProductsListFailure(error?.response?.data?.error));
  }
};

// создание номенклатурной позиции
export const addProduct = (params): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> =>
  async (dispatch) => {
    dispatch(addProductRequest());

    try {
      const response = await ProductsAPI.add(params);

      dispatch(addProductSuccess(response?.data));

      return response?.data;
    } catch (error) {
      console.log("error", error);
      dispatch(addProductFailureType(error?.response?.data?.error));
    }
  };

// получение номенклатурной позиции по ID
export const fetchProduct = (product_id: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> =>
  async (dispatch) => {
    dispatch(fetchProductRequest(product_id));

    try {
      const response = await ProductsAPI.getItem(product_id);

      dispatch(fetchProductSuccess(response?.data));

      return response;
    } catch (error: any) {
      dispatch(fetchProductFailure(error?.response?.data?.error));

      return error;
    }
  };

// редактирование номенклатурной позиции
export const updateProduct = (product_id: string, params): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> =>
  async (dispatch) => {
    dispatch(updateProductRequest(product_id, params));

    try {
      const response = await ProductsAPI.update(product_id, params);

      dispatch(updateProductSuccess(response?.data));

      return response;
    } catch (error) {
      dispatch(updateProductFailure(error?.response?.data?.error));
    }
  };

// редактирование номенклатурной позиции целиком
export const updateWholeProduct = (product_id: string, params): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> =>
  async (dispatch) => {
    dispatch(updateWholeProductRequest(product_id, params));

    try {
      const response = await ProductsAPI.updateWhole(product_id, params);

      dispatch(updateWholeProductSuccess(response?.data));

      return response;
    } catch (error) {
      dispatch(updateWholeProductFailureType(error?.response?.data?.error));
    }
  };

// удаление номенклатурной позиции по id
export const deleteProduct = (product_id: string): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> =>
  async (dispatch) => {
    dispatch(deleteProductRequest(product_id));

    try {
      const response = await ProductsAPI.remove(product_id);

      dispatch(deleteProductSuccess(product_id));

      return response;
    } catch (error) {
      dispatch(deleteProductFailureType(error?.response?.data?.error));
    }
  };

// Синхронизация номенклатурных позиций
export const syncProducts = (marketplaceId: string, products: string[]): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> => async (dispatch) => {
  dispatch(syncProductsRequest());

  try {
    const response = await ProductsAPI.syncProducts(marketplaceId, products);

    dispatch(syncProductsSuccess());

    return response;
  } catch (error) {
    dispatch(syncProductsFailure(error.response?.data?.error));

    return (error);
  }
};

// Синхронизация карточки
export const syncProductsCard = (marketplaceId: string, products: string[]): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> => async (dispatch) => {
  dispatch(syncProductsCardRequest());

  try {
    const response = await ProductsAPI.syncProductsCard(marketplaceId, products);

    dispatch(syncProductsCardSuccess());

    return response;
  } catch (error) {
    dispatch(syncProductsCardFailure(error.response?.data?.error));

    return (error);
  }
};

export const fetchCardInfo = (cardId: string): ThunkAction<
  Promise<CardInfoType>,
  AppStateType,
  undefined,
  ProductsActionsType
> => async (dispatch) => {
  dispatch(fetchCardInfoRequest(cardId));

  try {
    const response = await ProductsAPI.getCardInfo(cardId);

    dispatch(fetchProductSuccess(response?.data));

    return response?.data;
  } catch (error) {
    dispatch(fetchProductFailure(error.response?.data?.error));

    return (error);
  }
};

// Очистка найденных номенклатурных позиций
export const clearProducts = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> => async (dispatch) => {
  dispatch(clearProductsList());
};

// Фасеты
export const fetchProductsFacets = (
  filters: RequestProductsFiltersType,
  tab?: string
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsType
> => async (dispatch) => {
  dispatch(fetchProductsFacetsRequest(filters, tab));
  try {
    const response = await ProductsAPI.facets(filters);

    dispatch(fetchProductsFacetsSuccess(response?.data, tab));
  } catch (error) {

    dispatch(fetchProductsFacetsFailure(error));
  }
};