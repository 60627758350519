import * as WBColorsAPI from "../../api/wbColors.api";
import { ThunkAction } from "redux-thunk";
import {
  fetchWBColorSuccess,
  fetchWBColorFailure,
  fetchWBColorsListSuccess,
  fetchWBColorsListFailure,
  WbColorsActionsType, fetchWBColorsListRequest, fetchWBColorRequest,
} from "../actions/wbColors.actions";
import { AppStateType } from "../reducers/mainReducer";
import { FilterWBColorsType } from "app/types";

// получение списка WB цветов
export const fetchWBColors = (params: FilterWBColorsType | null = null):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbColorsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBColorsListRequest());

    try {
      const response = await WBColorsAPI.list(params);

      dispatch(fetchWBColorsListSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBColorsListFailure(error?.response?.data?.error));
    }
  };

// получение WB цвета
export const fetchWBColor = (colorId: string):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbColorsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBColorRequest());

    try {
      const response = await WBColorsAPI.getItem(colorId);

      dispatch(fetchWBColorSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBColorFailure(error?.response?.data?.error));
    }
  };
