import * as process from "process";
import axios from "../utils/axios";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const list = async (): Promise<any> => {
	return axios.GET(API_BASE, `/api/catalog/marketplaces`);
};

export const getItem = async (marketplaceId: string): Promise<any> => {
	return axios.GET(API_BASE, `/api/catalog/marketplaces/${marketplaceId}`);
};
