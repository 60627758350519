import React, { FC } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers/mainReducer";
import { Layout } from "antd";
import Sidebar from "../Sidebar/Sidebar";

interface ILayoutProps {
  children: React.ReactNode;
}

const { Header, Content } = Layout;

const MainLayout: FC<ILayoutProps> = ({ children }) => {
  const { authToken } = useSelector((state: AppStateType) => state.account);

  return (
    <Layout className="min-h-screen">
      <Layout hasSider>
        {authToken && <Sidebar />}
        <Layout>
          <Content className="bg-white">{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
