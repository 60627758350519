import * as RestsAPI from "../../api/rests";
import { ThunkAction } from "redux-thunk";
import { RequestPaginationType, RequestRestsFiltersType } from "app/types";
import {
  fetchRestsListFailure,
  fetchRestsListRequest,
  fetchRestsListSuccess,
  RestsActionsType
} from "../actions/rests.actions";
import { AppStateType } from "../reducers/mainReducer";

// Получение списка остатков по складу с пагинацией, фильтрацией и поиском
export const fetchRests = (
  pagination: RequestPaginationType,
  filters?: RequestRestsFiltersType
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  RestsActionsType
> => async (dispatch) => {
  dispatch(fetchRestsListRequest(pagination, filters ?? {}));
  try {
    const response = await RestsAPI.list(pagination, filters ?? {});

    dispatch(fetchRestsListSuccess(response?.data));
  } catch (error: any) {
    dispatch(fetchRestsListFailure(error?.response?.data?.error));
  }
};
