import { WBCharacteristicType } from "app/types";

export const getType = (
  charc: WBCharacteristicType,
  hasDictionary: boolean,
): "input" | "inputNumber" | "textArea" | "datePicker" | "select" => {
    // charc_type: 4 - число, max_count: 0 - неограниченное количество
  if (charc.charc_type === 4 && charc.max_count === 0) {
    return "inputNumber";
  } else if (hasDictionary || charc.max_count > 1) {
    return "select";
  }

  return "input";
};

export const getSelectMode = (
  maxCount: number,
  hasDictionary: boolean
): "tags" | "multiple" | null => {
  if (maxCount > 1) {
    if (!hasDictionary) {
      return "tags";
    }
    return "multiple";
  }

  return null;
};
