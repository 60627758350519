import { ThunkAction } from "redux-thunk";
import * as WBSeasonsAPI from "../../api/wbSeasons.api";
import {
  fetchWBSeasonSuccess,
  fetchWBSeasonFailure,
  fetchWBSeasonsListSuccess,
  fetchWBSeasonsListFailure,
  WbSeasonsActionsType, fetchWBSeasonsListRequest, fetchWBSeasonRequest,
} from "../actions/wbSeasons.actions";
import { AppStateType } from "../reducers/mainReducer";

// получение списка сезонов
export const fetchWBSeasons = (search: { search: string } | null = null):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbSeasonsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBSeasonsListRequest());

    try {
      const response = await WBSeasonsAPI.list(search);

      dispatch(fetchWBSeasonsListSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBSeasonsListFailure(error?.response?.data?.error));
    }
  };

// получение сезона
export const fetchWBSeason = (seasonId: string):
  ThunkAction<Promise<void>,
    AppStateType,
    undefined,
    WbSeasonsActionsType
  > =>
  async (dispatch) => {
    dispatch(fetchWBSeasonRequest());

    try {
      const response = await WBSeasonsAPI.getItem(seasonId);

      dispatch(fetchWBSeasonSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBSeasonFailure(error?.response?.data?.error));
    }
  };
