import React, { JSX, FC, useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import * as WBCategoriesAPI from "../../../api/wbCategories.api";
import { InitialAccountStateType } from "../../../redux/reducers/account.reducer";
import { AppStateType } from "../../../redux/reducers/mainReducer";
import type { DraggerFileType, ProductSizeType, ProductType, WBCharacteristicType } from "app/types";
import { InitialProductsStateType } from "../../../redux/reducers/products.reducer";
import { InitialWBCategoriesStateType } from "../../../redux/reducers/wbCategories.reducer";
import { InitialWBCharacteristicsStateType } from "../../../redux/reducers/wbCharacteristics.reducer";
import { AppDispatch } from "../../../redux/store/store";
import { updateProduct } from "../../../redux/thunks/products.thunks";
import { fetchWBCategories } from "../../../redux/thunks/wbCategories.thunks";
import { fetchWBCharacteristics } from "../../../redux/thunks/wbCharacteristics.thunks";
import { formatCategoriesList, OptionType } from "../../../utils/formatCategoriesList";

//components
import { Cascader, Divider, Form, FormInstance, message, Tooltip } from "antd";
import PhotoAndVideoBlock from "../ProductInfoCard/PhotoAndVideoBlock/PhotoAndVideoBlock";
import ProductInfoBlock from "../ProductInfoCard/ProductInfoBlock/ProductInfoBlock";
import DimensionsBlock from "../ProductInfoCard/DimensionsBlock/DimensionsBlock";
import DocumentsBlock from "../ProductInfoCard/DocumentsBlock/DocumentsBlock";
import CustomInput from "../../ui/CustomInput/CustomInput";

const { Item } = Form;

export type ProductCardFormType<T = string | number | string[]> = {
  [key: string]: T;
};
type CharacteristicParamType = {
  characteristic_id: string,
  value: string | string[];
};

type ParamType = {
  [key: string]: CharacteristicParamType[];
};

type ParamsType = {
  company_id: string;
  name?: string;
  description?: string;
  vendor_code?: string;
  sizes?: ProductSizeType[];
  preload_files?: string[];
  loaded_files?: string[];
};

const dimensionsNames: string[] = [
  "Длина упаковки",
  "Высота упаковки",
  "Ширина упаковки",
  "Ширина предмета",
  "Длина предмета",
  "Глубина предмета",
  "Высота предмета",
  "Вес товара с упаковкой (г)",
  "Вес с упаковкой (кг)",
  "Вес товара без упаковки (г)",
  "Вес без упаковки (кг)",
  "Толщина столешницы",
];

const documentsNames: string[] = [
  "Дата окончания действия сертификата/декларации",
  "Дата регистрации сертификата/декларации",
  "Номер декларации соответствия",
  "Номер сертификата соответствия",
];

interface IProductCardWrapperProps {
  form: FormInstance;
  values: ProductCardFormType;
  isEditMode: boolean;
  productCardId: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setProductCardId: React.Dispatch<React.SetStateAction<string>>;
  nomenclatureId: string;
  setNomenclatureId: React.Dispatch<React.SetStateAction<string>>;
  isResetFileList: boolean;
  isDisabledCategory: boolean;
}

const ProductCardWrapper: FC<IProductCardWrapperProps> = ({
  form,
  values,
  isEditMode,
  productCardId,
  setIsLoading,
  setProductCardId,
  nomenclatureId,
  setNomenclatureId,
  isResetFileList,
  isDisabledCategory
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const wbCategories: InitialWBCategoriesStateType = useSelector((state: AppStateType) => state.wbCategories);
  const wbCharacteristics: InitialWBCharacteristicsStateType = useSelector(
    (state: AppStateType) => state.wbCharacteristics,
  );
  const products: InitialProductsStateType = useSelector((state: AppStateType) => state.products);
  const account: InitialAccountStateType = useSelector((state: AppStateType) => state.account);

  const { getFieldValue } = form;

  const [options, setOptions] = useState<OptionType[]>(null);
  const [dimensions, setDimensions] = useState<WBCharacteristicType[]>(null);
  const [documents, setDocuments] = useState<WBCharacteristicType[]>(null);
  const [characteristics, setCharacteristics] = useState<WBCharacteristicType[]>(null);
  const [childCategoryId, setChildCategoryId] = useState<string>(null);
  const [isDisabledFields, setIsDisabledFields] = useState<boolean>(true);

  useEffect((): void => {
    !wbCategories?.list && dispatch(fetchWBCategories());
  }, [wbCategories?.list, dispatch]);

  useEffect(() => {
     setIsDisabledFields(!nomenclatureId);
  }, [nomenclatureId]);

  useEffect(() => {
    if (!options) {
      setOptions(wbCategories?.formattedList);
    }
  }, [options, wbCategories?.formattedList]);

  useMemo(() => {
    const category = values?.category;

    category && setChildCategoryId(category[(category as string[])?.length - 1]);
  }, [values?.category]);

  useEffect(() => {
    childCategoryId && dispatch(fetchWBCharacteristics({ category: childCategoryId }));
  }, [childCategoryId, dispatch]);

  useEffect(() => {
    const filterCharacteristics = (list: WBCharacteristicType[], names: string[]) =>
      list?.filter((charc: WBCharacteristicType) => names?.includes(charc.name));

    const onlyDimensions: WBCharacteristicType[] = filterCharacteristics(wbCharacteristics?.list, dimensionsNames);
    const onlyDocuments: WBCharacteristicType[] = filterCharacteristics(wbCharacteristics?.list, documentsNames);

    // Из общего списка характеристик убираем габариты и документы, т.к. они вынесены в отдельные списки
    const croppedCharacteristics: WBCharacteristicType[] = wbCharacteristics?.list?.filter(
      (charc: WBCharacteristicType) => !onlyDimensions?.includes(charc) && !onlyDocuments?.includes(charc)
    );

    setDimensions(onlyDimensions);
    setDocuments(onlyDocuments);
    setCharacteristics(croppedCharacteristics?.sort((a, b) => a.name.localeCompare(b.name)));
  }, [wbCharacteristics?.list]);

  useEffect(() => {
    if (isEditMode && options && values?.category) {
      setChildrenInOptions();
    }
  }, [values?.category, options?.length]);

  const setChildrenInOptions = async (): Promise<void> => {
    const category = getFieldValue("category");

    const updatedOptions: OptionType[] = await Promise.all(
      options.map(async (option) => {
        if (category.includes(option.id)) {
          const response = await WBCategoriesAPI.list({ parent: option.id });

          if (response.status === 200) {
            return {
              ...option,
              children: formatCategoriesList(response.data),
            };
          }
        }
        return option;
      })
    );

    setOptions(updatedOptions);
    setIsLoading(false);
  };

  const loadData = async (selectedOptions: OptionType[]): Promise<void> => {
    const targetOption: OptionType = selectedOptions[selectedOptions.length - 1];

    try {
      setOptions((prevOptions) => prevOptions.map((option: OptionType) =>
        option.id === targetOption.id ? { ...option, loading: true } : option
      ));

      const response = await WBCategoriesAPI.list({ parent: targetOption?.id });

      if (response.status === 200) {
        const updatedOptions: OptionType[] = options.map((option: OptionType) =>
            option.id === targetOption.id ? { ...option, children: formatCategoriesList(response.data) } : option
        );

        setOptions(updatedOptions);
      }
    } catch (error) {
      message.error("Не удалось загрузить категории. Попробуйте еще раз");
    } finally {
      setOptions((prevOptions) => prevOptions.map((option: OptionType) =>
        option.id === targetOption.id ? { ...option, loading: false } : option
      ));
    }
  };

  const currentProduct: ProductType = products.list?.find((product) => product.id === nomenclatureId);

  const formatPrice = (value: string): string => {
    // разделяет символы по тысячам и удаляет точки
    return `${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")?.replace(/\./g, "");
  };

  const updateCharc = (fieldName: string): void => {
    const fieldValue = getFieldValue(fieldName);
    const params: ParamsType | ParamType = {
      company_id: account?.userInfo?.company?.id,
    };

      switch (fieldName) {
        case "name": {
          if (fieldValue) {
            const nameFieldId: string = characteristics
              ?.find((charc) => charc.name === "Наименование")?.id;

            params["name"] = fieldValue;
            params["card_id"] = productCardId;
            params["characteristic_values"] = [{
              characteristic_id: nameFieldId,
              value: getFieldValue(nameFieldId).trim(),
            }];
          }

          break;
        }
        case "description": {
          const descriptionFieldId: string = characteristics
            ?.find((charc) => charc.name === "Описание")?.id;

          params["description"] = fieldValue?.trim() ?? "";
          params["characteristic_values"] = [{
            characteristic_id: descriptionFieldId,
            value: getFieldValue(descriptionFieldId)?.trim() ?? "",
          }];

          break;
        }
        case "vendor_code": {
          params["vendor_code"] = fieldValue?.trim() ?? "";

          break;
        }
        case "barcode": {
          if (fieldValue) {
            params["sizes"] = [{
              barcodes: [{ [fieldName]: fieldValue?.toString() ?? "" }]
            }];
          } else {
            params["sizes"] = [{
              barcodes: []
            }];
          }
          break;
        }
        case "wb_size":
        case "tech_size": {
          params["sizes"] = [{ [fieldName]: fieldValue?.toString()?.trim() ?? "" }];

          break;
        }
        default: {
          if (Array.isArray(fieldValue) && !!fieldValue?.length) {
            const characteristicValues = fieldValue?.map((val) => {
              return { characteristic_id: fieldName, value: val.toString() };
            });

            params["characteristic_values"] = characteristicValues?.length ? [...characteristicValues] : [];
          } else if (dayjs.isDayjs(fieldValue) && typeof fieldValue === "object") {
            params["characteristic_values"] = [{
              characteristic_id: fieldName,
              value: fieldValue.toISOString() ?? ""
            }];
          } else {
            params["characteristic_values"] = [{
              characteristic_id: fieldName,
              value: fieldValue?.toString() ?? ""
            }];
          }
        }
      }

    editProduct(nomenclatureId, params);
  };

  const addMedia = (file: DraggerFileType): DraggerFileType => {
    const preloadFilesIds: string[] = [];
    const params: ParamsType | ParamType = {
      company_id: account?.userInfo?.company?.id,
    };

    preloadFilesIds.push(file?.responseId);

    params["preload_files"] = preloadFilesIds;

    editProduct(nomenclatureId, params)
      .then((response) => {
        if (response?.medias) {
          const medias = response?.medias;

          file.loadingType = "loaded";
          file.responseId = medias[medias?.length - 1]?.id;
        } else {
          message.error("Не удалось сохранить в черновик");
        }
    });

    return file;
  };

  const deleteMedia = (fileList: DraggerFileType[]): DraggerFileType[] => {
    const loadedFilesIds: string[] = [];
    let filelist = fileList;
    const params: ParamsType | ParamType = {
      company_id: account?.userInfo?.company?.id,
    };

    fileList?.forEach((file) => {
      if (file?.responseId && file?.loadingType === "loaded") {
        loadedFilesIds.push(file?.responseId);
      }
    });

    params["loaded_files"] = loadedFilesIds;

    editProduct(nomenclatureId, params)
      .then((response) => {
        filelist = response?.medias;
      });

    return filelist;
  };

  const editProduct = async (nomenclatureId: string, params: ParamsType | ParamType): Promise<any> => {
    return await dispatch(updateProduct(nomenclatureId, params))
      .then((res: any) => {
        if (res?.status === 200) {
          message.success("Черновик сохранен");
        } else {
          message.error(`Не удалось сохранить черновик`);
        }

        return res?.data;
      });
  };

  return (
    <div>
      <h4 className="text-h4-b mb-3">Информация о товаре</h4>
      <p className="secondary-color mb-8">
        {`Внимательно заполните все необходимые поля и соблюдайте установленные правила 
        для корректного размещения товаров.`}
      </p>
      <p className="text-14-m mb-2">
        <Tooltip title="Является обязательным полем">
          <span className="text-red-500 pr-1 cursor-default">*</span>
        </Tooltip>
        Категория
      </p>
      <Item
        name="category"
        rules={[
          {
            required: true,
            message: "Это поле обязательно для заполнения",
          },
        ]}
      >
        <Cascader
          options={options}
          loadData={loadData}
          className="w-full input-height"
          placeholder="Выберите категорию"
          notFoundContent="Категория не найдена"
          showSearch
          disabled={isDisabledCategory || !!products.list?.length || !!productCardId}
        />
      </Item>
      {values?.category && (
        <>
          <ProductInfoBlock
            form={form}
            characteristics={characteristics}
            isDisabledFields={isDisabledFields}
            productCardId={productCardId}
            setProductCardId={setProductCardId}
            nomenclatureId={nomenclatureId}
            setNomenclatureId={setNomenclatureId}
            setIsDisabledFields={setIsDisabledFields}
            updateCharc={updateCharc}
          />
          {!!dimensions?.length && (
            <DimensionsBlock
              dimensions={dimensions}
              form={form}
              isDisabledFields={isDisabledFields}
              updateCharc={updateCharc}
            />
          )}
          {!!documents?.length && (
            <DocumentsBlock
              documents={documents}
              form={form}
              isDisabledFields={isDisabledFields}
              updateCharc={updateCharc}
            />
          )}
          <PhotoAndVideoBlock
            form={form}
            resetFileList={!nomenclatureId || isResetFileList}
            isDisabledFields={isDisabledFields}
            addMedia={addMedia}
            deleteMedia={deleteMedia}
            currentProduct={currentProduct}
          />
          {/*Временно скрываем цену*/}
          {/*<Divider />*/}
          {/*<h4 className="text-h4-b mb-3">Цена</h4>*/}
          {/*<CustomInput*/}
          {/*  min={50}*/}
          {/*  type="inputNumber"*/}
          {/*  form={form}*/}
          {/*  format={formatPrice}*/}
          {/*  title="Цена товара"*/}
          {/*  suffix="RUB"*/}
          {/*  fieldName="price"*/}
          {/*  isDisabled={isDisabledFields}*/}
          {/*  placeholder="Введите стоимость"*/}
          {/*  tooltipTitle="Цена указывается без копеек. Минимальная цена 50 руб."*/}
          {/*/>*/}
        </>
      )}
    </div>
  );
};

export default ProductCardWrapper;
