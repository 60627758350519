import React, { JSX, FC } from "react";
import ConnectWrapper from "../../components/Dashboard/ConnectWrapper/ConnectWrapper";

interface IConnectPageProps {

}

const ConnectPage: FC<IConnectPageProps> = (): JSX.Element => {
  return (
    <div className="p-6">
      <ConnectWrapper />
    </div>
  );
};

export default ConnectPage;
