import * as WBCategoriesAPI from "../../api/wbCategories.api";
import { ThunkAction } from "redux-thunk";
import {
  fetchWBCategorySuccess,
  fetchWBCategoryFailure,
  fetchWBCategoriesListSuccess,
  fetchWBCategoriesListFailure,
  WBCategoriesActionsType,
  fetchWBCategoriesListRequest,
  fetchWBCategoryRequest,
} from "../actions/wbCategories.actions";
import { WBCharacteristicsActionsType } from "../actions/wbCharacteristics.actions";
import { AppStateType } from "../reducers/mainReducer";
import { FilterWBCategoriesType } from "app/types";

// получение списка WB категорий
export const fetchWBCategories = (params: FilterWBCategoriesType | null = null):
  ThunkAction<Promise<void>,
  AppStateType,
  undefined,
  WBCategoriesActionsType | WBCharacteristicsActionsType
> =>
  async (dispatch) => {
    dispatch(fetchWBCategoriesListRequest(params));

    try {
      const response = await WBCategoriesAPI.list(params);

      dispatch(fetchWBCategoriesListSuccess(response?.data));
    } catch (error: any) {
      dispatch(fetchWBCategoriesListFailure(error.response?.data?.error));
    }
  };

// получение WB категории
export const fetchWBCategory = (categoryId: string):
  ThunkAction<Promise<void>,
  AppStateType,
  undefined,
  WBCategoriesActionsType
> =>
  async (dispatch) => {
    dispatch(fetchWBCategoryRequest(categoryId));

    try {
      const response = await WBCategoriesAPI.getItem(categoryId);

      dispatch(fetchWBCategorySuccess(response?.data));

      return response;
    } catch (error: any) {
      dispatch(fetchWBCategoryFailure(error.response?.data?.error));
    }
  };
