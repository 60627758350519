import * as WarehousesAPI from "../../api/warehouses.api";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { WarehouseRequestType, WarehouseType } from "app/types";
import { AppStateType } from "../reducers/mainReducer";
import * as WarehouseActions from "../actions/warehouses.actions";

// получение листинга складов
export const fetchWarehouses = (): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	WarehouseActions.WarehousesActionsType
> => async (dispatch) => {
	dispatch(WarehouseActions.fetchWarehousesListRequest());
	try {
		const response: AxiosResponse<WarehouseType[]> = await WarehousesAPI.list();

		dispatch(WarehouseActions.fetchWarehousesListSuccess(response.data));
	} catch (error) {
		dispatch(WarehouseActions.fetchWarehousesListFailure(error.response?.data?.error));
	}
};

// Получение склада по ID
export const findWarehouse = (warehouseId: string): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	WarehouseActions.WarehousesActionsType
> => async (dispatch) => {
	dispatch(WarehouseActions.findWarehouseRequest(warehouseId));
	try {
		const response: AxiosResponse<WarehouseType> = await WarehousesAPI.find(warehouseId);

		dispatch(WarehouseActions.findWarehouseSuccess(response.data));
	} catch (error) {
		dispatch(WarehouseActions.findWarehouseFailure(error.response?.data?.error));
	}
};

// Очистка найденного склада
export const clearFoundWarehouse = (): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	WarehouseActions.WarehousesActionsType
> => async (dispatch) => {
	dispatch(WarehouseActions.findWarehouseClear());
};

// Добавление склада
export const addWarehouse = (warehouse: WarehouseRequestType): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	WarehouseActions.WarehousesActionsType
> => async (dispatch) => {
	dispatch(WarehouseActions.addWarehouseRequest(warehouse));
	try {
		await WarehousesAPI.add(warehouse);

		dispatch(WarehouseActions.addWarehouseSuccess());

		await dispatch(fetchWarehouses());
	} catch (error) {
		dispatch(WarehouseActions.addWarehousesFailure(error.response?.data?.error));
	}
};

// Обновление склада
export const updateWarehouse = (
	warehouse: WarehouseRequestType,
	warehouseId: string
): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	WarehouseActions.WarehousesActionsType
> => async (dispatch) => {
	dispatch(WarehouseActions.updateWarehouseRequest(warehouse, warehouseId));
	try {
		await WarehousesAPI.update(warehouse, warehouseId);

		dispatch(WarehouseActions.updateWarehouseSuccess());

		await dispatch(fetchWarehouses());
	} catch (error) {
		dispatch(WarehouseActions.updateWarehousesFailure(error.response?.data?.error));
	}
};

// Удаление склада
export const deleteWarehouse = (warehouseId: string): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	WarehouseActions.WarehousesActionsType
> => async (dispatch) => {
	dispatch(WarehouseActions.deleteWarehouseRequest(warehouseId));
	try {
		await WarehousesAPI.remove(warehouseId);

		dispatch(WarehouseActions.deleteWarehouseSuccess());

		await dispatch(fetchWarehouses());
	} catch (error) {
		dispatch(WarehouseActions.deleteWarehousesFailure(error.response?.data?.error));
	}
};

// Синхронизация склада
export const syncWarehouse = (warehouseId: string, marketplaceId: string): ThunkAction<
	Promise<void>,
	AppStateType,
	undefined,
	WarehouseActions.WarehousesActionsType
> => async (dispatch) => {
	dispatch(WarehouseActions.syncWarehouseRequest(warehouseId, marketplaceId));
	try {
		await WarehousesAPI.sync(warehouseId, marketplaceId);

		dispatch(WarehouseActions.syncWarehouseSuccess());

		await dispatch(fetchWarehouses());
	} catch (error) {
		dispatch(WarehouseActions.syncWarehousesFailure(error.response?.data?.error));

		return (error);
	}
};