import React, { JSX, FC } from "react";
import { WBCharacteristicType } from "app/types";
import { FormInstance } from "antd";
import CustomInput from "../../../ui/CustomInput/CustomInput";

interface IDocumentsBlockProps {
  form: FormInstance;
  documents: WBCharacteristicType[];
  isDisabledFields: boolean;
  updateCharc: (fieldName: string) => void;
}

const DocumentsBlock: FC<IDocumentsBlockProps> = ({
  form,
  documents,
  isDisabledFields,
  updateCharc,
}): JSX.Element => {
  return (
    <div>
      <h4 className="text-h4-b mb-3">Документы</h4>
      <p className="secondary-color mb-8">
        {`Добавьте документы, чтобы получить статус «Качество подтверждено». 
          По статистике товары с проверенным качеством покупают чаще.`}
      </p>
      <div className="grid grid-cols-2 gap-3">
        {documents?.map(({ id, name, required }) => (
          <CustomInput
            key={id}
            form={form}
            type={name?.toLowerCase().includes("дата") ? "datePicker" : "input"}
            title={name}
            required={required}
            placeholder={name}
            fieldName={id}
            isDisabled={isDisabledFields}
            onBlurHandler={() => updateCharc(id)}
          />
        ))}
      </div>
    </div>
  );
};

export default DocumentsBlock;
