import { BasicEntityType } from "app/types";
import {
  FETCH_WB_KIND_FAILURE,
  FETCH_WB_KIND_REQUEST,
  FETCH_WB_KIND_SUCCESS,
  FETCH_WB_KINDS_LIST_FAILURE,
  FETCH_WB_KINDS_LIST_REQUEST,
  FETCH_WB_KINDS_LIST_SUCCESS
} from "../../constants/actionTypes";

export type WbKindsActionsType =
  | FetchWBKindsListRequestType
  | FetchWBKindsListSuccessType
  | FetchWBKindsListFailureType
  | FetchWBKindRequestType
  | FetchWBKindSuccessType
  | FetchWBKindFailureType;

// получение списка полов
type FetchWBKindsListRequestType = {
  type: typeof FETCH_WB_KINDS_LIST_REQUEST
};

export const fetchWBKindsListRequest = (): FetchWBKindsListRequestType => ({
  type: FETCH_WB_KINDS_LIST_REQUEST
});

type FetchWBKindsListSuccessType = {
  type: typeof FETCH_WB_KINDS_LIST_SUCCESS;
  payload: {
    kinds: BasicEntityType[];
  };
};

export const fetchWBKindsListSuccess = (
  kinds: BasicEntityType[],
): FetchWBKindsListSuccessType => ({
  type: FETCH_WB_KINDS_LIST_SUCCESS,
  payload: {
    kinds,
  },
});

type FetchWBKindsListFailureType = {
  type: typeof FETCH_WB_KINDS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBKindsListFailure = (error: string): FetchWBKindsListFailureType => ({
  type: FETCH_WB_KINDS_LIST_FAILURE,
  payload: {
    error,
  },
});

// получение пола
type FetchWBKindRequestType = {
  type: typeof FETCH_WB_KIND_REQUEST
};

export const fetchWBKindRequest = (): FetchWBKindRequestType => ({
  type: FETCH_WB_KIND_REQUEST
});

type FetchWBKindSuccessType = {
  type: typeof FETCH_WB_KIND_SUCCESS;
  payload: {
    kind: BasicEntityType;
  };
};

export const fetchWBKindSuccess = (
  kind: BasicEntityType,
): FetchWBKindSuccessType => ({
  type: FETCH_WB_KIND_SUCCESS,
  payload: {
    kind,
  },
});

type FetchWBKindFailureType = {
  type: typeof FETCH_WB_KIND_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBKindFailure = (error: string): FetchWBKindFailureType => ({
  type: FETCH_WB_KIND_FAILURE,
  payload: {
    error,
  },
});
