import React, { JSX, FC } from "react";

// components
import PricesAndStocksTab from "components/PricesAndStocks/PricesAndStocksTabs/PricesAndStocksTabs";
import StickyHeader from "../../components/ui/StickyHeader/StickyHeader";

interface IProductsPageProps {}

const ProductsPage: FC<IProductsPageProps> = (): JSX.Element => {
  return (
    <div>
      <StickyHeader
        title="Цены и остатки"
        isShowDivider={false}
      />
      <PricesAndStocksTab />
    </div>
  );
};

export default ProductsPage;
