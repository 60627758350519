import { WBColorType } from "app/types";
import {
  FETCH_WB_COLOR_FAILURE, FETCH_WB_COLOR_REQUEST,
  FETCH_WB_COLOR_SUCCESS,
  FETCH_WB_COLORS_LIST_FAILURE, FETCH_WB_COLORS_LIST_REQUEST,
  FETCH_WB_COLORS_LIST_SUCCESS
} from "../../constants/actionTypes";

export type WbColorsActionsType =
  | FetchWBColorsListSuccessType
  | FetchWBColorsListRequestType
  | FetchWBColorsListFailureType
  | FetchWBColorRequestType
  | FetchWBColorSuccessType
  | FetchWBColorFailureType;

// получение списка цветов
type FetchWBColorsListRequestType = {
  type: typeof FETCH_WB_COLORS_LIST_REQUEST
};

export const fetchWBColorsListRequest = (): FetchWBColorsListRequestType => ({
  type: FETCH_WB_COLORS_LIST_REQUEST
});

type FetchWBColorsListSuccessType = {
  type: typeof FETCH_WB_COLORS_LIST_SUCCESS;
  payload: {
    colors: WBColorType[];
  };
};

export const fetchWBColorsListSuccess = (
  colors: WBColorType[],
): FetchWBColorsListSuccessType => ({
  type: FETCH_WB_COLORS_LIST_SUCCESS,
  payload: {
    colors,
  },
});

type FetchWBColorsListFailureType = {
  type: typeof FETCH_WB_COLORS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBColorsListFailure = (error: string): FetchWBColorsListFailureType => ({
  type: FETCH_WB_COLORS_LIST_FAILURE,
  payload: {
    error,
  },
});

// получение цвета
type FetchWBColorRequestType = {
  type: typeof FETCH_WB_COLOR_REQUEST
};

export const fetchWBColorRequest = (): FetchWBColorRequestType => ({
  type: FETCH_WB_COLOR_REQUEST
});

type FetchWBColorSuccessType = {
  type: typeof FETCH_WB_COLOR_SUCCESS;
  payload: {
    color: WBColorType;
  };
};

export const fetchWBColorSuccess = (
  color: WBColorType,
): FetchWBColorSuccessType => ({
  type: FETCH_WB_COLOR_SUCCESS,
  payload: {
    color,
  },
});

type FetchWBColorFailureType = {
  type: typeof FETCH_WB_COLOR_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchWBColorFailure = (error: string): FetchWBColorFailureType => ({
  type: FETCH_WB_COLOR_FAILURE,
  payload: {
    error,
  },
});
