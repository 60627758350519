import React, { JSX, FC } from "react";
import { ReactComponent as InfoIcon } from "../../../assets/icons/warning_circle_icon.svg";

import css from "./style.module.css";

interface IInfoCardProps {
  text: string;
  actionBtn?: React.ReactNode;
  className?: string;
}

const InfoCard: FC<IInfoCardProps> = ({text, actionBtn, className = ""}): JSX.Element => {
  return (
    <div className={`flex justify-between items-center p-5 rounded-xl my-3 ${css.wrapper} ${className}`}>
      <div className="flex items-center">
        <div className={`mr-3 blue-color ${css.icon}`}>
          <InfoIcon />
        </div>
        <p className={`text-14-r mr-3 ${css.text}`}>{text}</p>
      </div>
      {actionBtn}
    </div>
  );
};

export default InfoCard;
