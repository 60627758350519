import { WBCategoryType } from "app/types";
import React from "react";

export type OptionType = {
  id: string;
  parent: string;
  value: string;
  label: React.ReactNode;
  isLeaf: boolean;
  loading?: boolean;
  children?: OptionType[];
};

export const formatCategoriesList = (categories: WBCategoryType[]): OptionType[] => {
  const newCategories = categories?.map((category) => category) ?? null;

  return newCategories
    ?.map((category: WBCategoryType) => {
      return {
        id: category.id,
        parent: category.parent,
        value: category.id,
        label: category.name,
        isLeaf: !category.has_children,
      };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));
};
