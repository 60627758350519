import * as process from "process";
import axios from "../utils/axios";
import { FilterWBColorsType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

export const list = async (params: FilterWBColorsType | null = null): Promise<any> => {
  return axios.GET(API_BASE, "/api/catalog/wb_colors", params);
};

export const getItem = async (wbColorId: string): Promise<any> => {
  return axios.GET(API_BASE, `/api/catalog/wb_colors/${wbColorId}`);
};
