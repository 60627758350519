import React, { JSX, FC } from "react";
import { Tag } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import type {AppStateType} from "../../../../redux/reducers/mainReducer";
import { InitialSuppliesStateType } from "../../../../redux/reducers/supplies.reducer";
import { ReactComponent as HelpOutlinedIcon } from "../../../../assets/icons/help_outlined_icon.svg";
import { SupplyStatuses } from "../SupplyWrapper";

interface ISupplyInfoPanelProps {
  isAssemblyStatus: boolean;
}

const SupplyInfoPanel: FC<ISupplyInfoPanelProps> = ({isAssemblyStatus}): JSX.Element => {
  const supplies: InitialSuppliesStateType = useSelector((state: AppStateType) => state.supplies);

  const renderParagraph = (title: string, descr: string | number, isTag: boolean = false): JSX.Element => {
    return (
      <p className="flex flex-col secondary-color">
        {title}
        {!isTag ? (
          <span className="main-color">{descr}</span>
        ) : (
          <Tag color="processing" className="flex items-end justify-center">
            {descr}
            <HelpOutlinedIcon className="ml-1" />
          </Tag>
        )}
      </p>
    );
  };

  return (
    <div className="flex justify-between items-center p-6 rounded-xl" style={{backgroundColor: "#F3F4F6"}}>
      {renderParagraph("Дата создания поставки", dayjs(supplies.found?.created_at).format("DD.MM.YY"))}
      {renderParagraph("Количество товаров", supplies.found?.orders_count ?? 0)}
      {renderParagraph("Стоимость товаров", `${supplies.found?.orders_sum ?? 0} руб`)}
      <div style={{marginRight: 150}}>
        {renderParagraph(
          "Статус поставки",
          isAssemblyStatus
            ? SupplyStatuses.assemblyStatus
            : SupplyStatuses.deliveryStatus,
          true
        )}
      </div>
    </div>
  );
};

export default SupplyInfoPanel;
