import React, { JSX, FC } from "react";

import GoBackButton from "../../ui/GoBackButton/GoBackButton";
import ConnectForm from "./ConnectForm/ConnectForm";
import ConnectInfoCard from "./ConnectInfoCard/ConnectInfoCard";

interface IConnectWrapperProps {

}

const ConnectWrapper: FC<IConnectWrapperProps> = (): JSX.Element => {
  return (
    <div>
      <GoBackButton link="/dashboard" text="Назад" />
      <div className="flex gap-3">
        <ConnectForm />
        <ConnectInfoCard />
      </div>
    </div>
  );
};

export default ConnectWrapper;
