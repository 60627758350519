import React, { JSX, FC, useState, Key } from "react";

import * as SupplyBoxesAPI from "../../../api/supplyBoxes.api";
import { TableRowSelection } from "antd/es/table/interface";
import { OrderType, RequestOrdersFiltersType, RequestPaginationType } from "app/types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import type { AppStateType } from "../../../redux/reducers/mainReducer";
import type { InitialOrdersStateType } from "../../../redux/reducers/orders.reducer";

// components
import { Divider, message, Modal } from "antd";
import GhostButton from "../../ui/GhostButton/GhostButton";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";
import ProductsTable, { TableColumnType } from "../../Products/ProductsTable/ProductsTable";
import EmptyBlock from "components/EmptyBlock/EmptyBlock";
import { SearchInput } from "components/ui/SearchInput/SearchInput";

// Icons
import { ReactComponent as ExclamationDarkIcon } from "../../../assets/icons/exclamation_dark.svg";

import css from "./style.module.css";

interface IAddBoxModalProps {
  onCancelHandler: () => void;
  fetchOrders: (pagination: RequestPaginationType, filters: RequestOrdersFiltersType) => Promise<void>;
  currentBoxId: string;
  title: string;
  onSuccessHandler?: () => void;
}

const commonColumns: TableColumnType[] = [
  "photo", "name", "size", "color",
  "order_number", "barcode", "due_date"
];

const AddBoxModal: FC<IAddBoxModalProps> = ({
  onCancelHandler,
  fetchOrders,
  currentBoxId,
  title,
  onSuccessHandler
}): JSX.Element => {
  const { supplyId } = useParams();

  const orders: InitialOrdersStateType = useSelector((state: AppStateType) => state.orders);

  const [filters, setFilters] = useState<RequestOrdersFiltersType>({
    supply_id: supplyId,
    without_box: true
  });
  const [selectedRows, setSelectedRows] = useState<OrderType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);

  const renderSearch = (): JSX.Element => {
    return (
      <div className={css.wrap}>
        <SearchInput
          placeholder="Найти по номеру задания, названию товара или баркоду"
          onSearch={(state: RequestOrdersFiltersType) => setFilters(state)}
          filters={filters}
          isGeneralSearch={false}
          isDisabled={false}
        />
      </div>
    );
  };

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  const clearData = (): void => {
    setSelectedKeys(null);
    setSelectedRows(null);
  };

  const handleAddOrdersToBox = async (): Promise<void> => {
    const orderIds: string[] = selectedRows.map((order: OrderType) => order.id);

    if (currentBoxId && !!orderIds?.length) {
      await SupplyBoxesAPI.addOrdersToBox({
        supply_id: supplyId,
        box_id: currentBoxId,
        order_ids: orderIds
      })
        .then(() => {
          message.success("Товары успешно добавлены");

          clearData();
          onCancelHandler();
          onSuccessHandler && onSuccessHandler();
        })
        .catch((err) => message.error(err?.response?.data?.error));
    } else {
      message.error("При добавлении товаров в короб произошла ошибка. Пожалуйста, попробуйте позднее");
    }
  };

  return (
    <Modal
      width={1200}
      open
      footer={null}
      centered
      onCancel={() => {
        clearData();
        onCancelHandler();
      }}
      maskStyle={{ backgroundColor: "rgba(103, 118, 140, 0.5)" }}
    >
      <h4 className="mb-4">{title}</h4>
      <p className="secondary-color mb-4">Выберите задания для добавления в короб и дальнейшей поставки в ПВЗ</p>
      {renderSearch()}
      <ProductsTable
        className="mt-8"
        columns={commonColumns}
        list={orders.list}
        count={orders.count}
        fetchList={fetchOrders}
        isFetching={orders.isFetching}
        rowSelection={rowSelection}
        filters={filters}
        emptyBlock={
          <div className="flex flex-1 justify-center items-center">
            <EmptyBlock
              style={{ height: "calc(100dvh - 25rem)" }}
              icon={<ExclamationDarkIcon />}
              title="Заданий в коробку нет"
              description={<>Cборочных заданий не найдено</>}
            />
          </div>
        }
        isShowEmptyBlock={true}
      />
      <Divider />
      <div className="flex justify-end">
        <GhostButton
          className="mr-3"
          size="large"
          text="Назад"
          onClickHandler={onCancelHandler}
        />
        <PrimaryButton
          text="Добавить товары в короб"
          onClickHandler={handleAddOrdersToBox}
          isDisabled={!selectedRows || selectedRows?.length === 0}
        />
      </div>
    </Modal>
  );
};

export default AddBoxModal;
