import React, { JSX, FC, useEffect } from "react";
import { useWatch } from "antd/es/form/Form";

// components
import { Divider, Form, Modal, Spin } from "antd";
import CustomInput from "../../ui/CustomInput/CustomInput";
import GhostButton from "../../ui/GhostButton/GhostButton";
import PrimaryButton from "../../ui/PrimaryButton/PrimaryButton";

interface ICreateSupplyModalProps {
  isOpen: boolean;
  isLoading: boolean;
  createSupply: (name: string) => Promise<void>;
  onCancelHandler: () => void;
}

const CreateSupplyModal: FC<ICreateSupplyModalProps> = ({
  isOpen,
  isLoading,
  createSupply,
  onCancelHandler
}): JSX.Element => {
  const [form] = Form.useForm();
  const values = useWatch([], form);

  useEffect(() => {
    form.setFieldsValue({ type: { label: "Обычная", value: 1 } });
  }, []);

  return (
    <Modal
      width={572}
      open={isOpen}
      footer={null}
      centered
      onCancel={onCancelHandler}
      maskStyle={{ backgroundColor: "rgba(103, 118, 140, 0.5)" }}
    >
      <Spin spinning={isLoading}>
        <Form form={form}>
          <h4 className="mb-6">О поставке</h4>
          <p className="secondary-color mb-4">
            Укажите название поставки и выберите ее тип.
          </p>
            <CustomInput
              type="input"
              title="Название поставки"
              placeholder="Введите название поставки"
              form={form}
              fieldName="name"
              required
              maxLength={60}
              showCount
            />
            <CustomInput
              type="select"
              title="Тип поставки"
              form={form}
              fieldName="type"
              required
              isDisabled={true}
            />
          <Divider/>
          <div className="flex justify-end">
            <GhostButton
              text="Назад"
              onClickHandler={onCancelHandler}
              size="large"
              className="mr-3"
            />
            <PrimaryButton
              text="Создать поставку"
              isDisabled={!values?.name || !values?.type}
              onClickHandler={() => createSupply(values?.name)}
            />
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateSupplyModal;
