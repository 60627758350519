import React, { FC, useState } from "react";
import { ReactComponent as NoPictureIcon } from "../../../assets/icons/no_photography.svg";
import css from "./style.module.css";

interface ImageContainerProps {
  src: string;
  alt: string;
  filename: string;
  imageClass?: string;
  noImageClass?: string;
  imageContainerClass?: string;
  onClick?: () => void;
}

const ImageContainer: FC<ImageContainerProps> = ({
  src,
  alt,
  filename,
  imageClass,
  noImageClass,
  imageContainerClass,
  onClick,
}) => {
  const [hasError, setHasError] = useState<boolean>(false);

  const noImageClassName: string =
    typeof (noImageClass) === "string" ? noImageClass : css.noImage;
  const imageContainerClassName: string =
    typeof (imageContainerClass) === "string" ? imageContainerClass : css.imageContainer;

  const handleError = (): void => {
    setHasError(true);
  };
  const isImage = (name: string = ""): boolean => {
    // Регулярное выражение для проверки расширения файла изображения
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(name);
  };

  return (
    <div className={imageContainerClassName} onClick={onClick}>
      {isImage(filename) && !hasError ? (
        <img src={src} alt={alt} onError={handleError} className={imageClass ?? ""} />
      ) : (
        <NoPictureIcon
          width="100%"
          height="100%"
          className={noImageClassName}
        />
      )}
    </div>
  );
};

export default ImageContainer;
